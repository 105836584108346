(function () {
    'use strict';

    angular
            .module('gestioneAttiApp')
            .controller('SettingsController', SettingsController);

    SettingsController.$inject = ['Principal', 'Auth', 'JhiLanguageService', '$translate', 'toastr', 'Account', '$http'];

    function SettingsController(Principal, Auth, JhiLanguageService, $translate, toastr, Account, $http) {
        var vm = this;

        vm.error = null;
        vm.save = save;
        vm.settingsAccount = null;
        vm.success = null;
        
        vm.protocolPassword = null;

        vm.isExternal = true;

        $http.get("/api/internal/check").then(onSuccess, onError)

        function onSuccess(){
            vm.isExternal = false;
        }

        function onError(error){
            if(error.status === 403){
                vm.isExternal = true;
            } else {
                vm.isExternal = false;
            }
        }

        /**
         * Store the "settings account" in a separate variable, and not in the shared "account" variable.
         */
        var copyAccount = function (account) {
            return {
                activated: account.activated,
                email: account.email,
                firstName: account.firstName,
                langKey: account.langKey,
                lastName: account.lastName,
                login: account.login,
                protocolLogin: account.protocolLogin,
                protocolPassword: account.protocolPassword
            };
        };

        Principal.identity().then(function (account) {
            vm.settingsAccount = copyAccount(account);
        });

        vm.testProtocol = function () {
            if (!vm.settingsAccount.protocolLogin || !vm.settingsAccount.protocolPassword) {
                toastr.error($translate.instant('settings.messages.error.protocolInfoNotComplete'), 'Errore!');
                return;
            }
            Account.testProtocolCredentials({
                protocolLogin: vm.settingsAccount.protocolLogin,
                protocolPassword: vm.settingsAccount.protocolPassword
            }, function (result) {
                if (result === true) {
                    toastr.success($translate.instant('settings.messages.protocolTestSuccess'), 'Ok!');
                } else {
                    toastr.error($translate.instant('settings.messages.error.protocolTestWentWrong'), 'Errore!');
                }
            }, function (error) {
                toastr.error(error, 'Errore!');
            });
        };

        function save() {
            //vm.settingsAccount.protocolPassword = vm.protocolPassword;
            if (!vm.settingsAccount.languageKey) {
                vm.settingsAccount.languageKey = 'it';
            }
            Auth.updateAccount(vm.settingsAccount).then(function () {
                vm.error = null;
                vm.success = 'OK';
                Principal.identity(true).then(function (account) {
                    vm.settingsAccount = copyAccount(account);
                });
                JhiLanguageService.getCurrent().then(function (current) {
                    if (vm.settingsAccount.langKey !== current) {
                        $translate.use(vm.settingsAccount.langKey);
                    }
                });
            }).catch(function () {
                vm.success = null;
                vm.error = 'ERROR';
            });
        }
    }
})();
