(function () {
    'use strict';

    angular
            .module('gestioneAttiApp')
            .controller('PermissionsDialogController', PermissionsDialogController);

            PermissionsDialogController.$inject = ['$mdDialog', '$state', 'Atto'];

    function PermissionsDialogController($mdDialog, $state, Atto) {
        var vm = this;
       
        vm.permission = vm.entity;       
        vm.clear = clear;
        vm.languages = null;
        vm.save = save;
       

        function clear() {
            $mdDialog.cancel();
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
            $mdDialog.cancel();
            $state.go('permissions', null, {reload: true});
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function save() {
            vm.isSaving = true;

            if (vm.permission.id !== null) {
                Atto.updateTipoAtto(vm.permission, onSaveSuccess, onSaveError);
            } else {
                Atto.saveTipoAtto(vm.permission, onSaveSuccess, onSaveError);
            }
        }

        
    }
})();
