(function () {
    'use strict';

    angular
            .module('gestioneAttiApp')
            .filter('findLanguageFromKey', findLanguageFromKey);

    angular
            .module('gestioneAttiApp')
            .filter('yesNo', function () {
                return function (input) {
                    return input ? 'Sì' : 'No';
                }
            });

    angular
            .module('gestioneAttiApp')
            .filter('protocolState', function () {
                return function (input) {
                    if (input === 'ARCHIVED') {
                        return 'Protocollato';
                    } else if (input === 'TO_BE_ARCHIVED') {
                        return 'Da Protocollare';
                    } else if (input === 'ARCHIVE_NOW') {
                        return 'Protocollazione in corso';
                    } else if (input === 'ALREADY_ARCHIVED') {
                        return 'Già protocollato';
                    } else if (input === 'ERROR') {
                        return 'Errore';
                    } else {
                        return 'Non Protocollato';
                    }
                }
            });

    angular
            .module('gestioneAttiApp')
            .filter('visibility', function () {
                return function (input) {
                    if (input === 'PUBLIC') {
                        return 'Pubblico';
                    } else if (input === 'INTRANET') {
                        return 'Intranet';
                    } else if (input === 'COMMISSION_MEMBER') {
                        return 'Membro commissione';
                    } else if (input === 'COMMISSION_PRESIDENT') {
                        return 'Presidente commissione';
                    } else {
                        return '';
                    }
                }
            });

    angular
            .module('gestioneAttiApp')
            .filter('protocolDirection', function () {
                return function (input) {
                    if (input === 'P') {
                        return 'Partenza';
                    } else if (input === 'A') {
                        return 'Arrivo';
                    } else {
                        return '';
                    }
                }
            });

    angular
            .module('gestioneAttiApp')
            .filter('romanize', function () {
                return function (num) {
                    if (!+num)
                        return false;
                    var digits = String(+num).split(""),
                            key = ["", "C", "CC", "CCC", "CD", "D", "DC", "DCC", "DCCC", "CM",
                                "", "X", "XX", "XXX", "XL", "L", "LX", "LXX", "LXXX", "XC",
                                "", "I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX"],
                            roman = "",
                            i = 3;
                    while (i--)
                        roman = (key[+digits.pop() + (i * 10)] || "") + roman;
                    return Array(+digits.join("") + 1).join("M") + roman;
                }
            });

    function findLanguageFromKey() {
        return findLanguageFromKeyFilter;

        function findLanguageFromKeyFilter(lang) {
            return {
                'ca': 'Català',
                'cs': 'Český',
                'da': 'Dansk',
                'de': 'Deutsch',
                'el': 'Ελληνικά',
                'en': 'English',
                'es': 'Español',
                'et': 'Eesti',
                'fr': 'Français',
                'gl': 'Galego',
                'hu': 'Magyar',
                'hi': 'हिंदी',
                'hy': 'Հայերեն',
                'it': 'Italiano',
                'ja': '日本語',
                'ko': '한국어',
                'mr': 'मराठी',
                'nl': 'Nederlands',
                'pl': 'Polski',
                'pt-br': 'Português (Brasil)',
                'pt-pt': 'Português',
                'ro': 'Română',
                'ru': 'Русский',
                'sk': 'Slovenský',
                'sr': 'Srpski',
                'sv': 'Svenska',
                'ta': 'தமிழ்',
                'th': 'ไทย',
                'tr': 'Türkçe',
                'vi': 'Tiếng Việt',
                'zh-cn': '中文（简体）',
                'zh-tw': '繁體中文'
            }[lang];
        }
    }
})();
