angular.module('gestioneAttiApp').directive('archiveTree', function () {
    return {
        restrict: "E",
        templateUrl: 'app/components/archiveTree/archiveTree.directive.html',
        scope: {
            attoId: '=',
            publicView: '=?'
        },
        transclude: false,
        controllerAs: 'vm',
        bindToController: true,
        controller: ['Atto', 'Documento', 'toastr', '$rootScope', '$mdSidenav', 'FileSaver', '$window', 'ArchiveTreeService',
            function (Atto, Documento, toastr, $rootScope, $mdSidenav, FileSaver, $window, ArchiveTreeService) {
                var vm = this;
                
                vm.loading = false;
                vm.loadingZip = false;

                vm.getArchiveTree = getArchiveTree;
                vm.downloadArchive = downloadArchive;
                vm.downloadFile = downloadFile;
                vm.getLink = ArchiveTreeService.getLink;

                vm.getArchiveTree();

                $rootScope.$watch('attoIdForArchiveTree', function (newVal, oldVal) {
                    vm.attoId = newVal;
                    getArchiveTree();
                }, true);

                function getArchiveTree() {
                    if (!vm.attoId) {
                        return;
                    }
                    vm.loading = true;
                    Atto.getArchiveTree(vm.attoId, vm.publicView).then(function (response) {
                        if (response && response.data) {
                            vm.archiveTree = [response.data];
                        }
                        vm.loading = false;
                    }, function (error) {
                        console.log('Impossibile scaricare il fascicolo');
                        toastr.error('Impossibile scaricare il fascicolo', error.data.message);
                        vm.loading = false;
                    });

                }
                
                function downloadArchive() {
                    toastr.warning("Attenzione: La generazione dell'archivio può impiegare del tempo per essere completata. Si prega di rimanere nella pagina e attendere l'esito.", '', {
                        timeOut: 10000
                    });
                    if (!vm.attoId)
                        return;
                    vm.loadingZip = true;
                    Atto.getArchive(vm.attoId, vm.publicView).then(function (response) {
                        if (response && response.data) {
                            FileSaver.saveAs(new Blob([response.data], {}), 'Fascicolo-' + vm.attoId + '.zip');
                        }
                        vm.loadingZip = false;
                    }, function (error) {
                        console.log('Impossibile scaricare il fascicolo');
                        toastr.error('Impossibile scaricare il fascicolo', error.data.message);
                        vm.loadingZip = false;
                    });

                }
                
                function downloadFile(id, name) {
                    if (!vm.attoId)
                        return;
                                        
                    vm.loading = true;
                    Documento.get({id:id}, {}, function(doc) {
                        Documento.getContent(id).then(function (response) {
                        if (response && response.data) {
                                var fileType = 'application/octet-stream';
                                if(doc.nome.toLowerCase().endsWith(".pdf") ){
                                    fileType = 	'application/pdf';
                                } else if((doc.nome.toLowerCase()).endsWith(".doc")) {
                                    fileType = 	'application/msword';
                                } else if((doc.nome.toLowerCase()).endsWith(".docx")){
                                    fileType = 	'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
                                } else if((doc.nome.toLowerCase()).endsWith(".xls")){
                                    fileType = 	'application/vnd.ms-excel';
                                } else if((doc.nome.toLowerCase()).endsWith(".xlsx")){
                                    fileType = 	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                                } else if((doc.nome.toLowerCase()).endsWith(".ppt")){
                                    fileType = 	'application/vnd.ms-powerpoint';
                                } else if((doc.nome.toLowerCase()).endsWith(".pptx")){
                                    fileType = 	'application/vnd.openxmlformats-officedocument.presentationml.presentation';
                                }  else if((doc.nome.toLowerCase()).endsWith(".png")){
                                    fileType = 	'image/png';
                                }  else if((doc.nome.toLowerCase()).endsWith(".jpeg")){
                                    fileType = 	'image/jpeg';
                                }  else if((doc.nome.toLowerCase()).endsWith(".gif")){
                                    fileType = 	'image/gif';
                                }
                                FileSaver.saveAs(new Blob([response.data], {type: fileType}), doc.nome);
                        }
                        
                        vm.loading = false;
                    }, function (error) {
                        console.log('Impossibile scaricare il fascicolo');
                        toastr.error('Impossibile scaricare il fascicolo', error.data.message);
                        vm.loading = false;
                    });
                    });
                    
                    
                }

                vm.routeOrDownloadFile = function(node) {
                    // Mobile download it
                    if(navigator.userAgent && /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent)) {
                        downloadFile(node.id, node.title);
                    } else {
                        //Desktop route to preview
                        $window.open('/#/document/' + node.id + '/content', '_blank');
                    }
                }
                
                vm.closeArchiveTree = function() {
                    $rootScope.attoIdForArchiveTree = null;
                    vm.attoIdForArchiveTree = null;
                    
                    if ($mdSidenav('right').isOpen()) {
                        $mdSidenav('right').close();
                    }
                };
            }]
    };

});
