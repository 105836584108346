(function () {
    'use strict';

    angular
            .module('gestioneAttiApp')
            .controller('AnagraficaProtocolloDialogController', AnagraficaProtocolloDialogController);

    AnagraficaProtocolloDialogController.$inject = ['$timeout', '$scope', 'toastr', '$mdDialog', 'entity', 'AnagraficaProtocollo', '$q', '$state'];

    function AnagraficaProtocolloDialogController($timeout, $scope, toastr, $mdDialog, entity, AnagraficaProtocollo, $q, $state) {
        var vm = this;

        vm.anagraficaProtocollo = vm.entity;
        vm.clear = clear;
        vm.save = save;

        vm.selectedItem = vm.anagraficaProtocollo.anagraficaProtocollo;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $mdDialog.cancel();
        }

        function save() {
            vm.isSaving = true;
            if (vm.anagraficaProtocollo.id !== null) {
                AnagraficaProtocollo.update(vm.anagraficaProtocollo, onSaveSuccess, onSaveError);
            } else {
                AnagraficaProtocollo.save(vm.anagraficaProtocollo, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('gestioneAttiApp:anagraficaProtocolloUpdate', result);
            vm.isSaving = false;
            $mdDialog.cancel();
            $state.go('anagrafica-protocollo', null, {reload: true});
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.setAnagrafic = function (item) {
            if (item) {
                vm.anagraficaProtocollo.anagraficaProtocollo = item;
                vm.anagraficaProtocollo.codice = item.anagraficCode;
                vm.anagraficaProtocollo.descrizione = item.anagraficDescription;
            }
        };

        vm.searchAnagrafics = function (text) {
            var deferred = $q.defer();
            AnagraficaProtocollo.listFromProtocol({'code': text}, function (data) {
                deferred.resolve(data);
                return deferred.promise;
            }, function (error) {
                toastr.error(error);
                deferred.reject(error);
            });
            return deferred.promise;
        };

        vm.getDisplayName = function (item) {
            return item.anagraficCode + ' - ' + item.anagraficDescription;
        };
    }
})();
