(function () {
    'use strict';

    var macroMateriaMateriaListComponent = {

        templateUrl: 'app/components/macro-materia-materia-list/macro-materia-materia-list.html',
        bindings: {
            // model will be populated with a list of pairs of <macroMateriaId, materiaId>
            'model' : '=',
            'disabled' : '=',
            'macroMateriaList': '=',
            'materiaList': '='
        },
        controller: ['toastr', 'Materia', 'MacroMateria', '$q', function (toastr, Materia, MacroMateria, $q) {
            var vm = this;
            vm.selectedMacroMateria = null;
            vm.selectedMateria = null;
            vm.macroMateriaIdToMateriaListMap = {};
            vm.availableMateriaList = [];
            // list of the row with macro materia and materia with the full object
            vm.translatedRowList = [];
            vm.translating = vm.model && vm.model.length > 0;
            
            translateFromIds();
            initMacroMateriaToMateriaListMap();

            function initMacroMateriaToMateriaListMap() {
                vm.materiaList.forEach(function(materia) {
                    if (vm.macroMateriaIdToMateriaListMap[materia.macroMateria.id]) {
                        vm.macroMateriaIdToMateriaListMap[materia.macroMateria.id].push(materia);
                    } else {
                        vm.macroMateriaIdToMateriaListMap[materia.macroMateria.id] = [materia];
                    }
                });
            }

            function translateFromIds() {
                if (!vm.model || vm.model.length === 0) {
                    vm.translating = false;
                    return;
                }

                var promises = [];
                var fetchedMacroMateria = [];
                var fetchedMateria = [];
                vm.model.forEach(function(pair) {
                    if (pair.materiaId) {
                        promises.push(Materia.get({id: pair.materiaId}, onSuccessMateria, onError).$promise);
                    } else {
                        promises.push(MacroMateria.get({id: pair.macroMateriaId}, onSuccessMacroMateria, onError).$promise);
                    }
                });

                if(promises.length > 0) {
                    $q.all(promises).then(function(result) {
                        vm.model.forEach(function(untraslatedRow){
                            if (untraslatedRow.materiaId) {
                                var materiaTranslated = fetchedMateria.find(function(m){
                                    return m.id === untraslatedRow.materiaId;
                                });
                                if (materiaTranslated) {
                                   vm.translatedRowList.push({macroMateria: materiaTranslated.macroMateria, materia: materiaTranslated}); 
                                }
                            } else {
                                var macroMateriaTranslated = fetchedMacroMateria.find(function(mm){
                                    return mm.id === untraslatedRow.macroMateriaId;
                                });
                                if (macroMateriaTranslated) {
                                   vm.translatedRowList.push({macroMateria: macroMateriaTranslated, materia: null}); 
                                }
                            }
                        });
                        vm.translating = false;
                    });
                }
                function onSuccessMateria(result) { 
                    fetchedMateria.push(result);
                }
                function onSuccessMacroMateria(result) { 
                    fetchedMacroMateria.push(result);
                }
                function onError(error) {
                    console.log('Error while retrieving materia or macromateria ', error);
                }
            }

            vm.macroMateriaChanged = function() {
                vm.selectedMateria = null;
                if (!vm.selectedMacroMateria) {
                    vm.availableMateriaList = [];
                    return;
                }
                vm.availableMateriaList = vm.macroMateriaIdToMateriaListMap[vm.selectedMacroMateria.id];
            }

            vm.addNewMacromateriaMateriaRow = function() {
                if (!vm.model) {
                    vm.model = [];
                }

                // Validate the new row
                var alreadyPresent = vm.translatedRowList.find(function(tr){
                    var sameMacroMateria = vm.selectedMacroMateria.id === tr.macroMateria.id;
                    var sameMateria = (vm.selectedMateria && tr.materia && vm.selectedMateria.id === tr.materia.id) || (!vm.selectedMateria && !tr.materia);
                    return sameMacroMateria && sameMateria; 
                });

                if (alreadyPresent) {
                    toastr.error('Coppia macro materia e materia già presente!');
                    return;
                }
                
                var newRow = {
                    macroMateria: vm.selectedMacroMateria,
                    materia: vm.selectedMateria
                };
                //Valid lets insert it
                vm.translatedRowList.push(newRow);
                vm.selectedMateria = null;
                updateModel();
            }

            vm.changeOrder = function($event, direction, idx) {
                var elem = vm.translatedRowList[idx];
                if(direction < 0 && idx > 0) {
                    vm.translatedRowList.splice(idx, 1);
                    vm.translatedRowList.splice(idx - 1, 0, elem);
                } else if(direction >= 0 && idx >= 0 && idx < vm.translatedRowList.length - 1) {
                    vm.translatedRowList.splice(idx, 1);
                    vm.translatedRowList.splice(idx + 1, 0, elem);
                }
                updateModel();
            }

            vm.onDeleteRow = function(idx) {
                vm.translatedRowList.splice(idx, 1);
                updateModel();
            }

            /**
             * Recreate the model from the list of translated rows
             */
            function updateModel() {
                var newModel = [];
                vm.translatedRowList.forEach(function(row, idx){
                    newModel.push({macroMateriaId: row.macroMateria.id, materiaId: row.materia ? row.materia.id : null, progressivo: idx + 1});
                });
                vm.model = newModel;
            }
        }],
        controllerAs: 'vm'
    };

    angular.module('gestioneAttiApp').component('macroMateriaMateriaListComponent', macroMateriaMateriaListComponent);
})();
