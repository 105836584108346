(function () {
    'use strict';

    angular
            .module('gestioneAttiApp')
            .controller('AnagraficaProtocolloController', AnagraficaProtocolloController);

    AnagraficaProtocolloController.$inject = ['$state', 'ParseLinks', 'paginationConstants', 'pagingParams', 'AnagraficaProtocollo', '$stateParams'];

    function AnagraficaProtocolloController($state, ParseLinks, paginationConstants, pagingParams, AnagraficaProtocollo, $stateParams) {

        var vm = this;
        vm.loadPage = loadPage;
        vm.transition = transition;
        vm.paging = pagingParams;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.protocolSystem = $stateParams.protocolSystem;
        vm.searchTerm = $stateParams.term;

        vm.onChangeProtocolSystem = onChangeProtocolSystem;

        vm.anagraficaProtocollos = [];

        loadAll();

        function loadAll() {
            if(!vm.protocolSystem) {
                vm.protocolSystem = 'INSIEL';
            }
            AnagraficaProtocollo.search({
                protocolSystem: vm.protocolSystem,
                term: vm.searchTerm,
                page: vm.paging.page - 1,
                size: vm.itemsPerPage
            }, onSuccess, onError);

        }

        vm.search = function () {
            vm.paging.page = 1;
            AnagraficaProtocollo.search({
                protocolSystem: vm.protocolSystem,
                term: vm.searchTerm,
                page: vm.paging.page - 1,
                size: vm.itemsPerPage
            }, onSuccess, onError);
        };

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                protocolSystem: vm.protocolSystem,
                term: vm.searchTerm,
                page: vm.page,
                size: vm.itemsPerPage
            });
        }

        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.anagraficaProtocollos = data;
            vm.page = vm.paging.page;
            vm.loading = false;
        }

        function onError(error) {
            console.log("Failure!", error);
            vm.loading = false;
        }

        function onChangeProtocolSystem(){
            $state.transitionTo($state.$current, {
                protocolSystem: vm.protocolSystem,
                term: vm.searchTerm,
                page: 1,
                size: vm.itemsPerPage
            }, {reload : true});
        }
    }
})();
