angular.module('gestioneAttiApp').directive('documentToolbar', function () {
    return {
        restrict: "E",
        templateUrl: 'app/components/documentToolbar/documentToolbar.directive.html',
        scope: {
            changeDocumentTypeCallback: '&?',
            changeDocumentVisibilityCallback: '&?',
            removeDocumentCallback: '&?',
            defaultLinks: '=?',
            documentTypes: '=?',
            editable: '=',
            includeLinks: '=?',
            includeNotes: '=?',
            publicView: '=?',
            section: '=',
            uploadedFileCallback: '&',
            user: '=',
            tipoAtto: '='
        },
        transclude: false,
        controllerAs: 'vm',
        bindToController: true,
        controller: ['Atto', 'Documento', 'AnagraficaProtocollo', 'toastr', 'FileSaver', '$timeout', '$uibModal', 'Principal', 'GESTIONE_ATTI_DOC_VISIBILITIES', 'MAX_UPLOAD_SIZE', '$mdDialog', 'User', '$anchorScroll','$state', 'PROTOCOLLAZIONE_DISABILITATA',
            function (Atto, Documento, AnagraficaProtocollo, toastr, FileSaver, $timeout, $uibModal, Principal, GESTIONE_ATTI_DOC_VISIBILITIES, MAX_UPLOAD_SIZE, $mdDialog, User, $anchorScroll, $state, PROTOCOLLAZIONE_DISABILITATA) {
                var vm = this;

                vm.includeNotes = vm.includeNotes || false;
                vm.includeLinks = vm.includeLinks || true;
                vm.defaultLinks = vm.defaultLinks || [];
                
                vm.instantProtocolEnabled = vm.user && vm.user.instantProtocolEnabled;

                initUserRoles();
                initDocVisibilities();
                initToolbarSectionVisibilities();

                vm.commissionPresidentOrMember = false;

                vm.uploadPanel = 'app/components/documentToolbar/documentToolbar.uploadPanel.html';
                vm.documentsPanel = 'app/components/documentToolbar/documentToolbar.documentsPanel.html';
                vm.documentTableBody = 'app/components/documentToolbar/documentToolbar.documentTableBody.html';

                vm.docTypes = vm.documentTypes || [];

                vm.files = [];
                vm.uploading = false;
                vm.uploadingGroup = false;
                vm.protocolGroups = [];
                vm.singleFilesCount = 0;
                vm.singleDocumentsCount = 0;

                vm.defaultVisibility = "INTRANET";

                vm.frontespieceAllowedExtensions = ['pdf'];

                vm.canChangeDocumentType = canChangeDocumentType;
                vm.canChangeDocumentVisibility = canChangeDocumentVisibility;
                vm.changeDocumentType = changeDocumentType;
                vm.changeDocumentVisibility = changeDocumentVisibility;
                vm.closeDocumentToolbarPanels = closeDocumentToolbarPanels;
                vm.deleteDocument = deleteDocument;
                vm.downloadDocument = downloadDocument;
                vm.downloadGroupDocuments = downloadGroupDocuments;
                vm.dragOverClass = dragOverClass;
                vm.enableSectionNotes = enableSectionNotes;
                vm.isDeletable = isDeletable;
                vm.isLinkPresent = isLinkPresent;
                vm.isRealGroup = isRealGroup;
                vm.isSingleFileGroup = isSingleFileGroup;
                vm.loadDocuments = loadDocuments;
                vm.onFileSelect = onFileSelect;
                vm.openProtocolDataDialog = openProtocolDataDialog;
                vm.openSenderReceiverDialog = openSenderReceiverDialog;
                vm.removeGroupUpload = removeGroupUpload;
                vm.removeSingleUpload = removeSingleUpload;
                vm.resetUploads = resetUploads;
                vm.retryProcolAction = retryProcolAction;
                vm.setDefaultVisibility = setDefaultVisibility;
                vm.setPrimaryDoc = setPrimaryDoc;
                vm.setProtocolStateForGroup = setProtocolStateForGroup;
                vm.setProtocolStateForSingle = setProtocolStateForSingle;
                vm.showLinksToggler = showLinksToggler;
                vm.uploadFiles = uploadFiles;

                vm.loadDocuments(false);

                vm.setProtocolStateForAllFilesOfGroup = function(group) {
                    angular.forEach(group.documents, function (file) {
                        file.protocolState = group.protocolState;
                    });
                }

                vm.setProtocolSystemForAllFilesOfGroup = function(group) {
                    angular.forEach(group.documents, function (file) {
                        file.protocolSystem = group.protocolSystem;
                    });
                }

                vm.protocolOptions = [
                    {
                        'value': 'NO_ARCHIVE',
                        'textKey': 'documentToolbar.noArchive'
                    },
                    {
                        'value': 'ALREADY_ARCHIVED',
                        'textKey': 'documentToolbar.alreadyArchived'
                    }
                ];

                if (!PROTOCOLLAZIONE_DISABILITATA && vm.editable && vm.section.nomeSezione !== "POST_COURT"
                        && vm.section.nomeSezione !== "CONSTITUTIONAL_APPEAL") {
                    vm.protocolOptions.push({
                        'value': 'TO_BE_ARCHIVED',
                        'textKey': 'documentToolbar.toBeArchived'
                    });
                }

                if (!PROTOCOLLAZIONE_DISABILITATA && vm.instantProtocolEnabled) {
                    vm.protocolOptions.push({
                        'value': 'ARCHIVE_NOW',
                        'textKey': 'documentToolbar.toBeImmediatelyArchived'
                    });
                }

                // by Anno too
                vm.orderByNumeroProtocolloConsiglio = function(v1, v2){
                    if(!v2) {
                        return 1;
                    }
                    var doc1 = v1.value.documents[0];
                    var doc2 = v2.value.documents[0];
                    if(!doc1 || !doc1.datiProtocollo || !doc1.datiProtocollo.numeroProtocolloConsiglio) {
                        return -1;
                    } else if(!doc2 || !doc2.datiProtocollo || !doc2.datiProtocollo.numeroProtocolloConsiglio) {
                        return 1;
                    } else {
                        var dataProtCons1 = new Date(doc1.datiProtocollo.dataProtocolloConsiglio);
                        var dataProtCons2 = new Date(doc2.datiProtocollo.dataProtocolloConsiglio);
                        // Protocolli di anno differente possono avere un numero uguale o inferiori, quindi prima si ordina per anno
                        if (dataProtCons1 && dataProtCons2 && dataProtCons1.getFullYear() !== dataProtCons2.getFullYear()) {
                            if (dataProtCons1.getFullYear() < dataProtCons2.getFullYear()) {
                                return 1;    
                            } else {
                                return -1;
                            }
                        }
                        if(doc1.datiProtocollo.numeroProtocolloConsiglio <= doc2.datiProtocollo.numeroProtocolloConsiglio){
                            return 1;
                        } else {
                            return -1;
                        }
                    }
                }

                vm.toggleUpload = function () {
                    if (vm.showUpload) {
                        vm.showUpload = false;
                        vm.showCloseAll = false;
                    } else {
                        vm.showUpload = true;
                        vm.showCloseAll = true;
                        vm.showDocuments = false;
                        vm.showNotes = false;
                        vm.showLinks = false;
                    }
                };

                vm.toggleDocuments = function () {
                    if (vm.showDocuments) {
                        vm.showDocuments = false;
                        vm.showCloseAll = false;
                    } else {
                        vm.showDocuments = true;
                        vm.showCloseAll = true;
                        vm.showUpload = false;
                        vm.showNotes = false;
                        vm.showLinks = false;
                    }
                };

                vm.section.showDocuments = function(){
                    vm.showDocuments = true;
                    vm.showCloseAll = true;
                    vm.showUpload = false;
                    vm.showNotes = false;
                    vm.showLinks = false;
                };

                vm.toggleNotes = function () {
                    if (vm.showNotes) {
                        vm.showNotes = false;
                    } else {
                        vm.showNotes = true;
                        vm.showDocuments = false;
                        vm.showCloseAll = false;
                        vm.showUpload = false;
                        vm.showLinks = false;
                    }
                };

                vm.toggleLinks = function () {
                    if (vm.showLinks) {
                        vm.showLinks = false;
                    } else {
                        vm.showLinks = true;
                        vm.showNotes = false;
                        vm.showDocuments = false;
                        vm.showCloseAll = false;
                        vm.showUpload = false;
                    }
                };

                vm.cancelUpload = function () {
                    vm.resetUploads();
                    vm.uploading = false;
                    vm.showUpload = false;
                    vm.protocolGroups = [];
                    vm.singleFilesCount = 0;
                    //vm.singleDocumentsCount = 0;
                };

                function openProtocolDataDialog(group) {
                    var doc = group.documents[0];
                    doc.protocoldDataOpen = true;
                    $mdDialog.show({
                        templateUrl: 'app/components/documentToolbar/protocol-data-dialog-editable.html',
                        controller: ['$scope', '$mdDialog', 'Atto', 'entity', 'protocolState', 'editable', 'idSezione', 'idAtto', 'documents', 'documento', 'group', 'tipoAtto', 'anagrafiche',
                            function ($scope, $mdDialog, Atto, entity, protocolState, editable, idSezione, idAtto, documents, documento, group, tipoAtto, anagrafiche) {
                                var vm = this;
                                vm.protocolData = entity || {};
                                vm.protocolState = protocolState;
                                vm.editable = editable;
                                vm.documents = documents;
                                vm.documento = documento;
                                vm.anagrafiche = [];//anagrafiche;
                                vm.selectedSenderSearchText = '';
                                vm.selectedReceiverSearchText = '';
                                vm.group = group;
                                Atto.getFilingCode({'tipoAtto': tipoAtto, 'protocolSystem': doc.protocolSystem}, {}, function (value) {
                                    vm.protocolData.classificazioneArchivistica = value.data;
                                }, function (reason) {
                                    toastr.error('Impossibile Recuperare il Codice Classificazione ', 'Errore!');
                                });

                                vm.dataProtocolloMittenteAusiliare;

                                vm.dataProtocolloMittenteAusiliareChanged = function() {
                                    vm.protocolData.dataProtocolloMittente = vm.dataProtocolloMittenteAusiliare;
                                }
                                
                                vm.isDataProtocolloMittentiWrong = function(){
                                    if(!vm.protocolData || !vm.protocolData.dataProtocolloMittente){
                                        return true;
                                    }
                                    var data = new Date(vm.protocolData.dataProtocolloMittente);
                                    if(data.getFullYear() == 1000) {
                                        return true;
                                    }
                                    return false;
                                }

                                vm.clear = function () {
                                    $mdDialog.cancel();
                                };

                                vm.checkProtocolState = function (protocolState) {
                                    return protocolState === 'ALREADY_ARCHIVED';
                                };

                                vm.saveProtocolData = function () {
                                    // Non è possibile salvare i dettagli se è presente solo uno tra "dataProtocolloConsiglio" e "numeroProtocolloConsiglio"
                                    if(vm.protocolData.dataProtocolloConsiglio && !vm.protocolData.numeroProtocolloConsiglio 
                                        || !vm.protocolData.dataProtocolloConsiglio && vm.protocolData.numeroProtocolloConsiglio) {
                                            toastr.error('Inserire sia il numero di protocollo che la data di protocollo', 'Errore!');
                                            return;
                                    }
                                    if(vm.protocolData && vm.protocolData.dataProtocolloConsiglio && vm.protocolData.dataProtocolloConsiglio instanceof Date) {
                                        // Quando la data è impostata dal datepicker è di tipo Date
                                        //Converte la data in UTC altrimenti da BE viene fatto un parsing errato
                                        newDataProtCons = new Date(Date.UTC(vm.protocolData.dataProtocolloConsiglio.getFullYear(), 
                                        vm.protocolData.dataProtocolloConsiglio.getMonth(),
                                        vm.protocolData.dataProtocolloConsiglio.getDate()))
                                        vm.protocolData.dataProtocolloConsiglio = newDataProtCons;
                                    } // Altrimenti La data è in formato stringa "dd-MM-yyyy" quindi non è stata modificata
                                    angular.forEach(vm.documents, function (doc) {
                                        doc.datiProtocollo = vm.protocolData;
                                    });
                                    Atto.updateFileProtocolData(idAtto, idSezione, documents).then(
                                            function (response) {
                                                toastr.success('Dati di protocollazione salvati', 'Successo!');
                                            },
                                            function (response) {
                                                toastr.error('Impossibile salvare i dati di protocollazione', 'Errore!');
                                            });
                                    vm.clear();
                                };

                                vm.addPersonToList = function (chip) {
                                    // If it is an object, it's already a known chip
                                    if (angular.isObject(chip)) {
                                        return chip;
                                    }
                                    // Otherwise, create a new one
                                    return {descrizione: chip};
                                };


                                // vm.searchFirmatari = function (searchText) {
                                //     var lista = vm.anagrafiche;
                                //     return searchText ? lista.filter(createFilterFor(searchText)) : [];
                                // };

                                vm.searchFirmatari = function (searchText) {

                                    if(searchText.length < 3) {
                                        return [];
                                    }
                        
                                    return AnagraficaProtocollo.search(
                                        { 
                                            term: searchText,
                                            protocolSystem: vm.documento.protocolSystem,
                                            page: 0,
                                            size: 20
                                        }).$promise.then(onSuccessAnag, onErrorAnag);
                        
                                };
                        
                                function onSuccessAnag(data, headers) {
                                    vm.anagrafiche = data;
                                    return data;
                                }
                        
                                function onErrorAnag(error) {
                                    console.log("Errore nel recupero anagrafiche!", error);
                                }
                        

                                function createFilterFor(query) {
                                    var lowercaseQuery = angular.lowercase(query);

                                    return function filterFn(firmatario) {
                                        var codice = firmatario.codice ? firmatario.codice.toLowerCase() : '';
                                        var descrizione = firmatario.descrizione ? firmatario.descrizione.toLowerCase() : '';
                                        return (codice.indexOf(lowercaseQuery) >= 0 || descrizione.indexOf(lowercaseQuery) >= 0);
                                    };
                                }
                            }],
                        controllerAs: 'vm',
                        size: 'lg',
                        locals: {
                            entity: doc.datiProtocollo || doc.protocolData,
                            protocolState: doc.statoProtocollo || doc.protocolState,
                            editable: vm.isAdmin || vm.isProtocollo || vm.editable,
                            idSezione: vm.section.id,
                            idAtto: vm.section.idAtto,
                            documents: group.documents,
                            documento: doc,
                            group: group,
                            tipoAtto: vm.tipoAtto,
                            anagrafiche: AnagraficaProtocollo.query({protocolSystem: doc.protocolSystem}).$promise,
                        }
                    });
                }

                function openSenderReceiverDialog(group, docIdx) {
                    var currDoc = group.documents[docIdx];
                    var currGroup = group;
                    $mdDialog.show({
                        templateUrl: 'app/components/documentToolbar/protocol-data-sender-receiver-dialog.html',
                        controller: 'SenderReceiverDialogController',
                        //size: 'lg',
                        fullscreen: true,
                        controllerAs: 'vm',
                        locals: {
                            $mdDialog: $mdDialog,
                            anagrafiche: AnagraficaProtocollo.query({}).$promise,
                            uffici: AnagraficaProtocollo.query({}).$promise,//AnagraficaProtocollo.listOffices({}).$promise,
                            document: currDoc,
                            group: currGroup
                        }
                    });
                }

                function calculateSingleDocGroups(groupedDocs) {
                    var single = 0;
                    angular.forEach(groupedDocs, function (group) {
                        if (group.documents.length === 1) {
                            single++;
                        }
                    });
                    vm.singleDocumentsCount = single;
                }

                function updateArchiveNowProtocolState(groupedDocs) {
                    var archiveNowCounts = 0;
                    angular.forEach(groupedDocs, function (group) {
                        angular.forEach(group.documents, function (document) {
                            if (document.protocolState === 'ARCHIVE_NOW') {
                                archiveNowCounts++;
                                Documento.get({id: document.id}, function (result) {
                                    if (result.statoProtocollo === 'ARCHIVED' || result.statoProtocollo === 'ERROR') {
                                        document.protocolData = result.datiProtocollo;
                                        document.protocolState = result.statoProtocollo;

                                        document.datiProtocollo = result.datiProtocollo;

                                        group.protocolState = result.statoProtocollo;
                                        archiveNowCounts--;
                                    }
                                });
                            }
                        });
                    });

                    if (archiveNowCounts > 0) {
                        $timeout(function () {
                            updateArchiveNowProtocolState(groupedDocs)
                        }, 5000);
                    }
                }

                function validate() {
                    for (var j = 0; j < vm.protocolGroups.length; j++) {
                        var primaryFound = false;
                        for (var i = 0; i < vm.protocolGroups[j].documents.length; i++) {
                            var file = vm.protocolGroups[j].documents[i];
                            primaryFound = primaryFound || file.primary;
                            if (!file.docType || !file.protocolState || !file.visibility) {
                                return false;
                            } else if ((file.protocolState === 'ARCHIVE_NOW' || file.protocolState === 'TO_BE_ARCHIVED')
                                    && (!vm.protocolGroups[j].mittenti || vm.protocolGroups[j].mittenti.length <= 0
                                            || !vm.protocolGroups[j].destinatari || vm.protocolGroups[j].destinatari.length <= 0)) {
                                return false;
                            } else if((file.protocolState === 'ARCHIVE_NOW' || file.protocolState === 'TO_BE_ARCHIVED') && !vm.protocolGroups[j].operatingOfficeSelected) {
                                return false;
                            }
                        }
                        if (!primaryFound) {
                            return false;
                        }
                    }
                    return true;
                }

                function loadDocuments(pollForProtocolUpdates) {
                    vm.existingDocs = [];
                    vm.groupedDocs = {};
                    if (vm.section) {
                        Atto.sezioniFilesGrouped({id: vm.section.idAtto, idSezione: vm.section.id, publicView: vm.publicView}, function (result) {
                            vm.groupedDocs = result.data;
                            calculateSingleDocGroups(vm.groupedDocs);
                            if (pollForProtocolUpdates) {
                                updateArchiveNowProtocolState(vm.groupedDocs);
                            }
                        }, function () {
                            toastr.error('Impossibile ottenere la lista dei documenti caricati', 'Errore!');
                        });
                    }
                }

                function closeDocumentToolbarPanels() {
                    vm.showUpload = false;
                    vm.showDocuments = false;
                    vm.showCloseAll = false;
                }

                function onFileSelect($files) {
                    if($files.length === 0) {
                        return;
                    }
                    var tooBigFiles = [];

                    if (vm.uploadingGroup === true) {
                        var lastIterator = getAlreadyUploadedGreaterGroupNumber(vm.groupedDocs) + vm.protocolGroups.length;
                        // var lastIterator = vm.groupedDocs.length + vm.protocolGroups.length;
                        var newGroupUpload = {
                            documents: [],
                            name: lastIterator + 1,
                            protocolState: null,
                            isGroup: true
                        };

                        angular.forEach($files, function (file) {
                            if (file.size > MAX_UPLOAD_SIZE) {
                                tooBigFiles.push(file.name);
                            } else {
                                file.frontpieceDisabled = isFrontpieceDisabled(file);

                                newGroupUpload.documents.push({
                                    name: file.name,
                                    type: file.type,
                                    size: file.size,
                                    visibilityStates: vm.visibilityStates,
                                    protocolState: null,
                                    protocolGroup: newGroupUpload.name,
                                    protocolOptions: vm.protocolOptions,
                                    docType: null,
                                    frontpiece: false,
                                    notArchive: true,
                                    frontpieceDisabled: file.frontpieceDisabled,
                                    progress: 0,
                                    file: file,
                                    primary: file.primary
                                });
                            }
                        });
                        vm.protocolGroups.push(newGroupUpload);
                    } else {
                        angular.forEach($files, function (file) {
                            var lastIterator = getAlreadyUploadedGreaterGroupNumber(vm.groupedDocs) + vm.protocolGroups.length;
                        // var lastIterator = vm.groupedDocs.length + vm.protocolGroups.length;
                            if (file.size > MAX_UPLOAD_SIZE) {
                                tooBigFiles.push(file.name);
                            } else {
                                var newSingleUpload = {
                                    documents: [],
                                    name: lastIterator + 1,
                                    protocolState: null,
                                    isGroup: false,
                                };

                                file.frontpieceDisabled = isFrontpieceDisabled(file);

                                newSingleUpload.documents.push({
                                    name: file.name,
                                    type: file.type,
                                    size: file.size,
                                    protocolGroup: newSingleUpload.name,
                                    protocolState: null,
                                    protocolOptions: vm.protocolOptions,
                                    visibilityStates: vm.visibilityStates,
                                    docType: null,
                                    frontpiece: false,
                                    notArchive: true,
                                    frontpieceDisabled: file.frontpieceDisabled,
                                    progress: 0,
                                    file: file,
                                    primary: true
                                });
                                vm.protocolGroups.push(newSingleUpload);
                                vm.singleFilesCount++;
                            }
                        });
                    }

                    displayTooBigFilesWarning(tooBigFiles);
                    vm.uploadingGroup = false;
                    $files = [];
                }

                function getAlreadyUploadedGreaterGroupNumber(groupList) {
                    var maxValue = 0;
                    angular.forEach(groupList, function(group) {
                        if(group.name > maxValue) {
                            maxValue = group.name;
                        }        
                    });
                    return maxValue;
                }

                function changeDocumentType(document) {
                    if (!vm.docTypesById) {
                        initDocTypesById();
                    }
                    var newDocumentType = vm.docTypesById[document.tipoDocumento.id];
                    vm.changeDocumentTypeCallback({document: document, newDocumentType: newDocumentType});
                }

                function changeDocumentVisibility(document) {
                    vm.changeDocumentVisibilityCallback({document: document, newDocumentVisibility: document.visibilita});
                }

                function uploadFiles() {
                    var valid = validate();
                    if (!valid) {
                        toastr.error('Per ogni file o gruppo che carichi devi specificare:<ul><li>Tipo Documento</li><li>Tipo Protocollazione</li><li>Visibilità</li><li>Mittenti di protocollo</li><li>Destinatari di protocollo</li><li>Ufficio operante</li></ul>Se stai caricando un gruppo di protocollazione, allora devi specificare anche il documento primario.', {
                            timeOut: 60000,
                            extendedTimeOut: 0
                        });
                        return;
                    }

                    vm.uploading = true;

                    //files: an array of files selected, each file has name, size, and type.
                    vm.resetUploads();
                    var totalFiles = 0;
                    var uploadCounter = 0;
                    for (var j = 0; j < vm.protocolGroups.length; j++) {
                        mittentiId = [];
                        destinatariId = [];
                        if (vm.protocolGroups[j].documents.length > 0) {
                            angular.forEach(vm.protocolGroups[j].mittenti, function (mittente) {
                                mittentiId.push(mittente.id);
                            });

                            angular.forEach(vm.protocolGroups[j].destinatari, function (destinatario) {
                                destinatariId.push(destinatario.id);
                            });
                        }
                        setProtocolStateForGroup(vm.protocolGroups[j]);
                        for (var i = 0; i < vm.protocolGroups[j].documents.length; i++) {
                            var file = vm.protocolGroups[j].documents[i];
                            totalFiles++;
                            var params = {
                                protocolSystem: file.protocolSystem,
                                idTipoDocumento: file.docType.id,
                                visibilita: file.visibility,
                                frontespizio: file.frontpiece,
                                gruppoProtocollo: file.protocolGroup,
                                statoProtocollo: file.protocolState,
                                primario: file.primary,
                                operatingOffice: vm.protocolGroups[j].operatingOfficeSelected                                
                            };

                            function updateProgress(fileRef) {
                                return function (evt) {
                                    fileRef.progress = parseInt(100.0 * evt.loaded / evt.total);
                                    fileRef.progress = 'info';
                                };
                            }

                            function uploadOutcomeHandler(fileRef, success, iterator) {
                                return function (status) {
                                    fileRef.progress = success ? 100 : fileRef.progress;
                                    fileRef.progressClass = success ? 'success' : 'danger';
                                    uploadCounter++;

                                    if (success) {
                                        if (vm.uploadedFileCallback) {
                                            vm.uploadedFileCallback({documento: fileRef});
                                        }
                                    } else {
                                        var message = 'Impossibile caricare il file: ' + fileRef.name + ' Attenzione a non caricare file con lo stesso nome!';
                                        if (status && status.message) {
                                            message += ' ERRORE: ' + status.message;
                                        }
                                        console.error(message);
                                        toastr.error(message, 'Errore!');
                                    }

                                    if (uploadCounter === totalFiles) {
                                        Atto.protocolNow({id: vm.section.idAtto, idSezione: vm.section.id}, function (result) {
                                        }, function () {
                                            toastr.error('Impossibile completare la protocollazione', 'Errore!');
                                        });
                                        vm.loadDocuments(true);
                                        vm.uploads = [];

                                        if (success) {
                                            var p1 = $timeout(vm.cancelUpload, 2000);
                                            p1.then(function(){
                                                return $timeout(vm.toggleDocuments);
                                            });
                                        }
                                    }
                                }

                            }
                            //params.mittenti = params.mittenti || [];
                            //params.destinatari = params.mittenti || [];
                            //if(file.primary) {
                            params.mittenti = JSON.stringify(mittentiId);
                            params.destinatari = JSON.stringify(destinatariId);
                            //}
                            var currUpload = Atto.uploadFileSezione(vm.section.idAtto, vm.section.id, file.file, params)
                                    .progress(updateProgress(file))
                                    .success(uploadOutcomeHandler(file, true, j))
                                    .error(uploadOutcomeHandler(file, false));

                            vm.uploads.push(currUpload);
                        }
                    }
                }

                function dragOverClass($event) {
                    var items = $event.dataTransfer.items;
                    var hasFile = false;
                    if (items !== null) {
                        for (var i = 0; i < items.length; i++) {
                            if (items[i].kind === 'file') {
                                hasFile = true;
                                break;
                            }
                        }
                    } else {
                        hasFile = true;
                    }
                    return hasFile ? "dragover" : "dragover-err";
                }

                function resetUploads() {
                    if (vm.uploads) {
                        angular.forEach(vm.uploads, function (upload) {
                            upload.abort();
                        });
                    }
                    vm.uploads = [];
                }

                function downloadDocument(docId, docName) {
                    Documento.getContent(docId).then(function (result) {
                        if (result) {
                            FileSaver.saveAs(new Blob([result.data], {}), docName);
                        }
                    }, function () {
                        toastr.error('Impossibile scaricare il documento', 'Errore!');
                    });
                }

                function downloadGroupDocuments(group) {
                    Documento.getGroupArchive(vm.section.id, group, publicView).then(function (result) {
                        if (result) {
                            FileSaver.saveAs(new Blob([result.data], {}), 'Sezione-' + vm.section.id + '_' + group + '.zip');
                        }
                    }, function () {
                        toastr.error('Impossibile scaricare i documenti', 'Errore!');
                    });
                }

                function removeSingleUpload(group, docIdx) {
                    angular.forEach(group.documents, function (document, iterator) {
                        var currDoc = document;
                        if (currDoc.id === docIdx) {
                            if (vm.removeDocumentCallback) {
                                vm.removeDocumentCallback({documento: currDoc});
                            }
                        }
                    });
                    group.documents.splice(docIdx, 1);
                    if (!group.isGroup) {
                        vm.singleFilesCount--;
                    } else if (group.documents && group.documents.length === 1) {
                        vm.singleFilesCount++;
                    }
                }

                function removeGroupUpload(group) {
                    for (var idx = 0; idx < vm.protocolGroups.length; idx++) {
                        if (vm.protocolGroups[idx].name === group.name) {
                            break;
                        }
                    }
                    vm.protocolGroups.splice(idx, 1);
                }

                function deleteDocument(docId) {
                    $uibModal.open({
                        template: '<div class="modal-header">\
                            <h4 class="modal-title" ng-bind="title"></h4>\
                        </div>\
                        <div class="modal-body" ng-bind="::modalVm.deleteConfirmMsg"></div>\
                        <div class="modal-footer">\
                            <button class="btn btn-default" ng-click="modalVm.deleteDoc()" ng-bind="::modalVm.confirmBtnText"></button>\
                            <button class="btn btn-primary" ng-click="modalVm.cancel()" ng-bind="::modalVm.cancelBtnText"></button>\
                        </div>',
                        controller: ['$uibModalInstance', '$translate', function ($uibModalInstance, $translate) {
                                var modalVm = this;
                                modalVm.deleteConfirmMsg = $translate.instant('documentToolbar.deleteConfirmMsg');
                                modalVm.confirmBtnText = $translate.instant('entity.action.delete');
                                modalVm.cancelBtnText = $translate.instant('entity.action.cancel');

                                modalVm.cancel = function () {
                                    $uibModalInstance.dismiss('cancel');
                                };

                                modalVm.deleteDoc = function () {
                                    Documento.delete({id: docId}, {}, function () {
                                        for (var i = 0; i < vm.groupedDocs.length; i++) {
                                            var currentGroup = vm.groupedDocs[i];
                                            var isSingleDoc = currentGroup.documents.length === 1;
                                            angular.forEach(vm.groupedDocs[i].documents, function (document, iterator) {
                                                var currDoc = document;
                                                if (currDoc.id === docId) {
                                                    if (vm.removeDocumentCallback) {
                                                        vm.removeDocumentCallback({documento: currDoc});
                                                    }
                                                    currentGroup.documents.splice(iterator, 1);
                                                    if (currentGroup.documents.length === 0) {
                                                        if (isSingleDoc) {
                                                            vm.singleDocumentsCount--;
                                                        }
                                                        vm.groupedDocs.splice(i, 1);
                                                    } else if (currentGroup.documents.length === 1) {
                                                        vm.singleDocumentsCount++;
                                                    }
                                                }
                                            });
                                        }
                                        toastr.success('Eliminazione documento completata');
                                        $uibModalInstance.close();
                                    }, function (error) {
                                        console.error(error);
                                        toastr.error('Impossibile eliminare il documento', 'Errore!');
                                    });
                                };
                            }],
                        controllerAs: 'modalVm',
                        backdrop: 'static',
                        size: 'lg',
                    });
                }

                function enableSectionNotes() {
                    return vm.includeNotes
                            && vm.isAuthenticated
                            && (vm.editable || (vm.section.metadati.sectionNotes && vm.section.metadati.sectionNotes.toString().trim().length));
                }

                function setDefaultVisibility(document) {
                    if (document.docType && document.docType.defaultVisibility) {
                        document.visibility = document.docType.defaultVisibility;
                    } else {
                        document.visibility = vm.defaultVisibility;
                    }
                }

                function setPrimaryDoc(documents, primaryDocIdx) {
                    var i = 0;
                    angular.forEach(documents, function (document) {
                        if (i === primaryDocIdx) {
                            document.primary = true;
                        } else {
                            document.primary = false;
                        }
                        i++;
                    });
                }

                function retryProcolAction(group) {
                    group.protocolState = 'ARCHIVE_NOW';
                    angular.forEach(group.documents, function (document) {
                        document.protocolState = 'ARCHIVE_NOW';
                    });
                    Documento.retryProtocol({idSezione: vm.section.id, group: group.name}, {}, function (result) {
                        if (angular.isArray(group)) {
                            updateArchiveNowProtocolState(group);
                        } else {
                            updateArchiveNowProtocolState([group]);
                        }
                    });
                }

                function canChangeDocumentType(document) {
                    if ($state.current.name !== 'atto-edit') {
                        return false;
                    }
                    if (!vm.isProtocollo) {
                        return false;
                    }

                    if (document.owner.id === 'SYSTEM') {
                        return true;
                    }

                    if (document.owner.authorities) {
                        for (var i = 0; i < document.owner.authorities.length; i++) {
                            var auth = document.owner.authorities[i];
                            if (auth.name === 'ROLE_PROTOCOLLO') {
                                return true;
                            }
                        }
                    }

                    return false;
                }

                function canChangeDocumentVisibility(document) {
                    if ($state.current.name !== 'atto-edit') {
                        return false;
                    }
                    if (vm.isAdmin) { //non do la possibilità all'admin in quanto non ha le visibilità membro e presidente commissione
                        return false;
                    }

                    var authorities = [];
                    angular.forEach(document.owner.authorities, function (auth) {
                        if (auth.name !== 'ROLE_USER') {
                            authorities.push(auth.name);
                        }
                    });

                    if(document.owner.id === 'SYSTEM' && vm.isProtocollo) {
                        return true;
                    }

                    if (!vm.editable || document.owner.id === 'SYSTEM' || !Principal.hasAnyAuthority(authorities)) {
                        return false;
                    }

                    if (document.owner.id === vm.user.id) {
                        return true;
                    }

                    if (vm.isCommissione) {
                        return User.hasAnyCommission(vm.user, document.owner.commissioni) && User.isCommissionRole(vm.user, 'Dipendente');
                    } else if (vm.isSettore) {
                        return User.hasAnySector(vm.user, document.owner.settori);
                    } else if (vm.isExternal) {
                        return User.hasAnyExternalSubject(vm.user, document.owner.soggettiEsterni);
                    } else {
                        return true;
                    }
                }

                function showLinksToggler() {
                    if (!vm.includeLinks) {
                        return false;
                    } else if (vm.editable) {
                        return true;
                    }

                    if (!vm.section.metadati || !vm.section.metadati.links) {
                        return false;
                    }

                    for (var i = 0; i < vm.section.metadati.links.length; i++) {
                        var link = vm.section.metadati.links[i];
                        if (link.url && link.description) {
                            return true;
                        }
                    }

                    return false;
                }

                function displayTooBigFilesWarning(docs) {
                    if (!docs || docs.length === 0) {
                        return;
                    }
                    var msg = 'I seguenti file sono troppo grandi per essere caricati:';
                    angular.forEach(docs, function (doc) {
                        msg += '<br>&bullet;&nbsp;' + doc;
                    });
                    toastr.error('Attenzione', msg);
                }

                function initUserRoles() {
                    vm.isAuthenticated = Principal.isAuthenticated();
                    if (vm.isAuthenticated) {
                        vm.isAdmin = Principal.hasAnyAuthority(['ROLE_ADMIN']);
                        vm.isProtocollo = Principal.hasAnyAuthority(['ROLE_PROTOCOLLO']);
                        vm.isCommissione = Principal.hasAnyAuthority(['ROLE_COMMISSIONE']);
                        vm.isSettore = Principal.hasAnyAuthority(['ROLE_SETTORE']);
                        vm.isExternal = Principal.hasAnyAuthority(['ROLE_EXTERNAL']);
                    }
                }

                function initDocTypesById() {
                    vm.docTypesById = {};
                    angular.forEach(vm.docTypes, function (docType) {
                        vm.docTypesById[docType.id] = docType;
                    });
                }

                function initDocVisibilities() {
                    vm.visibilityStates = [];
                    if (!vm.isCommissione) {
                        angular.forEach(GESTIONE_ATTI_DOC_VISIBILITIES, function (docVisibility) {
                            if (!docVisibility.onlyForCommission) {
                                vm.visibilityStates.push(docVisibility);
                            }
                        });
                    } else {
                        vm.visibilityStates = GESTIONE_ATTI_DOC_VISIBILITIES;
                    }
                }

                function initToolbarSectionVisibilities() {
                    vm.showCloseAll = false;
                    vm.showDocuments = false;
                    vm.showUpload = false;
                    vm.showNotes = false;
                    vm.showLinks = isLinkPresent(vm.section.metadati.links);
                }

                function isLinkPresent(linksObj) {
                    for (var i = 0; i < linksObj.length; i++) {
                        var url = linksObj[i].url;
                        if (url && url.length)
                            return true;
                    }
                    return false;
                }

                function setProtocolStateForSingle(file) {
                    if (file.protocolState === 'NO_ARCHIVE') {
                        file.frontpiece = false;
                        file.notArchive = true;
                        file.protocolGroup = 0;
                    } else {
                        file.notArchive = false;
                        file.frontpiece = !isFrontpieceDisabled(file);
                    }
                }

                function setProtocolStateForGroup(group) {
                    angular.forEach(group.documents, function (file) {
                        file.protocolState = group.protocolState ? group.protocolState : file.protocolState;
                        if (file.protocolState === 'NO_ARCHIVE') {
                            file.frontpiece = false;
                            file.notArchive = true;
                            file.protocolGroup = 0;
                        } else {
                            file.protocolGroup = group.name;
                            file.notArchive = false;
                            file.frontpiece = !isFrontpieceDisabled(file);
                        }
                    });
                }

                function isSingleFileGroup(group) {
                    return !vm.isRealGroup(group);
                }

                function isRealGroup(group) {
                    return group && group.documents && group.documents.length > 1;
                }

                function isDeletable(doc) {
                    if (vm.isAdmin) {
                        return doc.protocolState !== "ARCHIVE_NOW" && vm.editable;
                    }
                    return doc.protocolState !== "ARCHIVED" && doc.protocolState !== "ARCHIVE_NOW"
                            && (vm.isProtocollo || vm.editable);
                }

                function isFrontpieceDisabled(file) {
                    var getExtension = /(?:\.([^.]+))?$/;
                    var extension = getExtension.exec(file.name)[1];
                    if (typeof (extension) === 'undefined' || extension === null) {
                        extension = '';
                    } else {
                        extension = extension.trim().toLowerCase();
                    }
                    return vm.frontespieceAllowedExtensions.indexOf(extension) === -1;
                }


                // FOR ANCHORING TO THE DOCUMENTS 
                if(vm.section.anchorToDocuments) {
                    vm.section.showDocuments();
                    $timeout(function () {
                        $anchorScroll(vm.section.id);
                    }, 1500);
                }
            }]
    };

});
