(function () {

    'use strict';

    angular.module('gestioneAttiApp').service('PdlValidator', ['BasicValidator', function (BasicValidator) {

        this.validatePresentation = BasicValidator.validatePresentation;

        this.validate = function (state, numeroRinvio, utente, userRolesMap, idPrimaCommissione, action, primaryCommission) {
            var invalidFields = [];

            // if (userRolesMap.isAdmin) {
            //     var result = BasicValidator.validateSectorSections(invalidFields, idPrimaCommissione);
            //     if (result.valid) {
            //         if (!BasicValidator.PdlService.sezioniMap.ASSIGNMENT_LETTER.metadati.aulaDiretta) {
            //             result = BasicValidator.validateCommissionSections(invalidFields, utente, userRolesMap, numeroRinvio,
            //                 idPrimaCommissione, action && action.name === 'assignToCourtRoom');
            //         }
            //         if (result.valid) {
            //             result = BasicValidator.validateCourtroomSections(invalidFields, numeroRinvio);
            //         }
            //     }
            //     return result;
            // } else {
                switch (state) {
                    case "PRESENTATO":
                        return BasicValidator.validateSectorSections(invalidFields, idPrimaCommissione);
                    case "COMMISSIONE":
                        return validateCommissionSections(invalidFields, utente, userRolesMap,
                                numeroRinvio, idPrimaCommissione, action && action.name === 'assignToCourtRoom', primaryCommission);
                    case "AULA":
                        return BasicValidator.validateCourtroomSections(invalidFields, numeroRinvio);
                    case "APPROVATO":
                        return BasicValidator.validatePostCourtroomSections(invalidFields, numeroRinvio);
                    default:
                        console.error("STATO SCONOSCIUTO: " + state);
                        return {valid: false, message: "STATO SCONOSCIUTO: " + state};
                }
        };

        function validateCommissionSections(invalidFields, utente, userRolesMap, numeroRinvio, idPrimaCommissione, action, primaryCommission) {
            var result = null;
            for (var i = 0; i <= numeroRinvio; i++) {
                result = BasicValidator.validateLegitimacy(invalidFields, userRolesMap, i);
                if (result.valid) {
                    result = BasicValidator.validateFeasibility(invalidFields, userRolesMap, i);
                    if (result.valid) {
                        result = BasicValidator.validateCommissionEmendments(invalidFields, i, primaryCommission);
                        if (result.valid) {
                            //result = BasicValidator.validateCommissionOpinions(invalidFields, utente, userRolesMap, i, idPrimaCommissione, primaryCommission);
                            if (result.valid) {
                                result = BasicValidator.validateFinancialCoverage(invalidFields, i, primaryCommission);
                                if (result.valid) {
                                    result = BasicValidator.validateCommissionMessage(invalidFields, i, primaryCommission);
                                    if (result.valid) {
                                        result = validateUnifiedText(invalidFields, i, primaryCommission);
                                    } else {
                                        break;
                                    }
                                } else {
                                    break;
                                }
                            } else {
                                break;
                            }
                        } else {
                            break;
                        }
                    } else {
                        break;
                    }
                } else {
                    break;
                }
            }
            return result;
        }

        function validateUnifiedText(invalidFields, idx, primaryCommission) {
            var sezione = BasicValidator.PdlService.getSezioniFromMap('UNIFIED_TEXT', idx);

            if (!sezione || !primaryCommission) {
                return {valid: true, message: message, invalidFields: invalidFields};
            }

            var suffix = "";
            if (idx > 0) {
                suffix += "_" + idx;
            }

            var metadata = sezione.metadati;

            var message = '<ul>';

            if (typeof (metadata.hasUnifiedTexts) === 'undefined' || metadata.hasUnifiedTexts === null) {
                throw "Has unified text does not exist";
            } else if (metadata.hasUnifiedTexts === true && metadata.unifiedTexts.length === 0) {
                message += '<li><i>Specificare</i> almeno un testo unificato</li>';
                invalidFields.push('hasUnifiedTexts' + suffix);
            }

            message += '</ul>';

            var valid = invalidFields.length === 0;
            if (!valid) {
                BasicValidator.$location.hash('unifiedText' + suffix);
                BasicValidator.$anchorScroll();
                sezione.visible = true;
            }

            return {valid: valid, message: message, invalidFields: invalidFields};
        }

    }]);
})();
