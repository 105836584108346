(function () {
    'use strict';

    angular
            .module('gestioneAttiApp')
            .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
                .state('anagrafica-iterlegis', {
                    parent: 'admin',
                    url: '/anagrafica-iterlegis?page',
                    data: {
                        authorities: ['ROLE_ADMIN'],
                        pageTitle: 'anagrafica-iterlegis.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/entities/anagrafica-iterlegis/anagrafica-iterlegis.html',
                            controller: 'AnagraficaIterlegisController',
                            controllerAs: 'vm'
                        }
                    },
                    params: {
                        page: {
                            value: '1',
                            squash: true
                        },
                        sort: {
                            value: 'descrizioneAnagrafica,desc',
                            squash: true
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('atto');
                                $translatePartialLoader.addPart('tipoAtto');
                                $translatePartialLoader.addPart('tipoProponente');
                                $translatePartialLoader.addPart('global');
                                $translatePartialLoader.addPart('form');
                                $translatePartialLoader.addPart('addable-input');
                                $translatePartialLoader.addPart('document-toolbar');
                                $translatePartialLoader.addPart('archive-tree');
                                $translatePartialLoader.addPart('commission-opinion');
                                $translatePartialLoader.addPart('emendments');
                                $translatePartialLoader.addPart('sectionNotes');
                                $translatePartialLoader.addPart('anagrafica-iterlegis');
                                return $translate.refresh();
                            }],
                        pagingParams: ['PaginationUtil', '$stateParams', function (PaginationUtil, $stateParams) {
                            return {
                                page: PaginationUtil.parsePage($stateParams.page),
                                sort: $stateParams.sort,
                                predicate: PaginationUtil.parsePredicate($stateParams.sort),
                                ascending: PaginationUtil.parseAscending($stateParams.sort)
                            }
                        }]
                    }
                }).state('anagrafica-iterlegis.new', {
                    parent: 'anagrafica-iterlegis',
                    url: '/new',
                    data: {
                        authorities: ['ROLE_ADMIN']
                    },
                    onEnter: ['$mdDialog', function ($mdDialog) {
                            $mdDialog.show({
                                clickOutsideToClose: false,
                                fullscreen: true,
                                templateUrl: 'app/entities/anagrafica-iterlegis/anagrafica-iterlegis-dialog.html',
                                controller: 'AnagraficaIterlegisDialogController',
                                controllerAs: 'vm',
                                bindToController: true,
                                locals: {
                                    entity: {
                                        tipoAtto: null,
                                        descrizioneAnagrafica: null,
                                        id: null
                                    }
                                }
                            });
                        }]
                }).state('anagrafica-iterlegis.delete', {
                    parent: 'anagrafica-iterlegis',
                    url: '/{id}/delete',
                    data: {
                        authorities: ['ROLE_ADMIN']
                    },
                    onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                            $uibModal.open({
                                templateUrl: 'app/entities/anagrafica-iterlegis/anagrafica-iterlegis-delete-dialog.html',
                                controller: 'AnagraficaIterlegisDeleteController',
                                controllerAs: 'vm',
                                size: 'md',
                                resolve: {
                                    entity: ['AnagraficaIterlegis', function (AnagraficaIterlegis) {
                                            return AnagraficaIterlegis.get({id: $stateParams.id}).$promise;
                                        }]
                                }
                            }).result.then(function () {
                                $state.go('anagrafica-iterlegis', null, {reload: 'anagrafica-iterlegis'});
                            }, function () {
                                $state.go('^');
                            });
                        }]
                })
            }
})();