(function () {
    'use strict';

    var macroMateriaChipSearchComponent = {

        templateUrl: 'app/components/macro-materia-chip-search/macro-materia-chip-search.html',
        bindings: {
            'model' : '=',
            'macroMateriaList' : '=',
            'maxChips' : '=',
            'disabled' : '=',
            'onModelChange': '<',
            'label': '@',
            'showEndDate' : '@'
        },
        controller: ['toastr', '$scope', function (toastr, $scope) {
            var vm = this;
            vm.selectedMacroMateria = vm.model ? [vm.model] : [];

            vm.searchMacroMateria = function (tokenMacroMateria) {
                var results = vm.macroMateriaList ? vm.macroMateriaList : [];
                return tokenMacroMateria ? results.filter(createFilterForMacroMateria(tokenMacroMateria)) : [];
            }
    
            function createFilterForMacroMateria(tokenMacroMateria) {
                return function filterFn(macroMateria) {
                    var description = macroMateria.description;
                    var code = macroMateria.code;
                    return description.toLowerCase().includes(tokenMacroMateria.toLowerCase()) || code.toString().includes(tokenMacroMateria.toString());
                };
            }
            vm.changedMacroMateria = function() {
                var output;
                if (vm.maxChips && vm.maxChips === 1) {
                    output = vm.selectedMacroMateria ? vm.selectedMacroMateria[0] : null;
                } else {
                    output = vm.selectedMacroMateria;
                }
                vm.model = output;
                
                setTimeout(function(){ 
                    if (vm.onModelChange != null) {
                        vm.onModelChange(output);
                    } 
                },0);
            }
            /**
             * Clean both the outer and inned models
             */
            $scope.$on('cleanMacroMateriaChip', function() {
                vm.selectedMacroMateria = [];
                vm.model = null;
            });
        }],
        controllerAs: 'vm'
    };

    angular.module('gestioneAttiApp').component('macroMateriaChipSearchComponent', macroMateriaChipSearchComponent);
})();
