(function () {
    'use strict';

    var eventMailNotificationButtonComponent = {

        templateUrl: 'app/components/eventMailNotificationButton/eventMailNotificationButton.html',
        bindings: {
            'eventType' : '=',
            'atto' : '=',
        },
        controller: ['Atto','toastr', 'EVENTI_NOTIFICA_ATTO', '$mdDialog', '$translate', function (Atto, toastr, EVENTI_NOTIFICA_ATTO, $mdDialog, $translate) {
            var vm = this;
            var notifica = EVENTI_NOTIFICA_ATTO[EVENTI_NOTIFICA_ATTO.findIndex(function(evento) {return evento.value === vm.eventType})];
            var notificaDescr = $translate.instant(notifica.name);
            var confirmDlg = $mdDialog.confirm()
            .title('ATTENZIONE!')
            .textContent("Si ricorda che le email sono mandate automaticamente dall'applicazione! Se confermi la seguente operazione saranno notificate via email tutte le liste di distribuzione interessate a questo evento! L'evento interessato e': " + notificaDescr)
            .ariaLabel('conferma Aria Label')
            .ok('Conferma Invio Email')
            .cancel('Annulla');
            
            vm.isSendingMails = false;
            vm.onButtonClick = function() {
                $mdDialog.show(confirmDlg).then(function () {
                    sendEventNotification();        
                }, function(){});
            }

            function sendEventNotification() {
                vm.isSendingMails = true; 
                Atto.sendAttoNotificationByEmail({idAtto: vm.atto.id, eventoNotificaAtto: vm.eventType}, {}, 
                    function(result) {
                        vm.isSendingMails = false;
                        toastr.success('Notifiche mandate con successo!');
                    },
                    function(error) {
                        vm.isSendingMails = false;
                        toastr.error('Errore durante invio delle notifiche!');
                    }
                );
            }
        }],
        controllerAs: 'vm'
    };

    angular.module('gestioneAttiApp').component('eventMailNotificationButtonComponent', eventMailNotificationButtonComponent);
})();
