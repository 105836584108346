(function() {
    'use strict';

    angular
        .module('gestioneAttiApp')
        .controller('LogsController', LogsController);

    LogsController.$inject = ['LogsService', 'toastr', '$translate'];

    function LogsController (LogsService, toastr, $translate) {
        var vm = this;

        vm.changeLevel = changeLevel;
        vm.loggers = LogsService.findAll({}, {}, angular.noop, onError);

        function onError(error) {
            if(error.status === 403){
                toastr.error($translate.instant('error.access.external.forbidden'));
            } else {
                toastr.error(error.data);
            }
        }

        function changeLevel (name, level) {
            LogsService.changeLevel({name: name, level: level}, function () {
                vm.loggers = LogsService.findAll();
            });
        }
    }
})();
