(function() {
    "use strict";

    angular
        .module("gestioneAttiApp")
        .controller(
            "AttoReportGeneratorController",
            AttoReportGeneratorController
        );

    AttoReportGeneratorController.$inject = [
        "$state",
        "toastr",
        "ACT_TYPES",
        "SEARCH_STATES",
        "Commissione",
        "SoggettoEsterno",
        "AnagraficaProtocollo",
        "Direzione",
        "Atto",
        "FileSaver",
        "$mdDialog",
        "Principal"
    ];

    function AttoReportGeneratorController(
        $state,
        toastr,
        ACT_TYPES,
        SEARCH_STATES,
        Commissione,
        SoggettoEsterno,
        AnagraficaProtocollo,
        Direzione,
        Atto,
        FileSaver,
        $mdDialog,
        Principal
    ) {
        var vm = this;
        vm.loading = false;
        vm.searchParams = {};
        vm.isTipoGRselected = false;
        vm.cambioStatoAttoList = [];
        vm.clearSearch = clearSearch;
        SEARCH_STATES.forEach(function(stato){
            if(stato.value !== 'PRESENTATO') {
                vm.cambioStatoAttoList.push(stato);
            }
        });
        vm.commissionsReportVisible = Principal.hasAnyAuthority(['ROLE_COMMISSIONE']) || Principal.hasAnyAuthority(['ROLE_ADMIN']);
        loadAll();

        function loadAll() {
            vm.clearSearch();
            vm.queryStates = SEARCH_STATES;
            vm.actTypes = ACT_TYPES;
            vm.Atto = Atto;
            vm.proponenti = [];

            Commissione.query({}, function(result) {
                vm.commissioni = result;
            });

            SoggettoEsterno.query({}, function(result) {
                vm.soggettiEsterni = result;
            });

            AnagraficaProtocollo.query({}, function(result) {
                vm.proponenti = result;
            });

            Direzione.query({}, function(result) {
                vm.direzione = result;
            });

            Atto.queryLegislature({}, onSuccessLeg, onErrorLeg);
            function onSuccessLeg(data) {
                vm.legislature = data;
            }
            function onErrorLeg(error) {
                console.log("Failure!", error);
                toastr.error(error.data.message);
            }
        }

        function clearSearch() {
            vm.searchParams = {
                // Criteri entita Atto
                dataPresentazioneInizio: null,
                dataPresentazioneFine: null,
                legislatura: null,
                tipoProponenteList: [],
                proponenti: [],
                proponentiExclusionType: 'ALL_INCLUDED',
                numeroAtto: null,
                titoloAtto: null,
                statoAttoList: [],
                direzioneGRId: null,
                dataGR: null,
                tipoAttoList: [],
                statiCambioStato: [],
                dataCambioStatoInizio: null,
                dataCambioStatoFine: null,
                // Criteri entita Sezione
                parereReferente: [],
                parereSecondario: [],
                parereDiMerito: [],
                parereObbligatorio: [],
                osservazioni: [],
                parereIstituzionale: null,
                primiFirmatari: [],
                primiFirmatariExclusionType: 'ALL_INCLUDED',
                attiCollegati: [],
                attiCollegatiExclusionType: 'ALL_INCLUDED',
                testoSostitutivoPresentato: null,
                testoSostitutivoInizio: null,
                testoSostitutivoFine: null,
                dataBurtInizio: null,
                dataBurtFine: null,
                dataTrattazioneInizio: null,
                dataTrattazioneFine: null,
                ricorsoCostituzionale: null,
                impegnoCaricoGiunta: null,
                dataInvioRichiestaInizio: null,
                dataInvioRichiestaFine: null,
                dataRicezioneInizio: null,
                dataRicezioneFine: null,
                dataSollecitoInizio: null,
                dataSollecitoFine: null,
                attiGiacentiOrLicenziatiInCommissione: null,
                commissioneReport: null,
                dataEsitoCommissioneInizio: null,
                dataEsitoCommissioneFine: null,
                // Parametri custom per il report finale
                titoloReport: null,
                tipologiaReport: 'DEFAULT',
                showReportCriteria: null
            };
            vm.attachedActsReadOnlyListModel = [];
        }

        vm.search = function() {      
            var di = $mdDialog.show({
                clickOutsideToClose: true,
                templateUrl: 'app/entities/atto-report-generator/confirm-report-modal/confirm-report-modal.html',
                controller: ['$scope', '$mdDialog', 'searchParams', 'TIPOLOGIA_REPORT', function ($scope, $mdDialog, searchParams, TIPOLOGIA_REPORT) {
                        $scope.searchParams = searchParams;
                        $scope.TIPOLOGIA_REPORT = TIPOLOGIA_REPORT;
                        $scope.cancel = function () {
                            $mdDialog.cancel();
                        };

                        $scope.confirm = function () {
                            $mdDialog.hide(true);
                        };
                    }],
                locals: {
                    searchParams: vm.searchParams
                }
            });
            di.then(
                function(result) {
                    // Dialog confirmed
                    vm.Atto.getPDFReport(vm.searchParams)
                        .then(
                            function(result) {
                                FileSaver.saveAs(new Blob([result.data], {}), "report.pdf");
                            }, 
                            function(error) {
                                var errorCode = error.headers('X-gestioneAttiApp-error');
                                var errorText = '';
                                if(errorCode === 'error.noContent') {
                                    errorText = 'La ricerca effettuata non ha trovato nessun atto come risultato, quindi non e\' stato possibile generare nessun report!';
                                } else if(errorCode === 'error.forbiddenOp') {
                                    errorText = 'La tua utenza non e\' abilitata alla generazione di report. Si prega di contattare l\'amministratore!'
                                } else if(errorCode === 'error.requiredParam') {
                                    errorText = 'Parametri mancanti per effettuare il report commissioni. Si prega di ricontrollare i dati inseriti!'
                                } else {
                                    errorText = 'Errore inaspettato. Si prega di riprovare o contattare l\'amministratore!'
                                }

                                toastr.error(errorText, "Errore!", {
                                    timeOut: 60000,
                                    extendedTimeOut: 0
                                });
                            });
                },
                function(cancel) {
                    // Dialog cancelled
                }
            );

        }

        vm.searchWithCommissionCheck = function() {
            // Controllo in caso di report commissioni con parametri obbligatori mancanti
            if(missingParameterCommissionReport(this.searchParams)){
                $('html, body').animate({scrollTop: 0}, 'medium');
                return toastr.error('Parametri "Legislatura" e "Commissione" necessari per il report commissioni. Si prega di ricontrollare i dati inseriti!');
            }

            // In caso di report generico richiama la search() standard
            if(!commissionReportWithOtherParams(this.searchParams)) {
                return vm.search();
            }

            // Se è stato selezionato il report commissioni con altri parametri mostra un dialog di conferma
            var confirmCommissionOtherParam = $mdDialog.confirm()
                  .title('Effettuare il Report Commissioni con parametri generici?')
                  .htmlContent("<p>Selezionando i parametri del report generalizzato, oltre a \"Legislatura\" e \"Commissione\", \
                  <br/> il Report degli atti giacenti/licenziati verra\' filtrato con quei parametri.</p> \
                  <p>Proseguire nella generazione del Report Commissioni?</p>")
                  .ariaLabel('')
                  .targetEvent()
                  .ok('Procedi')
                  .cancel('Annulla');
        
            $mdDialog.show(confirmCommissionOtherParam).then(function() {
                vm.search();
            }, function() {
              // Dialog cancelled
            });
          };


        function commissionReportWithOtherParams(searchParams){
            if( searchParams.attiGiacentiOrLicenziatiInCommissione != null ){

                // Esclusione dei parametri necessari al report commissioni
                var commissionReportKeys = ["legislatura", "commissioneReport", "attiGiacentiOrLicenziatiInCommissione"];
                // Esclusione dei parametri generici del report o di parametri dipendenti da altri parametri
                var exclusionKeys = ["proponentiExclusionType", "primiFirmatariExclusionType", "attiCollegatiExclusionType", "titoloReport", "tipologiaReport", "showReportCriteria"];
                
                for ( var key in searchParams) {
                    if (Object.prototype.hasOwnProperty.call(searchParams, key)) {
                        // Controllo soltanto i parametri non presenti nelle liste precedentemente definite
                        if(commissionReportKeys.indexOf(key) == -1 && exclusionKeys.indexOf(key) == -1){
                            var value = searchParams[key];
                            if(Array.isArray(value)){
                                if(value != null && value.length != 0) {
                                    return true;
                                }
                            } else {
                                if(value != null) {
                                    return true;
                                }
                            }
                        }
                    }
                }
            }
            return false;
        }

        function missingParameterCommissionReport(searchParams){
            if( searchParams.attiGiacentiOrLicenziatiInCommissione != null ){
                return searchParams.legislatura == null || searchParams.commissioneReport == null;
            }
            return false;
        }

        vm.addPersonToList = function (chip) {
            // Otherwise, create a new one
            if (angular.isObject(chip)) {
                return chip;
            }
            return {descrizione: chip};

        };

        vm.searchProponenti = function (searchText) {
            //console.log("SEARCH PROPONENTI- tutti i sistemi di protocollo e tutti i tipoAnagrafica");

            if(searchText.length < 3) {
                return [];
            }

            return AnagraficaProtocollo.search(
                { 
                    term: searchText,
                    protocolSystem: 'ALL',
                    tipoAnagrafica: '',
                    page: 0,
                    size: 20
                }).$promise.then(onSuccessAnag, onErrorAnag);

            //return searchText ? vm.proponenti.filter(createFilterForProponenti(searchText)) : [];
        };

        function onSuccessAnag(data, headers) {
            vm.proponenti = data;
            return data;
        }

        function onErrorAnag(error) {
            console.log("Errore nel recupero anagrafiche!", error);
        }


        function createFilterForProponenti(query) {
            var lowercaseQuery = angular.lowercase(query);

            return function filterFn(firmatario) {
                var codice = firmatario.codice.toLowerCase();
                var descrizione = firmatario.descrizione.toLowerCase();
                return (codice.indexOf(lowercaseQuery) >= 0 || descrizione.indexOf(lowercaseQuery) >= 0);
            };
        }

        vm.tipoProponenteChange = function() {
            vm.isTipoGRselected = vm.searchParams.tipoProponenteList.includes('GR');
        }

        vm.toggleCheck = function (fieldName, value, dependentField) {
            var idx = vm.searchParams[fieldName].indexOf(value);
            if (idx > -1) {
                vm.searchParams[fieldName].splice(vm.searchParams[fieldName].indexOf(value), 1);
            } else {
                if (dependentField && vm.searchParams[dependentField] && vm.searchParams[dependentField].indexOf(value) > -1) {
                    vm.searchParams[dependentField].splice(vm.searchParams[dependentField].indexOf(value), 1);
                }
                vm.searchParams[fieldName].push(value);
            }
        };

        vm.searchParamExists = function(fieldName, value) {
            return vm.searchParams && vm.searchParams[fieldName] && vm.searchParams[fieldName].indexOf(value) > -1;
        };


        // ATTACHED ACTS
        vm.acts = [];
        vm.basicSearchType = null;
        vm.attachedActsModel = [];
        vm.attachedActsReadOnlyListModel = [];
        vm.numeroAttoSearchText = '';

        vm.onAddAttachedAct = function onAddAttachedAct($index, $chip) {
            if (isValidChip($chip)) {
                vm.searchParams.attiCollegati.push($chip.id);
                vm.attachedActsReadOnlyListModel.push($chip);
            }
            vm.attachedActsModel = [];
        }
        function isValidChip(chip) {
            return chip && chip.id && chip.numeroAtto && chip.titolo && chip.tipoAtto;
        }

        vm.onRemoveAttachedAct = function onRemoveAttachedAct($index, $chip) {
            for (var i in vm.searchParams.attiCollegati) {
                if ($chip.id === vm.searchParams.attiCollegati[i]) {
                    vm.searchParams.attiCollegati.splice(i, 1);
                    break;
                }
            }
        }

        vm.getAllActs = function () {
            vm.acts = [];
            if (!vm.basicSearchType) {
                return;
            }
            Atto.queryNonPaged({
                type: vm.basicSearchType
            }, onSuccess, onError);
            function onSuccess(data) {
                vm.acts = data;
            };
            function onError(error) {
                console.log(error);
            }
        }

        vm.transformChip = function transformChip($chip) {
            //Qui scegliere cosa tenere dentro la chip!
            return { "id": $chip.id, "numeroAtto": $chip.numeroAtto, "titolo": $chip.titolo, "tipoAtto": $chip.tipoAtto };
        }

        vm.searchActs = function searchActs(tokenNumeroAtto) {
            var results = vm.acts;
            return tokenNumeroAtto ? results.filter(createFilterForAttiCollegati(tokenNumeroAtto)) : [];
        }

        function createFilterForAttiCollegati(tokenNumeroAtto) {
            return function filterFn(atto) {
                var numeroAtto = atto.numeroAtto;
                return (numeroAtto.includes(tokenNumeroAtto) && !alreadySelected(atto.id));
            };
        }
        function alreadySelected(idAtto) {
            for (var i in vm.searchParams.attiCollegati) {
                if (idAtto === vm.searchParams.attiCollegati[i]) {
                    return true;
                }
            }
            return false;
        }
    }
})();
