(function() {
    'use strict';
    angular
        .module('gestioneAttiApp')
        .factory('AttoReportGenerator', AttoReportGenerator);

        AttoReportGenerator.$inject = ['$resource'];

    function AttoReportGenerator ($resource) {
        var resourceUrl =  'api/:public/sincronizzatore-anagrafiche/:id/:latest';

        var resource = $resource(resourceUrl, {}, {
            'queryPaged': {
                method: 'GET',
                isArray: true,
                url: 'api/public/paged/protocol-import-details'
            }
        });
        return resource;
    }
})();