//(function validateCommissionSections () {
(function () {
    'use strict';

    angular.module('gestioneAttiApp').service('PeaValidator', ['PdlService', '$location', '$anchorScroll',
        function (PdlService, $location, $anchorScroll) {

            this.PdlService = PdlService;
            this.$location = $location;
            this.$anchorScroll = $anchorScroll;

            // Presentato
            this.validatePresentation = validatePresentation;
            this.validateAssignmentLetter = validateAssignmentLetter;

            // Commissione

            // Stato Approvato
            this.validatePostCourtroomSections = validatePostCourtroomSections;
            this.validatePostCourtroom = validatePostCourtroom;

            this.validate = function (state, numeroRinvio, utente, userRolesMap, idPrimaCommissione, action, primaryCommission) {
                var invalidFields = [];

                if (userRolesMap.isAdmin) {
                    var result = validateSectorSections(invalidFields, idPrimaCommissione);
                    if (result.valid) {
                        if (!PdlService.sezioniMap.ASSIGNMENT_LETTER.metadati.aulaDiretta) {
                            // result = validateCommissionSections(invalidFields, utente, userRolesMap, numeroRinvio,
                            //         idPrimaCommissione, action && action.name === 'assignToCourtRoom', primaryCommission);
                        }
                    }
                    return result;
                } else {
                    switch (state) {
                        case "PRESENTATO":
                            return validateSectorSections(invalidFields, idPrimaCommissione);
                        case "COMMISSIONE":
                            return validateCommissionSections(invalidFields, utente, userRolesMap, numeroRinvio,
                                    idPrimaCommissione, action && action.name === 'assignToCourtRoom', primaryCommission);
                        case "APPROVATO":
                            return {valid: true};/* validatePostCourtroomSections(invalidFields, numeroRinvio); */
                        default:
                            console.error("STATO SCONOSCIUTO: " + state);
                            return {valid: false, message: "STATO SCONOSCIUTO: " + state};
                    }
                }
            };

            function validateAssignmentLetter(invalidFields, idPrimaCommissione) {
                var metadata = PdlService.sezioniMap.ASSIGNMENT_LETTER.metadati;

                var message = '<ul>';
                if (!metadata.dateAssegnazione || metadata.dateAssegnazione.length === 0 || metadata.dateAssegnazione[0] === "") {
                    message += '<li><i>Data assegnazione</i> è un campo obbligatorio</li>';
                    invalidFields.push('dataAssegnazione');
                }
                if (!metadata.parereReferente || !metadata.parereReferente.length) {
                    message += '<li><i>Parere Referente</i> è un campo obbligatorio</li>';
                    invalidFields.push('parereReferente');
                }
                message += '</ul>';

                var valid = invalidFields.length === 0;
                if (!valid) {
                    $location.hash('assignmentLetter');
                    $anchorScroll();
                    PdlService.sezioniMap.ASSIGNMENT_LETTER.visible = true;
                }

                return {valid: valid, message: message, invalidFields: invalidFields};
            }

            function validateSectorSections(invalidFields, idPrimaCommissione) {
                var result = validateAssignmentLetter(invalidFields, idPrimaCommissione);
                return result;
            }

            function validatePostCourtroomSections(invalidFields, numeroRinvio) {
                var result;

                for (var i = 0; i <= numeroRinvio; i++) {
                    result = validatePostCourtroom(invalidFields, i);
                    if (!result.valid) {
                        break;
                    }
                }

                return result;
            }

            function validateCommissionSections(invalidFields, utente, userRolesMap, numeroRinvio, idPrimaCommissione, action, primaryCommission) {
                return {valid: true, invalidFields: invalidFields};
            }

            function validatePostCourtroom(invalidFields, idx) {
                var sezione = PdlService.getSezioniFromMap('POST_COURT', idx);
                if (!sezione) {
                    return {valid: true, message: message, invalidFields: invalidFields};
                }
                var suffix = "";
                if (idx > 0) {
                    suffix += "_" + idx;
                }

                var metadata = sezione.metadati;
                var message = '<ul>';
                if (!metadata.numeroLeggeAC || metadata.numeroLeggeAC.trim().length === 0) {
                    message += '<li><i>Numero legge AC</i> è un campo obbligatorio</li>';
                    invalidFields.push('numeroLeggeAC' + suffix);
                }
                if (!metadata.dataLeggeAC) {
                    message += '<li><i>Data legge AC</i> è un campo obbligatorio</li>';
                    invalidFields.push('dataLeggeAC' + suffix);
                }
                message += '</ul>';
                var valid = invalidFields.length === 0;
                if (!valid) {
                    $location.hash('postCourtroom' + suffix);
                    $anchorScroll();
                    sezione.visible = true;
                }
                return {valid: valid, message: message, invalidFields: invalidFields};
            }

            function validatePresentation(invalidFields, atto) {
                if (!invalidFields)
                    invalidFields = [];

                var message = '<ul>';

                if (!atto.legislatura) {
                    message += '<li><i>Legislatura</i> è un campo obbligatorio</li>';
                    invalidFields.push('legislatura');
                } else if (isNaN(atto.legislatura)) {
                    message += '<li><i>Legislatura</i> contiene un valore non valido</li>';
                    invalidFields.push('legislatura');
                }

                if (!atto.numeroAtto) {
                    message += '<li><i>Numero Atto</i> è un campo obbligatorio</li>';
                    invalidFields.push('numeroAtto');
                }

                if (!atto.tipoAtto) {
                    message += '<li><i>Tipo Atto</i> è un campo obbligatorio</li>';
                    invalidFields.push('tipoAtto');
                }

                if (!atto.titolo) {
                    message += '<li><i>Titolo</i> è un campo obbligatorio</li>';
                    invalidFields.push('titolo');
                }

                if (!atto.numeroProtocollo) {
                    message += '<li><i>Numero Protocollo</i> è un campo obbligatorio</li>';
                    invalidFields.push('numeroProtocollo');
                } else if (isNaN(atto.numeroProtocollo)) {
                    message += '<li><i>Numero Protocollo</i> contiene un valore non valido</li>';
                    invalidFields.push('numeroProtocollo');
                }

                if (!atto.dataProtocollo) {
                    message += '<li><i>Data Protocollo</i> è un campo obbligatorio</li>';
                    invalidFields.push('dataProtocollo');
                }

                if(atto.tipoAtto === 'PLIP'){
                    if (!atto.proponentiText || atto.proponentiText.length === 0 || atto.proponentiText[0] === '') {
                        message += '<li>Devi specificare almeno un proponente</li>';
                        invalidFields.push('proponenti');
                    }
                } else {
                    if (!atto.tipoProponente) {
                        message += '<li><i>Tipo Proponente</i> è un campo obbligatorio</li>';
                        invalidFields.push('tipoProponente');
                    } else if (!atto.proponente.proponenti || atto.proponente.proponenti.length === 0) {
                        message += '<li>Devi specificare almeno un proponente</li>';
                        invalidFields.push('proponenti');
                    }
                }


                message += '</ul>';

                var valid = invalidFields.length === 0;
                if (!valid) {
                    $location.hash('presentation');
                    $anchorScroll();
                    if (PdlService.sezioniMap.PRESENTATION) {
                        PdlService.sezioniMap.PRESENTATION.visible = true;
                    }
                }

                return {valid: valid, message: message, invalidFields: invalidFields};
            }

        }]);
})();
