(function() {
    'use strict';

    angular
        .module('gestioneAttiApp')
        .factory('errorHandlerInterceptor', errorHandlerInterceptor);

    errorHandlerInterceptor.$inject = ['$q', '$rootScope'];

    function errorHandlerInterceptor ($q, $rootScope) {
        var service = {
            responseError: responseError
        };

        return service;

        function responseError (response) {
            if (!(response.status === 401 && (response.data === '' || (response.data.path && response.data.path.indexOf('/api/account') === 0 )))) {
                if(response.status === 403 && response.config.url.indexOf('/api/internal/check') === 0) {
                    //Remove error emit for directive is-intranet
                } else {
                    $rootScope.$emit('gestioneAttiApp.httpError', response);
                }
            }
            return $q.reject(response);
        }
    }
})();
