(function () {
    'use strict';

    angular
            .module('gestioneAttiApp')
            .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
                .state('user-management', {
                    parent: 'admin',
                    url: '/user-management?page&sort&nome&cognome&email&ruolo',
                    data: {
                        authorities: ['ROLE_ADMIN'],
                        pageTitle: 'userManagement.home.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/admin/user-management/user-management.html',
                            controller: 'UserManagementController',
                            controllerAs: 'vm'
                        }
                    }, params: {
                        page: {
                            value: '1',
                            squash: true
                        },
                        sort: {
                            value: 'id,asc',
                            squash: true
                        },
                        nome: {
                            value: null,
                            squash: true
                        },
                        cognome: {
                            value: null,
                            squash: true
                        },
                        email: {
                            value: null,
                            squash: true
                        },
                        ruolo: {
                            value: null,
                            squash: true
                        },
                    },
                    resolve: {
                        pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                                return {
                                    page: PaginationUtil.parsePage($stateParams.page),
                                    sort: $stateParams.sort,
                                    nome: $stateParams.nome,
                                    cognome: $stateParams.cognome,
                                    email: $stateParams.email,
                                    ruolo: $stateParams.ruolo,
                                    predicate: PaginationUtil.parsePredicate($stateParams.sort),
                                    ascending: PaginationUtil.parseAscending($stateParams.sort)
                                };
                            }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('user-management');
                                return $translate.refresh();
                            }]

                    }})
                .state('user-management.new', {
                    url: '/new',
                    data: {
                        authorities: ['ROLE_ADMIN']
                    },
                    onEnter: ['$mdDialog', 'ProfileService', '$state', function ($mdDialog, ProfileService, $state) {
                            $mdDialog.show({
                                clickOutsideToClose: false,
                                fullscreen: true,
                                templateUrl: 'app/admin/user-management/user-management-dialog.html',
                                controller: 'UserManagementDialogController',
                                controllerAs: 'vm',
                                bindToController: true,
                                locals: {
                                    entity: {
                                        id: null, login: null, firstName: null, lastName: null, email: null,
                                        activated: true, langKey: null, createdBy: null, createdDate: null,
                                        lastModifiedBy: null, lastModifiedDate: null, resetDate: null,
                                        resetKey: null, authorities: null
                                    },
                                    env: ProfileService.getProfileInfo()
                                }
                            }).then(function (result) {
                                $state.go('user-management', null, {reload: true});
                              }, function () {
                                $state.go('^');
                              });
                        }]
                })
                .state('user-management.edit', {
                    url: '/{login}/edit',
                    data: {
                        authorities: ['ROLE_ADMIN']
                    },
                    onEnter: ['$stateParams', '$mdDialog', 'User', 'ProfileService', '$state', function ($stateParams, $mdDialog, User, ProfileService, $state) {
                            $mdDialog.show({
                                clickOutsideToClose: false,
                                fullscreen: true,
                                templateUrl: 'app/admin/user-management/user-management-dialog.html',
                                controller: 'UserManagementDialogController',
                                controllerAs: 'vm',
                                bindToController: true,
                                locals: {
                                    entity: User.get({login: $stateParams.login}),                                      
                                    env: ProfileService.getProfileInfo()
                                }
                            }).then(function (result) {
                                $state.go('user-management', null, {reload: true});
                              }, function () {
                                $state.go('^');
                              });
                        }]
                })
                .state('user-management-detail', {
                    parent: 'user-management',
                    url: '/{login}',
                    data: {
                        authorities: ['ROLE_ADMIN'],
                        pageTitle: 'user-management.detail.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/admin/user-management/user-management-detail.html',
                            controller: 'UserManagementDetailController',
                            controllerAs: 'vm'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('user-management');
                                return $translate.refresh();
                            }]
                    }
                })
                .state('user-management.delete', {
                    url: '/{login}/delete',
                    data: {
                        authorities: ['ROLE_ADMIN']
                    },
                    onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                            $uibModal.open({
                                templateUrl: 'app/admin/user-management/user-management-delete-dialog.html',
                                controller: 'UserManagementDeleteController',
                                controllerAs: 'vm',
                                size: 'md',
                                resolve: {
                                    entity: ['User', function (User) {
                                            return User.get({login: $stateParams.login});
                                        }]
                                }
                            }).result.then(function () {
                                $state.go('user-management', null, {reload: true});
                            }, function () {
                                $state.go('^');
                            });
                        }]
                });
    }
})();
