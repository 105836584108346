(function() {
    'use strict';

    angular
        .module('gestioneAttiApp')
        .controller('AttoLandingPageController', AttoLandingPageController);

        AttoLandingPageController.$inject = ['$state', '$stateParams', '$translate', 'attoParams', 'Atto', 'Principal'];

    /**
     * Landing page created for the integration with Raccolta Normativa
     * They are going to be able to dynamically generate links to Iterlegis atto's
     * by using <NumeroAtto, Data (formato aaaa-mm-gg), TipoAtto>
     * The generated link will be [iterlegis-static-url]/atto-lp?numeroAtto={numeroAtto}&data={data}&tipoAtto={tipoAtto}
     * To identify an Atto all 3 fields are REQUIRED
     * Localhost example: 
     * http://localhost:8081/#/atto-lp?numeroAtto=97&data=2020-12-29&tipoAtto=LR --> 1 result
     * http://localhost:8081/#/atto-lp?numeroAtto=82&data=2020-08-07&tipoAtto=LR --> 2 result
     * @param {*} $state 
     * @param {*} $stateParams 
     * @param {*} $translate 
     */
    function AttoLandingPageController ($state, $stateParams, $translate, attoParams, Atto, Principal) {
        $translate.refresh();

        var vm = this;
        vm.notFound = !attoParams.data || !attoParams.numeroAtto;
        vm.attoParams = attoParams;
        Atto.getLandingPageAtto({data: attoParams.data, tipoAtto: attoParams.tipoAtto, numeroAtto: attoParams.numeroAtto}, 
            function(result) {
                if( result && result.length > 0) {
                    if (result.length === 1) {
                        $state.go('atto-public-detail', {id: result[0].id});
                    } else {
                        var nextState = Principal.isAuthenticated() ? 'home_logged' : 'home'; 
                        $state.go(nextState, {numeroLegge: attoParams.numeroAtto, dataPromulgazione: attoParams.data, legislatura: 'ALL'})
                    }
                    
                } else {
                    vm.notFound = true;
                }
            }, 
            function(error) {
                console.log('Error occurred!');
                vm.notFound = true;
            });

    }
})();