(function () {
    'use strict';

    angular.module('gestioneAttiApp').controller('SenderReceiverDialogController', SenderReceiverDialogController);

    SenderReceiverDialogController.$inject = ['$mdDialog', 'anagrafiche', 'document', 'group', 'uffici', 'AnagraficaProtocollo'];

    function SenderReceiverDialogController($mdDialog, anagrafiche, document, group, uffici, AnagraficaProtocollo) {
        var descAnagraficaMaxLength = 20;

        var vm = this;
        vm.userOperatingOffices = [];
        vm.selectedSenderSearchText = '';
        vm.selectedReceiverSearchText = '';
        vm.anagrafiche = anagrafiche;
        vm.uffici = uffici;
                
        vm.document = document;
        vm.group = group;
        
        vm.group.mittenti = vm.group.mittenti || [];
        vm.group.destinatari = vm.group.destinatari || [];
        
        AnagraficaProtocollo.userOperatingOffices({}, function (data) {
            vm.userOperatingOffices = data.operatingOffice;
            if (data.operatingOffice.length === 1) {
                vm.group.operatingOfficeSelected = data.operatingOffice[0].code;
            }
        }, function (error) {
            console.log(error);
        });

        vm.getPlaceholder = function () {
            if (vm.group.operatingOfficeSelected) {
                return vm.group.operatingOfficeSelected.description;
            } else {
                return "Seleziona l'ufficio operante!"
            }
        }
        
        
        vm.clear = function () {
            $mdDialog.cancel();
        };
        
        vm.addPersonToList = function (chip) {
            // If it is an object, it's already a known chip
            if (angular.isObject(chip)) {
                return chip;
            }
            // Otherwise, create a new one
            return {descrizione: chip};
        };

        
        vm.searchUffici = function (searchText) {
            return searchText ? vm.uffici.filter(createFilterFor(searchText)) : [];
        };
        
        vm.searchAnagrafiche = function (searchText) {
            
            //return searchText ? vm.anagrafiche.filter(createFilterFor(searchText)) : [];

            if(searchText.length < 3) {
                return [];
            }

            return AnagraficaProtocollo.search(
                { 
                    term: searchText,
                    protocolSystem: vm.document.protocolSystem,
                    page: 0,
                    size: 20
                }).$promise.then(onSuccessAnag, onErrorAnag);

        };

        function onSuccessAnag(data, headers) {
            vm.anagrafiche = data;
            return vm.anagrafiche;
        }

        function onErrorAnag(error) {
            console.log("Errore nel recupero anagrafiche!", error);
        }

        function createFilterFor(query) {
            var lowercaseQuery = angular.lowercase(query);

            return function filterFn(anag) {
                var codice = anag.codice ? anag.codice.toLowerCase() : '';
                var descrizione = anag.descrizione ? anag.descrizione.toLowerCase() : '';
                return (codice.indexOf(lowercaseQuery) >= 0 || descrizione.indexOf(lowercaseQuery) >= 0);
            };
        }
        
        vm.getDescAnagrafica = function(item, limitText) {
            var email = item.anagraficaProtocollo.emailPec || item.anagraficaProtocollo.email;
            var desc = item.descrizione + ' ' + (email ? '(' + email + ')' : '(EMAIL NON PRESENTE)');
            if (limitText && desc.length > descAnagraficaMaxLength) {
                desc = item.descrizione.substring(0, descAnagraficaMaxLength) + ' ' + (email ? '(' + email + ')' : '(EMAIL NON PRESENTE)');
            }
            return desc;
        };
    }
})();
