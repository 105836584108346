(function () {

    'use strict';

    angular.module('gestioneAttiApp').service('PdlpValidator', ['BasicValidator', function (BasicValidator) {

        this.validatePresentation = BasicValidator.validatePresentation;

        this.validate = function (state, numeroRinvio, utente, userRolesMap, idPrimaCommissione, action, primaryCommission) {
            var invalidFields = [];

            switch (state) {
                case "PRESENTATO":
                    return BasicValidator.validateSectorSections(invalidFields, idPrimaCommissione);
                case "COMMISSIONE":
                    return BasicValidator.validateCommissionSections(invalidFields, utente, userRolesMap,
                        numeroRinvio, idPrimaCommissione, action && action.name === 'assignToCourtRoom', primaryCommission);
                case "AULA":
                    return BasicValidator.validateCourtroomSections(invalidFields, numeroRinvio);
                case "APPROVATO":
                    return validatePostCourtroomSections(invalidFields, numeroRinvio);
                default:
                    console.error("STATO SCONOSCIUTO: " + state);
                    return {valid: false, message: "STATO SCONOSCIUTO: " + state};
            }
            // }
        };

        function validatePostCourtroomSections(invalidFields, numeroRinvio) {
            var result;

            for (var i = 0; i <= numeroRinvio; i++) {
                result = validatePostCourtroom(invalidFields, i);
                if (!result.valid) {
                    break;
                }
            }

            return result;
        }

        function validatePostCourtroom(invalidFields, idx) {
            var sezione = BasicValidator.PdlService.getSezioniFromMap('POST_COURT', idx);

            if (!sezione) {
                return {valid: true, message: message, invalidFields: invalidFields};
            }

            var suffix = "";
            if (idx > 0) {
                suffix += "_" + idx;
            }

            var metadata = sezione.metadati;

            var message = '<ul>';

            message += '</ul>';
            var valid = invalidFields.length === 0;
            if (!valid) {
                BasicValidator.$location.hash('postCourtroom' + suffix);
                BasicValidator.$anchorScroll();
                sezione.visible = true;
            }
            return {valid: valid, message: message, invalidFields: invalidFields};
        }


    }]);
})();
