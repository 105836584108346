angular.module('gestioneAttiApp').directive('transferCompetenceBlock', function () {
    return {
        restrict: "E",
        templateUrl: 'app/components/transferCompetenceBlock/transferCompetenceBlock.html',
        scope: {
            atto: '=',
            statoAttoCorrente: '=',
            decree: '=',
            noticeOfRectification: '=',
            isProtocollo: '=',
            isSettoreAtti: '=',
            utente: "=",
            docTypes: "=",
            changeDocumentTypeCallback: "=",
            changeDocumentVisibilityCallback: "=",
            publicView: "=",
            viewMode: '='
            
        },
        transclude: false,
        controllerAs: 'vm',
        bindToController: true,
        controller: ['Atto', 'User', 'PdlService', 'Documento', '$location','$anchorScroll', '$timeout', function (Atto, User, PdlService, Documento, $location, $anchorScroll, $timeout) {
            var vm = this;
            vm.actType = vm.atto.tipoAtto;
            vm.decree.visible = true;
            vm.noticeOfRectification.visible = true;

            vm.sezioni = {
                DECREE: {
                    form: 'app/components/transferCompetenceBlock/decree.html'
                },
                NOTICE_OF_RECTIFICATION_TRANSFER_COMPETENCE : {
                    form: 'app/components/courtroomBlock/noticeOfRectification.html'
                }
                
            };

            vm.stato = {
                transferCompetence: {
                    isCollapsed: vm.statoAttoCorrente === 'TRASFERITA COMPETENZA' || vm.viewMode
                }
            };

            vm.getSezioniFromMap = PdlService.getSezioniFromMap;
            vm.invalidFields = vm.invalidFields || [];

        }]
    };
});