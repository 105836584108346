(function(){

    angular.module('gestioneAttiApp').factory('ValidatorFactory', ['BasicValidator', 'PdlValidator', 'PddValidator', 'PdlpValidator', 'MinorBasicValidator','OdgValidator','MozioneValidator', 'PeaValidator', 'IoValidator','IeaValidator', 'RelValidator', 'PdrisValidator', 'RintValidator', 'PdlsValidator',
        function(BasicValidator, PdlValidator, PddValidator, PdlpValidator, MinorBasicValidator, OdgValidator, MozioneValidator, PeaValidator, IoValidator, IeaValidator, RelValidator, PdrisValidator, RintValidator, PdlsValidator) {

            var validators = {
                'PDL': PdlValidator,
                'PDD': PddValidator,
                'PLIP': PdlValidator,
                'PDLP': PdlpValidator,
                'PDLS': PdlsValidator,
                'BASIC': BasicValidator,
                'ODG': OdgValidator,
                'PDR': MinorBasicValidator,
                'MOZIONE': MozioneValidator,
                'PEA': PeaValidator,
                'IO': IoValidator,
                'IS': IoValidator,
                'CDG': IoValidator,
                'PEL': PeaValidator,
                'IEA': IeaValidator,
                'DEA': IoValidator,
                'REL': RelValidator,
                'PDRIS': PdrisValidator,
                'RINT': RintValidator
            };

            return {
                getValidator : function(param) {
                    if (validators[param])
                        return validators[param];
                    return BasicValidator;
                }
            };
        }
    ]);

})();
