(function() {
    'use strict';
    angular
        .module('gestioneAttiApp')
        .factory('AnagraficaIterlegis', AnagraficaIterlegis);

        AnagraficaIterlegis.$inject = ['$resource'];

    function AnagraficaIterlegis ($resource) {
        var resourceUrl =  'api/:public/anagrafiche-iterlegis';

        var resource = $resource(resourceUrl, {}, {
            'queryPaged': {
                method: 'GET',
                params: { public: 'public'},
                isArray: true,
                url: resourceUrl + '/paged'
            },
            'get': {
                method: 'GET',
                params: {public: 'public'},
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                },
                url: resourceUrl + '/:id'
            },
            'delete': {method: 'DELETE', url: resourceUrl + '/:id'},
            'query': {
                params: {public: 'public'},
                method: 'GET',
                isArray: true,
                url: resourceUrl
            }
        });

        return resource;
    }
})();