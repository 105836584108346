(function () {
    'use strict';

    angular
            .module('gestioneAttiApp')
            .controller('UserManagementDialogController', UserManagementDialogController);

    UserManagementDialogController.$inject = ['$mdDialog', 'User', 'JhiLanguageService', 'toastr', 'Commissione',
        'CommissioneRuolo', 'SoggettoEsterno', 'Settore', 'AUTHORITIES', '$state', '$translate'];

    function UserManagementDialogController($mdDialog, User, JhiLanguageService, toastr, Commissione, CommissioneRuolo,
            SoggettoEsterno, Settore, AUTHORITIES, $state, $translate) {
        var vm = this;
        vm.windowsAuthEnabled = vm.env.windowsAuthEnabled;
        vm.user = vm.entity;       
        vm.clear = clear;
        vm.languages = null;
        vm.save = save;
        vm.settori = [];
        vm.commissioni = [];
        vm.commissioniRuoli = [];
        vm.soggettiEsterni = [];
        vm.user.langKey = 'it';
        
        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });

        initController();

        vm.isCommissionUser = function () {
            return hasAuthority('ROLE_COMMISSIONE');
        };

        vm.isExternalUser = function () {
            return hasAuthority('ROLE_EXTERNAL');
        };

        vm.isSectorUser = function () {
            return hasAuthority('ROLE_SETTORE');
        };

        vm.setUserData = function (ldapUser) {
            if (ldapUser) {
                vm.user.login = ldapUser.principal;
                vm.user.firstName = ldapUser.firstName;
                vm.user.lastName = ldapUser.lastName;
                vm.user.email = ldapUser.email;
            }
        };

        vm.searchLdapUsers = function (text) {
            if (!text || text.length < 3) {
                return [];
            }
            return vm.ldapUsers.filter(createFilterFor(text));
        };


        function createFilterFor(query) {
            var lowercaseQuery = angular.lowercase(query);
            return function filterFn(user) {
                return ((user.lastName && user.lastName.toString().toLowerCase().indexOf(lowercaseQuery) === 0)
                        || (user.firstName && user.firstName.toString().toLowerCase().indexOf(lowercaseQuery) === 0)
                        || (user.principal && user.principal.toString().toLowerCase().indexOf(lowercaseQuery) === 0));
            };
        }

        function hasAuthority(authority) {
            return vm.user.authorities !== null
                    && typeof (vm.user.authorities) !== 'undefined'
                    && vm.user.authorities.indexOf(authority) > -1;
        }

        function clear() {
            $mdDialog.cancel();
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
            $mdDialog.cancel();
            $state.go('user-management', null, {reload: true});
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function save() {
            vm.isSaving = true;

            if (vm.user.id !== null) {
                User.update(vm.user, onSaveSuccess, onSaveError);
            } else {
                User.save(vm.user, onSaveSuccess, onSaveError);
            }
        }

        function initController() {
            vm.authorities = [];
            angular.forEach(AUTHORITIES, function(auth) {
                vm.authorities.push(auth.name);
            });
            
            
            // load settori
            Settore.query({}, function (settori) {
                vm.settori = settori;
            }, function (error) {
                toastr.error('Impossibile reperire la lista dei settori: ' + error.message, 'Errore!');
            });

            // load commissioni
            Commissione.query({}, function (commissioni) {
                vm.commissioni = commissioni;
            }, function (error) {
                toastr.error('Impossibile reperire la lista delle commissioni: ' + error.message, 'Errore!');
            });

            // load commissioni ruoli
            CommissioneRuolo.query({}, function (commissioniRuoli) {
                vm.commissioniRuoli = commissioniRuoli;
            }, function (error) {
                toastr.error('Impossibile reperire la lista dei ruoli delle commissioni: ' + error.message, 'Errore!');
            });

            // load soggetti
            SoggettoEsterno.query({}, function (soggetto) {
                vm.soggettiEsterni = soggetto;
            }, function (error) {
                toastr.error('Impossibile reperire la lista dei soggetti esterni: ' + error.message, 'Errore!');
            });

            if (vm.windowsAuthEnabled) {
                vm.filteredLdapUsers = [];
                vm.loadingLdapUsers = true;
                User.fromLdap({}, function (results) {
                    vm.ldapUsers = results;
                    vm.loadingLdapUsers = false;
                }, function (error) {
                    if(error.status === 403) {
                        toastr.error($translate.instant('userManagement.error.forbidden'));
                    } else {
                        toastr.error("Errore nell'interrogazione dell'Active Directory: " + error);
                    }
                    vm.loadingLdapUsers = false;
                });
            }
        }
    }
})();
