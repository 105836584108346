(function () {
    'use strict';
    angular
        .module('gestioneAttiApp')
        .factory('Atto', Atto);

    Atto.$inject = ['$resource', 'Upload', 'DateUtils', '$http'];

    function Atto($resource, Upload, DateUtils, $http) {
        var resourceUrl = 'api/:public/attos/:id/:sezioni/:idSezione/:files/:protocolNow/:legislatura/:anno/:tipoAtto/:numeroAtto/:proponente/:tipoProponente/:advancedSearch/:legislature/:filingcode/:commissione/:idCommissione/:tipoPaginaCommissione';

        var resource = $resource(resourceUrl, { id: '@id', idSezione: '@idSezione' }, {
            'query': {
                method: 'GET',
                params: { public: 'public' },
                isArray: true
            },
            'queryLite': {
                method: 'GET',
                url: 'api/public/lite/attos',
                isArray: true
            },
            'queryNonPaged': {
                method: 'GET',
                url: 'api/public/non-paged/attos',
                isArray: true
            },
            'advancedSearch': {
                method: 'GET',
                params: {
                    public: 'public',
                    advancedSearch: 'advancedSearch'
                },
                isArray: true
            },
            'get': {
                method: 'GET',
                params: { public: 'public' },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.dataProtocollo = DateUtils.convertLocalDateFromServer(data.dataProtocollo);
                    }
                    return data;
                }
            },
            'getByPk': {
                method: 'GET',
                params: { public: 'public' },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.dataProtocollo = DateUtils.convertLocalDateFromServer(data.dataProtocollo);
                    }
                    return data;
                }
            },
            'getSezioni': {
                method: 'GET',
                url: resourceUrl + '/sezioni',
                params: { public: 'public' },
                isArray: true
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);

                    // salvataggio del proponente a parte
                    copy.proponente = null;

                    copy.dataProtocollo = DateUtils.convertLocalDateToServer(copy.dataProtocollo);
                    return angular.toJson(copy);
                }
            },
            'updateFirmatariProponente': {
                method: 'PUT',
                url: '/api/attos/:idAtto/proponente/firmatari',
                params: { 'idAtto': '@idAtto' }
            },
            'updateSezione': {
                method: 'PUT',
                url: '/api/attos/:idAtto/sezioni/:idSezione',
                params: { 'idAtto': '@idAtto', 'idSezione': '@id' }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);

                    // salvataggio del proponente a parte
                    copy.proponente = null;

                    copy.dataProtocollo = DateUtils.convertLocalDateToServer(copy.dataProtocollo);
                    return angular.toJson(copy);
                }
            },
            'transition': {
                method: 'PUT',
                url: '/api/attos/:id/transizione/:nextState',
                params: { 'nextState': '@nextState' },
                responseType: 'string'
            },
            'sezioni': {
                method: 'GET',
                params: {
                    public: 'public',
                    sezioni: 'sezioni'
                },
                isArray: true
            },
            'sezioniFiles': {
                method: 'GET',
                params: {
                    public: 'public',
                    sezioni: 'sezioni',
                    files: 'files'
                },
                isArray: true
            },
            'sezioniFilesGrouped': {
                method: 'GET',
                params: {
                    public: 'public',
                    sezioni: 'sezioni',
                    files: 'files'
                },
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    var result = [];
                    var lastGroup = null;
                    if (data && (!data.status || data.status === 200)) {
                        angular.forEach(data, function (document) {
                            if (lastGroup === null || lastGroup.name !== document.gruppoProtocollo) {
                                lastGroup = {
                                    name: document.gruppoProtocollo,
                                    protocolState: document.statoProtocollo,
                                    mittenti: document.mittentiProtocollo || [],
                                    destinatari: document.destinatariProtocollo || [],
                                    documents: [],
                                    operatingOffice: document.operatingOffice
                                };
                                result.push(lastGroup);
                            }
                            document.name = document.nome;
                            document.public = document.pubblico;
                            document.frontpiece = document.frontespizio;
                            document.protocolState = document.statoProtocollo;
                            document.protocolGroup = document.gruppoProtocollo;
                            document.docType = document.tipoDocumento.tipo;
                            lastGroup.documents.push(document);
                        });
                    }
                    return { data: result };
                }
            },
            'protocolNow': {
                method: 'POST',
                params: {
                    sezioni: 'sezioni',
                    files: 'files',
                    protocolNow: 'protocolNow'
                },
                isArray: true
            },
            'updateProponente': {
                method: 'PUT',
                params: {
                    proponente: 'proponente'
                }
            },
            'queryLegislature': {
                method: 'GET',
                params: {
                    public: 'public',
                    legislature: 'legislature'
                },
                isArray: true
            },
            'queryCommissioniAtti': {
                method: 'GET',
                params: {
                    public: 'public',
                    commissione: 'commissione'
                },
                isArray: true
            },
            'getFilingCode': {
                method: 'GET',
                params: {
                    public: 'public',
                    filingcode: 'filingcode',
                },
                transformResponse: function (data) {
                    return { data: data };
                }
            },
            'getAllTipoAtto': {
                method: 'GET',
                url: '/api/visibilita-tipo-atto',
                isArray: true
            },
            'getTipoAttoForNonAuth': {
                method: 'GET',
                url: '/api/public/visibilita-tipo-atto',
                isArray: true
            },
            'saveTipoAtto': {
                method: 'POST',
                url: '/api/visibilita-tipo-atto'
            },
            'updateTipoAtto': {
                method: 'PUT',
                url: '/api/visibilita-tipo-atto'
            },
            'getTipoAtto': {
                method: 'GET',
                url: '/api/visibilita-tipo-atto/get-one/:id'
            },
            'deleteTipoAtto': {
                method: 'DELETE',
                url: '/api/visibilita-tipo-atto/:id'
            },
            'createConfigurazioneNotifiche': {
                method: 'POST',
                url: '/api/attos/configurazione-notifiche'
            },
            'updateConfigurazioneNotifiche': {
                method: 'PUT',
                url: '/api/attos/configurazione-notifiche/:idConfigurazione'
            },
            'getConfigurazioneNotifiche': {
                method: 'GET',
                url: '/api/attos/configurazione-notifiche/:idConfigurazione'
            },
            'getConfigurazioneNotificheByTipoAtto': {
                method: 'GET',
                url: '/api/attos/configurazione-notifiche/tipo-atto/:tipoAtto'
            },
            'sendAttoNotificationByEmail': {
                method: 'PUT',
                url: '/api/attos/notifications/send/:idAtto'
            },
            'getPDFReport': {
                method: 'POST',
                url: '/api/attos/report',
                responseType: 'arraybuffer'
            },
            'getPDFReportAddressesActs': {
                method: 'POST',
                url: '/api/attos/report-atti-indirizzo',
                responseType: 'arraybuffer'
            },
            'getLandingPageAtto': {
                method: 'GET',
                url: '/api/public/attos-lp',
                isArray: true
            },
            'cancelCurrentState': {
                method: 'PUT',
                url: '/api/attos/:id/annulla-stato-corrente',
                responseType: 'string'
            }

        });

        resource.getPDFReport = function (body) {
            return $http.post('/api/public/attos/report',
                body,
                { responseType: 'arraybuffer' });
        };

        resource.getPDFReportAddressesActs = function (body) {
            return $http.post('/api/public/attos/report-atti-indirizzo',
                body,
                { responseType: 'arraybuffer' });
        };

        resource.getEmendamentiPDFReport = function (sezioneId) {
            return $http.get('/api/public/attos/' + sezioneId + '/emendamentiReport',
                { responseType: 'arraybuffer' });
        };

        resource.uploadFileSezione = function (idAtto, idSezione, file, params) {
            return Upload.upload({
                url: 'api/attos/' + idAtto + '/sezioni/' + idSezione + '/files',
                method: 'POST',
                data: params || {},
                file: file,
            })
        };

        resource.updateFileProtocolData = function (idAtto, idSezione, fileList) {
            return $http.put('api/public/attos/' + idAtto + '/sezioni/' + idSezione + '/files', fileList).then(
                function (response) {
                    // success callback
                    console.log("Success: ", response);
                },
                function (response) {
                    // failure callback
                    console.log("Failure: ", response);
                });
        };



        resource.getArchive = function (id, publicView) {
            return $http.get('api/public/attos/' + id + '/fascicolo', {
                responseType: 'arraybuffer',
                params: { publicView: publicView }
            });

        };

        resource.getArchiveTree = function (id, publicView) {
            return $http.get('api/public/attos/' + id + '/fascicolo/tree', {
                params: { publicView: publicView }
            });

        };

        resource.getArchiveTreeSection = function (id, stato, sezione, publicView) {
            var sezioneParam = "";
            if (sezione && sezione !== "") {
                sezioneParam = "?sezione=" + sezione;
            }
            return $http.get('api/public/attos/' + id + '/fascicolo/tree/section/' + stato + sezioneParam, {
                params: { publicView: publicView }
            });

        };

        resource.getByNumeroLeggeDef = function (legislatura, numeroLeggeDf) {
            return $http.get('api/public/attos/legislatura/' + legislatura + '/numeroLeggeDef/' + numeroLeggeDf).then(
                function (response) {
                    return response.data;
                },
                function (response) {
                    // failure callback
                    console.log("Failure: ", response);
                });
        };


        resource.getSezioneByIdAttoAndNomeSezione = function (idAtto, nomeSezione) {
            return $http.get('api/public/attos/' + idAtto + '/sezioni/' + nomeSezione).then(
                function (response) {
                    return response.data;
                },
                function (response) {
                    console.log("Failure: ", response);
                });
        };

        return resource;
    }
})();
