(function () {
    'use strict';

    angular
        .module('gestioneAttiApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
                .state('adempimenti-atti-indirizzo-report-generator', {
                    parent: 'app',
                    url: '/adempimenti-atti-indirizzo-report-generator',
                    data: {
                        pageTitle: 'atto-report-generator.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/entities/atto-report-generator/adempimenti-atti-indirizzo-report-generator.html',
                            controller: 'AdempimentiAttiIndirizzoReportGeneratorController',
                            controllerAs: 'vm'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('atto');
                                $translatePartialLoader.addPart('tipoAtto');
                                $translatePartialLoader.addPart('tipoProponente');
                                $translatePartialLoader.addPart('global');
                                $translatePartialLoader.addPart('form');
                                $translatePartialLoader.addPart('addable-input');
                                $translatePartialLoader.addPart('document-toolbar');
                                $translatePartialLoader.addPart('archive-tree');
                                $translatePartialLoader.addPart('commission-opinion');
                                $translatePartialLoader.addPart('emendments');
                                $translatePartialLoader.addPart('sectionNotes');
                                $translatePartialLoader.addPart('commissione');
                                $translatePartialLoader.addPart('materia');
                                return $translate.refresh();
                            }]
                    }
                })
            }
})();