(function () {
    'use strict';

    angular
        .module('gestioneAttiApp')
        .controller(
            'AdempimentiAttiIndirizzoReportGeneratorController', 
            AdempimentiAttiIndirizzoReportGeneratorController
        );

    AdempimentiAttiIndirizzoReportGeneratorController.$inject = [
        "$scope",
        "$state",
        "toastr",
        "ACT_TYPES",
        "SEARCH_STATES",
        'TIPOLOGIA_ORDINAMENTO_REPORT',
        "Commissione",
        "Atto",
        "FileSaver",
        "$mdDialog",
        "Principal",
        "$translate",
        "MacroMateria"
    ];

    function AdempimentiAttiIndirizzoReportGeneratorController(
        $scope,
        $state,
        toastr,
        ACT_TYPES,
        SEARCH_STATES,
        TIPOLOGIA_ORDINAMENTO_REPORT,
        Commissione,
        Atto,
        FileSaver,
        $mdDialog,
        Principal,
        $translate,
        MacroMateria
    ) {
        var vm = this;
        vm.loading = false;
        vm.searchParams = {};
        vm.clearSearch = clearSearch;
        vm.fulfillmentReportVisible = Principal.hasAnyAuthority(['ROLE_COMMISSIONE']) || Principal.hasAnyAuthority(['ROLE_ADMIN']);
        loadAll();

        function loadAll() {
            vm.clearSearch();
            vm.queryStates = SEARCH_STATES;
            vm.actTypes = ACT_TYPES;
            vm.approvalSites = ["AULA", "COMMISSIONE"];
            vm.fulfillmentDelegates = ["GIUNTA", "CONSIGLIO", "ALTRO"];
            vm.fulfillmentRecipients = ["CONSIGLIO", "COMMISSIONE", "ALTRO"];
            vm.Atto = Atto;
            vm.proponenti = [];
            vm.macroMaterie = [];

            Commissione.query({}, function(result) {
                vm.commissioni = result;
                vm.commissioni.sort(function(a,b) { 
                    if (a.nome.toUpperCase() < b.nome.toUpperCase()) {
                        return -1;
                    }
                    if (a.nome.toUpperCase() > b.nome.toUpperCase()) {
                        return 1;
                    }
                    return 0;
                });
            });

            Atto.queryLegislature({}, onSuccessLeg, onErrorLeg);
            function onSuccessLeg(data) {
                vm.legislature = data;
            }
            function onErrorLeg(error) {
                console.log("Failure!", error);
                toastr.error(error.data.message);
            }

            MacroMateria.query({}, 
                function (data){
                    vm.macroMaterie = data;
                },
                function (error) {
                    console.log("Failure!", error);
                    toastr.error(error.data.message);
                });
        }

        vm.changedReportType = function(tipoReport) {
            if (vm.searchParams.tipoReportAttiIndirizzo === tipoReport) {
                return;
            } else {
                vm.searchParams.tipoReportAttiIndirizzo = tipoReport;
            }
        }
        
        function clearSearch() {
            vm.searchParams = {
                // Criteri entita Atto
                legislatura: null,
                tipoReportAttiIndirizzo: "adempimenti",

                // Criteri entita Sezione
                dataApprovazioneInizio: null,
                dataApprovazioneFine: null,
                sedeApprovazione: [],
                commissioneApprovazione: [],
                macroMateria: null,
                incaricatoAdempimento: [],
                destinatarioAdempimento: [],
                commissioneDestinataria: [],
                flagAdempimentoEffettuato: null,
                // Parametri custom per il report finale
                titoloReport: null,
                
                tipologiaOrdinamento: TIPOLOGIA_ORDINAMENTO_REPORT[0],
                showReportCriteria: true
            };
            $scope.$broadcast("cleanMacroMateriaChip");
            $('html, body').animate({scrollTop: 0}, 'medium');
        }

        vm.toggleCheck = function(fieldName, value) {
            var idx = vm.searchParams[fieldName].indexOf(value);
            if (idx > -1) {
                vm.searchParams[fieldName].splice(vm.searchParams[fieldName].indexOf(value), 1);
            } else {
                vm.searchParams[fieldName].push(value);
            }

        }
    
        vm.searchParamExists = function(fieldName, value){
            return vm.searchParams && vm.searchParams[fieldName] && vm.searchParams[fieldName].indexOf(value) > -1;
        }
    
        vm.searchValidation = function() {
            // Controllo in caso di report commissioni con parametri obbligatori mancanti
            if(missingParameterForReport(this.searchParams)){
                $('html, body').animate({scrollTop: 0}, 'medium');
                return  toastr.error($translate.instant('report-generator.errors.required-fields'), 'Errore!');
            }

            var di = $mdDialog.show({
                clickOutsideToClose: true,
                templateUrl: 'app/entities/atto-report-generator/confirm-report-modal/confirm-report-modal.html',
                controller: ['$scope', '$mdDialog', 'searchParams', 'TIPOLOGIA_ORDINAMENTO_REPORT', function ($scope, $mdDialog, searchParams, TIPOLOGIA_ORDINAMENTO_REPORT) {
                        if(searchParams.tipoReportAttiIndirizzo ) {
                            $scope.TIPOLOGIA_ORDINAMENTO_REPORT = searchParams.tipoReportAttiIndirizzo === 'adempimenti' ? TIPOLOGIA_ORDINAMENTO_REPORT : [TIPOLOGIA_ORDINAMENTO_REPORT[1], TIPOLOGIA_ORDINAMENTO_REPORT[2]];   
                            searchParams.tipologiaOrdinamento = $scope.TIPOLOGIA_ORDINAMENTO_REPORT[0];
                        }
                        $scope.searchParams = searchParams;
                        $scope.showOrdinamento = true;
                        $scope.cancel = function () {
                            $mdDialog.cancel();
                        };

                        $scope.confirm = function () {
                            $mdDialog.hide(true);
                        };
                    }],
                locals: {
                    searchParams: vm.searchParams
                }
            });
            di.then(
                function(result) {
                    // Dialog confirmed
                    vm.Atto.getPDFReportAddressesActs(vm.searchParams)        
                        .then(
                            function(result) {
                                FileSaver.saveAs(new Blob([result.data], {}), "report.pdf");
                            }, 
                            function(error) {
                                var errorCode = error.headers('X-gestioneAttiApp-error');
                                var errorText = '';
                                if(errorCode === 'error.noContent') {
                                    errorText = 'La ricerca effettuata non ha trovato nessun atto come risultato, quindi non e\' stato possibile generare nessun report!';
                                } else if(errorCode === 'error.forbiddenOp') {
                                    errorText = 'La tua utenza non e\' abilitata alla generazione di report. Si prega di contattare l\'amministratore!'
                                } else if(errorCode === 'error.requiredParam') {
                                    errorText = 'Parametri mancanti per effettuare il report commissioni. Si prega di ricontrollare i dati inseriti!'
                                } else {
                                    errorText = 'Errore inaspettato. Si prega di riprovare o contattare l\'amministratore!'
                                }

                                toastr.error(errorText, "Errore!", {
                                    timeOut: 60000,
                                    extendedTimeOut: 0
                                });
                            });
                },
                function(cancel) {
                    // Dialog cancelled
                }
            );
        }

        function missingParameterForReport(searchParams){
            if( searchParams.legislatura == null ){
                return true;
            } else if((searchParams.dataApprovazioneInizio != null && searchParams.dataApprovazioneFine == null)
                || (searchParams.dataApprovazioneFine != null && searchParams.dataApprovazioneInizio == null)) {
                    return true;
            }
            return false;
        }
    }

}());

