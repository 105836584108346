angular.module('gestioneAttiApp').directive('addableInput', function () {
    return {
        restrict: "E",
        templateUrl: 'app/components/addableInput/addableInput.directive.html',
        scope: {
            dateLabel: '=?',
            disabled: '=',
            editOnlyLastValue: '=?',
            inputModel: '=',
            inputType: '=',
            invalidFields: '=?',
            textLabel: '=?',
            validating: '=?',
            fieldName: '@?',
            anagraficheProtocollo: '=?',
            protocolSystem: '=?',
            organismiSeduta: '=?'
        },
        transclude: false,
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$translate','AnagraficaProtocollo', function ($translate, AnagraficaProtocollo) {
            var vm = this;

            var dateObjTemplate = "";
            var textObjTemplate = "";
            var textAndDateObjTemplate = {text:"", date:""};
            var attachedActObjTemplate = {type:"", number:"", state:""};
            var richiestaDiGiuntaObjTemplate = {proponente:[], dataRichiesta:"", numeroProtocollo:""};
            var resolutionObjTemplate = {date:"", esito:"", voto:"",resolutionNumber:""};
            var deaPresentationInputObjTemplate = {ente: "", carica: "", personaNominata: ""};
            var avvisoRettificaObjTemplate = { numeroBurt: "", parteBurt: "", dataBurt: ""};

            vm.addInput = addInput;
            vm.clearOrRemoveInput = clearOrRemoveInput;
            vm.membroSelected = membroSelected;

            init();

            vm.isInputDisabled = function (index) {
                if (vm.disabled) {
                    return true;
                }

                if (vm.blockedEntries.length > 0) {
                    return index < vm.blockedEntries.length;
                }

                return false;
            };

            vm.isButtonEnabled = function (index, type) {
                if (vm.disabled || !vm.inputModel) {
                    return false;
                }

                if (vm.blockedEntries.length > 0) {
                    return index >= vm.blockedEntries.length;
                }

                if (type === 'add') {
                    if (vm.inputType === 'textAndDate') {
                        return vm.inputModel[index] && index === vm.inputModel.length - 1 && vm.inputModel[index].text && vm.inputModel[index].date;
                    } else if (vm.inputType === 'attachedActs') {
                        return vm.inputModel[index] && index === vm.inputModel.length - 1 && vm.inputModel[index].type && vm.inputModel[index].number;
                    } else {
                        return vm.inputModel[index] && index === vm.inputModel.length - 1;
                    }
                } else if (type === 'remove') {
                    return index === 0 ? vm.inputModel[index] : true;
                } else {
                    return false;
                }
            };

            function clearOrRemoveInput(index){
                if(vm.inputModel.length > 1 ) {
                    vm.inputModel.splice(index, 1);
                } else {
                    vm.inputModel[index] = angular.copy(vm.objTemplate);
                }
            }

            //var tmp = angular.copy(params);
            function isFieldsetFilled(fieldset){
                if (typeof fieldset === 'string' || fieldset instanceof String) {
                    return fieldset.length
                } else {
                    for (var i in fieldset) {
                        if(fieldset[i] instanceof Array && fieldset[i].length === 0){
                            return false;
                        } else if (fieldset[i] === "") {
                            return false;
                        }
                    }
                    return true;
                }
            }

            function areAllFieldsetFilled() {
                for (var i in vm.inputModel) {
                    if(!isFieldsetFilled(vm.inputModel[i])){
                        return false;
                    }
                }
                return true;
            }

            function addInput(template){
                if (areAllFieldsetFilled()) {
                    vm.inputModel.push(template);
                }
            }

            function init() {
                vm.invalidFields = vm.invalidFields || [];
                vm.blockedEntries = [];
                vm.attachedActsTypes = ['resolution', 'motion', 'odg'];
                vm.attachedActsStates = ['approved', 'rejected', 'expired', 'withdrawn', 'delayed'];

                switch (vm.inputType) {
                    case "text": vm.objTemplate=textObjTemplate; break;
                    case "date": vm.objTemplate=dateObjTemplate;break;
                    case "textAndDate": vm.objTemplate=textAndDateObjTemplate ;break;
                    case "attachedActs": vm.objTemplate=attachedActObjTemplate ;break;
                    case "richiestaDiGiunta": vm.objTemplate= richiestaDiGiuntaObjTemplate;break;
                    case "resolution": vm.objTemplate = resolutionObjTemplate; break;
                    case "dea-presentation-input": vm.objTemplate = deaPresentationInputObjTemplate; break;
                    case "avvisoRettifica": vm.objTemplate = avvisoRettificaObjTemplate; break;
                }

                if (!vm.inputType) throw Exception;

                if (vm.editOnlyLastValue && vm.inputModel && vm.inputModel.length > 0) {
                    for (var i = 0; i < vm.inputModel.length - 1; i++) {
                        vm.blockedEntries.push(vm.inputModel[i]);
                    }
                }

                if (!vm.inputModel) {
                    vm.inputModel = [];
                }

                if (vm.inputModel.length === 0) {
                    vm.addInput(angular.copy(vm.objTemplate));
                } else if (!vm.inputModel[0]) {
                    vm.inputModel[0] = angular.copy(vm.objTemplate);
                }

                vm.label = $translate.instant(vm.inputLabel);
            }

            vm.isFieldInvalid = function (idx) {
                if (!vm.fieldName) {
                    return false;
                }

                var invalidFieldPresent = false;
                if (vm.invalidFields) {
                    if (idx) {
                        invalidFieldPresent = vm.invalidFields.indexOf(vm.fieldName + '_' + idx) > -1;
                    } else {
                        invalidFieldPresent = vm.invalidFields.indexOf(vm.fieldName) > -1
                    }
                }
                return vm.validating && invalidFieldPresent;
            };


            vm.searchFirmatari = function (searchText) {

                if(searchText.length < 3) {
                    return [];
                }
        
                return AnagraficaProtocollo.search(
                    { 
                        term: searchText,
                        protocolSystem: vm.protocolSystem,
                        //tipoAnagrafica: 'CR',
                        page: 0,
                        size: 20
                    }).$promise.then(onSuccessAnag, onErrorAnag);

                //var lista = vm.anagraficheProtocollo;
    /*             if (vm.atto.tipoProponente === 'GR') {
                    lista = vm.assessori;
                } else if (vm.atto.tipoProponente === 'COMMISSIONE') {
                    lista = vm.commissioni;
                } else {
                    lista = vm.consiglieri;
                } */
                //return searchText ? lista.filter(createFilterFor(searchText)) : [];
            };
            
            function onSuccessAnag(data, headers) {
                vm.anagraficheProtocollo = data;
                return data;
            }
        
            function onErrorAnag(error) {
                console.log("Errore nel recupero anagrafiche!", error);
            }

            function createFilterFor(query) {
                var lowercaseQuery = angular.lowercase(query);
    
                return function filterFn(firmatario) {
                    var codice = firmatario.codice ? firmatario.codice.toLowerCase() : '';
                    var descrizione = firmatario.descrizione ? firmatario.descrizione.toLowerCase() : '';
                    return (codice.indexOf(lowercaseQuery) >= 0 || descrizione.indexOf(lowercaseQuery) >= 0);
                };
            }

            function membroSelected(event){
                console.log(event);
            }


        }]
    };
});
