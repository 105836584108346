angular.module('gestioneAttiApp').directive('giuntaTrasmissionBlock', function () {
    return {
        restrict: "E",
        templateUrl: 'app/components/giuntaTrasmissionBlock/giuntaTrasmissionBlock.html',
        scope: {
            atto: '=',
            docTypes: "=",
            statoAttoCorrente: '=',
            workDocumentsMap: '=',
            giuntaTrasmissionSection: '=',
            utente: "=",
            changeDocumentTypeCallback: "=",
            changeDocumentVisibilityCallback: "=",
            publicView: "=",
            viewMode: '='
        },
        transclude: false,
        controllerAs: 'vm',
        bindToController: true,
        controller: ['Atto', 'User', 'PdlService', 'Documento', '$location','$anchorScroll', '$timeout', function (Atto, User, PdlService, Documento, $location, $anchorScroll, $timeout) {
                var vm = this;
                vm.attoRitiratoDocs = [];
                vm.clearRadio = PdlService.clearRadio;
                vm.giuntaTrasmission = {visible: true};
                vm.sezioni = {
                    GIUNTA_TRASMISSION: {
                        form: 'app/components/giuntaTrasmissionBlock/giuntaTrasmissionSection.html'
                    }
                };

                vm.stato = {
                    giuntaTrasmission: {
                        isCollapsed: vm.statoAttoCorrente === 'TRASMISSIONE GIUNTA' || vm.viewMode
                    }
                };

                vm.getSezioniFromMap = PdlService.getSezioniFromMap;
                vm.invalidFields = vm.invalidFields || [];

            }]
    };
});