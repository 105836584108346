(function () {
    'use strict';

    var attachedActsComponent = {

        templateUrl: 'app/entities/atto/sezioni/attachedActs.html',
        bindings: {
            'attachedActs' : '=',
            'atto' : '=',
            'changeDocumentType' : '=',
            'changeDocumentVisibility' : '=',
            'docTypes' : '=',
            'publicView' : '=',
            'utente' : '=',
            'headingPanelDisabled' : '=',
            'footerPanelDisabled' : '='
        },
        controller: ['Atto', 'ACT_TYPES', 'toastr', 'Principal', function (Atto, ACT_TYPES, toastr, Principal) {
            var vm = this;
            //ATTACHED ACTS
            if (vm.attachedActs) {
                vm.attachedActs.metadati.unifiedTexts = vm.attachedActs.metadati.unifiedTexts || [];
                vm.attachedActs.metadati.hasUnifiedTexts = vm.attachedActs.metadati.hasUnifiedTexts || false;

                vm.isAdmin = Principal.hasAnyAuthority(['ROLE_ADMIN']);
                vm.isSettoreAtti = Principal.hasAnyAuthority(['ROLE_SETTORE']);
                vm.isProtocollo = Principal.hasAnyAuthority(['ROLE_PROTOCOLLO']);
                vm.numeroAttoSearchText = '';
                vm.searchActs = searchActs;
                vm.attachedActsModel = [];
                vm.attachedActsReadOnlyListModel = [];
                resolveAttachedActs();
                vm.onAddAttachedAct = onAddAttachedAct;
                vm.onRemoveAttachedAct = onRemoveAttachedAct;
                vm.transformChip = transformChip;

                vm.acts = [];
                vm.basicSearchType = null;
                vm.actsTypeSelection = [];

                ACT_TYPES.forEach(function (actType) {
                    vm.actsTypeSelection.push(actType);
                });

                if (vm.atto.tipoAtto === 'ODG') {
                    removeTipoAtto(vm.actsTypeSelection, 'ODG');
                } else if (vm.atto.tipoAtto === 'PDRIS') {
                    removeTipoAtto(vm.actsTypeSelection, 'PDRIS');
                } else if(vm.atto.tipoAtto === 'CDG') {
                    vm.actsTypeSelection = []
                    vm.actsTypeSelection.push(getTipoAtto('PDRIS'));
                    vm.actsTypeSelection.push(getTipoAtto('ODG'));
                    vm.actsTypeSelection.push(getTipoAtto('MOZIONE'));
                    vm.actsTypeSelection.push(getTipoAtto('IO'));
                    vm.actsTypeSelection.push(getTipoAtto('IS'));
                }
                else if(vm.atto.tipoAtto === 'IEA'){
                    vm.actsTypeSelection = []
                    vm.actsTypeSelection.push(getTipoAtto('MOZIONE'));
                    vm.actsTypeSelection.push(getTipoAtto('ODG'));
                    vm.actsTypeSelection.push(getTipoAtto('PDRIS'));
                }
                else if(vm.atto.tipoAtto === 'REL'){
                    vm.actsTypeSelection = []
                    vm.actsTypeSelection.push(getTipoAtto('PDRIS'));
                }
            }

            function removeTipoAtto(listaTipi, tipoAtto) {
                for (var i = 0; i < listaTipi.length; i++) {
                    if (listaTipi[i].value === tipoAtto) {
                        listaTipi.splice(i, 1);
                        break;
                    }
                }
            }

            function getTipoAtto(tipoAtto) {
                for(var i = 0; i < ACT_TYPES.length; i++){
                    if(ACT_TYPES[i].value === tipoAtto) {
                        return ACT_TYPES[i];
                    }
                }
            }

            function transformChip($chip) {
                //Qui scegliere cosa tenere dentro la chip!
                return { "id": $chip.id, "legislatura": $chip.legislatura, "numeroAtto": $chip.numeroAtto, "titolo": $chip.titolo, "tipoAtto": $chip.tipoAtto, "statoAtto": $chip.statoCorrente.statoAtto.name };
            }

            function searchActs(tokenNumeroAtto) {
                var results = vm.acts;
                return tokenNumeroAtto ? results.filter(createFilterFor(tokenNumeroAtto)) : [];
            }

            function createFilterFor(tokenNumeroAtto) {
                return function filterFn(atto) {
                    var numeroAtto = atto.numeroAtto;
                    return (numeroAtto.includes(tokenNumeroAtto) && !alreadySelected(atto.id));
                };
            }
            function alreadySelected(idAtto) {
                for (var i in vm.attachedActs.metadati.unifiedTexts) {
                    if (idAtto === vm.attachedActs.metadati.unifiedTexts[i].id) {
                        return true;
                    }
                }
                return false;
            }

            function onAddAttachedAct($index, $chip) {
                if (isValidChip($chip)) {
                    vm.attachedActs.metadati.unifiedTexts.push({ id: $chip.id });
                    vm.attachedActsReadOnlyListModel.push($chip);
                    sortAttachedActs();
                }
                vm.attachedActsModel = [];
            }
            function isValidChip(chip) {
                return chip && chip.id && chip.legislatura && chip.numeroAtto && chip.titolo && chip.tipoAtto;
            }

            function onRemoveAttachedAct($index, $chip) {
                for (var i in vm.attachedActs.metadati.unifiedTexts) {
                    if ($chip.id === vm.attachedActs.metadati.unifiedTexts[i].id) {
                        vm.attachedActs.metadati.unifiedTexts.splice(i, 1);
                        break;
                    }
                }
            }

            vm.getAllActs = function () {
                vm.acts = [];
                if (!vm.basicSearchType) {
                    return;
                }
                Atto.queryNonPaged({
                    type: vm.basicSearchType
                }, onSuccess, onError);
                function onSuccess(data) {
                    vm.acts = data;
                };
                function onError(error) {
                    console.log(error);
                }
            }


            function resolveAttachedActs() {
                if (!vm.attachedActs.metadati.unifiedTexts)
                    return [];

                for (var i in vm.attachedActs.metadati.unifiedTexts) {
                    Atto.get({
                        id: vm.attachedActs.metadati.unifiedTexts[i].id
                    }, onRetrieveSuccess, onError)
                }
                function onRetrieveSuccess(data, headers) {
                    vm.attachedActsReadOnlyListModel.push({ id: data.id, legislatura: data.legislatura, numeroAtto: data.numeroAtto, titolo: data.titolo, tipoAtto: data.tipoAtto, statoAtto: data.statoCorrente.statoAtto.name });
                    sortAttachedActs();
                }
                function onError(error) {
                    console.log(error);
                }
            }

            function sortAttachedActs(){
                vm.attachedActsReadOnlyListModel.sort(function(a,b){
                    if(a.legislatura !== b.legislatura) {
                        if( a.legislatura > b.legislatura ) {
                            return 1;
                        } else {
                            return -1;
                        }
                    }
                    if(a.tipoAtto !== b.tipoAtto) {
                        if ( a.tipoAtto > b.tipoAtto ) {
                            return 1;
                        } else {
                            return -1;
                        }
                    }
                    if(a.numeroAtto !== b.numeroAtto) {
                        if ( a.numeroAtto > b.numeroAtto) {
                            return 1;
                        } else {
                            return -1;
                        }
                    }
                    return 0;
                })
            }
        }],
        controllerAs: 'vm'
    };

    angular.module('gestioneAttiApp').component('attachedActsComponent', attachedActsComponent);
})();
