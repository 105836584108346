(function () {

    'use strict';

    var PdlService = ['Atto', 'Principal', 'User', 'toastr',
        function (Atto, Principal, User, toastr) {
            var service = this;

            service.datiPubblici = false;
            service.primaryCommission = false;
            service.sezioniMap = {};
            service.workDocumentsMap = {};
            service.dateRinvio = {};

            /**
             * Carica e inizializza le sezioni della PDL.
             *
             * @param {type} atto
             * @param {type} sezioniAtto
             * @param {type} numeroRinvii
             * @param {type} userRolesMap
             * @param {type} editable
             * @param {type} utente
             * @param {type} idPrimaCommissione
             * @returns {undefined}
             */
            service.loadSezioni = function (atto, sezioniAtto, numeroRinvii, userRolesMap, editable, utente, idPrimaCommissione, publicView, userSoggettiEsterniMap) {
                // Put assignment letter in first position if not already there!
                changeAssignmentLetterPosition(sezioniAtto);
                service.sezioniMap = {};
                service.workDocumentsMap = {};
                angular.forEach(sezioniAtto, function (sezione) {
                    sezione.metadati = sezione.metadati || {};

                    sezione.metadati.links = sezione.metadati.links || [];

                    var authorities = sezione.ruoli;
                    if (sezione.nomeSezione === 'COMMISSION_OPINIONS') {
                        if(authorities.indexOf("ROLE_EXTERNAL") === -1) {
                            authorities.push('ROLE_EXTERNAL');
                        }
                    } else if (sezione.nomeSezione === 'COMMISSION_ASSIGNED_EMENDMENTS' || sezione.nomeSezione === 'COMMISSION_EMENDMENTS'
                            || sezione.nomeSezione === 'COURT_EMENDMENTS') {
                        authorities = ['ROLE_ADMIN', 'ROLE_PROTOCOLLO'];

                        /* MODIFICHE TEMPORANEE DA RIMUOVERE A INTEGRAZIONE CON PROTOCOLLO COMPLETATA */
                        if (sezione.nomeSezione === 'COMMISSION_ASSIGNED_EMENDMENTS' || sezione.nomeSezione === 'COMMISSION_EMENDMENTS') {
                            authorities.push('ROLE_COMMISSIONE');
                        } else if (sezione.nomeSezione === 'COURT_EMENDMENTS') {
                            authorities.push('ROLE_AULA');
                        }
                        /* FINE */

                        sezione.ruoli = authorities;
                    } else if (sezione.nomeSezione === 'WORK_DOCUMENTS') {
                        Atto.sezioniFiles({id: sezione.idAtto, idSezione: sezione.id, publicView: publicView}, {}, function (documenti) {
                            angular.forEach(documenti, function (documento) {
                                service.initDocumentsCheck(documento, sezione.metadati.numeroRinvio, service.workDocumentsMap);
                            });
                        }, function () {
                            toastr.error('Impossibile recuperare le informazioni relative ai documenti di lavoro')
                        });
                    } else if (sezione.nomeSezione === 'POST_COURT') {
                        Atto.sezioniFiles({id: sezione.idAtto, idSezione: sezione.id, publicView: publicView}, {}, function (documenti) {
                            if(documenti.length > 0) service.workDocumentsMap['ADEMPIMENTI'] = true;
                            angular.forEach(documenti, function (documento) {
                                service.initDocumentsCheck(documento, sezione.metadati.numeroRinvio, service.workDocumentsMap);
                            });
                        }, function () {
                            toastr.error('Impossibile recuperare le informazioni relative agli adempimenti')
                        });
                    }else if (sezione.nomeSezione === 'LEGITIMACY') {
                        Atto.sezioniFiles({id: sezione.idAtto, idSezione: sezione.id, publicView: publicView}, {}, function (documenti) {
                            angular.forEach(documenti, function (documento) {
                                service.initDocumentsCheck(documento, sezione.metadati.numeroRinvio, service.workDocumentsMap);
                            });
                        }, function () {
                            toastr.error('Impossibile recuperare le informazioni relative ai documenti di legittimità')
                        });
                    } else if (sezione.nomeSezione === 'FEASIBILITY') {
                        Atto.sezioniFiles({id: sezione.idAtto, idSezione: sezione.id, publicView: publicView}, {}, function (documenti) {
                            angular.forEach(documenti, function (documento) {
                                service.initDocumentsCheck(documento, sezione.metadati.numeroRinvio, service.workDocumentsMap);
                            });
                        }, function () {
                            toastr.error('Impossibile recuperare le informazioni relative ai documenti di fattibilità')
                        });
                    } else if(atto.tipoAtto === 'IO' && sezione.nomeSezione === 'ASSIGNMENT_LETTER') {
                        Atto.sezioniFiles({id: sezione.idAtto, idSezione: sezione.id, publicView: publicView}, {}, function (documenti) {
                            angular.forEach(documenti, function (documento) {
                                service.initDocumentsCheck(documento, 0, service.workDocumentsMap);
                            });
                        }, function () {
                            toastr.error('Impossibile recuperare le informazioni relative ai documenti della lettera di assegnazione per questa interrogazione orale!')
                        });
                    } else if((atto.tipoAtto === 'DEA' || atto.tipoAtto === 'PEA') && sezione.nomeSezione === 'GIUNTA_TRASMISSION') {
                        if(authorities.indexOf("ROLE_COMMISSIONE") === -1) {
                            authorities.push('ROLE_COMMISSIONE');
                        }
                        Atto.sezioniFiles({id: sezione.idAtto, idSezione: sezione.id, publicView: publicView}, {}, function (documenti) {
                            angular.forEach(documenti, function (documento) {
                                service.initDocumentsCheck(documento, 0, service.workDocumentsMap);
                            });
                        }, function () {
                            toastr.error('Impossibile recuperare le informazioni relative ai documenti della lettera di trasmissione!')
                        });
                    } else if (atto.tipoAtto === 'PDD' && sezione.nomeSezione === 'PROCEDIMENTO') {
                        if(authorities.indexOf("ROLE_COMMISSIONE") === -1) {
                            authorities.push('ROLE_COMMISSIONE');
                        }
                    } else if (sezione.nomeSezione === 'RETIRED') {
                        if(authorities.indexOf("ROLE_COMMISSIONE") === -1) {
                            authorities.push('ROLE_COMMISSIONE');
                        }
                    }

                    if (editable) {
                        sezione.editable = userRolesMap.isAdmin;

                        if (sezione.nomeSezione === 'PRESENTATION') {
                            sezione.skipCurrentStateCheck = true;
                        } else if (atto.statoCorrente.statoAtto.name === 'COMMISSIONE' && sezione.statoAttoAssociato.name === atto.statoCorrente.statoAtto.name) {
                            service.datiPubblici = sezione.semprePubblica;
                        } else if (atto.statoCorrente.statoAtto.name === 'AULA' && sezione.nomeSezione === 'COURT_MEETING') {
                            service.datiPubblici = sezione.semprePubblica;
                        }

                        if (!sezione.editable) {
                            if (sezione.nomeSezione === 'PRESENTATION') {
                                sezione.editable = userRolesMap.isSettoreAtti;
                            } else if(sezione.nomeSezione === 'GIUNTA_TRASMISSION'){
                                // Ticket-2481 Per le PEA, la sezione TRASMISSIONE GIUNTA, quando lo stato dell'atto è "TRASMISSIONE GIUNTA", deve essere gestita dai ruoli previsti come uno stato non finale
                                if (atto.tipoAtto === "PEA" && atto.statoCorrente.statoAtto.name === 'TRASMISSIONE GIUNTA'){
                                    sezione.editable = Principal.hasAnyAuthority(authorities) && (userRolesMap.isSettoreAtti || User.hasAnyCommission(utente, service.sezioniMap.ASSIGNMENT_LETTER.metadati.parereReferente));
                                } else if (atto.tipoAtto === "DEA" && atto.statoCorrente.statoAtto.name === 'TRASMISSIONE GIUNTA'){
                                    sezione.editable = Principal.hasAnyAuthority(authorities) && (userRolesMap.isSettoreAtti || User.hasAnyCommission(utente, service.sezioniMap.ASSIGNMENT_LETTER.metadati.parereReferente));;
                                }
                            } else if(sezione.nomeSezione === 'PROCEDIMENTO'){
                                if (atto.tipoAtto === "PDD" && atto.statoCorrente.statoAtto.name === 'APPROVATO'){
                                    sezione.editable = Principal.hasAnyAuthority(authorities); 
                                }
                            } else {
                                if (atto.statoCorrente.statoAtto.name === 'COMMISSIONE' && sezione.nomeSezione !== 'ATTACHED_ACTS') {
                                    if (sezione.nomeSezione === 'ASSIGNMENT_LETTER') {
                                        sezione.editable = userRolesMap.isSettoreAtti || userRolesMap.isProtocollo;
                                        sezione.skipCurrentStateCheck = true;
                                    } else if (sezione.nomeSezione === 'PARERE_CAL') {
                                        sezione.editable = userSoggettiEsterniMap.isCAL || userRolesMap.isProtocollo;
                                    } else if (sezione.nomeSezione === 'LEGITIMACY') {
                                        sezione.editable = userRolesMap.isLegitimacy;
                                    } else if (sezione.nomeSezione === 'FEASIBILITY') {
                                        sezione.editable = userRolesMap.isFeasibility;
                                    } else if (sezione.nomeSezione === 'REFERENDUM_REPORT') {
                                        sezione.editable = userRolesMap.isProtocollo;
                                    } else if (sezione.nomeSezione === 'UNION_PROPOSAL') {
                                        sezione.editable = User.hasAnyCommission(utente, service.sezioniMap.ASSIGNMENT_LETTER.metadati.parereReferente);
                                    } else {
                                        sezione.editable = User.isCommissionRole(utente, 'Dipendente')
                                            || (sezione.nomeSezione === 'COMMISSION_OPINIONS' && userRolesMap.isExternalSubject);
                                        if (sezione.editable) {
                                            var assignmentMetadata = service.getSezioniFromMap('ASSIGNMENT_LETTER', 0).metadati;
                                            var isReferente = User.hasAnyCommission(utente, assignmentMetadata.parereReferente);

                                            if (sezione.nomeSezione === 'COMMISSION_OPINIONS') {
                                                //devi essere tra gli esprimenti parere
                                                sezione.editable = isReferente
                                                    || User.hasAnyCommission(utente, assignmentMetadata.parereSecondario)
                                                    || (assignmentMetadata.parereDiMerito && User.hasAnyCommission(utente, assignmentMetadata.parereDiMerito))
                                                    || User.hasAnyExternalSubject(utente, assignmentMetadata.parereObbligatorio)
                                                    || User.hasAnyExternalSubject(utente, assignmentMetadata.osservazioni)
                                                    || (assignmentMetadata.parereIstituzionale && User.hasAnyCommission(utente, [idPrimaCommissione]));
                                            } else {
                                                //devi essere referente
                                                sezione.editable = isReferente;
                                            }
                                        }
                                    }
                                } else if (atto.statoCorrente.statoAtto.name === 'APPROVATO' && (sezione.nomeSezione === 'ADEMPIMENTI' || sezione.nomeSezione === 'MATERIE')) {
                                    sezione.editable = Principal.hasAnyAuthority(authorities);
                                } else if (atto.tipoAtto === "PEA" && atto.statoCorrente.statoAtto.name === 'APPROVATO' && (sezione.nomeSezione === 'EMANAZIONE' || sezione.nomeSezione === 'POST_COURT')) {
                                    sezione.editable = Principal.hasAnyAuthority(authorities);
                                } else if (atto.statoCorrente.statoAtto.name === 'TRASFERITA COMPETENZA') {
                                    sezione.editable = Principal.hasAnyAuthority(authorities);
                                } else if (sezione.nomeSezione === 'COURT_OUTCOME' || (sezione.statoAttoAssociato.finalState && sezione.statoAttoAssociato.name !== 'RITIRATO')) {
                                    sezione.editable = userRolesMap.isSettoreAtti || userRolesMap.isAula;
                                } else {
                                    sezione.editable = Principal.hasAnyAuthority(authorities);
                                }

                                sezione.editable = sezione.editable
                                    && (sezione.skipCurrentStateCheck || sezione.statoAttoAssociato.name === atto.statoCorrente.statoAtto.name || sezione.nomeSezione === 'COURT_OUTCOME' || sezione.statoAttoAssociato.finalState)
                                    && (numeroRinvii === 0 || sezione.metadati.numeroRinvio === numeroRinvii || sezione.nomeSezione === 'PROCEDIMENTO' || sezione.nomeSezione === 'ASSIGNMENT_LETTER');
                            }
                        }
                    } else {
                        sezione.editable = false;
                    }

                    // sezione è visibile solo se quella da editare correntemente, oppure siamo in view
                    sezione.visible = !editable || sezione.editable;

                    if (sezione.nomeSezione === 'COMMISSION_EMENDMENTS' && (sezione.metadati.firmatari === null || sezione.metadati.firmatari === undefined)) {
                        sezione.metadati.firmatari = [];
                    } else if (sezione.nomeSezione === 'COMMISSION_OPINIONS') {
                        sezione.metadati.parereReferente = sezione.metadati.parereReferente || {};
                        sezione.metadati.parereSecondario = sezione.metadati.parereSecondario || {};
                        sezione.metadati.parereIstituzionale = sezione.metadati.parereIstituzionale || {};
                        sezione.metadati.parereObbligatorio = sezione.metadati.parereObbligatorio || {};
                        sezione.metadati.osservazioni = sezione.metadati.osservazioni || {};
                    } else if (sezione.nomeSezione === 'ASSIGNMENT_LETTER') {
                        sezione.metadati.aulaDiretta = sezione.metadati.aulaDiretta || false;
                        sezione.metadati.riduzioneTermineParere = sezione.metadati.riduzioneTermineParere || 'false';
                    }

                    service.sezioniMap[service.getSezioniMapKey(sezione.nomeSezione, sezione.metadati.numeroRinvio)] = sezione;

                    if (sezione.nomeSezione === 'ASSIGNMENT_LETTER') {
                        service.primaryCommission = User.isPrimaryCommission(utente, service.sezioniMap.ASSIGNMENT_LETTER.metadati.parereReferente);
                    }
                });
            };


            function changeAssignmentLetterPosition(sezioniAtto) {
                if(sezioniAtto && sezioniAtto.length > 0) {
                    for(var i = 0 ; i < sezioniAtto.length; i++) {
                        if(i >= 1 && sezioniAtto[i].nomeSezione === "ASSIGNMENT_LETTER") {
                            var sezioneAssignment = sezioniAtto.splice(i, 1);
                            sezioniAtto.splice(0,0,sezioneAssignment[0]);
                            break;
                        }
                    }
                }
            }

            /**
             * Inizializza la sezione "Documenti di lavoro", "Legittimità" e "Fattibilità" predispondendo la
             * mappa che rappresenta la selezione dei checkbox.
             *
             * @param {type} documento
             * @param {type} numeroRinvio
             * @returns {undefined}
             */
            service.initDocumentsCheck = function (documento, numeroRinvio) {
                var suffix = "";
                if (numeroRinvio > 0) {
                    suffix += "_" + numeroRinvio;
                }

                var tipoDoc = documento.tipoDocumento || documento.docType;
                switch (tipoDoc.tipo) {
                    case 'Scheda istruttoria':
                        service.workDocumentsMap['SCHEDA_ISTRUTTORIA' + suffix] = true;
                        break;
                    case 'Testo a fronte':
                        service.workDocumentsMap['TESTI_FRONTE' + suffix] = true;
                        break;
                    case 'Ipotesi testo riformulato':
                        service.workDocumentsMap['IPOTESI_TESTO' + suffix] = true;
                        break;
                    case 'Rassegna stampa':
                    case 'Articoli di interesse':
                        service.workDocumentsMap['RASSEGNA_STAMPA' + suffix] = true;
                        break;
                    case 'Scheda di legittimità (parere referente)':
                        service.workDocumentsMap['SCHEDA_LEGITTIMITA_REF' + suffix] = true;
                        break;
                    case 'Scheda di legittimità (parere secondario)':
                        service.workDocumentsMap['SCHEDA_LEGITTIMITA_SEC' + suffix] = true;
                        break;
                    case 'Scheda di legittimità (parere istituzionale)':
                        service.workDocumentsMap['SCHEDA_LEGITTIMITA_IST' + suffix] = true;
                        break;
                    case 'Scheda di analisi di fattibilità':
                        service.workDocumentsMap['SCHEDA_FATTIBILITA' + suffix] = true;
                        break;
                    case 'Richiesta di risposta interrogazione':
                        service.workDocumentsMap['RISPOSTA_INTERROGAZIONE'] = true;
                        break;
                    case 'Lettera di assegnazione':
                        service.workDocumentsMap['LETTERA_ASSEGNAZIONE'] = true;
                        break;
                    case 'Lettera trasmissione':
                        service.workDocumentsMap['LETTERA_TRASMISSIONE'] = true;
                        break;
                    case 'Lettera trasmissione atti collegati':
                        service.workDocumentsMap['LETTERA_TRASMISSIONE_ATTI_COLLEGATI'] = true;
                        break;
                    case 'Lettera trasmissione giunta':
                        service.workDocumentsMap['LETTERA_TRASMISSIONE_GIUNTA'] = true;
                        break;
                    case 'Lettera trasmissione consiglieri':
                        service.workDocumentsMap['LETTERA_TRASMISSIONE_CONSIGLIERI'] = true;
                        break;
                    case 'Comunicazione Giunta Regionale':
                        service.workDocumentsMap['COMUNICAZIONE_GIUNTA_REGIONALE'] = true;
                        break;
                    case 'Corrispondenza Varia':
                        service.workDocumentsMap['CORRISPONDENZA_VARIA' + suffix] = true;
                        break;   
                }
            };

            /**
             * Resetta la sezione "Legittimità" e "Fattibilità" predispondendo la
             * mappa che rappresenta la deselezione dei checkbox.
             *
             * @param {type} documento
             * @param {type} numeroRinvio
             * @returns {undefined}
             */
            service.removeDocumentsCheck = function (documento, numeroRinvio) {
                var suffix = "";
                if (numeroRinvio > 0) {
                    suffix += "_" + numeroRinvio;
                }

                var tipoDoc = documento.tipoDocumento || documento.docType;
                switch (tipoDoc.tipo) {
                    case 'Scheda istruttoria':
                        service.workDocumentsMap['SCHEDA_ISTRUTTORIA' + suffix] = false;
                        break;
                    case 'Testo a fronte':
                        service.workDocumentsMap['TESTI_FRONTE' + suffix] = false;
                        break;
                    case 'Ipotesi testo riformulato':
                        service.workDocumentsMap['IPOTESI_TESTO' + suffix] = false;
                        break;
                    case 'Rassegna stampa':
                    case 'Articoli di interesse':
                        service.workDocumentsMap['RASSEGNA_STAMPA' + suffix] = false;
                        break;
                    case 'Scheda di legittimità (parere referente)':
                        service.workDocumentsMap['SCHEDA_LEGITTIMITA_REF' + suffix] = false;
                        break;
                    case 'Scheda di legittimità (parere secondario)':
                        service.workDocumentsMap['SCHEDA_LEGITTIMITA_SEC' + suffix] = false;
                        break;
                    case 'Scheda di legittimità (parere istituzionale)':
                        service.workDocumentsMap['SCHEDA_LEGITTIMITA_IST' + suffix] = false;
                        break;
                    case 'Scheda di analisi di fattibilità':
                        service.workDocumentsMap['SCHEDA_FATTIBILITA' + suffix] = false;
                        break;
                }
            };


            /**
             * Restituisce la sezione corrispondente al rinvio fornito.
             *
             * @param {type} nomeSezione
             * @param {type} numeroRinvio
             * @returns {pdl.serviceL#1.pdl.serviceL#1#PdlService.service.getSezioniFromMap.sezioniMap}
             */
            service.getSezioniFromMap = function (nomeSezione, numeroRinvio) {
                return service.sezioniMap[service.getSezioniMapKey(nomeSezione, numeroRinvio)];
            };

            /**
             * Restituisce la chiave univoca che identifica una sezione relativa
             * ad un dato rinvio.
             * @param {type} nomeSezione
             * @param {type} numeroRinvio
             * @returns {String}
             */
            service.getSezioniMapKey = function (nomeSezione, numeroRinvio) {
                if (numeroRinvio && numeroRinvio > 0) {
                    return nomeSezione + '_' + numeroRinvio;
                }
                return nomeSezione;
            };

            /**
             * Restituisce l'array delle sezioni presenti nei vari rinvii, dalla
             * prima fase fino al rinvio specificato compreso.
             * @param {type} nomeSezione
             * @param {type} numeroRinvio
             * @returns {pdl.serviceL#1.pdl.serviceL#1#PdlService.service.getAllRepeatableSectionsByName.result}
             */
            service.getAllRepeatableSectionsByName = function (nomeSezione, numeroRinvio) {
                var result = [];
                if (!numeroRinvio) {
                    var sezione = service.sezioniMap[service.getSezioniMapKey(nomeSezione, 0)];
                    if (sezione) {
                        result.push(sezione);
                    }
                } else {
                    for (var i = 0; i <= numeroRinvio; i++) {
                        var sezione = service.sezioniMap[service.getSezioniMapKey(nomeSezione, i)];
                        if (sezione) {
                            result.push(sezione);
                        }
                    }
                }
                return result;
            };

            /**
             * Inizializza le date di rinvio.
             *
             * @param {type} atto
             * @returns {undefined}
             */
            service.initDateRinvio = function (atto) {
                service.dateRinvio = {};
                if (atto.numeroRinvii && atto.numeroRinvii > 0) {
                    var commissionStatesCount = 0;
                    angular.forEach(atto.storico, function (state) {
                        if (state.statoAtto.name === 'COMMISSIONE') {
                            if (commissionStatesCount > 0) {
                                state.statoAtto.dataRinvio = service.getSezioniFromMap('COURT_OUTCOME', commissionStatesCount - 1).metadati.dataRinvio;
                                service.dateRinvio[commissionStatesCount] = state.statoAtto.dataRinvio;
                            }
                            commissionStatesCount++;
                        }
                    });
                }
            };

            /**
             * Restituisce true se l'utente può modificare l'opinione relativa
             * alle commissioni specificate in subjects, false altrimenti.
             *
             * @param {type} utente
             * @param {type} subjects
             * @param {type} idx
             * @param {type} isAdmin
             * @returns {Boolean}
             */
            service.isCommissionOpinionEditable = function (utente, subjects, idx, isAdmin) {
                var sezione = service.getSezioniFromMap('COMMISSION_OPINIONS', idx);
                return sezione.editable && (isAdmin || User.hasAnyCommission(utente, subjects));
            };

            /**
             * Restituisce true se l'utente può modificare l'osservazione
             * relativa al soggetto esternospecificate in subjects, false
             * altrimenti.
             *
             * @param {type} utente
             * @param {type} subjects
             * @param {type} idx
             * @param {type} isAdmin
             * @returns {Boolean}
             */
            service.isExternalSubjectOpionionEditable = function (utente, subjects, idx, isAdmin) {
                var sezione = service.getSezioniFromMap('COMMISSION_OPINIONS', idx);
                return sezione.editable && (isAdmin || User.hasAnyExternalSubject(utente, subjects));
            };
            
            service.clearRadio = function(disabled, section, metadataName) {
              if (!disabled) {
                  section.metadati[metadataName] = null;
              }  
            };

        }];

    angular.module('gestioneAttiApp').service('PdlService', PdlService);

})();
