(function () {
    'use strict';

    angular.module('gestioneAttiApp').controller('AttoDetailController', AttoDetailController);

    AttoDetailController.$inject = ['$rootScope', '$timeout', '$state', 'toastr', 'previousState', 'entity', 'sezioniAtto', 'editable', 'Atto',
        'Principal', 'utente', 'commissioni', 'soggettiEsterni', 'settori', 'FileSaver', '$mdDialog', '$mdSidenav', 'docTypes', 'direzioni',
        'User', 'PdlService', 'ValidatorFactory', 'Documento', 'publicView', 'ACT_TYPES', 'enti', 'organi', '$stateParams', '$location', 'AnagraficaProtocollo'];

    function AttoDetailController($rootScope, $timeout, $state, toastr, previousState, entity, sezioniAtto, editable, Atto,
            Principal, utente, commissioni, soggettiEsterni, settori, FileSaver, $mdDialog, $mdSidenav, docTypes, direzioni, User, PdlService,
            ValidatorFactory, Documento, publicView, ACT_TYPES, enti, organi, $stateParams, $location, AnagraficaProtocollo) {

        var vm = this;
        //Gestione Esito atto in presentazione per IS
        vm.hideEsitoAttoMedatati = true;
        vm.isCourtOutcomeInPresentationSection = true;
        var opinionTypes = ["REFERENTE", "SECONDARIO", "ISTITUZIONALE", "OBBLIGATORIO", "OSSERVAZIONI"];
        if (entity.tipoAtto === 'PDD') {
            opinionTypes.push("DI_MERITO");
        }

        initController();

        vm.addPersonToList = function (chip) {
            // If it is an object, it's already a known chip
            if (angular.isObject(chip)) {
                return chip;
            }
            // Otherwise, create a new one
            return {descrizione: chip};
        };

        var confirmTransitionDlg = $mdDialog.confirm()
                .title('Attenzione!')
                .textContent("L'operazione richiesta non è reversibile, sei sicuro di voler procedere?")
                .ariaLabel('conferma transizione di stato')
                .ok('Conferma')
                .cancel('Annulla');

        var statoAuthorities = vm.atto.statoCorrente.statoAtto.roles;
        if (vm.atto.statoCorrente.statoAtto.name === 'COMMISSIONE') {
            statoAuthorities.push('ROLE_EXTERNAL');
        }

        vm.searchFirmatari = function (searchText) {

            if(searchText.length < 3) {
                return [];
            }

            //console.log("CERCO FIRMATARI ", searchText);

            return AnagraficaProtocollo.search(
                {
                    term: searchText,
                    protocolSystem: vm.atto.protocolSystem,
                    tipoAnagrafica: vm.atto.tipoProponente,
                    page: 0,
                    size: 20
                }).$promise.then(onSuccessAnag, onErrorAnag);

/*             if (vm.atto.tipoProponente === 'GR') {
                lista = vm.assessori;
            } else if (vm.atto.tipoProponente === 'COMMISSIONE') {
                lista = vm.commissioni;
            } else {
                lista = vm.consiglieri;
            } */
            /*var lista = vm.anagraficheProtocollo;
            return searchText ? lista.filter(createFilterFor(searchText)) : [];*/
        };

        function onSuccessAnag(data, headers) {
            vm.anagraficheProtocollo = data;
            return vm.anagraficheProtocollo;
        }

        function onErrorAnag(error) {
            console.log("Errore nel recupero anagrafiche!", error);
        }

        vm.searchEnti = function (searchText) {
            return searchText ? vm.enti.filter(createFilterForAnagraficaIterlegis(searchText)) : [];
        };

        vm.searchOrgani = function (searchText) {
            return searchText ? vm.organi.filter(createFilterForAnagraficaIterlegis(searchText)) : [];
        };

        function createFilterForAnagraficaIterlegis(query) {
            var lowercaseQuery = angular.lowercase(query);

            return function filterFn(anagrafica) {
                var descrizioneAnagrafica = anagrafica.descrizioneAnagrafica ? anagrafica.descrizioneAnagrafica.toLowerCase() : '';
                return descrizioneAnagrafica.indexOf(lowercaseQuery) >= 0;
            };
        }

        vm.isSectorUserEnabled = function (actions) {
            if (!angular.isArray(actions)) {
                actions = [actions];
            }
            return User.isSectorUserEnabled(vm.utente, actions, vm.azioniSettoriById);
        };

        function createFilterFor(query) {
            var lowercaseQuery = angular.lowercase(query);

            return function filterFn(firmatario) {
                var codice = firmatario.codice ? firmatario.codice.toLowerCase() : '';
                var descrizione = firmatario.descrizione ? firmatario.descrizione.toLowerCase() : '';
                return (codice.indexOf(lowercaseQuery) >= 0 || descrizione.indexOf(lowercaseQuery) >= 0);
            };
        }

        vm.canSeeEditButton = function () {
            if (vm.isAdmin) return true;
            return vm.viewMode
                    && vm.isAuthenticated
                    && !Principal.isEnteUser(vm.utente.authorities)
                    && (!vm.atto.statoCorrente.statoAtto.finalState ||
                            (vm.userRolesMap.isAula && vm.atto.statoCorrente.statoAtto.name === 'APPROVATO') ||
                            (vm.userRolesMap.isSettoreAtti && vm.atto.statoCorrente.statoAtto.name === 'TRASMISSIONE GIUNTA') ||
                            ((vm.atto.tipoAtto === 'PEA' || vm.atto.tipoAtto === 'DEA') && (vm.atto.statoCorrente.statoAtto.name === 'TRASMISSIONE GIUNTA' || vm.atto.statoCorrente.statoAtto.name === 'APPROVATO') && vm.userRolesMap.isCommissione) ||
                            (vm.userRolesMap.isCommissione && vm.atto.tipoAtto === 'PDD' && vm.atto.statoCorrente.statoAtto.name === 'APPROVATO') ||
                            ((vm.userRolesMap.isCommissione || vm.userRolesMap.isSettoreAtti) && vm.atto.statoCorrente.statoAtto.name === 'RITIRATO') ||
                            ((vm.userRolesMap.isSettoreAtti) && vm.atto.statoCorrente.statoAtto.name === 'TRASFERITA COMPETENZA')
                        )
                    && (vm.atto.statoCorrente.statoAtto.name !== 'COMMISSIONE' || !vm.userRolesMap.isCommissione || User.isCommissionRole(vm.utente, 'Dipendente'));
        };

        toggleParereSecondario();
        toggleParereDiMerito();

        function canSeeButton(button) {
            if (!editable) {
                return false;
            }
            var authorities = button.authorities || [];
            authorities.push('ROLE_ADMIN');
            if (button.forceHide) {
                return false;
            } else if (button.showFunction) {
                return button.showFunction();
            } else if (button.onlyPrimaryCommission) {
                return vm.isAdmin || vm.primaryCommission || (button.authorities && Principal.hasAnyAuthority(button.authorities) && vm.userRolesMap.isSettoreAtti);
            } else if (button.onlyInvolvedSubjects && vm.sezioniMap.ASSIGNMENT_LETTER) {
                var assignmentMetadata = vm.sezioniMap.ASSIGNMENT_LETTER.metadati;
                return vm.isAdmin
                        || User.hasAnyCommission(vm.utente, assignmentMetadata.parereReferente)
                        || User.hasAnyCommission(vm.utente, assignmentMetadata.parereSecondario)
                        || (assignmentMetadata.parereDiMerito && User.hasAnyCommission(vm.utente, assignmentMetadata.parereDiMerito))
                        || User.hasAnyExternalSubject(vm.utente, assignmentMetadata.parereObbligatorio)
                        || User.hasAnyExternalSubject(vm.utente, assignmentMetadata.osservazioni)
                        || (assignmentMetadata.parereIstituzionale && User.hasAnyCommission(vm.utente, [vm.idPrimaCommissione]));
            } else {
                // Visibile se il bottone non ha restrizioni oppure l'utente ha uno di quei ruoli
                // E si verifica una delle seguenti:
                //      L'Utente è admin
                //      Il Bottone non ha "sectorCapabilities"
                //      L'Utente ha ruolo "ROLE_SETTORE" e ha i permessi presenti in button.sectorCapabilities
                return (!button.authorities || Principal.hasAnyAuthority(authorities)) &&
                        (vm.isAdmin || !button.sectorCapabilities || User.isSectorUserEnabled(vm.utente, button.sectorCapabilities, vm.azioniSettoriById));
            }
        }

        function isLegitimacy() {
            return vm.atto.statoCorrente.statoAtto.name === 'COMMISSIONE' && vm.userRolesMap.isLegitimacy;
        }

        function isFeasibility() {
            return vm.atto.statoCorrente.statoAtto.name === 'COMMISSIONE' && vm.userRolesMap.isFeasibility;
        }

        function getOpinionsSubjects(opinionType) {
            if (!vm.sezioniMap.ASSIGNMENT_LETTER) {
                return;
            }

            var selectedSubjectsIds = null;
            var subjects = null;
            var result = [];
            switch (opinionType) {
                case "REFERENTE":
                    selectedSubjectsIds = vm.sezioniMap.ASSIGNMENT_LETTER.metadati.parereReferente;
                    subjects = vm.commissioni;
                    break;
                case "SECONDARIO":
                    selectedSubjectsIds = vm.sezioniMap.ASSIGNMENT_LETTER.metadati.parereSecondario;
                    subjects = vm.commissioni;
                    break;
                case "ISTITUZIONALE":
                    if (vm.sezioniMap.ASSIGNMENT_LETTER.metadati.parereIstituzionale) {
                        selectedSubjectsIds = [vm.idPrimaCommissione];
                        subjects = vm.commissioni;
                    }
                    break;
                case "OBBLIGATORIO":
                    selectedSubjectsIds = vm.sezioniMap.ASSIGNMENT_LETTER.metadati.parereObbligatorio;
                    subjects = vm.soggettiEsterni;
                    break;
                case "OSSERVAZIONI":
                    selectedSubjectsIds = vm.sezioniMap.ASSIGNMENT_LETTER.metadati.osservazioni;
                    subjects = vm.soggettiEsterni;
                    break;
                case "DI_MERITO":
                    selectedSubjectsIds = vm.sezioniMap.ASSIGNMENT_LETTER.metadati.parereDiMerito;
                    subjects = vm.commissioni;
                    break;
            }

            if (selectedSubjectsIds && selectedSubjectsIds.length > 0) {
                angular.forEach(subjects, function (subject) {
                    if (selectedSubjectsIds.indexOf(subject.id) > -1) {
                        result.push(subject);
                    }
                });
            }

            return result;
        }

        function draft(state, section) {
            var isArray = section instanceof Array;
            var promise;
            vm.isSaving = true;
            if (vm.isAdmin || vm.userRolesMap.isSettoreAtti) {
                Atto.update(vm.atto, function () {
                    toastr.success('Update atto con successo');
                    if (vm.atto.proponente) {
                        Atto.updateProponente({id: vm.atto.id, tipoProponente: vm.atto.tipoProponente}, vm.atto.proponente, function () {
                            toastr.success('Update atto proponente con successo');
                        }, function (error) {
                            if (vm.atto.tipoAtto !== 'PLIP') {
                                toastr.error('Impossibile effettuare update atto proponente', 'Errore!', error);
                            }
                        });
                    }
                }, function (error) {
                    toastr.error('Impossibile effettuare update atto', 'Errore!', error);
                });
            }

            if (isArray) {
                for (var i = 0; i < section.length; i++) {

                    if (!promise) {
                        promise = Atto.updateSezione(section[i], terminalFunction, terminalFunction).$promise;
                    } else {
                        var funcToApply = function (index) {
                            return function (result) {
                                Atto.updateSezione(section[index], terminalFunction, terminalFunction);
                            };
                        };
                        promise = promise.then(funcToApply(i));
                    }
                }
            } else {
                promise = Atto.updateSezione(section, terminalFunction, terminalFunction).$promise;
            }
            promise = promise.then(function (successData) {
                toastr.success('Sezioni salvate correttamente', 'Okay!');
            }, function (error) {
                toastr.error('Impossibile salvare alcune sezioni: ' + (error ? error.message : 'Errore Sconosciuto'), 'Errore!');

            });

            vm.updateDocuments();

            return promise;
        }

        function terminalFunction(payload) {
            vm.isSaving = false;
        }

        /**
         * From PRESENTATO to COMMISSIONE or AULA (depending on a flag)
         */
        function assign(state, sections) {
            draft(state, sections).then(function (sezione) {
                if(vm.atto.tipoAtto !== 'ODG' && vm.atto.tipoAtto !== 'PDR'){
                    var inAula = vm.sezioniMap.ASSIGNMENT_LETTER.metadati.aulaDiretta;
                } else {
                    var inAula = true;
                }
                $mdDialog.show(confirmTransitionDlg).then(function () {
                    transitionTo(inAula ? 'AULA' : 'COMMISSIONE').then(function (success) {
                        toastr.success('Atto assegnato correttamente', 'Okay!');
                        reloadPage();
                    });
                });
            });

        }

        function saveAndTogglePublish(state, sections) {
            vm.datiPubblici = !vm.datiPubblici;
            angular.forEach(sections, function (sezione) {
                sezione.semprePubblica = vm.datiPubblici;
            });
            draft(state, sections).then(function (sezione) {
                var toastrMsg = 'Dati correttamente ' + (vm.datiPubblici ? 'pubblicati' : 'rimossi dalla pubblicazione');
                toastr.success(toastrMsg, 'Okay!');
                reloadPage();
            });
        }

        function assignToCourtRoom(state, sections) {
            if (!vm.isAdmin && !vm.primaryCommission && !vm.userRolesMap.isSettoreAtti) {
                return;
            }

            draft(state, sections).then(function (sezione) {
                $mdDialog.show(confirmTransitionDlg).then(function () {
                    transitionTo('AULA').then(function (success) {
                        toastr.success('Atto passato correttamente in <b>AULA</b>', 'Okay!');
                        reloadPage();
                    });
                });
            });
        }

        function approve(state, sections) {
            draft(state, sections).then(function (sezione) {
                $mdDialog.show(confirmTransitionDlg).then(function () {
                    transitionTo('APPROVATO').then(function (success) {
                        toastr.success('Atto <b>approvato</b> con successo', 'Okay!');
                        reloadPage();
                    });
                });
            });
        }

        function decay(state, sections) {
            draft(state, sections).then(function (sezione) {
                $mdDialog.show(confirmTransitionDlg).then(function () {
                    transitionTo('DECADUTO').then(function (success) {
                        toastr.success('Atto concluso come <b>decaduto</b>', 'Okay!');
                        reloadPage();
                    });
                });
            });
        }

        function toInammissibile(state, sections) {
            draft(state, sections).then(function (sezione) {
                $mdDialog.show(confirmTransitionDlg).then(function () {
                    transitionTo('INAMMISSIBILE').then(function (success) {
                        toastr.success('Atto concluso come <b>inammissibile</b>', 'Okay!');
                        reloadPage();
                    });
                });
            });
        }

        function unprocessable(state, sections) {
            draft(state, sections).then(function (sezione) {
                $mdDialog.show(confirmTransitionDlg).then(function () {
                    transitionTo('IMPROCEDIBILE').then(function (success) {
                        toastr.success('Atto concluso come <b>improcedibile</b>', 'Okay!');
                        reloadPage();
                    });
                });
            });
        }

        function withdrawn(state, sections) {
            draft(state, sections).then(function (sezione) {
                $mdDialog.show(confirmTransitionDlg).then(function () {
                    transitionTo('RITIRATO').then(function (success) {
                        toastr.success('Atto concluso come <b>ritirato</b>', 'Okay!');
                        reloadPage();
                    });
                });
            });
        }

        function toTrasmissioneGiunta(state, sections) {
            draft(state, sections).then(function (sezione) {
                $mdDialog.show(confirmTransitionDlg).then(function () {
                    transitionTo('TRASMISSIONE_GIUNTA').then(function (success) {
                        toastr.success('Atto concluso come <b>trasmissione giunta</b>', 'Okay!');
                        reloadPage();
                    });
                });
            });
        }

        function toSvolta(state, sections) {
            draft(state, sections).then(function (sezione) {
                $mdDialog.show(confirmTransitionDlg).then(function () {
                    transitionTo('SVOLTA').then(function (success) {
                        toastr.success('Atto concluso come <b>svolta</b>', 'Okay!');
                        reloadPage();
                    });
                });
            });
        }

        function toRispostaScritta(state, sections) {
            if(vm.atto.statoCorrente.statoAtto.name === 'AULA' && vm.atto.tipoAtto !== 'IO') {
                // var result = vm.Validator.validateEsitoRispostaScritta();
                var result = vm.Validator.validate(state, vm.numeroRinvii, vm.utente, vm.userRolesMap, vm.idPrimaCommissione, null, vm.primaryCommission);
                if(!result.valid){
                    vm.invalidFields = result.invalidFields;
                    toastr.error('Ricontrollare i dati inseriti: ' + result.message, "Errore di Validazione!", {
                        timeOut: 60000,
                        extendedTimeOut: 0
                    });
                    return;
                }
            }
            draft(state, sections).then(function (sezione) {
                $mdDialog.show(confirmTransitionDlg).then(function () {
                    transitionTo('RISPOSTA_SCRITTA').then(function (success) {
                        toastr.success('Atto concluso come <b>risposta scritta</b>', 'Okay!');
                        reloadPage();
                    });
                });
            });
        }

        function sendBackToCommission(state, sections) {
            sendBackWithDialog(state, sections, "AULA", "COMMISSIONE");
        }

        function sendToSecondaCommission(state, sections) {
            sendBack(state, sections, "AULA", "COMMISSIONE");
        }

        function saveAndTogglePublicMeeting(state, sections) {
            vm.datiPubblici = !vm.datiPubblici;
            for (var i = 0; i < sections.length; i++) {
                if (sections[i].nomeSezione === 'COURT_MEETING') {
                    sections[i].semprePubblica = vm.datiPubblici;
                    break;
                }
            }
            draft(state, sections).then(function (sezione) {
                toastr.success('Date di iscrizione/trattazione correttamente ' + vm.datiPubblici ? 'pubblicate' : 'rimosse dalla pubblicazione', 'Okay!');
                reloadPage();
            });
        }

        function sendBackWithDialog(state, sections, currentState, nextState) {
            $mdDialog.show({
                clickOutsideToClose: false,
                templateUrl: 'app/entities/atto/sezioni/sendBackDate.html',
                controller: ['$scope', '$mdDialog', 'editable', function ($scope, $mdDialog, editable) {
                        $scope.editable = editable;

                        var sezione = PdlService.getSezioniFromMap('COURT_OUTCOME', vm.numeroRinvii);
                        if(sezione.metadati.dataRinvio) {
                            $scope.dataRinvio = sezione.metadati.dataRinvio;
                        }

                        $scope.cancel = function () {
                            sezione.metadati.dataRinvio = null;
                            $mdDialog.cancel();
                        };

                        $scope.confirm = function () {
                            if (!$scope.dataRinvio) {
                                toastr.error('Devi specificare la data di rinvio');
                                return;
                            }
                            vm.validating = true;
                            var outcome = vm.Validator.validate(currentState, vm.numeroRinvii, vm.utente, vm.userRolesMap, vm.idPrimaCommissione, null, vm.primaryCommission);
                            vm.invalidFields = outcome.invalidFields;
                            if (!outcome.valid) {
                                toastr.error('Ricontrollare i dati inseriti: ' + outcome.message, "Errore di Validazione!", {
                                    timeOut: 60000,
                                    extendedTimeOut: 0
                                });
                                return;
                            }
                            vm.validating = false;

                            sezione.metadati.dataRinvio = $scope.dataRinvio;
                            draft(state, sections).then(function (sezione) {
                                $mdDialog.show(confirmTransitionDlg).then(function () {
                                    transitionTo(nextState).then(function (success) {
                                        $mdDialog.cancel();
                                        toastr.success('Atto <b>rinviato</b> con successo', 'Okay!');
                                        reloadPage();
                                    });
                                });
                            });
                        };

                        $scope.removeDataRinvio = function () {
                            sezione.metadati.dataRinvio = null;
                            $scope.dataRinvio = null;
                        }
                    }],
                locals: {
                    editable: editable && (Principal.hasAnyAuthority(statoAuthorities) && !vm.atto.statoCorrente.statoAtto.finalState)
                }
            });
        }

        function sendBack(state, sections, currentState, nextState) {
            var sezione = PdlService.getSezioniFromMap('COURT_OUTCOME', vm.numeroRinvii);
            vm.validating = true;
            var outcome = vm.Validator.validate(currentState, vm.numeroRinvii, vm.utente, vm.userRolesMap, vm.idPrimaCommissione, null, vm.primaryCommission);
            vm.invalidFields = outcome.invalidFields;
            if (!outcome.valid) {
                toastr.error('Ricontrollare i dati inseriti: ' + outcome.message, "Errore di Validazione!", {
                    timeOut: 60000,
                    extendedTimeOut: 0
                });
                return;
            }
            vm.validating = false;

            draft(state, sections).then(function (sezione) {
                $mdDialog.show(confirmTransitionDlg).then(function () {
                    transitionTo(nextState).then(function (success) {
                        $mdDialog.cancel();
                        toastr.success('Atto <b>inviato</b>in seconda lettura con successo', 'Okay!');
                        reloadPage();
                    });
                });
            });
        }

        function reject(state, sections) {
            draft(state, sections).then(function (sezione) {
                $mdDialog.show(confirmTransitionDlg).then(function () {
                    transitionTo('RESPINTO').then(function (success) {
                        toastr.success('Atto <b>respinto</b> con successo', 'Okay!');
                        reloadPage();
                    });
                });
            });
        }

        function toWithdrawnState(state, sections) {
/*             if(vm.atto.statoCorrente.statoAtto.name === 'AULA'){
                $mdDialog.show(confirmTransitionDlg).then(function () {
                    transitionTo('RITIRATO').then(function (success) {
                        toastr.success('Atto <b>ritirato</b> con successo', 'Okay!');
                        reloadPage();
                    });
                });
            }
            else {*/
            // 2023-10 - Modifica per rimozione data ritiro (non più necessaria)
            draft(state, sections).then(function (sezione) {
                $mdDialog.show(confirmTransitionDlg).then(function () {
                    transitionTo('RITIRATO').then(function (success) {
                        toastr.success('Atto <b>ritirato</b> con successo', 'Okay!');
                        reloadPage();
                    });
                });
            });
            /*} 
            withdrawnWithDialog(state, sections, "RITIRATO");*/
        }

        function withdrawnWithDialog(state, sections, nextState) {
            $mdDialog.show({
                clickOutsideToClose: false,
                templateUrl: 'app/components/retiredBlock/ritirato-date-dialog.html',
                controller: ['$scope', '$mdDialog', 'editable', function ($scope, $mdDialog, editable) {
                        $scope.editable = editable;

                        var sezione = PdlService.getSezioniFromMap('PRESENTATION', 0);

                        $scope.cancel = function () {
                            sezione.metadati.dataRitiro = null;
                            $mdDialog.cancel();
                        };

                        $scope.confirm = function () {
                            sezione.metadati.dataRitiro = $scope.dataRitiro;
                            draft(state, sections).then(function (sezione) {
                                $mdDialog.show(confirmTransitionDlg).then(function () {
                                    transitionTo(nextState).then(function (success) {
                                        $mdDialog.cancel();
                                        toastr.success('Atto <b>ritirato</b> con successo', 'Okay!');
                                        reloadPage();
                                    });
                                });
                            });
                        };
                    }],
                locals: {
                    editable: editable && (Principal.hasAnyAuthority(statoAuthorities) && !vm.atto.statoCorrente.statoAtto.finalState)
                }
            });
        }

        function toTransferCompetence(state, sections) {
            draft(state, sections).then(function (sezione) {
                $mdDialog.show(confirmTransitionDlg).then(function () {
                    transitionTo('TRASFERITA_COMPETENZA').then(function (success) {
                        toastr.success('Atto passato in fase <b>trasferita competenza</b> con successo', 'Okay!');
                        reloadPage();
                    }, function (error) {
                        toastr.error('Impossibile eseguire il passaggo allo stato <b>TRASFERITA COMPETENZA</b>:<br/>' + error.data.message, 'Errore!');
                    });
                });
            });
        }

        function transitionTo(newState) {
            return Atto.transition({id: vm.atto.id, nextState: newState}, function (outcome) {
            }, function (error) {
                toastr.error('Impossibile eseguire il passaggo allo stato <b>' + newState + '</b>:<br/>' + error.data.message, 'Errore!');
            }).$promise;
        }

        function reloadPage() {
            $timeout(function () {
                $state.reload();
            }, 0);
        }

        function toggle(item, metadata, fieldName, selectionLimit) {
            var list = metadata[fieldName];
            if (!list || !angular.isArray(list)) {
                metadata[fieldName] = [];
                list = metadata[fieldName];
            }

            var idx = list.indexOf(item);
            if (idx > -1) {
                list.splice(idx, 1);
            } else {
                if (selectionLimit && list.length === selectionLimit) {
                    toastr.warning('Sono consentite al massimo ' + selectionLimit + ' scelte');
                } else {
                    list.push(item);
                }
            }
        }

        function toggleParereSecondario() {
            vm.parereSecondarioDisableMap = {};
            toggleMasterSlaveChecks('parereReferente', 'parereSecondario', vm.parereSecondarioDisableMap);
        }

        function toggleParereDiMerito(masterCheck) {
            vm.parereDiMeritoDisableMap = {};
            toggleMasterSlaveChecks(masterCheck, 'parereDiMerito', vm.parereDiMeritoDisableMap);
        }

        function toggleOsservazioni() {
            vm.osservazioniDisableMap = {};
            vm.parereSecondarioDisableMap = {};
            toggleMasterSlaveChecks('parereObbligatorio', 'osservazioni', vm.osservazioniDisableMap);
        }

        function toggleMasterSlaveChecks(masterMetadata, slaveMetadata, disableMap) {
            if (vm.sezioniMap.ASSIGNMENT_LETTER && vm.sezioniMap.ASSIGNMENT_LETTER.metadati[masterMetadata]) {
                angular.forEach(vm.sezioniMap.ASSIGNMENT_LETTER.metadati[masterMetadata], function (entityId) {
                    if (vm.sezioniMap.ASSIGNMENT_LETTER.metadati[slaveMetadata]) {
                        var idx = vm.sezioniMap.ASSIGNMENT_LETTER.metadati[slaveMetadata].indexOf(entityId);
                        if (idx > -1) {
                            vm.sezioniMap.ASSIGNMENT_LETTER.metadati[slaveMetadata].splice(idx, 1);
                        }
                    }
                    disableMap[entityId] = true;
                });
            }
        }

        function toggleParereIstituzionale() {
            if ((vm.sezioniMap.ASSIGNMENT_LETTER.metadati.parereReferente && vm.sezioniMap.ASSIGNMENT_LETTER.metadati.parereReferente.indexOf(vm.idPrimaCommissione) > -1) ||
                    (vm.sezioniMap.ASSIGNMENT_LETTER.metadati.parereSecondario && vm.sezioniMap.ASSIGNMENT_LETTER.metadati.parereSecondario.indexOf(vm.idPrimaCommissione) > -1)) {
                vm.parereIstituzionaleDisabled = true;
                vm.sezioniMap.ASSIGNMENT_LETTER.metadati.parereIstituzionale = false;
            } else if ((!vm.sezioniMap.ASSIGNMENT_LETTER.metadati.parereReferente || vm.sezioniMap.ASSIGNMENT_LETTER.metadati.parereReferente.indexOf(vm.idPrimaCommissione) === -1) &&
                    (!vm.sezioniMap.ASSIGNMENT_LETTER.metadati.parereSecondario || vm.sezioniMap.ASSIGNMENT_LETTER.metadati.parereSecondario.indexOf(vm.idPrimaCommissione) === -1)) {
                vm.parereIstituzionaleDisabled = false;
            }
        }

        function exists(item, list) {

            if (!list || !angular.isArray(list)) {
                list = [];
            }

            return list.indexOf(item) > -1;
        }

        function performButtonAction(button) {
            var state = button.state;
            if (!state || !button) {
                return;
            }

            toastr.clear();

            var sections = [];

            if (isLegitimacy()) {
                sections = sections.concat(PdlService.getAllRepeatableSectionsByName('LEGITIMACY'));
            } else if (isFeasibility()) {
                sections = sections.concat(PdlService.getAllRepeatableSectionsByName('FEASIBILITY'));
            } else {
                angular.forEach(vm.sezioniMap, function (sezione) {
                    if (vm.isAdmin) {
                        sections.push(sezione);
                    } else if (vm.numeroRinvii === 0 || (sezione.metadati && vm.numeroRinvii === sezione.metadati.numeroRinvio)) {
                        if (sezione.statoAttoAssociato.name === state.name
                                && sezione.nomeSezione !== 'LEGITIMACY'
                                && sezione.nomeSezione !== 'FEASIBILITY') {
                            if (state.name === 'COMMISSIONE') {
                                if (vm.primaryCommission) {
                                    sections.push(sezione);
                                } else if (sezione.nomeSezione === 'COMMISSION_OPINIONS') {
                                    sections.push(sezione);
                                }
                            } else {
                                sections.push(sezione);
                            }
                        } else if (state.name === 'COMMISSIONE'
                                && (sezione.nomeSezione === 'ASSIGNMENT_LETTER' || sezione.nomeSezione === 'PRESENTATION')
                                && vm.userRolesMap.isSettoreAtti) {
                            sections.push(sezione);
                        }  else if(state.name === 'AULA' && sezione.nomeSezione === "ASSIGNMENT_LETTER" && vm.isAula && vm.atto.tipoAtto === 'MOZIONE' && vm.assignmentLetter.metadati && vm.assignmentLetter.metadati.aulaDiretta) {
                            sections.push(sezione);
                        } else if ((state.name === 'AULA' && sezione.nomeSezione === 'PRESENTATION' && vm.userRolesMap.isSettoreAtti) || (state.name === 'RITIRATO' && sezione.nomeSezione === 'PRESENTATION')) { //ADDED FOR RITIRATO STATE!
                            sections.push(sezione);
                        } else if (((sezione.statoAttoAssociato.finalState && vm.atto.statoCorrente.statoAtto.name === sezione.statoAttoAssociato.name) || (vm.atto.statoCorrente.statoAtto.finalState && sezione.nomeSezione) === "COURT_OUTCOME")  && sezione.editable) {
                            sections.push(sezione);
                        }
                    } else if (state.name === 'COMMISSIONE'
                            && sezione.nomeSezione === 'ASSIGNMENT_LETTER'
                            && (vm.userRolesMap.isSettoreAtti || vm.isAdmin) && (vm.atto.tipoAtto === 'MOZIONE' || vm.atto.tipoAtto === 'ODG' || vm.atto.tipoAtto === 'PDRIS')) {
                        sections.push(sezione);
                    } else if(state.name === 'AULA' && sezione.nomeSezione === "ATTACHED_ACTS_COURTROOM") {
                        sections.push(sezione);
                    } else if(state.name === 'AULA' && sezione.nomeSezione === "ASSIGNMENT_LETTER" && vm.isAula && vm.atto.tipoAtto === 'MOZIONE') {
                        sections.push(sezione);
                    } else if(sezione.nomeSezione === "PROCEDIMENTO" && vm.atto.statoCorrente.statoAtto.name === 'APPROVATO') {
                        sections.push(sezione);
                    } else if (((sezione.statoAttoAssociato.finalState && vm.atto.statoCorrente.statoAtto.name === sezione.statoAttoAssociato.name) || (vm.atto.statoCorrente.statoAtto.finalState && sezione.nomeSezione) === "COURT_OUTCOME")  && sezione.editable) {
                        sections.push(sezione);
                    }
                });
            }
            // Se stato finale saltiamo la validazione
            if (!button.skipValidation && !vm.atto.statoCorrente.statoAtto.finalState) {
                vm.validating = true;
                var outcome;
                if (vm.userRolesMap.isAdmin || vm.userRolesMap.isSettoreAtti) { // rimuovere settore atti quando sarà presente funzionalità completa import da protocollo
                    outcome = vm.Validator.validatePresentation([], vm.atto);
                    vm.invalidFields = outcome.invalidFields;
                    if (!outcome.valid) {
                        toastr.error('Ricontrollare i dati inseriti: ' + outcome.message, "Errore di Validazione!", {
                            timeOut: 60000,
                            extendedTimeOut: 0
                        });
                        return;
                    }
                }

                outcome = vm.Validator.validate(state.name, vm.numeroRinvii, vm.utente, vm.userRolesMap, vm.idPrimaCommissione, button.action, vm.primaryCommission);
                vm.invalidFields = outcome.invalidFields;
                if (!outcome.valid) {
                    toastr.error('Ricontrollare i dati inseriti: ' + outcome.message, "Errore di Validazione!", {
                        timeOut: 60000,
                        extendedTimeOut: 0
                    });
                    return;
                }

                vm.validating = false;
            }

            if (button.action) {
                button.action(null, sections);
            }

        }

        vm.changeDocumentType = function (document, newDocumentType) {
            if (!vm.updatedDocsMap) {
                vm.updatedDocsMap = {};
            }
            if (vm.updatedDocsMap[document.id]) {
                vm.updatedDocsMap[document.id].newDocumentType = newDocumentType;
            } else {
                vm.updatedDocsMap[document.id] = {};
                vm.updatedDocsMap[document.id].document = document;
                vm.updatedDocsMap[document.id].newDocumentType = newDocumentType;
            }
        };

        vm.changeDocumentVisibility = function (document, newDocumentVisibility) {
            if (!vm.updatedDocsMap) {
                vm.updatedDocsMap = {};
            }
            if (vm.updatedDocsMap[document.id]) {
                vm.updatedDocsMap[document.id].newDocumentVisibility = newDocumentVisibility;
            } else {
                vm.updatedDocsMap[document.id] = {};
                vm.updatedDocsMap[document.id].document = document;
                vm.updatedDocsMap[document.id].newDocumentVisibility = newDocumentVisibility;
            }
        };

        vm.updateDocuments = function () {
            if (vm.updatedDocsMap) {
                var promise;
                vm.isSaving = true;
                angular.forEach(vm.updatedDocsMap, function (value, key) {
                    var document = angular.copy(value.document);
                    if (value.newDocumentType) {
                        document.tipoDocumento = value.newDocumentType;
                    }
                    if (value.newDocumentVisibility) {
                        document.visibilita = value.newDocumentVisibility;
                    }
                    if (!promise) {
                        promise = Documento.update(document, terminalFunction, terminalFunction).$promise;
                    } else {
                        var updateDocFuncToApply = function () {
                            return function (result) {
                                Documento.update(document, terminalFunction, terminalFunction);
                            };
                        };
                        promise = promise.then(updateDocFuncToApply());
                    }
                });

                promise = promise.then(function (successData) {
                    toastr.success('Documenti aggiornati', 'Okay!');
                    vm.updateDocsTypeMap = null;
                }, function (error) {
                    toastr.error('Impossibile salvare alcuni documenti: ' + (error ? error.message : 'Errore Sconosciuto'), 'Errore!');

                });
            }
        };

        vm.performProtocolloSave = function () {
            Atto.updateFirmatariProponente({idAtto: vm.atto.id}, vm.atto.proponente.proponenti, function (result) {
                //ignore
            }, function (error) {
                toastr.error('Impossibile aggiornare firmatari atto: ' + (error ? error.message || error.statusText : 'Errore Sconosciuto'), 'Errore!');
            });

            var sections = [];
            if (vm.sezioniMap['COMMISSION_ASSIGNED_EMENDMENTS']) {
                sections.push(vm.sezioniMap['COMMISSION_ASSIGNED_EMENDMENTS']);
            }
            sections = sections.concat(PdlService.getAllRepeatableSectionsByName('COMMISSION_EMENDMENTS'));
            sections = sections.concat(PdlService.getAllRepeatableSectionsByName('COURT_EMENDMENTS'));

            return draft(null, sections);
        };

        function downloadArchive(attoId) {
            if (!attoId)
                return;
            vm.loading = true;
            Atto.getArchive(attoId, vm.publicView).then(function (response) {
                if (response && response.data) {
                    FileSaver.saveAs(new Blob([response.data], {}), 'Fascicolo-' + attoId + '.zip');
                }
                vm.loading = false;
            }, function (error) {
                console.log('Impossibile scaricare il fascicolo');
                toastr.error('Impossibile scaricare il fascicolo', error.data.message);
                vm.loading = false;
            });
        }

        vm.isInvalidField = function (fieldName) {
            if (vm.invalidFields && vm.invalidFields.length > 0) {
                return vm.exists(fieldName, vm.invalidFields);
            }
            return false;
        };

        vm.setAttoIdForArchiveTree = function () {
            $rootScope.attoIdForArchiveTree = vm.atto.id;
            vm.attoIdForArchiveTree = vm.atto.id;
            if (!$mdSidenav('right').isOpen()) {
                $mdSidenav('right').open();
            }
        };

        vm.checkShowSection = function (section) {
            var show = false;
            if (section) {
                if (section.nomeSezione === "PRESENTATION" || section.statoAttoAssociato.name === vm.atto.statoCorrente.statoAtto.name) {
                    show = true;
                } else {
                    angular.forEach(section.metadati, function (metadato) {
                        if (metadato) {
                            show = true;
                        } else {
                            for (var key in metadato) {
                                if (!metadato.hasOwnProperty(key)) {
                                    continue;
                                }

                                if (metadato[key]) {
                                    show = true;
                                }
                            }
                        }
                    });
                }
            }

            return show;
        };

        vm.getIteratoreSezioniRipetibili = function () {
            return new Array(vm.numeroRinvii + 1);
        };


        vm.tipoProponenteChange = function () {
            vm.atto.proponente.proponenti = [];
            if(vm.atto.tipoProponente === 'CR') {
                initProponentiDetails();
            } else {
                clearProponentiDetails();
            }
        };

        vm.openMobileButtons = function () {
            if (!$mdSidenav('buttons-mobile').isOpen()) {
                $mdSidenav('buttons-mobile').open();
            }
        };

        vm.closeMobileButtons = function () {
            $mdSidenav('buttons-mobile').close();
        };

        function isResendableToCommission() {
            var sezione = PdlService.getSezioniFromMap('POST_COURT', vm.numeroRinvii);
            return sezione.metadati.collegioGaranzia === true
                    && sezione.metadati.manifestaInfondatezza === false
                    && sezione.metadati.conformita === false;
        }

        function showTrasferCompetence() {
            var button = this;
            var sezione = PdlService.getSezioniFromMap('ASSIGNMENT_LETTER', 0);
            if (sezione && sezione.metadati && sezione.metadati.isNominaPdd) {
                return showButtonSectorOrCommission(button);
            } else {
                return false;
            }
        }

        function showButtonSectorOrCommission(button) {
            var button;
            if(!button) {
                button = this;
            }
            if (vm.isAdmin) return true;
            // Se i ruoli non sono specificati si ritorna true
            if (!button.authorities) return true;
            // I ruoli sono specificati e l'utente ne ha almeno uno
            if (button.authorities && Principal.hasAnyAuthority(button.authorities)) {
                // Se è settore atti
                if(vm.userRolesMap.isSettoreAtti) {
                    // SectorCapabilities non impostati -> true
                    // SectorCapabilities impostati e l'utente ha quei ruoli ->
                    // altrimenti -> false
                    console.log(vm.utente, button, vm.azioniSettoriById);
                    return (!button.sectorCapabilities || User.isSectorUserEnabled(vm.utente, button.sectorCapabilities, vm.azioniSettoriById));

                } else if (vm.userRolesMap.isCommissione){
                    // Se l'utente è di una commissione
                    // Se è abilitato il controllo sulle commissioni "onlyInvolvedSubject" e l'utente appartiene ad una delle commisisoni presenti nella lettera di assegnazione -> true
                    if (button.onlyInvolvedSubjects && vm.sezioniMap.ASSIGNMENT_LETTER) {
                        var assignmentMetadata = vm.sezioniMap.ASSIGNMENT_LETTER.metadati;
                        return User.hasAnyCommission(vm.utente, assignmentMetadata.parereReferente)
                                || User.hasAnyCommission(vm.utente, assignmentMetadata.parereSecondario)
                                || (assignmentMetadata.parereDiMerito && User.hasAnyCommission(vm.utente, assignmentMetadata.parereDiMerito))
                                || User.hasAnyExternalSubject(vm.utente, assignmentMetadata.parereObbligatorio)
                                || User.hasAnyExternalSubject(vm.utente, assignmentMetadata.osservazioni)
                                || (assignmentMetadata.parereIstituzionale && User.hasAnyCommission(vm.utente, [vm.idPrimaCommissione]));
                    } else if(!button.onlyInvolvedSubjects) {
                        // Non c'è controllo stretto nelle commissioni -> true
                        return true;
                    } else {
                        // C'è il controllo "onlyInvolvedSubject" ma non c'è la lettera di assegnazione, non si mostra il bottone
                        return false;
                    }
                }

            }
            // Default non si mostra il bottone
            return false;

        }

        function showButtonSectorOrCommissionOrAula(button) {
            var button;
            if(!button) {
                button = this;
            }
            // Verifica i casi Commissione, Settore (include Admin)
            var showButton = showButtonSectorOrCommission(button);
            // Se false prova a valutare il caso "Aula"
            if(!showButton) {
                if(vm.userRolesMap.isAula) {
                    showButton = Principal.hasAnyAuthority(button.authorities);
                }
            }
            return showButton;
        }


        function loadButtons() {
            vm.visibleButtons = [];
            angular.forEach(vm.stati, function (stato) {
                if (vm.atto.statoCorrente.statoAtto.name === stato.name) {
                    angular.forEach(stato.buttons, function (button) {
                        if (canSeeButton(button)) {
                            vm.visibleButtons.push(button);
                        }
                        button.state = stato;
                    });
                }
            });
        }

        vm.back = function () {
            var type = "";
            var state = "";
            var term = "";
            var page = "";
            var numeroAttoSearch = "";
            var legislatura = "";
            var idCommissione = "";

            if (sessionStorage.getItem('type') !== "null") {
                type = sessionStorage.getItem('type');
            }
            if (sessionStorage.getItem('state') !== "null") {
                state = sessionStorage.getItem('state');
            }
            if (sessionStorage.getItem('term') !== "null") {
                term = sessionStorage.getItem('term');
            }
            if (sessionStorage.getItem('page') !== "null") {
                page = sessionStorage.getItem('page');
            }
            if (sessionStorage.getItem('numeroAttoSearch') !== "null") {
                numeroAttoSearch = sessionStorage.getItem('numeroAttoSearch');
            }
            if (sessionStorage.getItem('legislatura') !== "null") {
                legislatura = sessionStorage.getItem('legislatura');
            }
            if (sessionStorage.getItem('idCommissione') !== "null") {
                idCommissione = sessionStorage.getItem('idCommissione');
            }
            $state.go(vm.previousState, {type: type, state: state, term: term, page: page, legislatura: legislatura, numeroAttoSearch: numeroAttoSearch, idCommissione: idCommissione})
        };


        function getSoggettoEsternoId(soggettoEsternoName) {
            if(!vm.soggettiEsterni  || vm.soggettiEsterni.length === 0 ) {
                return -1;
            }
            for(var i = 0; i < vm.soggettiEsterni.length; i++) {
                if(soggettiEsterni[i].nome === soggettoEsternoName){
                    return soggettiEsterni[i];
                }
            }
            return -1;
        }

        // ogni volta che viene aggiunto o eliminato un consigliere aggiungerlo/toglierlo dalla tabella!
        function initProponentiDetails() {
            if(!vm.sezioniMap.PRESENTATION.metadati.proponentiDetailsMap) {
                vm.sezioniMap.PRESENTATION.metadati.proponentiDetailsMap = {};
            }
            if(vm.atto.proponente && vm.atto.proponente.proponenti) {
                vm.atto.proponente.proponenti.forEach(function(proponente) {
                    var oldProponenteDetail = vm.sezioniMap.PRESENTATION.metadati.proponentiDetailsMap[proponente.id];
                    if(!oldProponenteDetail) {
                        vm.sezioniMap.PRESENTATION.metadati.proponentiDetailsMap[proponente.id] = {};
                    }
                });
            }
        }

        function clearProponentiDetails() {
            delete vm.sezioniMap.PRESENTATION.metadati.proponentiDetailsMap;
        }

        vm.onAddProponente = function($index, $chip){
            if(vm.atto.tipoProponente !== 'CR') {
                return;
            }
            var oldProponenteDetail = vm.sezioniMap.PRESENTATION.metadati.proponentiDetailsMap[$chip.id];
            if(!oldProponenteDetail) {
                vm.sezioniMap.PRESENTATION.metadati.proponentiDetailsMap[$chip.id] = {};
            }
        }

        vm.onRemoveProponente = function($index, $chip, $event) {
            if(vm.atto.tipoProponente !== 'CR') {
                return;
            }
            delete vm.sezioniMap.PRESENTATION.metadati.proponentiDetailsMap[$chip.id];
        }

        function initController() {
            vm.atto = entity;
            // Uno dei casi di data 1/1/1000 eliminato
            if(vm.atto.proponente && vm.atto.proponente.dataTrasmissioneGR != null) {
                var dataGR = new Date(vm.atto.proponente.dataTrasmissioneGR);
                if(dataGR.getFullYear() === 1000) {
                    vm.atto.proponente.dataTrasmissioneGR = null;
                }
            }
            vm.parereSecondarioDisableMap = {};
            vm.parereDiMeritoDisableMap = {};
            if (vm.atto.tipoAtto === 'PDR') {
                vm.tipiProponente = ['GR', 'CR', 'COMMISSIONE'];
            } else if (vm.atto.tipoAtto === 'ODG') {
                vm.tipiProponente = ['CR', 'COMMISSIONE'];
            } else if (vm.atto.tipoAtto === 'MOZIONE' || vm.atto.tipoAtto === 'IO' ||  vm.atto.tipoAtto === 'IS') {
                vm.tipiProponente = ['CR'];
            } else if(vm.atto.tipoAtto === 'CDG' || vm.atto.tipoAtto === 'PEA' || vm.atto.tipoAtto === 'PEL' || vm.atto.tipoAtto === 'IEA' || vm.atto.tipoAtto === 'DEA'){
                vm.tipiProponente = ['GR'];
            } else if(vm.atto.tipoAtto === 'REL'){
                vm.tipiProponente = ['ORGANISMI'];
            } else {
                vm.tipiProponente = ['GR', 'CR', 'UP'];
            }
            vm.organi = organi;
            vm.enti = enti;
            vm.actTypes = ACT_TYPES;
            vm.anagraficheProtocollo = [];
            vm.assessori = [];
            vm.azioniSettoriById = {};
            vm.commissioni = commissioni;
            vm.consiglieri = [];
            vm.direzioni = direzioni;
            vm.docTypes = docTypes;
            vm.downloadArchive = downloadArchive;
            vm.exists = exists;
            vm.getSezioniFromMap = PdlService.getSezioniFromMap;
            vm.idCommissioneControllo = null;
            vm.idPrimaCommissione = null;
            vm.initDocumentsCheck = PdlService.initDocumentsCheck;
            vm.removeDocumentsCheck = PdlService.removeDocumentsCheck;
            vm.isAdmin = Principal.hasAnyAuthority(['ROLE_ADMIN']);
            vm.isAula = Principal.hasAnyAuthority(['ROLE_AULA']);
            vm.isAuthenticated = Principal.isAuthenticated();
            vm.numeroRinvii = vm.atto.numeroRinvii || 0;
            vm.performButtonAction = performButtonAction;
            //vm.anagrafiche = anagrafiche;
            vm.primaryCommission = false;
            vm.publicView = publicView;
            vm.selectedItem = null;
            vm.selectedProponenteFirmatarioSearchText = '';
            vm.selectedEnteSearchText = '';
            // IO assessore risponde
            vm.selectedAssessoreRisponde = null;
            vm.selectedAssessoreRispondeSearchText = '';
            vm.soggettiEsterni = soggettiEsterni;
            vm.toggle = toggle;
            vm.toggleOsservazioni = toggleOsservazioni;
            vm.toggleParereDiMerito = toggleParereDiMerito;
            vm.toggleParereIstituzionale = toggleParereIstituzionale;
            vm.toggleParereSecondario = toggleParereSecondario;
            vm.utente = utente;
            vm.viewMode = !editable;
            vm.clearRadio = PdlService.clearRadio;

            // probabilmente da rinominare
            vm.isAttoMinore = vm.atto.tipoAtto === 'ODG' || vm.atto.tipoAtto === 'PDRIS' || vm.atto.tipoAtto === 'PDR' || vm.atto.tipoAtto === 'MOZIONE' ? true : false;

            vm.stato = {
                presentato: {
                    isCollapsed: vm.atto.statoCorrente.statoAtto.name === 'PRESENTATO' || vm.viewMode
                }
            };

            vm.Validator = ValidatorFactory.getValidator(entity.tipoAtto);

            angular.forEach(settori, function (settore) {
                vm.azioniSettoriById[settore.id] = settore.azioniAbilitate;
            });

            vm.userSoggettiEsterniMap = {
                isCAL: User.hasAnyExternalSubject(vm.utente, [getSoggettoEsternoId('CAL')]),
                isCOPAS: User.hasAnyExternalSubject(vm.utente, [getSoggettoEsternoId('COPAS')]),
                isCRPO: User.hasAnyExternalSubject(vm.utente, [getSoggettoEsternoId('CRPO')]),
                isCommControllo: User.hasAnyExternalSubject(vm.utente, [getSoggettoEsternoId('Comm. controllo')])
            }

            vm.userRolesMap = {
                isAdmin: vm.isAdmin,
                isProtocollo: !vm.isAdmin && Principal.hasAnyAuthority(['ROLE_PROTOCOLLO']),
                isLegitimacy: !vm.isAdmin && User.isSectorUserEnabled(vm.utente, ['LEGITIMACY'], vm.azioniSettoriById),
                isFeasibility: !vm.isAdmin && User.isSectorUserEnabled(vm.utente, ['FEASIBILITY'], vm.azioniSettoriById),
                isSettoreAtti: !vm.isAdmin && User.isSectorUserEnabled(vm.utente, ['GENERAL_SECTIONS'], vm.azioniSettoriById),
                isExternalSubject: !vm.isAdmin && Principal.hasAnyAuthority(['ROLE_EXTERNAL']),
                isCommissione: !vm.isAdmin && Principal.hasAnyAuthority(['ROLE_COMMISSIONE']),
                isAula: !vm.isAdmin && Principal.hasAnyAuthority(['ROLE_AULA'])
            };
            vm.displayProtocolloSaveButton = editable && vm.userRolesMap.isProtocollo;

            /*angular.forEach(anagrafiche, function (anagrafica) {
                if (anagrafica.codice && (!publicView || (publicView && anagrafica.pubblica))) {
                    vm.anagraficheProtocollo.push(anagrafica);
                    if (anagrafica.tipoProponente === "GR") {
                        vm.assessori.push(anagrafica);
                    } else {
                        vm.consiglieri.push(anagrafica);
                    }
                }
            });*/

            for (var i = 0; i < commissioni.length; i++) {
                var commissione = commissioni[i];
                if (commissione.nome.toString().trim().toUpperCase() === "PRIMA COMMISSIONE") {
                    vm.idPrimaCommissione = commissione.id;
                } else if (commissione.nome.toString().trim().toUpperCase() === "COMMISSIONE DI CONTROLLO") {
                    vm.idCommissioneControllo = commissione.id;
                }
            }

            if (previousState !== false) {
                if (!previousState.name || previousState.name === $state.name) {
                    vm.previousState = 'home';
                } else {
                    //vm.previousState = previousState.name;
                    vm.previousState = sessionStorage.getItem('fromState');
                }
            }

            vm.sezioni = {
                PRESENTATION: {
                    form: getPresentationForm()
                },
                ASSIGNMENT_LETTER: {
                    form: getAssignmentLetterForm()
                },
                COMMISSION_ASSIGNED_EMENDMENTS: {
                    form: 'app/entities/atto/sezioni/commissionAssignedEmendments.html'
                },
                ISCRIZIONE: {
                    form: 'app/entities/atto/sezioni/iscrizione.html'
                },
                COURT_OUTCOME: {
                    form:  'app/components/courtroomBlock/io.courtroomOutcome.html'
                },
            };

            function getPresentationForm() {
                if(vm.atto.tipoAtto === 'CDG'){
                    return 'app/entities/atto/sezioni/cdg.presentation.html'
                } else if(vm.atto.tipoAtto === 'DEA'){
                    return 'app/entities/atto/sezioni/dea.presentation.html';
                }
                return 'app/entities/atto/sezioni/presentation.html'
            }

            function getAssignmentLetterForm() {
                if(vm.atto.tipoAtto === 'PDLS') {
                    return 'app/entities/atto/sezioni/pdls.assignmentLetter.html';
                } else if(vm.atto.tipoAtto === 'ODG' || vm.atto.tipoAtto === 'PDR' || vm.atto.tipoAtto === "MOZIONE"  || vm.atto.tipoAtto === 'PDRIS'){
                    vm.actType = vm.atto.tipoAtto;
                    return 'app/entities/atto/sezioni/onlyReferente.assignmentLetter.html';
                }else if(vm.atto.tipoAtto === 'PEA' || vm.atto.tipoAtto === 'PEL' || vm.atto.tipoAtto === 'DEA'){
                    return 'app/entities/atto/sezioni/pea.assignmentLetter.html';
                } else if(vm.atto.tipoAtto === 'IO'){
                    return 'app/entities/atto/sezioni/io.assignmentLetter.html';
                }else if(vm.atto.tipoAtto === 'IEA'){
                    return 'app/entities/atto/sezioni/iea.assignmentLetter.html';
                } else if(vm.atto.tipoAtto === 'REL') {
                    return 'app/entities/atto/sezioni/rel.assignmentLetter.html';
                }
                else if(vm.atto.tipoAtto === 'RINT') {
                    return 'app/entities/atto/sezioni/rint.assignmentLetter.html';
                }
                else {
                    return 'app/entities/atto/sezioni/assignmentLetter.html';
                }
            }

            vm.getAssignmentLetter = function() {
                if(vm.atto.tipoAtto === 'ODG' || vm.atto.tipoAtto === 'PDR' || vm.atto.tipoAtto === "MOZIONE" || vm.atto.tipoAtto === 'PDRIS') {
                    return vm.getSezioniFromMap('ASSIGNMENT_LETTER', 0);
                }
            }

            if (!vm.atto.proponente) {
                vm.atto.proponente = {
                    proponenti: []
                };
            } else if (!vm.atto.proponente.proponenti) {
                vm.atto.proponente.proponenti = [];
            }

            // load sezioni of atto
            PdlService.loadSezioni(vm.atto, sezioniAtto, vm.numeroRinvii, vm.userRolesMap, editable, vm.utente, vm.idPrimaCommissione, vm.publicView, vm.userSoggettiEsterniMap);
            PdlService.initDateRinvio(vm.atto);

            vm.datiPubblici = PdlService.datiPubblici;
            vm.primaryCommission = PdlService.primaryCommission;
            vm.sezioniMap = PdlService.sezioniMap;
            vm.assignmentLetter = vm.sezioniMap.ASSIGNMENT_LETTER;
            vm.workDocumentsMap = PdlService.workDocumentsMap;
            vm.dateRinvio = PdlService.dateRinvio;

            if(vm.atto.tipoAtto === 'PDD' && vm.sezioniMap.ASSIGNMENT_LETTER && vm.sezioniMap.ASSIGNMENT_LETTER.metadati && !vm.sezioniMap.ASSIGNMENT_LETTER.metadati.enti) {
                vm.sezioniMap.ASSIGNMENT_LETTER.metadati.enti = [];
            }
            if(vm.atto.tipoAtto === 'PDD' && vm.sezioniMap.ASSIGNMENT_LETTER && vm.sezioniMap.ASSIGNMENT_LETTER.metadati &&  !vm.sezioniMap.ASSIGNMENT_LETTER.metadati.organi) {
                vm.sezioniMap.ASSIGNMENT_LETTER.metadati.organi = [];
            }
            if(vm.atto.tipoAtto === 'IO' || vm.atto.tipoAtto === 'IS') {
                setDataDecadenza();
            }

            vm.soggettiOpinioni = {};
            angular.forEach(opinionTypes, function (opinionType) {
                vm.soggettiOpinioni[opinionType] = getOpinionsSubjects(opinionType);
            });


            vm.stati = initializaButtons(entity.tipoAtto);
            loadButtons();

            // Tabella firmatari
            if(vm.atto.tipoProponente === 'CR') {
                //Se non lo sono, inizializzo i dettagli dei proponenti
                initProponentiDetails();
            }

            manageDocumentAnchor();
        }


        function manageDocumentAnchor() {
            const sectionId = $stateParams.sectionId;
            if(!sectionId || sectionId === '') {
                console.log('Found no sectionId in params');
                return;
            }
            // pulisco l'url dai paramentri di anchor
            $location.search('sectionId', null);
            const targetSectionId = sectionId;
            const sectionkeys = Object.keys(vm.sezioniMap);
            var targetSection = null;
            for(var i = 0; i < sectionkeys.length; i++) {
                if(vm.sezioniMap[sectionkeys[i]].id === targetSectionId) {
                    targetSection = vm.sezioniMap[sectionkeys[i]];
                    break;
                }
            }
            if(!targetSection) {
                console.log('Some error occurred during anchoring to a document');
                return;
            }
            // Setting up a flag so in the documentToolbar we can anchor to the documents
            targetSection.anchorToDocuments = true;
        }

        function getSendBackToCommissionTitle() {
            if(!vm.isAttoMinore) {
                return 'entity.action.sendBackToCommission';
            } else {
                if(!entity.numeroRinvii || entity.numeroRinvii === 0){
                    return 'entity.action.sendToCommission';
                } else {
                    return 'entity.action.sendBackToCommission';
                }
            }
        }


        function initializaButtons(tipoAtto) {
            var buttons = [];

            buttons.push({
                name: 'PRESENTATO',
                buttons: [
                    {
                        title: 'entity.action.save',
                        action: draft,
                        skipValidation: true,
                        authorities: ['ROLE_SETTORE'],
                        sectorCapabilities: ['GENERAL_SECTIONS']
                    },
                    {
                        title: 'gestioneAttiApp.form.assignment.inviaCommissione',
                        action: assign,
                        iconClass: 'glyphicon-send',
                        authorities: ['ROLE_SETTORE'],
                        sectorCapabilities: ['GENERAL_SECTIONS']
                    },
                    {
                        title: 'entity.action.withdrawn',
                        action: toWithdrawnState,
                        skipValidation: true,
                        iconClass: 'glyphicon-inbox',
                        //skipValidation: true,
                        authorities: ['ROLE_SETTORE'],
                        sectorCapabilities: ['GENERAL_SECTIONS']
                    }
                ]
            });

            buttons.push({
                name: 'COMMISSIONE',
                buttons: [
                    {
                        title: 'entity.action.save',
                        action: draft,
                        skipValidation: vm.primaryCommission || vm.isAdmin,
                        onlyInvolvedSubjects: true
                    },
                    {
                        title: 'entity.action.save',
                        action: draft,
                        forceHide: vm.isAdmin,
                        authorities: ['ROLE_SETTORE'],
                        sectorCapabilities: ['LEGITIMACY', 'FEASIBILITY', 'GENERAL_SECTIONS']
                    },
                    {
                        title: vm.datiPubblici ? 'gestioneAttiApp.form.commission.salvaDepubblica' : 'gestioneAttiApp.form.commission.salvaPubblica',
                        action: saveAndTogglePublish,
                        skipValidation: true, //vm.datiPubblici,
                        iconClass: vm.datiPubblici ? 'glyphicon-alert' : 'glyphicon-bullhorn',
                        onlyPrimaryCommission: true,
                        authorities: ['ROLE_COMMISSIONE']
                    },
                    {
                        title: 'gestioneAttiApp.form.commission.inviaAula',
                        action: assignToCourtRoom,
                        iconClass: 'glyphicon-send',
                        authorities: ['ROLE_COMMISSIONE', 'ROLE_SETTORE'],
                        onlyPrimaryCommission: true
                    },
                    {
                        title: 'entity.action.withdrawn',
                        action: toWithdrawnState,
                        iconClass: 'glyphicon-inbox',
                        skipValidation: true,
                        authorities: ['ROLE_SETTORE', 'ROLE_COMMISSIONE'],
                        sectorCapabilities: ['GENERAL_SECTIONS'],
                        onlyInvolvedSubjects: true
                    }
                ]
            });



            if (tipoAtto === "PDLS") {

                buttons.push({
                    name: 'AULA',
                    buttons: [
                        {
                            title: 'entity.action.save',
                            action: draft,
                            skipValidation: true,
                            authorities: ['ROLE_AULA']
                        },
                        {
                            title: 'entity.action.reject',
                            action: reject,
                            skipValidation: true,
                            iconClass: 'glyphicon-remove-circle',
                            authorities: ['ROLE_AULA']
                        },
                        {
                            title: (entity.numeroRinvii % 2) ? 'entity.action.approve' : 'entity.action.sendToSecondaCommissione',
                            action: (entity.numeroRinvii % 2) ? approve : sendToSecondaCommission,
                            iconClass: 'glyphicon-ok-circle',
                            authorities: ['ROLE_AULA']
                        },
                        {
                            title: 'entity.action.decayed',
                            action: decay,
                            skipValidation: true,
                            iconClass: 'glyphicon-thumbs-down',
                            authorities: ['ROLE_AULA']
                        },
                        {
                            title: 'entity.action.unprocessable',
                            action: unprocessable,
                            skipValidation: true,
                            iconClass: 'glyphicon-ban-circle',
                            authorities: ['ROLE_AULA']
                        },
                        {
                            title: 'entity.action.withdrawn',
                            action: withdrawn,
                            skipValidation: true,
                            iconClass: 'glyphicon-paperclip',
                            authorities: ['ROLE_AULA']
                        },
                        {
                            title: 'entity.action.withdrawn',
                            action: toWithdrawnState,
                            iconClass: 'glyphicon-inbox',
                            skipValidation: true,
                            authorities: ['ROLE_SETTORE'],
                            sectorCapabilities: ['GENERAL_SECTIONS']
                        }
                    ]
                });

                if (entity.numeroRinvii % 2) {
                    buttons[buttons.length - 1].buttons.push(
                            {
                                title: 'entity.action.sendBackToFirstLevelCommission',
                                action: sendBackToCommission,
                                iconClass: 'glyphicon-ok-circle',
                                authorities: ['ROLE_AULA']
                            }
                    )
                }
            } else {

                buttons.push({
                    name: 'AULA',
                    buttons: [
                        {
                            title: 'entity.action.save',
                            action: draft,
                            skipValidation: true,
                            authorities: ['ROLE_AULA']
                        },
                        {
                            title: 'entity.action.reject',
                            action: reject,
                            skipValidation: true,
                            iconClass: 'glyphicon-remove-circle',
                            authorities: ['ROLE_AULA']
                        },
                        {
                            title: 'entity.action.approve',
                            action: approve,
                            skipValidation: true,
                            iconClass: 'glyphicon-ok-circle',
                            authorities: ['ROLE_AULA']
                        },
                        {
                            title: vm.datiPubblici ? 'entity.action.depublishMeeting' : 'entity.action.publishMeeting',
                            action: saveAndTogglePublicMeeting,
                            skipValidation: true,
                            iconClass: vm.datiPubblici ? 'glyphicon-alert' : 'glyphicon-bullhorn',
                            authorities: ['ROLE_AULA']
                        },
                        {
                            title: 'entity.action.decayed',
                            action: decay,
                            skipValidation: true,
                            iconClass: 'glyphicon-thumbs-down',
                            authorities: ['ROLE_AULA']
                        },
                        {
                            title: 'entity.action.unprocessable',
                            action: unprocessable,
                            skipValidation: true,
                            iconClass: 'glyphicon-ban-circle',
                            authorities: ['ROLE_AULA']
                        },
                        {
                            title: getSendBackToCommissionTitle(),
                            action: sendBackToCommission,
                            iconClass: 'glyphicon-paperclip',
                            authorities: ['ROLE_AULA']
                        },
                        {
                            title: 'entity.action.withdrawn',
                            action: toWithdrawnState,
                            iconClass: 'glyphicon-inbox',
                            skipValidation: true,
                            authorities: ['ROLE_SETTORE', 'ROLE_AULA'],
                        }
                    ]
                });
            }

            buttons.push({
                name: 'APPROVATO',
                buttons: [
                    {
                        title: 'entity.action.save',
                        action: draft,
                        authorities: ['ROLE_AULA']
                    },
                    {
                        title: 'entity.action.sendBackToCommission',
                        action: sendBackToCommission,
                        iconClass: 'glyphicon-paperclip',
                        authorities: ['ROLE_AULA'],
                        showFunction: isResendableToCommission
                    }]
            });

            //ODG BUTTONS
            if(vm.atto.tipoAtto === 'ODG'){
                buttons = getOdgButtons();
            }
            //MOZIONE BUTTONS
            if(vm.atto.tipoAtto === 'MOZIONE'){
                buttons = getMozioneButtons();
            }
            //PEA BUTTONS
            if(vm.atto.tipoAtto === 'PEA'){
               buttons = getPeaButtons();
            }
            //IEA BUTTONS
            if(vm.atto.tipoAtto === 'IEA'){
                buttons = getIeaButtons();
            }
            //PEL/DEA BUTTONS
            if(vm.atto.tipoAtto === 'PEL' || vm.atto.tipoAtto === 'DEA'){
                buttons = getPelButtons();
            }
            //IS BUTTONS
            if(vm.atto.tipoAtto === 'IS'){
                buttons = getIsButtons();
            }
            //CDG BUTTONS
            if(vm.atto.tipoAtto === 'CDG'){
                buttons = getCdgButtons();
            }
            //PDRIS BUTTONS
            if(vm.atto.tipoAtto === 'PDRIS'){
                buttons = getPdrisButtons();
            }
            //IO BUTTONS
            if(vm.atto.tipoAtto === 'IO') {
                buttons = getIOButtons();
            }
            //REL BUTTONS
            if(vm.atto.tipoAtto === 'REL') {
                buttons = getRELButtons();
            }
            //PDD BUTTONS
            if(tipoAtto === 'PDD') {
                buttons = getPDDButtons();
                // 2023-11-03- Spostato in getPDDButtons();
                // Ticket#943867: Nella fase APPROVATO, anche un utente con ROLE_COMMISSIONE, oltre a ROLE_AULA deve poter salvare la sezione PROCEDIMENTO
                // var buttonApprovato = buttons.find(function(buttonStato){return buttonStato.name === 'APPROVATO'});
                // buttonApprovato.buttons = buttonApprovato.buttons.filter(function(button){return button.title !== 'entity.action.save'});
                // buttonApprovato.buttons.push(
                //     {
                //         title: 'entity.action.save',
                //         action: draft,
                //         authorities: ['ROLE_AULA', 'ROLE_COMMISSIONE']
                //     }
                // );
            }

            addFinalStateButtons(buttons);
            return buttons;
        }

        function addFinalStateButtons(buttons) {
            var finalStates = ['IMPROCEDIBILE', 'DECADUTO', 'INAMMISSIBILE', 'RESPINTO', 'RITIRATO'];

            for(var i = 0 ; i < finalStates.length; i++) {
                if(finalStates[i] === "RITIRATO") {
                    buttons.push({
                        name: finalStates[i],
                        buttons: [
                            {
                                title: 'entity.action.save',
                                action: draft,
                                showFunction: showButtonSectorOrCommissionOrAula,
                                skipValidation: true,
                                authorities: ['ROLE_SETTORE', 'ROLE_AULA', 'ROLE_COMMISSIONE'],
                                sectorCapabilities: ['GENERAL_SECTIONS']
                            }
                        ]
                    });
                } else {
                    buttons.push({
                        name: finalStates[i],
                        buttons: [
                            {
                                title: 'entity.action.save',
                                action: draft,
                                skipValidation: true,
                                authorities: ['ROLE_SETTORE', 'ROLE_AULA'],
                                sectorCapabilities: ['GENERAL_SECTIONS']
                            }
                        ]
                    });
                }
            }
        }

        function getOdgButtons() {
            var buttons = [];
            buttons.push({
                name: 'PRESENTATO',
                buttons: [
                    {
                        title: 'entity.action.save',
                        action: draft,
                        skipValidation: true,
                        authorities: ['ROLE_SETTORE'],
                        sectorCapabilities: ['GENERAL_SECTIONS']
                    },
                    {
                        title: 'gestioneAttiApp.form.assignment.inviaCommissione',
                        action: assign,
                        iconClass: 'glyphicon-send',
                        authorities: ['ROLE_SETTORE'],
                        sectorCapabilities: ['GENERAL_SECTIONS']
                    },
                    {
                        title: 'entity.action.withdrawn',
                        action: toWithdrawnState,
                        skipValidation: true,
                        iconClass: 'glyphicon-inbox',
                        //skipValidation: true,
                        authorities: ['ROLE_SETTORE'],
                        sectorCapabilities: ['GENERAL_SECTIONS']
                    },
                    {
                        title: 'entity.action.inammissibile',
                        action: toInammissibile,
                        skipValidation: true,
                        iconClass: 'glyphicon-inbox',
                        //skipValidation: true,
                        authorities: ['ROLE_SETTORE'],
                        sectorCapabilities: ['GENERAL_SECTIONS']
                    }
                ]
            });

            buttons.push({
                name: 'COMMISSIONE',
                buttons: [
                    {
                        title: 'entity.action.save',
                        action: draft,
                        skipValidation: vm.primaryCommission || vm.isAdmin,
                        onlyInvolvedSubjects: true
                    },
                    {
                        title: 'entity.action.save',
                        action: draft,
                        forceHide: vm.isAdmin,
                        authorities: ['ROLE_SETTORE'],
                        sectorCapabilities: ['LEGITIMACY', 'FEASIBILITY', 'GENERAL_SECTIONS']
                    },
                    {
                        title: vm.datiPubblici ? 'gestioneAttiApp.form.commission.salvaDepubblica' : 'gestioneAttiApp.form.commission.salvaPubblica',
                        action: saveAndTogglePublish,
                        skipValidation: true, //vm.datiPubblici,
                        iconClass: vm.datiPubblici ? 'glyphicon-alert' : 'glyphicon-bullhorn',
                        onlyPrimaryCommission: true,
                        authorities: ['ROLE_COMMISSIONE']
                    },
                    {
                        title: 'gestioneAttiApp.form.commission.inviaAula',
                        action: assignToCourtRoom,
                        iconClass: 'glyphicon-send',
                        authorities: ['ROLE_COMMISSIONE', 'ROLE_SETTORE'],
                        onlyPrimaryCommission: true
                    },
                    {
                        title: "entity.action.approve",
                        action: approve,
                        iconClass: "glyphicon-ok-circle",
                        authorities: ["ROLE_COMMISSIONE"],
                        onlyPrimaryCommission: true
                    },
                    {
                        title: 'entity.action.withdrawn',
                        action: toWithdrawnState,
                        iconClass: 'glyphicon-inbox',
                        skipValidation: true,
                        authorities: ['ROLE_SETTORE', 'ROLE_COMMISSIONE'],
                        sectorCapabilities: ['GENERAL_SECTIONS'],
                        onlyInvolvedSubjects: true
                    },
                    {
                        title: 'entity.action.decayed',
                        action: decay,
                        skipValidation: true,
                        iconClass: 'glyphicon-thumbs-down',
                        authorities: ['ROLE_COMMISSIONE'],
                        onlyInvolvedSubjects: true
                    }
                ]
            });

            buttons.push({
                name: 'AULA',
                buttons: [
                    {
                        title: 'entity.action.save',
                        action: draft,
                        skipValidation: true,
                        authorities: ['ROLE_AULA']
                    },
                    {
                        title: 'entity.action.reject',
                        action: reject,
                        skipValidation: true,
                        iconClass: 'glyphicon-remove-circle',
                        authorities: ['ROLE_AULA']
                    },
                    {
                        title: 'entity.action.approve',
                        action: approve,
                        skipValidation: true,
                        iconClass: 'glyphicon-ok-circle',
                        authorities: ['ROLE_AULA']
                    },
                    {
                        title: vm.datiPubblici ? 'entity.action.depublishMeeting' : 'entity.action.publishMeeting',
                        action: saveAndTogglePublicMeeting,
                        skipValidation: true,
                        iconClass: vm.datiPubblici ? 'glyphicon-alert' : 'glyphicon-bullhorn',
                        authorities: ['ROLE_AULA']
                    },
                    {
                        title: 'entity.action.decayed',
                        action: decay,
                        skipValidation: true,
                        iconClass: 'glyphicon-thumbs-down',
                        authorities: ['ROLE_AULA']
                    },
                    {
                        title: 'entity.action.unprocessable',
                        action: unprocessable,
                        skipValidation: true,
                        iconClass: 'glyphicon-ban-circle',
                        authorities: ['ROLE_AULA']
                    },
                    {
                        title: getSendBackToCommissionTitle(),
                        action: sendBackToCommission,
                        iconClass: 'glyphicon-paperclip',
                        authorities: ['ROLE_AULA']
                    },
                    {
                        title: 'entity.action.withdrawn',
                        action: toWithdrawnState,
                        iconClass: 'glyphicon-inbox',
                        skipValidation: true,
                        authorities: ['ROLE_SETTORE', 'ROLE_AULA'],
                    }
                ]
            });

            buttons.push({
                name: 'APPROVATO',
                buttons: [
                    {
                        title: 'entity.action.save',
                        action: draft,
                        authorities: ['ROLE_AULA', 'ROLE_COMMISSIONE']
                    },
                    {
                        title: 'entity.action.sendBackToCommission',
                        action: sendBackToCommission,
                        iconClass: 'glyphicon-paperclip',
                        authorities: ['ROLE_AULA'],
                        showFunction: isResendableToCommission
                    }]
            });
            return buttons;
        }

        function getCdgButtons() {
            var buttons = [];
                buttons.push({
                    name: 'PRESENTATO',
                    buttons: [
                        {
                            title: 'entity.action.save',
                            action: draft,
                            skipValidation: true,
                            authorities: ['ROLE_SETTORE'],
                            sectorCapabilities: ['GENERAL_SECTIONS']
                        },
                        {
                            title: 'gestioneAttiApp.form.commission.inviaAula',
                            action: assignToCourtRoom,
                            iconClass: 'glyphicon-send',
                            authorities: ['ROLE_SETTORE'],
                            sectorCapabilities: ['GENERAL_SECTIONS']
                        },
                        {
                            title: 'entity.action.withdrawn',
                            action: toWithdrawnState,
                            iconClass: 'glyphicon-inbox',
                            skipValidation: true,
                            authorities: ['ROLE_SETTORE'],
                            sectorCapabilities: ['GENERAL_SECTIONS']
                        },
                        {
                            title: 'entity.action.decayed',
                            action: decay,
                            skipValidation: true,
                            iconClass: 'glyphicon-thumbs-down',
                            authorities: ['ROLE_SETTORE']
                        }
                    ]
                });
                buttons.push({
                    name: 'AULA',
                    buttons: [
                        {
                            title: 'entity.action.save',
                            action: draft,
                            skipValidation: true,
                            authorities: ['ROLE_AULA']
                        },
/*                         {
                            title: 'entity.action.decayed',
                            action: decay,
                            skipValidation: true,
                            iconClass: 'glyphicon-thumbs-down',
                            authorities: ['ROLE_AULA']
                        }, */
                        {
                            title: 'entity.action.withdrawn',
                            action: toWithdrawnState,
                            iconClass: 'glyphicon-inbox',
                            skipValidation: true,
                            authorities: ['ROLE_SETTORE', 'ROLE_AULA'],
                            // sectorCapabilities: ['GENERAL_SECTIONS']
                        },
                        {
                            title: 'entity.action.svolta',
                            action: toSvolta,
                            iconClass: 'glyphicon-remove-circle',
                            authorities: ['ROLE_AULA']
                        }
                    ]
                });
                buttons.push({
                    name: 'TRASMISSIONE GIUNTA',
                    buttons: [
                        {
                            title: 'entity.action.save',
                            action: draft,
                            skipValidation: true,
                            authorities: ['ROLE_SETTORE'],
                            sectorCapabilities: ['GENERAL_SECTIONS']
                        }
                    ]
                });
                buttons.push({
                    name: 'SVOLTA',
                    buttons: [
                        {
                            title: 'entity.action.save',
                            action: draft,
                            authorities: ['ROLE_AULA', 'ROLE_SETTORE']
                        }
                    ]});
            return buttons;
        }
        function getPdrisButtons() {
            var buttons = [];
            buttons.push({
                name: "PRESENTATO",
                buttons: [
                    {
                        title: "entity.action.save",
                        action: draft,
                        skipValidation: true,
                        authorities: ["ROLE_SETTORE"],
                        sectorCapabilities: ["GENERAL_SECTIONS"]
                    },
                    {
                        title: "gestioneAttiApp.form.commission.inviaAula",
                        action: assignToCourtRoom,
                        iconClass: "glyphicon-send",
                        authorities: ["ROLE_SETTORE"],
                        sectorCapabilities: ["GENERAL_SECTIONS"]
                    },
                    {
                        title: "entity.action.withdrawn",
                        action: toWithdrawnState,
                        iconClass: "glyphicon-inbox",
                        skipValidation: true,
                        authorities: ["ROLE_SETTORE"],
                        sectorCapabilities: ["GENERAL_SECTIONS"]
                    },
                    {
                        title: "entity.action.decayed",
                        action: decay,
                        skipValidation: true,
                        iconClass: "glyphicon-thumbs-down",
                        authorities: ["ROLE_SETTORE"]
                    }
                ]
            });
            buttons.push({
                name: 'COMMISSIONE',
                buttons: [
                    {
                        title: 'entity.action.save',
                        action: draft,
                        skipValidation: vm.primaryCommission || vm.isAdmin,
                        onlyInvolvedSubjects: true
                    },
                    {
                        title: 'entity.action.save',
                        action: draft,
                        forceHide: vm.isAdmin,
                        authorities: ['ROLE_SETTORE'],
                        sectorCapabilities: ['LEGITIMACY', 'FEASIBILITY', 'GENERAL_SECTIONS']
                    },
                    {
                        title: 'gestioneAttiApp.form.commission.inviaAula',
                        action: assignToCourtRoom,
                        iconClass: 'glyphicon-send',
                        authorities: ['ROLE_SETTORE'],
                        onlyPrimaryCommission: true
                    },
                    {
                        title: 'entity.action.reject',
                        action: reject,
                        skipValidation: true,
                        iconClass: 'glyphicon-remove-circle',
                        authorities: ['ROLE_COMMISSIONE'],
                        onlyPrimaryCommission: true
                    },
                    {
                        title: 'entity.action.approve',
                        action: approve,
                        iconClass: 'glyphicon-ok-circle',
                        authorities: ['ROLE_COMMISSIONE'],
                        onlyPrimaryCommission: true
                    },
                    {
                        title: 'entity.action.decayed',
                        action: decay,
                        iconClass: 'glyphicon-thumbs-down',
                        authorities: ['ROLE_COMMISSIONE'],
                        onlyPrimaryCommission: true
                    },
                    {
                        title: 'entity.action.withdrawn',
                        action: toWithdrawnState,
                        iconClass: 'glyphicon-inbox',
                        skipValidation: true,
                        authorities: ['ROLE_SETTORE'],
                        sectorCapabilities: ['GENERAL_SECTIONS'],
                        onlyPrimaryCommission: true
                    }
                ]
            });
            buttons.push({
                name: "AULA",
                buttons: [
                    {
                        title: "entity.action.save",
                        action: draft,
                        skipValidation: true,
                        authorities: ["ROLE_AULA"]
                    },
                    {
                        title: getSendBackToCommissionTitle(),
                        action: sendBackToCommission,
                        iconClass: 'glyphicon-paperclip',
                        authorities: ['ROLE_AULA']
                    },
                    {
                        title: "entity.action.approve",
                        action: approve,
                        skipValidation: true,
                        iconClass: "glyphicon-ok-circle",
                        authorities: ["ROLE_AULA"]
                    },
                    {
                        title: "entity.action.decayed",
                        action: decay,
                        skipValidation: true,
                        iconClass: "glyphicon-thumbs-down",
                        authorities: ["ROLE_AULA"]
                    },
                    {
                        title: "entity.action.withdrawn",
                        action: toWithdrawnState,
                        iconClass: "glyphicon-inbox",
                        skipValidation: true,
                        authorities: ["ROLE_SETTORE", "ROLE_AULA"]
                    },
                    {
                        title: "entity.action.reject",
                        action: reject,
                        skipValidation: true,
                        iconClass: "glyphicon-remove-circle",
                        authorities: ["ROLE_AULA"]
                    }
                ]
            });
            buttons.push({
                name: "APPROVATO",
                buttons: [
                    {
                        title: "entity.action.save",
                        action: draft,
                        skipValidation: true,
                        authorities: ["ROLE_SETTORE", "ROLE_AULA", 'ROLE_COMMISSIONE']
                    }
                ]
            });
            return buttons;
        }
        function getIsButtons() {
            var buttons = [];
            buttons.push({
                name: "PRESENTATO",
                buttons: [
                    {
                        title: "entity.action.save",
                        action: draft,
                        skipValidation: true,
                        authorities: ["ROLE_SETTORE"],
                        sectorCapabilities: ["GENERAL_SECTIONS"]
                    },
                    {
                        title:
                            "gestioneAttiApp.form.commission.inviaAula",
                        action: assignToCourtRoom,
                        iconClass: "glyphicon-send",
                        authorities: ["ROLE_SETTORE"],
                        sectorCapabilities: ["GENERAL_SECTIONS"]
                    },
                    {
                        title: "entity.action.withdrawn",
                        action: toWithdrawnState,
                        iconClass: "glyphicon-inbox",
                        skipValidation: true,
                        authorities: ["ROLE_SETTORE"],
                        sectorCapabilities: ["GENERAL_SECTIONS"]
                    },
                    {
                        title: "entity.action.decayed",
                        action: decay,
                        skipValidation: true,
                        iconClass: "glyphicon-thumbs-down",
                        authorities: ["ROLE_SETTORE"]
                    },
                    {
                        title: "entity.action.svolta",
                        action: toSvolta,
                        iconClass: "glyphicon-remove-circle",
                        authorities: ["ROLE_SETTORE"]
                    },
                    {
                        title: "entity.action.rispostaScritta",
                        action: toRispostaScritta,
                        iconClass: "glyphicon-remove-circle",
                        authorities: ["ROLE_SETTORE"]
                    },
                    {
                        title: 'entity.action.inammissibile',
                        action: toInammissibile,
                        skipValidation: true,
                        iconClass: 'glyphicon-inbox',
                        //skipValidation: true,
                        authorities: ['ROLE_SETTORE'],
                        sectorCapabilities: ['GENERAL_SECTIONS']
                    }
                ]
            });

            buttons.push({
                name: "AULA",
                buttons: [
                    {
                        title: "entity.action.save",
                        action: draft,
                        skipValidation: true,
                        authorities: ["ROLE_AULA"]
                    },
                    {
                        title: "entity.action.decayed",
                        action: decay,
                        skipValidation: true,
                        iconClass: "glyphicon-thumbs-down",
                        authorities: ["ROLE_AULA"]
                    },
                    {
                        title: "entity.action.withdrawn",
                        action: toWithdrawnState,
                        iconClass: "glyphicon-inbox",
                        skipValidation: true,
                        authorities: ["ROLE_SETTORE"],
                        sectorCapabilities: ["GENERAL_SECTIONS"]
                    },
                    {
                        title: "entity.action.svolta",
                        action: toSvolta,
                        iconClass: "glyphicon-remove-circle",
                        authorities: ["ROLE_AULA"]
                    },
                    {
                        title: "entity.action.rispostaScritta",
                        action: toRispostaScritta,
                        iconClass: "glyphicon-remove-circle",
                        authorities: ["ROLE_AULA"]
                    }
                ]
            });

            buttons.push({
                name: 'RISPOSTA_SCRITTA',
                buttons: [
                    {
                        title: 'entity.action.save',
                        action: draft,
                        authorities: ['ROLE_AULA', 'ROLE_SETTORE']
                    }
                ]});
            buttons.push({
                name: 'SVOLTA',
                buttons: [
                    {
                        title: 'entity.action.save',
                        action: draft,
                        authorities: ['ROLE_AULA', 'ROLE_SETTORE']
                    }
                ]});
            return buttons;
        }

        function getIOButtons() {
            var buttons = [];
            buttons.push({
                name: "PRESENTATO",
                buttons: [
                    {
                        title: "entity.action.save",
                        action: draft,
                        skipValidation: true,
                        authorities: ["ROLE_SETTORE"],
                        sectorCapabilities: ["GENERAL_SECTIONS"]
                    },
                    {
                        title:
                            "gestioneAttiApp.form.assignment.inviaCommissione",
                        action: assign,
                        iconClass: "glyphicon-send",
                        authorities: ["ROLE_SETTORE"],
                        sectorCapabilities: ["GENERAL_SECTIONS"]
                    },
                    {
                        title: "entity.action.withdrawn",
                        action: toWithdrawnState,
                        skipValidation: true,
                        iconClass: "glyphicon-inbox",
                        //skipValidation: true,
                        authorities: ["ROLE_SETTORE"],
                        sectorCapabilities: ["GENERAL_SECTIONS"]
                    },
                    {
                        title: "entity.action.decayed",
                        action: decay,
                        skipValidation: true,
                        iconClass: "glyphicon-thumbs-down",
                        authorities: ["ROLE_SETTORE"]
                    },
                    {
                        title: 'entity.action.inammissibile',
                        action: toInammissibile,
                        skipValidation: true,
                        iconClass: 'glyphicon-inbox',
                        //skipValidation: true,
                        authorities: ['ROLE_SETTORE'],
                        sectorCapabilities: ['GENERAL_SECTIONS']
                    }
                ]
            });
            buttons.push({
                name: "COMMISSIONE",
                buttons: [
                    {
                        title: "entity.action.save",
                        action: draft,
                        skipValidation:
                            vm.primaryCommission || vm.isAdmin,
                        onlyInvolvedSubjects: true
                    },
                    {
                        title: "entity.action.save",
                        action: draft,
                        forceHide: vm.isAdmin,
                        authorities: ["ROLE_SETTORE"],
                        sectorCapabilities: [
                            "LEGITIMACY",
                            "FEASIBILITY",
                            "GENERAL_SECTIONS"
                        ]
                    },
                    {
                        title: "entity.action.svolta",
                        action: toSvolta,
                        iconClass: "glyphicon-remove-circle",
                        authorities: ["ROLE_COMMISSIONE"],
                        onlyInvolvedSubjects: true
                    },
                    {
                        title: "entity.action.rispostaScritta",
                        action: toRispostaScritta,
                        iconClass: "glyphicon-remove-circle",
                        skipValidation: true,
                        authorities: ["ROLE_COMMISSIONE"],
                        onlyInvolvedSubjects: true
                    },
                    {
                        title: "gestioneAttiApp.form.commission.inviaAula",
                        action: assignToCourtRoom,
                        iconClass: "glyphicon-send",
                        authorities: ["ROLE_SETTORE"],
                        onlyPrimaryCommission: true
                    },
                    {
                        title: "entity.action.decayed",
                        action: decay,
                        iconClass: "glyphicon-thumbs-down",
                        authorities: ["ROLE_COMMISSIONE"],
                        onlyInvolvedSubjects: true
                    },
                    {
                        title: "entity.action.withdrawn",
                        action: toWithdrawnState,
                        iconClass: "glyphicon-inbox",
                        skipValidation: true,
                        authorities: [
                            "ROLE_SETTORE",
                            "ROLE_COMMISSIONE"
                        ],
                        sectorCapabilities: ["GENERAL_SECTIONS"],
                        onlyInvolvedSubjects: true
                    }
                ]
            });
            buttons.push({
                name: "AULA",
                buttons: [
                    {
                        title: "entity.action.save",
                        action: draft,
                        skipValidation: true,
                        authorities: ["ROLE_AULA"]
                    },
                    {
                        title: "entity.action.svolta",
                        action: toSvolta,
                        iconClass: "glyphicon-remove-circle",
                        authorities: ["ROLE_AULA"]
                    },
                    {
                        title: "entity.action.rispostaScritta",
                        action: toRispostaScritta,
                        skipValidation: true,
                        iconClass: "glyphicon-remove-circle",
                        authorities: ["ROLE_AULA"]
                    },
                    {
                        title: "entity.action.decayed",
                        action: decay,
                        skipValidation: true,
                        iconClass: "glyphicon-thumbs-down",
                        authorities: ["ROLE_AULA"]
                    },
                    {
                        title: "entity.action.withdrawn",
                        action: toWithdrawnState,
                        iconClass: "glyphicon-inbox",
                        skipValidation: true,
                        authorities: ["ROLE_SETTORE"],
                        sectorCapabilities: ["GENERAL_SECTIONS"]
                    }
                ]
            });

            buttons.push({
                name: "APPROVATO",
                buttons: [
                    {
                        title: "entity.action.save",
                        action: draft,
                        authorities: ["ROLE_AULA"]
                    },
                    {
                        title: "entity.action.sendBackToCommission",
                        action: sendBackToCommission,
                        iconClass: "glyphicon-paperclip",
                        authorities: ["ROLE_AULA"],
                        showFunction: isResendableToCommission
                    }
                ]
            });
            buttons.push({
                name: 'RISPOSTA_SCRITTA',
                buttons: [
                    {
                        title: 'entity.action.save',
                        action: draft,
                        authorities: ['ROLE_AULA', 'ROLE_SETTORE']
                    }
                ]});
            buttons.push({
                name: 'SVOLTA',
                buttons: [
                    {
                        title: 'entity.action.save',
                        action: draft,
                        authorities: ['ROLE_AULA', 'ROLE_SETTORE']
                    }
                ]});
            return buttons;
        }

        function getPelButtons() {
            var buttons = [];
            buttons.push({
                name: "PRESENTATO",
                buttons: [
                    {
                        title: "entity.action.save",
                        action: draft,
                        skipValidation: true,
                        authorities: ["ROLE_SETTORE"],
                        sectorCapabilities: ["GENERAL_SECTIONS"]
                    },
                    {
                        title:
                            "gestioneAttiApp.form.assignment.inviaCommissione",
                        action: assign,
                        iconClass: "glyphicon-send",
                        authorities: ["ROLE_SETTORE"],
                        sectorCapabilities: ["GENERAL_SECTIONS"]
                    },
                    {
                        title: "entity.action.withdrawn",
                        action: toWithdrawnState,
                        iconClass: "glyphicon-inbox",
                        skipValidation: true,
                        authorities: ["ROLE_SETTORE"],
                        sectorCapabilities: ["GENERAL_SECTIONS"]
                    },
                    {
                        title: "entity.action.decayed",
                        action: decay,
                        skipValidation: true,
                        iconClass: "glyphicon-thumbs-down",
                        authorities: ["ROLE_SETTORE"]
                    }
                ]
            });
            buttons.push({
                name: "COMMISSIONE",
                buttons: [
                    {
                        title: "entity.action.save",
                        action: draft,
                        skipValidation:
                            vm.primaryCommission || vm.isAdmin,
                        onlyInvolvedSubjects: true
                    },
                    {
                        title: "entity.action.save",
                        action: draft,
                        forceHide: vm.isAdmin,
                        authorities: ["ROLE_SETTORE"],
                        sectorCapabilities: [
                            "LEGITIMACY",
                            "FEASIBILITY",
                            "GENERAL_SECTIONS"
                        ]
                    },
                    {
                        title: "entity.action.trasmissioneGiunta",
                        action: toTrasmissioneGiunta,
                        iconClass: "glyphicon-send",
                        //skipValidation: true,
                        authorities: [
                            "ROLE_COMMISSIONE",
                            "ROLE_SETTORE"
                        ],
                        onlyInvolvedSubjects: true
                    },
                    {
                        title: "entity.action.withdrawn",
                        action: toWithdrawnState,
                        iconClass: "glyphicon-inbox",
                        skipValidation: true,
                        authorities: [
                            "ROLE_SETTORE",
                            "ROLE_COMMISSIONE"
                        ],
                        sectorCapabilities: ["GENERAL_SECTIONS"],
                        onlyInvolvedSubjects: true
                    },
                    {
                        title: "entity.action.decayed",
                        action: decay,
                        skipValidation: true,
                        iconClass: "glyphicon-thumbs-down",
                        authorities: ["ROLE_SETTORE"]
                    }
                ]
            });
            if(vm.atto.tipoAtto === 'DEA') {
                buttons.push({
                    name: "TRASMISSIONE GIUNTA",
                    buttons: [
                        {
                            title: "entity.action.save",
                            action: draft,
                            skipValidation: true,
                            showFunction: showButtonSectorOrCommission,
                            authorities: ["ROLE_SETTORE", "ROLE_COMMISSIONE"],
                            sectorCapabilities: ["GENERAL_SECTIONS"],
                            onlyInvolvedSubjects: true
                        }
                    ]
                });
            } else {
                buttons.push({
                    name: "TRASMISSIONE GIUNTA",
                    buttons: [
                        {
                            title: "entity.action.save",
                            action: draft,
                            skipValidation: true,
                            authorities: ["ROLE_SETTORE"],
                            sectorCapabilities: ["GENERAL_SECTIONS"]
                        }
                    ]
                });
            }
            return buttons;
        }

        function getIeaButtons() {
            var buttons = [];
            buttons.push({
                name: "PRESENTATO",
                buttons: [
                    {
                        title: "entity.action.save",
                        action: draft,
                        skipValidation: true,
                        authorities: ["ROLE_SETTORE"],
                        sectorCapabilities: ["GENERAL_SECTIONS"]
                    },
                    {
                        title:
                            "gestioneAttiApp.form.assignment.inviaCommissione",
                        action: assign,
                        iconClass: "glyphicon-send",
                        authorities: ["ROLE_SETTORE"],
                        sectorCapabilities: ["GENERAL_SECTIONS"]
                    },
                    {
                        title: "entity.action.withdrawn",
                        action: toWithdrawnState,
                        iconClass: "glyphicon-inbox",
                        skipValidation: true,
                        authorities: ["ROLE_SETTORE"],
                        sectorCapabilities: ["GENERAL_SECTIONS"]
                    },
                    {
                        title: "entity.action.decayed",
                        action: decay,
                        skipValidation: true,
                        iconClass: "glyphicon-thumbs-down",
                        authorities: ["ROLE_SETTORE"]
                    }
                ]
            });
            buttons.push({
                name: "COMMISSIONE",
                buttons: [
                    {
                        title: "entity.action.save",
                        action: draft,
                        skipValidation:
                            vm.primaryCommission || vm.isAdmin,
                        onlyInvolvedSubjects: true
                    },
                    {
                        title: "entity.action.save",
                        action: draft,
                        forceHide: vm.isAdmin,
                        authorities: ["ROLE_SETTORE"],
                        sectorCapabilities: [
                            "LEGITIMACY",
                            "FEASIBILITY",
                            "GENERAL_SECTIONS"
                        ]
                    },
                    {
                        title: "entity.action.trasmissioneGiunta",
                        action: toTrasmissioneGiunta,
                        iconClass: "glyphicon-send",
                        //skipValidation: true,
                        authorities: [
                            "ROLE_COMMISSIONE",
                            "ROLE_SETTORE"
                        ],
                        onlyInvolvedSubjects: true
                    },
                    {
                        title: "entity.action.withdrawn",
                        action: toWithdrawnState,
                        iconClass: "glyphicon-inbox",
                        skipValidation: true,
                        authorities: [
                            "ROLE_SETTORE",
                            "ROLE_COMMISSIONE"
                        ],
                        sectorCapabilities: ["GENERAL_SECTIONS"],
                        onlyInvolvedSubjects: true
                    },
                    {
                        title: "entity.action.decayed",
                        action: decay,
                        skipValidation: true,
                        iconClass: "glyphicon-thumbs-down",
                        authorities: ["ROLE_SETTORE"]
                    },
                    {
                        title: "entity.action.svolta",
                        action: toSvolta,
                        iconClass: "glyphicon-remove-circle",
                        skipValidation: true,
                        authorities: [
                            "ROLE_SETTORE",
                            "ROLE_COMMISSIONE"
                        ],
                        onlyInvolvedSubjects: true
                    }
                ]
            });
            buttons.push({
                name: "AULA",
                buttons: [
                    {
                        title: "entity.action.save",
                        action: draft,
                        skipValidation: true,
                        authorities: ["ROLE_AULA"]
                    },
                    /*                         {
                            title: 'entity.action.reject',
                            action: reject,
                            skipValidation: true,
                            iconClass: 'glyphicon-remove-circle',
                            authorities: ['ROLE_AULA']
                        }, */
                    {
                        title: "entity.action.withdrawn",
                        action: toWithdrawnState,
                        iconClass: "glyphicon-inbox",
                        skipValidation: true,
                        authorities: ["ROLE_SETTORE", "ROLE_AULA"]
                    },
                    {
                        title: "entity.action.trasmissioneGiunta",
                        action: toTrasmissioneGiunta,
                        iconClass: "glyphicon-send",
                        //skipValidation: true,
                        authorities: [
                            "ROLE_COMMISSIONE",
                            "ROLE_SETTORE"
                        ],
                        onlyInvolvedSubjects: true
                    },
                    {
                        title: "entity.action.svolta",
                        action: toSvolta,
                        skipValidation: true,
                        iconClass: "glyphicon-remove-circle",
                        authorities: ["ROLE_SETTORE", "ROLE_AULA"]
                    }
                ]
            });
            buttons.push({
                name: "TRASMISSIONE GIUNTA",
                buttons: [
                    {
                        title: "entity.action.save",
                        action: draft,
                        skipValidation: true,
                        authorities: ["ROLE_SETTORE"],
                        sectorCapabilities: ["GENERAL_SECTIONS"]
                    }
                ]
            });
            return buttons;
        }

        function getPeaButtons() {
            var buttons = [];
            buttons.push({
                name: "PRESENTATO",
                buttons: [
                    {
                        title: "entity.action.save",
                        action: draft,
                        skipValidation: true,
                        authorities: ["ROLE_SETTORE"],
                        sectorCapabilities: ["GENERAL_SECTIONS"]
                    },
                    {
                        title:
                            "gestioneAttiApp.form.assignment.inviaCommissione",
                        action: assign,
                        iconClass: "glyphicon-send",
                        authorities: ["ROLE_SETTORE"],
                        sectorCapabilities: ["GENERAL_SECTIONS"]
                    },
                    {
                        title: "entity.action.withdrawn",
                        action: toWithdrawnState,
                        iconClass: "glyphicon-inbox",
                        skipValidation: true,
                        authorities: ["ROLE_SETTORE"],
                        sectorCapabilities: ["GENERAL_SECTIONS"]
                    },
                    {
                        title: "entity.action.decayed",
                        action: decay,
                        skipValidation: true,
                        iconClass: "glyphicon-thumbs-down",
                        authorities: ["ROLE_SETTORE"]
                    }
                ]
            });
            buttons.push({
                name: "COMMISSIONE",
                buttons: [
                    {
                        title: "entity.action.save",
                        action: draft,
                        skipValidation:
                            vm.primaryCommission || vm.isAdmin,
                        onlyInvolvedSubjects: true
                    },
                    {
                        title: "entity.action.save",
                        action: draft,
                        forceHide: vm.isAdmin,
                        authorities: ["ROLE_SETTORE"],
                        sectorCapabilities: [
                            "LEGITIMACY",
                            "FEASIBILITY",
                            "GENERAL_SECTIONS"
                        ]
                    },
                    {
                        title: "entity.action.trasmissioneGiunta",
                        action: toTrasmissioneGiunta,
                        iconClass: "glyphicon-send",
                        //skipValidation: true,
                        authorities: [
                            "ROLE_COMMISSIONE",
                            "ROLE_SETTORE"
                        ],
                        onlyInvolvedSubjects: true
                    }
                    /*                         {
                        title: 'entity.action.chiusuraProcedimento',
                        action: approve,
                        skipValidation: true,
                        iconClass: 'glyphicon-ok-circle',
                        authorities: ['ROLE_COMMISSIONE'],
                        onlyInvolvedSubjects: true
                    } */
                ]
            });
            buttons.push({
                name: "TRASMISSIONE GIUNTA",
                buttons: [
                    {
                        title: "entity.action.save",
                        action: draft,
                        skipValidation: true,
                        authorities: ["ROLE_SETTORE",
                        "ROLE_COMMISSIONE"]
                        //sectorCapabilities: ["GENERAL_SECTIONS"]
                    },
                    {
                        title: "entity.action.chiusuraProcedimento",
                        action: approve,
                        skipValidation: true,
                        iconClass: "glyphicon-ok-circle",
                        authorities: ["ROLE_SETTORE",
                                    "ROLE_COMMISSIONE"]
                    }
                ]
            });
            buttons.push({
                name: "APPROVATO",
                buttons: [
                    {
                        title: "entity.action.save",
                        action: draft,
                        authorities: ["ROLE_SETTORE",
                                    "ROLE_COMMISSIONE"]
                    }
                ]
            });
            return buttons;
        }

        function getMozioneButtons() {
            var buttons = [];

            buttons.push({
                name: "PRESENTATO",
                buttons: [
                    {
                        title: "entity.action.save",
                        action: draft,
                        skipValidation: true,
                        authorities: ["ROLE_SETTORE"],
                        sectorCapabilities: ["GENERAL_SECTIONS"]
                    },
                    {
                        title:
                            "gestioneAttiApp.form.assignment.inviaCommissione",
                        action: assign,
                        iconClass: "glyphicon-send",
                        authorities: ["ROLE_SETTORE"],
                        sectorCapabilities: ["GENERAL_SECTIONS"]
                    },
                    {
                        title: "entity.action.withdrawn",
                        action: toWithdrawnState,
                        skipValidation: true,
                        iconClass: "glyphicon-inbox",
                        //skipValidation: true,
                        authorities: ["ROLE_SETTORE"],
                        sectorCapabilities: ["GENERAL_SECTIONS"]
                    },
                    {
                        title: 'entity.action.inammissibile',
                        action: toInammissibile,
                        skipValidation: true,
                        iconClass: 'glyphicon-inbox',
                        //skipValidation: true,
                        authorities: ['ROLE_SETTORE'],
                        sectorCapabilities: ['GENERAL_SECTIONS']
                    }
                ]
            });

            buttons.push({
                name: "COMMISSIONE",
                buttons: [
                    {
                        title: "entity.action.save",
                        action: draft,
                        skipValidation:
                            vm.primaryCommission || vm.isAdmin,
                        onlyInvolvedSubjects: true
                    },
                    {
                        title: "entity.action.save",
                        action: draft,
                        forceHide: vm.isAdmin,
                        authorities: ["ROLE_SETTORE"],
                        sectorCapabilities: [
                            "LEGITIMACY",
                            "FEASIBILITY",
                            "GENERAL_SECTIONS"
                        ]
                    },
                    {
                        title:
                            "gestioneAttiApp.form.commission.inviaAula",
                        action: assignToCourtRoom,
                        iconClass: "glyphicon-send",
                        authorities: ["ROLE_SETTORE"],
                        onlyPrimaryCommission: true
                    },
                    {
                        title: "entity.action.reject",
                        action: reject,
                        skipValidation: true,
                        iconClass: "glyphicon-remove-circle",
                        authorities: ["ROLE_COMMISSIONE"],
                        onlyPrimaryCommission: true
                    },
                    {
                        title: "entity.action.approve",
                        action: approve,
                        iconClass: "glyphicon-ok-circle",
                        authorities: ["ROLE_COMMISSIONE"],
                        onlyPrimaryCommission: true
                    },
                    {
                        title: "entity.action.decayed",
                        action: decay,
                        iconClass: "glyphicon-thumbs-down",
                        authorities: ["ROLE_COMMISSIONE"],
                        onlyPrimaryCommission: true
                    },
                    {
                        title: "entity.action.withdrawn",
                        action: toWithdrawnState,
                        iconClass: "glyphicon-inbox",
                        skipValidation: true,
                        authorities: ["ROLE_SETTORE"],
                        sectorCapabilities: ["GENERAL_SECTIONS"],
                        onlyPrimaryCommission: true
                    }
                ]
            });

            buttons.push({
                name: "AULA",
                buttons: [
                    {
                        title: "entity.action.save",
                        action: draft,
                        skipValidation: true,
                        authorities: ["ROLE_AULA"]
                    },
                    {
                        title: "entity.action.reject",
                        action: reject,
                        skipValidation: true,
                        iconClass: "glyphicon-remove-circle",
                        authorities: ["ROLE_AULA"]
                    },
                    {
                        title: "entity.action.approve",
                        action: approve,
                        skipValidation: true,
                        iconClass: "glyphicon-ok-circle",
                        authorities: ["ROLE_AULA"]
                    },
                    {
                        title: vm.datiPubblici
                            ? "entity.action.depublishMeeting"
                            : "entity.action.publishMeeting",
                        action: saveAndTogglePublicMeeting,
                        skipValidation: true,
                        iconClass: vm.datiPubblici
                            ? "glyphicon-alert"
                            : "glyphicon-bullhorn",
                        authorities: ["ROLE_AULA"]
                    },
                    {
                        title: "entity.action.decayed",
                        action: decay,
                        skipValidation: true,
                        iconClass: "glyphicon-thumbs-down",
                        authorities: ["ROLE_AULA"]
                    },
                    {
                        title: "entity.action.unprocessable",
                        action: unprocessable,
                        skipValidation: true,
                        iconClass: "glyphicon-ban-circle",
                        authorities: ["ROLE_AULA"]
                    },
                    {
                        title: getSendBackToCommissionTitle(),
                        action: sendBackToCommission,
                        iconClass: "glyphicon-paperclip",
                        authorities: ["ROLE_AULA"]
                    },
                    {
                        title: "entity.action.withdrawn",
                        action: toWithdrawnState,
                        iconClass: "glyphicon-inbox",
                        skipValidation: true,
                        authorities: ["ROLE_SETTORE", "ROLE_AULA"]
                    }
                ]
            });

            buttons.push({
                name: "APPROVATO",
                buttons: [
                    {
                        title: "entity.action.save",
                        action: draft,
                        authorities: ['ROLE_AULA', 'ROLE_COMMISSIONE']
                    },
                    {
                        title: "entity.action.sendBackToCommission",
                        action: sendBackToCommission,
                        iconClass: "glyphicon-paperclip",
                        authorities: ["ROLE_AULA"],
                        showFunction: isResendableToCommission
                    }
                ]
            });

            buttons.push({
                name: 'SVOLTA',
                buttons: [
                    {
                        title: 'entity.action.save',
                        action: draft,
                        authorities: ['ROLE_AULA', 'ROLE_SETTORE']
                    }
                ]});
            return buttons;
        }

        function getRELButtons() {
            var buttons = [];
            buttons.push({
                name: 'PRESENTATO',
                buttons: [
                    {
                        title: 'entity.action.save',
                        action: draft,
                        skipValidation: true,
                        authorities: ['ROLE_SETTORE'],
                        sectorCapabilities: ['GENERAL_SECTIONS']
                    },
                    {
                        title: 'gestioneAttiApp.form.assignment.inviaCommissione',
                        action: assign,
                        iconClass: 'glyphicon-send',
                        authorities: ['ROLE_SETTORE'],
                        sectorCapabilities: ['GENERAL_SECTIONS']
                    }
                ]
            });
            buttons.push({
                name: 'COMMISSIONE',
                buttons: [
                    {
                        title: 'entity.action.save',
                        action: draft,
                        skipValidation: vm.primaryCommission || vm.isAdmin,
                        onlyInvolvedSubjects: true
                    },
                    {
                        title: 'entity.action.save',
                        action: draft,
                        forceHide: vm.isAdmin,
                        authorities: ['ROLE_SETTORE'],
                        sectorCapabilities: ['LEGITIMACY', 'FEASIBILITY', 'GENERAL_SECTIONS']
                    },
                    {
                        title: 'gestioneAttiApp.form.commission.inviaAula',
                        action: assignToCourtRoom,
                        iconClass: 'glyphicon-send',
                        authorities: ['ROLE_SETTORE'],
                        onlyPrimaryCommission: true
                    }
                ]
            });
            buttons.push({
                name: 'AULA',
                buttons: [
                    {
                        title: 'entity.action.save',
                        action: draft,
                        skipValidation: true,
                        authorities: ['ROLE_AULA']
                    },
                    {
                        title: 'entity.action.svolta',
                        action: toSvolta,
                        iconClass: 'glyphicon-remove-circle',
                        authorities: ['ROLE_AULA']
                    }
                ]
            });

            buttons.push({
                name: 'APPROVATO',
                buttons: [
                    {
                        title: 'entity.action.save',
                        action: draft,
                        authorities: ['ROLE_AULA']
                    }
                ]
            });
            buttons.push({
                name: 'RISPOSTA_SCRITTA',
                buttons: [
                    {
                        title: 'entity.action.save',
                        action: draft,
                        authorities: ['ROLE_AULA', 'ROLE_SETTORE']
                    }
                ]});
            buttons.push({
                name: 'SVOLTA',
                buttons: [
                    {
                        title: 'entity.action.save',
                        action: draft,
                        authorities: ['ROLE_AULA', 'ROLE_SETTORE']
                    }
                ]});
            return buttons;
        }

        function getPDDButtons() {
            var buttons = [];
            buttons.push({
                name: 'PRESENTATO',
                buttons: [
                    {
                        title: 'entity.action.save',
                        action: draft,
                        skipValidation: true,
                        authorities: ['ROLE_SETTORE'],
                        sectorCapabilities: ['GENERAL_SECTIONS']
                    },
                    {
                        title: 'gestioneAttiApp.form.assignment.inviaCommissione',
                        action: assign,
                        iconClass: 'glyphicon-send',
                        authorities: ['ROLE_SETTORE'],
                        sectorCapabilities: ['GENERAL_SECTIONS']
                    },
                    {
                        title: 'entity.action.withdrawn',
                        action: toWithdrawnState,
                        skipValidation: true,
                        iconClass: 'glyphicon-inbox',
                        authorities: ['ROLE_SETTORE'],
                        sectorCapabilities: ['GENERAL_SECTIONS']
                    },
                    {
                        title: 'entity.action.transferCompetence',
                        action: toTransferCompetence,
                        showFunction: showTrasferCompetence,
                        skipValidation: true,
                        iconClass: 'glyphicon-share',
                        authorities: ['ROLE_SETTORE'],
                        sectorCapabilities: ['GENERAL_SECTIONS']
                    }
                ]
            });
            buttons.push({
                name: 'COMMISSIONE',
                buttons: [
                    {
                        title: 'entity.action.save',
                        action: draft,
                        skipValidation: vm.primaryCommission || vm.isAdmin,
                        onlyInvolvedSubjects: true
                    },
                    {
                        title: 'entity.action.save',
                        action: draft,
                        forceHide: vm.isAdmin,
                        authorities: ['ROLE_SETTORE'],
                        sectorCapabilities: ['LEGITIMACY', 'FEASIBILITY', 'GENERAL_SECTIONS']
                    },
                    {
                        title: vm.datiPubblici ? 'gestioneAttiApp.form.commission.salvaDepubblica' : 'gestioneAttiApp.form.commission.salvaPubblica',
                        action: saveAndTogglePublish,
                        skipValidation: true, //vm.datiPubblici,
                        iconClass: vm.datiPubblici ? 'glyphicon-alert' : 'glyphicon-bullhorn',
                        onlyPrimaryCommission: true,
                        authorities: ['ROLE_COMMISSIONE']
                    },
                    {
                        title: 'gestioneAttiApp.form.commission.inviaAula',
                        action: assignToCourtRoom,
                        iconClass: 'glyphicon-send',
                        authorities: ['ROLE_COMMISSIONE', 'ROLE_SETTORE'],
                        onlyPrimaryCommission: true
                    },
                    {
                        title: 'entity.action.withdrawn',
                        action: toWithdrawnState,
                        iconClass: 'glyphicon-inbox',
                        skipValidation: true,
                        authorities: ['ROLE_SETTORE', 'ROLE_COMMISSIONE'],
                        sectorCapabilities: ['GENERAL_SECTIONS'],
                        onlyInvolvedSubjects: true
                    },
                    {
                        title: 'entity.action.transferCompetence',
                        action: toTransferCompetence,
                        showFunction: showTrasferCompetence,
                        skipValidation: true,
                        iconClass: 'glyphicon-share',
                        authorities: ['ROLE_SETTORE', 'ROLE_COMMISSIONE'],
                        sectorCapabilities: ['GENERAL_SECTIONS'],
                        onlyInvolvedSubjects: true
                    }
                ]
            });
            buttons.push({
                name: 'AULA',
                buttons: [
                    {
                        title: 'entity.action.save',
                        action: draft,
                        skipValidation: true,
                        authorities: ['ROLE_AULA']
                    },
                    {
                        title: 'entity.action.reject',
                        action: reject,
                        skipValidation: true,
                        iconClass: 'glyphicon-remove-circle',
                        authorities: ['ROLE_AULA']
                    },
                    {
                        title: 'entity.action.approve',
                        action: approve,
                        skipValidation: true,
                        iconClass: 'glyphicon-ok-circle',
                        authorities: ['ROLE_AULA']
                    },
                    {
                        title: vm.datiPubblici ? 'entity.action.depublishMeeting' : 'entity.action.publishMeeting',
                        action: saveAndTogglePublicMeeting,
                        skipValidation: true,
                        iconClass: vm.datiPubblici ? 'glyphicon-alert' : 'glyphicon-bullhorn',
                        authorities: ['ROLE_AULA']
                    },
                    {
                        title: 'entity.action.decayed',
                        action: decay,
                        skipValidation: true,
                        iconClass: 'glyphicon-thumbs-down',
                        authorities: ['ROLE_AULA']
                    },
                    {
                        title: 'entity.action.unprocessable',
                        action: unprocessable,
                        skipValidation: true,
                        iconClass: 'glyphicon-ban-circle',
                        authorities: ['ROLE_AULA']
                    },
                    {
                        title: getSendBackToCommissionTitle(),
                        action: sendBackToCommission,
                        iconClass: 'glyphicon-paperclip',
                        authorities: ['ROLE_AULA']
                    },
                    {
                        title: 'entity.action.withdrawn',
                        action: toWithdrawnState,
                        iconClass: 'glyphicon-inbox',
                        skipValidation: true,
                        authorities: ['ROLE_SETTORE', 'ROLE_AULA'],
                    },
                    {
                        title: 'entity.action.transferCompetence',
                        action: toTransferCompetence,
                        showFunction: showTrasferCompetence,
                        skipValidation: true,
                        iconClass: 'glyphicon-share',
                        authorities: ['ROLE_SETTORE'],
                        sectorCapabilities: ['GENERAL_SECTIONS']
                    }
                ]
            });
            buttons.push({
                name: 'APPROVATO',
                buttons: [
                    // Ticket#943867: Nella fase APPROVATO, anche un utente con ROLE_COMMISSIONE, oltre a ROLE_AULA deve poter salvare la sezione PROCEDIMENTO
                    {
                        title: 'entity.action.save',
                        action: draft,
                        authorities: ['ROLE_AULA', 'ROLE_COMMISSIONE']
                    },
                    {
                        title: 'entity.action.sendBackToCommission',
                        action: sendBackToCommission,
                        iconClass: 'glyphicon-paperclip',
                        authorities: ['ROLE_AULA'],
                        showFunction: isResendableToCommission
                    }]
            });
            buttons.push({
                name: 'TRASFERITA COMPETENZA',
                buttons: [
                    {
                        title: 'entity.action.save',
                        action: draft,
                        authorities: ['ROLE_SETTORE'],
                        sectorCapabilities: ['GENERAL_SECTIONS']
                    }
                ]
            });
            return buttons;

        }


        function setDataDecadenza() {
            var dataIniziale;
            if(vm.sezioniMap.PRESENTATION.metadati.dateTestoSotitutivo && vm.sezioniMap.PRESENTATION.metadati.dateTestoSotitutivo.length > 0 && vm.sezioniMap.PRESENTATION.metadati.dateTestoSotitutivo[0] !== "") {
                dataIniziale = vm.sezioniMap.PRESENTATION.metadati.dateTestoSotitutivo[vm.sezioniMap.PRESENTATION.metadati.dateTestoSotitutivo.length - 1];
            } else {
                dataIniziale = vm.atto.dataProtocollo;
            }
            dataIniziale = new Date(dataIniziale);
            dataIniziale = dataIniziale.setMonth(dataIniziale.getMonth() + 6);
            vm.dataDecadenza = new Date(dataIniziale);
        }

        $rootScope.$on('conformitaChange', function () {
            loadButtons();
        });

        vm.showCommissionBlock = function(index){
            if(vm.atto.tipoAtto === 'IS'){
                return false;
            }

            if(vm.atto.tipoAtto === 'PEA' && vm.userSoggettiEsterniMap.isCAL && (vm.atto.statoCorrente.statoAtto.name !== 'PRESENTATO')) {
                return true;
            }

            if( vm.atto.tipoAtto !== 'ODG' && vm.atto.tipoAtto !== 'PDR' && vm.atto.tipoAtto !== 'CDG' && vm.atto.tipoAtto !== 'PDRIS') {
                var isAulaDiretta = !vm.sezioniMap.ASSIGNMENT_LETTER || !vm.sezioniMap.ASSIGNMENT_LETTER.metadati || vm.sezioniMap.ASSIGNMENT_LETTER.metadati.aulaDiretta;
                if (index === 0 && isAulaDiretta) {
                    return false;
                }
                return vm.wasAttoInState('COMMISSIONE', index, vm.atto, isAulaDiretta);
            } else {
                return index > 0;
            }
        }



        vm.showCourtroomBlock = function(index){
            if(vm.atto.tipoAtto === 'IEA' && !vm.assignmentLetter.metadati.aulaDiretta){
                return false;
            }
            if(vm.atto.tipoAtto === 'IS' && vm.atto.statoCorrente.statoAtto.name === 'PRESENTATO') {
                return false;
            }
            else if(vm.atto.tipoAtto === 'IO' || vm.atto.tipoAtto === 'IS') {
                var sezioneGestioneSedute = vm.getSezioniFromMap('COURT_MEETING', index);
                return sezioneGestioneSedute && (vm.atto.statoCorrente.statoAtto.name === 'AULA' || (sezioneGestioneSedute.metadati.seduteTrattazione && sezioneGestioneSedute.metadati.seduteTrattazione.length > 0) );
            } else {
                return vm.wasAttoInState('AULA', index, vm.atto, false);
            }
        }

        vm.showTrasmissioneGiuntaBlock = function() {
            if(!vm.sezioniMap['GIUNTA_TRASMISSION']) {
                return false;
            }

            if(vm.atto.tipoAtto === 'PEA') {
                return vm.atto.statoCorrente.statoAtto.name === 'TRASMISSIONE GIUNTA' || vm.atto.statoCorrente.statoAtto.finalState;
            } else {
                return vm.atto.statoCorrente.statoAtto.name === 'TRASMISSIONE GIUNTA';
            }
        }

        vm.showTrasmissioneGiuntaBeforeCourtroom = function() {
            if(vm.atto.tipoAtto === 'CDG') {
                return false;
            } else {
                return true;
            }
        }

        vm.showAssignmentLetter = function() {
            if(vm.atto.tipoAtto !== 'ODG' && vm.atto.tipoAtto !== 'PDR' && vm.atto.tipoAtto !== 'MOZIONE' && vm.atto.tipoAtto !== 'PDRIS'){
                return vm.checkShowSection(vm.sezioniMap.ASSIGNMENT_LETTER);
            } else if(vm.atto.tipoAtto === 'MOZIONE'){
                if (!vm.assignmentLetter || !vm.assignmentLetter.metadati) {
                    return false;
                }
                return !vm.assignmentLetter.metadati.aulaDiretta;
            } else {
                return false;
            }
        }

        /**
         *
         * @param {*} stateName name of the state to check in the atto history
         * @param {*} stateIndex index by the type of state depending of the block of sections.
         * @param {*} atto
         * @param {*} isAulaDiretta flag to know if the aulaDiretta is true or false
         * @returns
         */
        vm.wasAttoInState = function(stateName, stateIndex, atto, isAulaDiretta) {
            // numero che rappresenta la posizione in ordine per tipo di stato
            // Es: se abbiamo 2 stati aulee nello storico, avremo AULA con index 0 (con sectionNumberByType = 1)
            // e avremo AULA con index 1 (con sectionNumberByType = 2)
            var sectionNumberByType = stateIndex ? stateIndex + 1 : 1;
            // quante volte viene trovato questo stato nello storico
            // Tutti gli stati che sono nello stato attuale, sono gia' inseriti nello storico!
            var stateFoundInAttoHistory = 0;
            // We manage the aulaDiretta flag by ignoring the 1st commission block
            if (stateName === 'COMMISSIONE' && isAulaDiretta) {
                stateFoundInAttoHistory++;
            }
            // Attenzione se siamo in presentato lo storico e' vuoto!
            if (!atto.storico || atto.storico.length === 0) {
                return false;
            }

            for(var i = 0; i < atto.storico.length; i++) {
                if (atto.storico[i].statoAtto.name === stateName) {
                    stateFoundInAttoHistory++;
                }
            }
            return stateFoundInAttoHistory >= sectionNumberByType;
        }

/*         if((vm.atto.tipoAtto === 'PDR' || vm.atto.tipoAtto === "MOZIONE") && vm.utente && vm.atto.decaduto) {
            toastr.info('PROPOSTA DECADUTA', {
                timeOut: 60000,
                extendedTimeOut: 60000,
                progressBar: false
            });
        } */

        // Direction < 0 --> pull right, Direction >= 0 pull left
        vm.changeOrder = function($event, direction, chip) {
            var chipIdx = vm.atto.proponente.proponenti.findIndex(function(c){return c.id === chip.id});
            if(direction < 0 && chipIdx > 0) {
                vm.atto.proponente.proponenti.splice(chipIdx, 1);
                vm.atto.proponente.proponenti.splice(chipIdx - 1, 0, chip);
            } else if(direction >= 0 && chipIdx >= 0 && chipIdx < vm.atto.proponente.proponenti.length - 1) {
                vm.atto.proponente.proponenti.splice(chipIdx, 1);
                vm.atto.proponente.proponenti.splice(chipIdx + 1, 0, chip);
            }
            $event.stopPropagation();
        }

        vm.cancelCurrentState = function(atto){

            var statoPrecedenteString = atto.storico.length >= 2 ? atto.storico[atto.storico.length - 2].statoAtto.name : 'PRESENTAZIONE';

            var confirm = $mdDialog.confirm()
                .title('Sei sicuro di annullare lo stato attuale ' + atto.statoCorrente.statoAtto.name + "?")
                .textContent('Annullando lo stato corrente l\'atto ritornerà allo stato precedente ' +
                statoPrecedenteString + '. Eventuali modifiche verranno perse. Procedere?')
                .ariaLabel('Annulla Stato Corrente')
                .ok('Procedi')
                .cancel('Annulla');

            $mdDialog.show(confirm).then(function () {
                return Atto.cancelCurrentState({id: vm.atto.id},
                    function(success){
                        toastr.success('Atto correttamente aggiornato', 'Okay!');
                        reloadPage();
                    }, function(error){
                        toastr.error('Impossibile eseguire il passaggo allo stato precedente<br/>' + error.data.description, 'Errore!');
                    });
            }, function () {

            });
        }

        if(vm.atto.tipoAtto === 'IO') {
            // Gestione Assessore che risponde per IO nei metadata della lettera di assegnazione
            if(!vm.assignmentLetter.metadati.assessoreRispondeDetailsMap) {
                vm.assignmentLetter.metadati.assessoreRispondeDetailsMap = {};
            }
            if (!vm.assignmentLetter.metadati.assessoreRisponde) {
                vm.assignmentLetter.metadati.assessoreRisponde = {
                    assessori: []
                };
            } else if (!vm.assignmentLetter.metadati.assessoreRisponde.assessori) {
                vm.assignmentLetter.metadati.assessoreRisponde.assessori = [];
            }
            console.log(vm.assignmentLetter);

            vm.onAddAssessoreRisponde = function($index, $chip){
                if(vm.atto.tipoProponente !== 'CR') {
                    return;
                }
                var oldAssessoreDetail = vm.sezioniMap.ASSIGNMENT_LETTER.metadati.assessoreRispondeDetailsMap[$chip.id];
                if(!oldAssessoreDetail) {
                    vm.sezioniMap.ASSIGNMENT_LETTER.metadati.assessoreRispondeDetailsMap[$chip.id] = {};
                }
            }

            vm.onRemoveAssessoreRisponde = function($index, $chip, $event) {
                if(vm.atto.tipoProponente !== 'CR') {
                    return;
                }
                delete vm.sezioniMap.ASSIGNMENT_LETTER.metadati.assessoreRispondeDetailsMap[$chip.id];
            }
        }

        // Direction < 0 --> pull right, Direction >= 0 pull left
        vm.changeOrder1 = function(array, $event, direction, chip) {
            console.log("CHANGE ORDER", array);
            var chipIdx = array.findIndex(function(c){return c.id === chip.id});
            if(direction < 0 && chipIdx > 0) {
                array.splice(chipIdx, 1);
                array.splice(chipIdx - 1, 0, chip);
            } else if(direction >= 0 && chipIdx >= 0 && chipIdx < array.length - 1) {
                array.splice(chipIdx, 1);
                array.splice(chipIdx + 1, 0, chip);
            }
            console.log("CHANGE ORDER", array);
            $event.stopPropagation();
        }

    }
})();
