angular.module('gestioneAttiApp').directive('svoltaBlock', function () {
    return {
        restrict: "E",
        templateUrl: 'app/components/svoltaBlock/svoltaBlock.html',
        scope: {
            atto: '=',
            statoAttoCorrente: '=',
            courtOutcome: '=',
            isProtocollo: '=',
            isSettoreAtti: '=',
            utente: "=",
            docTypes: "=",
            changeDocumentTypeCallback: "=",
            changeDocumentVisibilityCallback: "=",
            publicView: "=",
            hideEsitoAttoMedatati: "=",
            viewMode: '='
        },
        transclude: false,
        controllerAs: 'vm',
        bindToController: true,
        controller: ['Atto', 'User', 'PdlService', 'Documento', '$location','$anchorScroll', '$timeout', function (Atto, User, PdlService, Documento, $location, $anchorScroll, $timeout) {
                var vm = this;
                vm.clearRadio = PdlService.clearRadio;
                vm.attoRitiratoDocs = [];
                vm.sezioni = {
                    COURT_OUTCOME: {
                        form: (vm.atto.tipoAtto === 'IO' || vm.atto.tipoAtto === 'IS' || vm.atto.tipoAtto === 'REL')
                                ? 'app/components/courtroomBlock/io.courtroomOutcome.html'
                                : 'app/components/courtroomBlock/courtroomOutcome.html'
                    }
                };

                vm.stato = {
                    svolta: {
                        isCollapsed: vm.statoAttoCorrente === 'SVOLTA' || vm.viewMode
                    }
                };

                vm.getSezioniFromMap = PdlService.getSezioniFromMap;
                vm.invalidFields = vm.invalidFields || [];
            }]
    };
});
