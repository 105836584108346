/**
 * Created by Michele Romani on 23/06/2017.
 */
(function() {
    'use strict';
    angular
        .module('gestioneAttiApp')
        .factory('NotificationsService', NotificationsService);

    NotificationsService.$inject = ['$resource'];

    function NotificationsService ($resource) {
        var resourceUrl =  'api/notifications/:idAtto';

        return $resource(resourceUrl, { idAtto: '@idAtto', id: '@id'}, {
            'getAllNotifications': {
                method: 'GET',
                isArray: true
            },
            'markAsRead': {
                method: 'PUT',
                url: resourceUrl + '/:id'
            },
            'sendNotification': {
                method: 'POST',
            }
        });
    }
})();
