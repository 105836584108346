(function () {

    'use strict';

    angular.module('gestioneAttiApp').service('PddValidator', ['BasicValidator', function (BasicValidator) {

        this.validatePresentation = BasicValidator.validatePresentation;

        this.validate = function (state, numeroRinvio, utente, userRolesMap, idPrimaCommissione, action, primaryCommission) {
            var invalidFields = [];

            if (userRolesMap.isAdmin) {

                var result = validateSectorSections(invalidFields, idPrimaCommissione);

                if (result.valid) {
                    if (!BasicValidator.PdlService.sezioniMap.ASSIGNMENT_LETTER.metadati.aulaDiretta) {
                        result = validateCommissionSections(invalidFields, utente, userRolesMap, numeroRinvio, idPrimaCommissione, primaryCommission);
                    }
                    if (result.valid) {
                        result = BasicValidator.validateCourtroomSections(invalidFields, numeroRinvio);
                    }
                }
                return result;
            } else {
                switch (state) {
                    case "PRESENTATO":
                        return validateSectorSections(invalidFields, idPrimaCommissione);
                    case "COMMISSIONE":
                        return validateCommissionSections(invalidFields, utente, userRolesMap, numeroRinvio, idPrimaCommissione, primaryCommission);
                    case "AULA":
                        return BasicValidator.validateCourtroomSections(invalidFields, numeroRinvio);
                    case "APPROVATO":
                        return {valid: true};
                    default:
                        console.error("STATO SCONOSCIUTO: " + state);
                        return {valid: false, message: "STATO SCONOSCIUTO: " + state, invalidFields: invalidFields};
                }
            }
        };

        function validateSectorSections(invalidFields, idPrimaCommissione) {
            var result = BasicValidator.validateAssignmentLetter(invalidFields, idPrimaCommissione);

            var openListTagMessage = result.message.slice(0, -5);
            result.message = openListTagMessage;

            result = validateAssignmentLetter(result.invalidFields, idPrimaCommissione, result.valid, result.message);

            return result;
        }

        function validateAssignmentLetter(invalidFields, idPrimaCommissione, valid, message) {
            var metadata = BasicValidator.PdlService.sezioniMap.ASSIGNMENT_LETTER.metadati;

            if (metadata.parereDiMerito && metadata.parereDiMerito.length) {
                for (var i = 0; i < metadata.parereDiMerito.length; i++) {
                    if (metadata.parereReferente && metadata.parereReferente.indexOf(metadata.parereDiMerito[i]) > -1) {

                        result.message += '<li><i>Parere di merito</i> non può contenere valori già specificati in Parere Referente</li>';
                        invalidFields.push('parereDiMerito');

                        break;
                    }
                }
            }

            message += '</ul>';
            valid = invalidFields.length === 0;
            if (!valid) {
                BasicValidator.$location.hash('assignmentLetter');
                BasicValidator.$anchorScroll();
                BasicValidator.PdlService.sezioniMap.ASSIGNMENT_LETTER.visible = true;
            }
            return {valid: valid, message: message, invalidFields: invalidFields};
        }

        function validateCommissionSections(invalidFields, utente, userRolesMap, numeroRinvio, idPrimaCommissione, primaryCommission) {
            var result = null;
            for (var i = 0; i <= numeroRinvio; i++) {
                result = BasicValidator.validateLegitimacy(invalidFields, userRolesMap, i);
                if (result.valid) {
                    result = BasicValidator.validateCommissionEmendments(invalidFields, i, primaryCommission);
                    if (result.valid) {
                        //result = BasicValidator.validateCommissionOpinions(invalidFields, utente, userRolesMap, i, idPrimaCommissione);
                        if (result.valid) {
                            result = BasicValidator.validateFinancialCoverage(invalidFields, i, primaryCommission);
                            if (result.valid) {
                                result = BasicValidator.validateCommissionMessage(invalidFields, i, primaryCommission);
                            } else {
                                break;
                            }
                        } else {
                            break;
                        }
                    } else {
                        break;
                    }
                } else {
                    break;
                }
            }
            return result;
        }
    }]);
})();
