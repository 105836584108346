(function () {
    'use strict';

    angular
        .module('gestioneAttiApp')
        .factory('AdempimentoUtil', AdempimentoUtil);

    function AdempimentoUtil () {

        var service = {
            isAdempimentoValid : isAdempimentoValid
        };

        return service;

        function isAdempimentoValid (adempimento) {
            return (adempimento.incaricatiAdempimento && adempimento.incaricatiAdempimento.length > 0) && 
                (adempimento.destinatariAdempimento && adempimento.destinatariAdempimento.length > 0) &&
                (!adempimento.destinatariAdempimento.includes('COMMISSIONE') || (adempimento.commissioniDestinatarie && adempimento.commissioniDestinatarie.length > 0)) &&
                (adempimento.descrizioneAdempimento && adempimento.descrizioneAdempimento.trim().length > 0)
        }
    }
})();
