(function () {
    'use strict';

    angular
            .module('gestioneAttiApp')
            .controller('AnagraficaIterlegisDialogController', AnagraficaIterlegisDialogController);

            AnagraficaIterlegisDialogController.$inject = ['$timeout', '$scope', 'toastr', '$mdDialog', 'entity', 'AnagraficaIterlegis', '$state', 'TIPO_ANAGRAFICA_ITERLEGIS'];

    function AnagraficaIterlegisDialogController($timeout, $scope, toastr, $mdDialog, entity, AnagraficaIterlegis, $state, TIPO_ANAGRAFICA_ITERLEGIS) {
        var vm = this;
        this.tipoAnagraficaList = TIPO_ANAGRAFICA_ITERLEGIS;
        vm.anagraficaIterlegis = vm.entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $mdDialog.cancel();
        }

        function save() {
            vm.isSaving = true;
/*             if (vm.anagraficaIterlegis.id !== null) {
                AnagraficaIterlegis.update(vm.anagraficaIterlegis, onSaveSuccess, onSaveError);
            } else { */
            AnagraficaIterlegis.save(vm.anagraficaIterlegis, onSaveSuccess, onSaveError);
            //}
        }

        function onSaveSuccess(result) {
            $scope.$emit('gestioneAttiApp:anagraficaIterlegisUpdate', result);
            vm.isSaving = false;
            $mdDialog.cancel();
            $state.go('anagrafica-iterlegis', null, {reload: true});
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();
