(function () {

    'use strict';

    angular.module('gestioneAttiApp').service('MozioneValidator', ['PdlService','MinorBasicValidator', '$location', '$anchorScroll', function (PdlService, MinorBasicValidator, $location, $anchorScroll) {
        this.PdlService = PdlService;
        this.validatePresentation = MinorBasicValidator.validatePresentation;

        this.validate = function (state, numeroRinvio, utente, userRolesMap, idPrimaCommissione, action, primaryCommission) {
            var invalidFields = [];

            switch (state) {
                case "PRESENTATO":
                    return validateSectorSections(invalidFields, idPrimaCommissione)
                case "COMMISSIONE":
                    return MinorBasicValidator.validateCommissionSections(invalidFields, utente, userRolesMap,
                        numeroRinvio, idPrimaCommissione, action && action.name === 'assignToCourtRoom', primaryCommission);
                case "AULA":
                    return MinorBasicValidator.validateCourtroomSections(invalidFields, numeroRinvio);
                case "APPROVATO":
                    return MinorBasicValidator.validatePostCourtroomSections(invalidFields, numeroRinvio);
                default:
                    console.error("STATO SCONOSCIUTO: " + state);
                    return {valid: false, message: "STATO SCONOSCIUTO: " + state};
            }

        };

        function validateSectorSections(invalidFields, idPrimaCommissione) {
            var result = MinorBasicValidator.validateSectorSections(invalidFields, idPrimaCommissione);
            if(result.valid) {
                result = validateAssignmentLetter(invalidFields, idPrimaCommissione);
            }
            return result;
        }



        function validateAssignmentLetter(invalidFields, idPrimaCommissione) {
            var metadata = PdlService.sezioniMap.ASSIGNMENT_LETTER.metadati;

            var message = '<ul>';

            if (typeof (metadata.aulaDiretta) === 'undefined' || metadata.aulaDiretta === null) {
                message += '<li><i>Direttamente in Aula</i> è un campo obbligatorio</li>';
                invalidFields.push('aulaDiretta');
            } else if (metadata.aulaDiretta) {

            } else {
                if (!metadata.parereReferente || !metadata.parereReferente.length) {
                    message += '<li><i>Parere Referente</i> è un campo obbligatorio</li>';
                    invalidFields.push('parereReferente');
                }

            }

            message += '</ul>';

            var valid = invalidFields.length === 0;
            if (!valid) {
                $location.hash('assignmentLetter');
                $anchorScroll();
                PdlService.sezioniMap.ASSIGNMENT_LETTER.visible = true;
            }

            return {valid: valid, message: message, invalidFields: invalidFields};
        }



    }]);
})();
