angular.module('gestioneAttiApp').directive('sectionNotes', function () {
    return {
        restrict: "E",
        templateUrl: 'app/components/sectionNotes/sectionNotes.directive.html',
        scope: {
            note: '=',
            disabled: '=',
            showNotes: '=?',
            showToggler: '=?'
        },
        transclude: false,
        controllerAs: 'vm',
        bindToController: true,
        controller: [
            function () {
                var vm = this;
                vm.showNotes = vm.showNotes || false;
            }]
    };
});
