angular.module('gestioneAttiApp').directive('commissionBlock', function () {
    return {
        restrict: "E",
        templateUrl: 'app/components/commissionBlock/commissionBlock.directive.html',
        scope: {
            actType: '=',
            changeDocumentTypeCallback: '&?',
            changeDocumentVisibilityCallback: '&?',
            commissionEmendments: '=',
            commissionMessage: '=',
            commissionOpinions: '=',
            consultations: '=',
            currentUser: '=',
            docTypes: '=',
            feasibility: '=',
            financialCoverage: '=',
            postCourt: '=?',
            index: '=',
            invalidFields: '=',
            isAdmin: '=',
            isAuthenticated: '=',
            isFeasibility: '=',
            isLegitimacy: '=',
            isPrimaryCommission: '=',
            isProtocollo: '=',
            iterationIndex: '=',
            legitimacy: '=',
            opinionSubjects: '=',
            peopleList: '=',
            referendumReport: '=',
            sendBackDate: '=?',
            showSectionCallback: '&',
            unifiedText: '=',
            unionProposal: '=',
            validating: '=',
            workDocuments: '=',
            documentsCheckCallback: '&',
            removeDocumentsCheckCallback: '&',
            workDocumentsMap: '=',
            statoAttoCorrente: '=',
            numeroRinvii: '=',
            isAttoMinore: '=',
            seatManagement: '=?',
            decadence: '=',
            parereCal: '=?',
            userSoggettiEsterniMap: '=?',
            resolution: '=?',
            atto: '=',
            viewMode: '='
        },
        transclude: false,
        controllerAs: 'vm',
        bindToController: true,
        controller: ['Atto', 'User', 'PdlService', 'Principal', function (Atto, User, PdlService) {
                var vm = this;
                vm.numeroRinvii = vm.numeroRinvii || 0;
                vm.clearRadio = PdlService.clearRadio;
                vm.atti = [];
                vm.allPdris = [];
                vm.numeroPdrisSearchText = '';
                vm.searchPdris = searchPdris;
                vm.pdrisSelectedChip = [];

                

                function resolveAttachedPdris() {
                    if (!vm.commissionMessage.metadati.attachedPdrisId) {
                        return;
                    }
                    Atto.get({
                        id: vm.commissionMessage.metadati.attachedPdrisId
                    }, onRetrieveSuccess, onError)
                    
                    function onRetrieveSuccess(data, headers) {
                        vm.pdrisSelectedChip.push(data);
                    }
                    function onError(error) {
                        console.log(error);
                    }
                }

                vm.onChipAdd = function ($index, $chip) {
                    vm.commissionMessage.metadati.attachedPdrisId = $chip.id;
                }

                vm.onRemoveChip = function ($index, $chip, $event) {
                    vm.commissionMessage.metadati.attachedPdrisId = null;
                }

                if(vm.actType === 'REL' || vm.atto.tipoAtto === 'REL') {
                    resolveAttachedPdris();
                    getAllPdris();
                }

                vm.sezioni = {
                    LEGITIMACY: {
                        form: 'app/components/commissionBlock/legitimacy.html'
                    },
                    FEASIBILITY: {
                        form: 'app/components/commissionBlock/feasibility.html'
                    },
                    CONSULTATIONS: {
                        form: 'app/components/commissionBlock/consultations.html'
                    },
                    COMMISSION_MESSAGE: {
                        form: 'app/components/commissionBlock/commissionMessage.html'
                    },
                    COMMISSION_ASSIGNED_EMENDMENTS: {
                        form: 'app/components/commissionBlock/commissionAssignedEmendments.html'
                    },
                    COMMISSION_EMENDMENTS: {
                        form: 'app/components/commissionBlock/commissionEmendments.html'
                    },
                    WORK_DOCUMENTS: {
                        form: 'app/components/commissionBlock/workDocuments.html'
                    },
                    PARERE_CAL:{
                        form: 'app/components/commissionBlock/parereCal.html'
                    },
                    RESOLUTION:{
                        form: 'app/components/commissionBlock/resolution.html'
                    },
                    FINANCIAL_COVERAGE: {
                        form: 'app/components/commissionBlock/financialCoverage.html'
                    },
                    COMMISSION_OPINIONS: {
                        form: 'app/components/commissionBlock/commissionOpinions.html'
                    },
                    UNION_PROPOSAL: {
                        form: 'app/components/commissionBlock/unionProposal.html'
                    },
                    REFERENDUM_REPORT: {
                        form: 'app/components/commissionBlock/referendumReport.html'
                    },
                    UNIFIED_TEXT: {
                        form: 'app/components/commissionBlock/unifiedText.html'
                    },
                    SEAT_MANAGEMENT: {
                        form: 'app/components/commissionBlock/seatManagement.html'
                    },
                    DECADENCE: {
                        form: 'app/components/commissionBlock/decadence.html'
                    }
                };

                vm.stato = {
                    commissione: {
                        isCollapsed: (vm.statoAttoCorrente === 'COMMISSIONE' && vm.numeroRinvii === vm.index) || vm.viewMode
                    }
                };

                vm.getSezioniFromMap = PdlService.getSezioniFromMap;
                vm.invalidFields = vm.invalidFields || [];
                vm.utente = vm.currentUser;
                vm.soggettiOpinioni = vm.opinionSubjects;

                if (vm.unifiedText) {
                    vm.unifiedText.metadati.unifiedTexts = vm.unifiedText.metadati.unifiedTexts || [];
                    vm.unifiedText.metadati.hasUnifiedTexts = vm.unifiedText.metadati.hasUnifiedTexts || false;
                    if (vm.unifiedText.metadati.hasUnifiedTexts) {
                        loadAtti();
                    }
                    vm.numeroAttoSearchText = '';
                    vm.searchAtti = searchUnifiedTexts;
                    vm.unifiedTextsModel = [];
                    resolveUnifiedTexts();
                    vm.onAddUnifiedText = onAddUnifiedText;
                    vm.onRemoveUnifiedText = onRemoveUnifiedText;
                    vm.transformChip = transformChip;
                    vm.toogleAddUnifiedText = toogleAddUnifiedText;
                }

                vm.fieldSuffix = '';
                if (vm.iterationIndex && vm.iterationIndex > 0) {
                    vm.fieldSuffix = '_' + vm.iterationIndex;
                }

                vm.checkShowSection = function (section) {
                    return vm.showSectionCallback({section: section});
                };

                vm.initDocumentsCheck = function (documento) {
                    vm.documentsCheckCallback({documento: documento, numeroRinvio: vm.iterationIndex});
                };

                vm.removeDocumentsCheck = function (documento) {
                    vm.removeDocumentsCheckCallback({documento: documento, numeroRinvio: vm.iterationIndex});
                };

                vm.changeDocumentType = function (document, newDocumentType) {
                    vm.changeDocumentTypeCallback({document: document, newDocumentType: newDocumentType});
                };

                vm.changeDocumentVisibility = function (document, newDocumentVisibility) {
                    vm.changeDocumentVisibilityCallback({document: document, newDocumentVisibility: newDocumentVisibility});
                };

                vm.hasAnyCommission = function (commissions) {
                    return User.hasAnyCommission(vm.utente, commissions);
                };

                vm.hasAnyExternalSubject = function (subjects) {
                    return User.hasAnyExternalSubject(vm.utente, subjects);
                };

                vm.isCommissionOpinionEditable = function (subjects) {
                    return PdlService.isCommissionOpinionEditable(vm.utente, subjects, vm.iterationIndex, vm.isAdmin);
                };

                vm.isExternalSubjectOpionionEditable = function (subjects) {
                    return PdlService.isExternalSubjectOpionionEditable(vm.utente, subjects, vm.iterationIndex, vm.isAdmin);
                };

                vm.isInvalidField = function (fieldName) {
                    return vm.invalidFields.indexOf(fieldName + vm.fieldSuffix) > -1;
                };

                vm.exists = function (item, list) {
                    if (!list || !angular.isArray(list)) {
                        list = [];
                    }
                    return list.indexOf(item) > -1;
                };

                vm.toggle = function (item, metadata, fieldName) {
                    var list = metadata[fieldName];
                    if (!list || !angular.isArray(list)) {
                        metadata[fieldName] = [];
                        list = metadata[fieldName];
                    }

                    var idx = list.indexOf(item);
                    if (idx > -1) {
                        list.splice(idx, 1);
                    } else {
                        list.push(item);
                    }
                };

                vm.showNonPrimaryOpinion = function () {
                    if (!vm.postCourt) {
                        return true;
                    } else if (vm.postCourt.metadati.collegioGaranzia === true
                            && vm.postCourt.metadati.manifestaInfondatezza === true
                            && vm.postCourt.metadati.conformita === false) {
                        return false;
                    }
                    return true;
                };

                // Autocomplete for section UnifiedText
                function searchUnifiedTexts(tokenNumeroAtto) {
                    var results = vm.atti;

                    return tokenNumeroAtto ? results.filter(createFilterFor(tokenNumeroAtto)) : [];
                }

                function createFilterFor(tokenNumeroAtto) {
                    return function filterFn(atto) {
                        var numeroAtto = atto.numeroAtto;
                        return (numeroAtto.includes(tokenNumeroAtto) && !alreadySelected(atto.id));
                    };
                }

                function alreadySelected(idAtto) {
                    for (i in vm.unifiedText.metadati.unifiedTexts) {
                        if (idAtto === vm.unifiedText.metadati.unifiedTexts[i].id) {
                            return true;
                        }
                    }
                    return false;
                }

                function getAllPdris () {
                    vm.allPdris = [];
                    Atto.queryNonPaged({
                        type: 'PDRIS'
                    }, onSuccess, onError);
                    function onSuccess(data) {
                        vm.allPdris = data;
                    };
                    function onError(error) {
                        console.log(error);
                    }
                }

                // Autocomplete for section Commission Message for REL
                function searchPdris(tokenNumeroAtto) {
                    var results = vm.allPdris;
                    return tokenNumeroAtto ? results.filter(createFilterForPdris(tokenNumeroAtto)) : [];
                }

                function createFilterForPdris(tokenNumeroAtto) {
                    return function filterFn(atto) {
                        var numeroAtto = atto.numeroAtto;
                        return numeroAtto.includes(tokenNumeroAtto);
                    };
                }

                function loadAtti() {
                    Atto.queryLite({
                        page: 0,
                        sort: 'numeroAtto,desc',
                        state: 'COMMISSIONE',
                        type: vm.actType
                    }, onSuccess, onError);
                }

                function onSuccess(data, headers) {
                    for (i in data) {
                        if (data[i].id === vm.unifiedText.idAtto) {
                            data.splice(i, 1); // remove myself
                            break;
                        }
                    }
                    vm.atti = data;
                }

                function onError(error) {
                    console.log("Failure!", error);
                    toastr.error(error.data.message);
                }

                function transformChip($chip) {
                    return {"id": $chip.id, "numeroAtto": $chip.numeroAtto, "titolo": $chip.titolo};
                }

                function resolveUnifiedTexts() {
                    if (!vm.unifiedText.metadati.unifiedTexts)
                        return [];

                    for (i in vm.unifiedText.metadati.unifiedTexts) {
                        Atto.get({
                            id: vm.unifiedText.metadati.unifiedTexts[i].id
                        }, onRetrieveSuccess, onError)
                    }
                }

                function onRetrieveSuccess(data, headers) {
                    vm.unifiedTextsModel.push({id: data.id, numeroAtto: data.numeroAtto, titolo: data.titolo});
                }

                function onAddUnifiedText($index, $chip) {
                    vm.unifiedText.metadati.unifiedTexts.push({id: $chip.id})
                }

                function onRemoveUnifiedText($index, $chip) {
                    for (i in vm.unifiedText.metadati.unifiedTexts) {
                        if ($chip.id === vm.unifiedText.metadati.unifiedTexts[i].id) {
                            vm.unifiedText.metadati.unifiedTexts.splice(i, 1);
                            break;
                        }
                    }
                }

                function toogleAddUnifiedText(status) {
                    if (status) {
                        if (vm.unifiedText.editable && !vm.atti.length) {
                            loadAtti();
                        }
                    } else {
                        vm.unifiedTextsModel = [];
                        vm.unifiedText.metadati.unifiedTexts = [];
                    }
                    vm.unifiedText.metadati.hasUnifiedTexts = status;
                }
            }]
    };
});
