(function () {
    'use strict';

    var adempimentoComponent = {

        templateUrl: 'app/components/adempimento/adempimento.html',
        bindings: {
            'model' : '=',
            'disabled' : '=',
            'commissioni': '=',
            'label': '@',
            'onDelete': '&',
        },
        controller: ['toastr', 'DateUtils', '$mdDialog', function (toastr, DateUtils, $mdDialog) {
            var vm = this;
            vm.incaricatoList = ['GIUNTA', 'CONSIGLIO', 'ALTRO'];
            vm.destinatarioAdempimento = ['COMMISSIONE', 'CONSIGLIO', 'ALTRO'];
            vm.model.commissioniDestinatarie = !vm.model.commissioniDestinatarie ? [] : vm.model.commissioniDestinatarie;
            vm.model.incaricatiAdempimento = !vm.model.incaricatiAdempimento ? [] : vm.model.incaricatiAdempimento;
            vm.model.destinatariAdempimento = !vm.model.destinatariAdempimento ? [] : vm.model.destinatariAdempimento;

            vm.destinatarioAdempimentoChanged = function() {
                if (vm.model.destinatariAdempimento && !vm.model.destinatariAdempimento.includes('COMMISSIONE')) {
                    vm.model.commissioniDestinatarie = [];
                }
            }
            
            vm.toggle = function (item, list) {
                var idx = list.indexOf(item);
                if (idx > -1) {
                list.splice(idx, 1);
                }
                else {
                list.push(item);
                }
            };

            vm.exists = function (item, list) {
                if (!list) {
                    return false;
                }
                return list.indexOf(item) > -1;
            };

            vm.onClearClick = function() {

                var confirm = $mdDialog.confirm()
                    .title('Sei sicuro di voler eliminare l\'adempimento?')
                    .textContent('Eliminando l\'adempimento le informazioni già inserite verranno perse. Procedere comunque?')
                    .ariaLabel('Eliminazione adempimento')
                    .ok('Conferma')
                    .cancel('Annulla');

                $mdDialog.show(confirm).then(function () {
                    vm.model = {
                        commissioniDestinatarie: []
                    };
                    vm.onDelete();
                }, function () {
                    
                });
                
            }
            
        }],
        controllerAs: 'vm'
    };

    angular.module('gestioneAttiApp').component('adempimentoComponent', adempimentoComponent);
})();
