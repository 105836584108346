(function() {
    'use strict';

    angular
        .module('gestioneAttiApp')
        .controller('PermissionsDeleteController', PermissionsDeleteController);

        PermissionsDeleteController.$inject = ['$uibModalInstance', 'entity', 'Atto'];

    function PermissionsDeleteController ($uibModalInstance, entity, Atto) {
        var vm = this;

        vm.tipoAtto = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete () {
            Atto.deleteTipoAtto({id: vm.tipoAtto.id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
