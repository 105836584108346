(function () {
    'use strict';

    var candidatureDesignazioniComponent = {

        templateUrl: 'app/entities/atto/sezioni/candidatureDesignazioni.html',
        bindings: {
            'candidatureDesignazioni' : '=',
            'atto' : '=',
            'changeDocumentType' : '=',
            'changeDocumentVisibility' : '=',
            'docTypes' : '=',
            'publicView' : '=',
            'utente' : '='
        },
        controller: ['Atto', 'ACT_TYPES', 'toastr', 'Principal', 'Documento', function (Atto, ACT_TYPES, toastr, Principal, Documento) {
            var vm = this;
            vm.candidatureDesignazioniList = [];
            vm.isAdmin = Principal.hasAnyAuthority(['ROLE_ADMIN']);
            vm.isSettoreAtti = Principal.hasAnyAuthority(['ROLE_SETTORE']);
            vm.isProtocollo = Principal.hasAnyAuthority(['ROLE_PROTOCOLLO']);

            vm.documentiEmendamento = Documento.getDocumentiBySezioneId({sezioneId: vm.candidatureDesignazioni.id}, onDocumentSuccess, onDocumentFail);
            
            function onDocumentSuccess(data){                
                data.forEach(function(document){
                    if(!document.candidatureDesignazioni) {
                        document.candidatureDesignazioni = [];
                    }
                    vm.candidatureDesignazioniList = vm.candidatureDesignazioniList.concat(document.candidatureDesignazioni);
                    document.mittentiProtocollo.forEach(function (mittenteProtocollo) {
                        if(!isMittentePresentInCandidatureDesignazioni(mittenteProtocollo, document)) {
                            var newCandidatura = {anagraficaProtocollo: mittenteProtocollo, nuovaDescrizioneCandidato: mittenteProtocollo.descrizione, documentId: document.id};
                            Documento.addOrUpdateDocumentCandidaturaDesignazione({documentId: document.id}, newCandidatura, 
                                function (result) {
                                    vm.candidatureDesignazioniList.push(newCandidatura);
                                },
                                function(error) {
                                    console.log(error);
                                })
                        }
                    });
                });
            }
            
            function isMittentePresentInCandidatureDesignazioni(mittenteProtocollo, document) {
                if(!document.candidatureDesignazioni || document.candidatureDesignazioni.length === 0) {
                    return false;
                }
                var index = document.candidatureDesignazioni.findIndex(function (candidatura) {return candidatura.anagraficaProtocollo.id === mittenteProtocollo.id});
                return index < 0 ? false : true;
            }

            vm.updateCandidatoDesignazioneDescription = function(candidatura) {
                if(!candidatura.nuovaDescrizioneCandidato || candidatura.nuovaDescrizioneCandidato === '') {
                    toastr.error('Impossibile salvare candidatura o designazione vuota!');
                } else {
                    Documento.addOrUpdateDocumentCandidaturaDesignazione({documentId: candidatura.documentId}, candidatura, 
                        function (result) {
                            toastr.success('Candidatura o Designazione salvata con successo!');
                        },
                        function(error) {
                            toastr.error('Errore duranta salvataggio della candidatura o designazione:' + error);
                        })
                }
            }

            function onDocumentFail(error) {
                console.log("Error retriving candidature e designazioni documents: " + error.message);
            }
        }],
        controllerAs: 'vm'
    };

    angular.module('gestioneAttiApp').component('candidatureDesignazioniComponent', candidatureDesignazioniComponent);
})();
