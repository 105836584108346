(function () {
    'use strict';

    var loadingSpinnerComponent = {

        templateUrl: 'app/components/custom-loading-spinner/loading-spinner.html',
        controller: [ function () {
            var vm = this;

        }],
        controllerAs: 'vm'
    };

    angular.module('gestioneAttiApp').component('loadingSpinnerComponent', loadingSpinnerComponent);
})();
