/**
 * Created by Michele Romani on 26/06/2017.
 */

angular.module('gestioneAttiApp').directive('uploadDialog',  function () {
    return {
        restrict: "E",
        scope: {
            dialogId: "=dialogId"
        },
        controller: ["$scope",
            function ($scope) {
                var vm = this;
            }],
        templateUrl: 'app/components/uploadDialog/uploadDialog.html'
    }
});
