angular.module('gestioneAttiApp').directive('advancedSearch', function () {
    return {
        restrict: "E",
        templateUrl: 'app/components/advancedSearch/advancedSearch.directive.html',
        scope: {
            loading: '=',
            searchCallback: '&'
        },
        transclude: false,
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$mdSidenav', 'SEARCH_STATES', 'Commissione', 'SoggettoEsterno', 'ACT_TYPES', 'AnagraficaProtocollo', 'Direzione', 'Atto', 'AdvanceSearchService',
            function ($mdSidenav, SEARCH_STATES, Commissione, SoggettoEsterno, ACT_TYPES, AnagraficaProtocollo, Direzione, Atto, AdvanceSearchService) {
                var vm = this;
                vm.listaAssessoriOrConsiglieri = [];
                vm.showConsiglieri = false;
                vm.legislature = [];
                initController();

                vm.closeSearchPanel = function () {
                    if ($mdSidenav('advanced-search-sidebar').isOpen()) {
                        $mdSidenav('advanced-search-sidebar').close();
                        AdvanceSearchService.setClosed();
                    }
                };

                vm.toggleCheck = function (fieldName, value, dependentField) {
                    var idx = vm.searchParams[fieldName].indexOf(value);
                    if (idx > -1) {
                        vm.searchParams[fieldName].splice(vm.searchParams[fieldName].indexOf(value), 1);
                    } else {
                        if (dependentField && vm.searchParams[dependentField] && vm.searchParams[dependentField].indexOf(value) > -1) {
                            vm.searchParams[dependentField].splice(vm.searchParams[dependentField].indexOf(value), 1);
                        }
                        vm.searchParams[fieldName].push(value);
                    }
                };

                vm.searchParamExists = function(fieldName, value) {
                    return vm.searchParams && vm.searchParams[fieldName] && vm.searchParams[fieldName].indexOf(value) > -1;
                };

                vm.search = function() {
                    if (!vm.loading) {
                        AdvanceSearchService.saveLatestSearchParams(vm.searchParams);
                        vm.searchCallback({searchParams: vm.searchParams});
                    }
                };

                function initController() {
                    vm.clearSearch = clearSearch;
                    var latestSearchParams = AdvanceSearchService.getLatestSearchParams();

                    if (latestSearchParams) {
                        vm.searchParams = latestSearchParams;
                        if (vm.searchParams.tipoProponente === 'GR') {
                            vm.showConsiglieri = false;  
                        } else {
                            vm.showConsiglieri = true;
                        }
                    } else {
                        vm.clearSearch();
                    }
                    
                    vm.queryStates = SEARCH_STATES;
                    vm.actTypes = ACT_TYPES;

                    Commissione.query({}, function (result) {
                        vm.commissioni = result;
                    });

                    SoggettoEsterno.query({}, function (result) {
                        vm.soggettiEsterni = result;
                    });

                    AnagraficaProtocollo.query({}, function(result){
                        vm.assessori = [];
                        vm.consiglieri = [];
                        angular.forEach(result, function (anagrafica) {
                            if (anagrafica.tipoProponente === "GR") {
                                vm.assessori.push(anagrafica);
                            } else {
                                vm.consiglieri.push(anagrafica);
                            }
                        });
                    });
                
                    Direzione.query({}, function(result){
                        vm.direzione = result;
                    });


                    Atto.queryLegislature({}, onSuccessLeg, onErrorLeg);
                    function onSuccessLeg(data){
                        vm.legislature=data;
                    }
                    function onErrorLeg(error){
                        console.log("Failure!", error);
                        toastr.error(error.data.message);
                    }
                    
                }

                vm.searchFirmatari = function (searchText) {
                    //console.log("SEARCH FIRMATARI");
                    return searchText ? vm.listaAssessoriOrConsiglieri.filter(createFilterFor(searchText)) : [];
                };

                vm.searchAssessori = function (searchText) {
                    //console.log("SEARCH ASSESSORI - tipoAnagrafica =GR");
                    //return searchText ? vm.assessori.filter(createFilterFor(searchText)) : [];

                    if(searchText.length < 3) {
                        return [];
                    }
        
                    return AnagraficaProtocollo.search(
                        { 
                            term: searchText,
                            protocolSystem: 'ALL',
                            tipoAnagrafica: 'GR',
                            page: 0,
                            size: 20
                        }).$promise.then(onSuccessAnagAss, onErrorAnag);
        
                };

                function onSuccessAnagAss(data, headers) {
                    vm.assessori = data;
                    return data;
                }
        
                function onErrorAnag(error) {
                    console.log("Errore nel recupero anagrafiche!", error);
                }

                vm.searchConsiglieri = function (searchText) {
                    //console.log("SEARCH CONSIGLIERI - tipoAnagrafica = CR");
                    //return searchText ? vm.consiglieri.filter(createFilterFor(searchText)) : [];
                    if(searchText.length < 3) {
                        return [];
                    }
        
                    return AnagraficaProtocollo.search(
                        { 
                            term: searchText,
                            protocolSystem: 'ALL',
                            tipoAnagrafica: 'CR',
                            page: 0,
                            size: 20
                        }).$promise.then(onSuccessAnagCons, onErrorAnag);
                };

                function onSuccessAnagCons(data, headers) {
                    vm.consiglieri = data;
                    return vm.consiglieri;
                }

                vm.addPersonToList = function (chip) {
                    // Otherwise, create a new one
                    if (angular.isObject(chip)) {
                        return chip;
                    }
                    return {descrizione: chip};
        
                };
        
                function createFilterFor(query) {
                    var lowercaseQuery = angular.lowercase(query);
        
                    return function filterFn(firmatario) {
                        var codice = firmatario.codice.toLowerCase();
                        var descrizione = firmatario.descrizione.toLowerCase();
                        return (codice.indexOf(lowercaseQuery) >= 0 || descrizione.indexOf(lowercaseQuery) >= 0);
                    };
                }

                vm.tipoProponenteChange = function () {
                    vm.searchParams.proponenti = [];
                    vm.searchParams.direzioneGRId = null;
                    if (vm.searchParams.tipoProponente === 'GR') {
                        vm.showConsiglieri = false;  
                    } else {
                        vm.showConsiglieri = true;
                    }
                };

                function clearSearch() {
                    vm.searchParams = {
                        parereReferente: [],
                        parereSecondario: [],
                        parereObbligatorio: [],
                        osservazioni: [],
                        legislatura : null,
                        tipoProponente: null,
                        proponenti: [],
                        direzioneGRId: null
                    };
                }
            }]
    };

});
