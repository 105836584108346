angular.module('gestioneAttiApp').directive('emendments', function () {
    return {
        restrict: "E",
        templateUrl: 'app/components/emendments/emendments.directive.html',
        scope: {
            editable: '=',
            emendments: '=',
            includeState: '=',
            invalidFields: '=?',
            peopleList: '=?',
            postponementNumber: '=?',
            validating: '=?',
            sezioneId: '='
        },
        transclude: false,
        controllerAs: 'vm',
        bindToController: true,
        controller: ['AnagraficaProtocollo', 'toastr', 'Documento', function (AnagraficaProtocollo, toastr, Documento) {
                var vm = this;
                vm.documentiEmendamento = Documento.getDocumentiEmendamento({sezioneId: vm.sezioneId}, onDocumentSuccess, onDocumentFail);
                function onDocumentSuccess(data){                 
                    var ementamentiFromDocumento = [];
                    data.forEach(function(document){
                        if(document.datiProtocollo && document.datiProtocollo.numeroProtocolloConsiglio && !protocolloAlreadyPresent(document.datiProtocollo.numeroProtocolloConsiglio)) {
                            ementamentiFromDocumento.push(
                                {
                                    firmatari: document.mittentiProtocollo,
                                    dataProtocollo: document.datiProtocollo.dataProtocolloConsiglio,
                                    protocollo: document.datiProtocollo.numeroProtocolloConsiglio
                                })
                        }
                    });

                    angular.forEach(ementamentiFromDocumento, function (emendment) {
                        vm.emendments.push(emendment);
                        vm.extendedEmendments.push({
                            emendment: emendment,
                            searchFunction: searchFunction()
                        });
                    });
                }

                function protocolloAlreadyPresent(numero) {
                    if(!vm.emendments || vm.emendments.length === 0) {
                        return false;
                    }
                    for(var i=0; i < vm.emendments.length; i++){
                        if( vm.emendments[i].protocollo &&  vm.emendments[i].protocollo == numero){
                            return true;
                        }   
                    }
                    return false;
                }

                function onDocumentFail(error) {
                    console.log("Error retriving emendamenti documents: " + error);
                }

                vm.invalidFields = vm.invalidFields || [];
                vm.postponementSuffix = '';
                if (vm.postponementNumber && vm.postponementNumber > 0) {
                    vm.postponementSuffix = '_' + vm.postponementNumber;
                }

                vm.firmatari = [];
                vm.emendments = vm.emendments || [];
                vm.states = ['APPROVED', 'REJECTED', 'WITHDRAWN', 'UNACCEPTABLE', 'DECAYED'];

                vm.extendedEmendments = [];
                angular.forEach(vm.emendments, function (emendment) {
                    vm.extendedEmendments.push({
                        emendment: emendment,
                        searchFunction: searchFunction()
                    });
                });

                vm.isInvalidField = function (fieldName, idx) {
                    return vm.invalidFields.indexOf(fieldName + vm.postponementSuffix + '_' + idx) > -1;
                };

                vm.newEmendment = function () {
                    var emendment = {firmatari: []};
                    vm.emendments.push(emendment);
                    vm.extendedEmendments.push({
                        emendment: emendment,
                        searchFunction: searchFunction()
                    });
                };

                vm.removeEmendment = function (idx) {
                    if (vm.extendedEmendments.length === 1) {
                        var emendment = {firmatari: []};
                        vm.emendments[0] = emendment;
                        vm.extendedEmendments[0].emendment = emendment;
                        return;
                    }

                    vm.extendedEmendments.splice(idx, 1);
                    vm.emendments.splice(idx, 1);
                };

                vm.addPersonToList = function (chip) {
                    // If it is an object, it's already a known chip
                    if (angular.isObject(chip)) {
                        return chip;
                    }
                    // Otherwise, create a new one
                    return {descrizione: chip};
                };

                if (vm.peopleList) {
                    vm.firmatari = vm.peopleList;
                } else {
                    //load firmatari (persone generiche atm)
                    AnagraficaProtocollo.query({}, function (anagrafiche) {
                        vm.firmatari = anagrafiche;
                    }, function (error) {
                        toastr.error('Impossibile reperire la lista dei firmatari: ' + error.message, 'Errore!');
                    });
                }

                function searchFunction() {
                    return function (query) {
                        var results = query ? vm.firmatari.filter(createFilterFor(query)) : [];
                        return results;
                    };
                }

                function createFilterFor(query) {
                    var lowercaseQuery = angular.lowercase(query);

                    return function filterFn(firmatario) {
                        var codice = firmatario.codice ? firmatario.codice.toLowerCase() : '';
                        var descrizione = firmatario.descrizione ? firmatario.descrizione.toLowerCase() : '';
                        return (codice.indexOf(lowercaseQuery) >= 0 || descrizione.indexOf(lowercaseQuery) >= 0);
                    };

                }

                //Not needed anymore because now emendments come only from protocollo
/*                 if (vm.emendments.length === 0) {
                    vm.newEmendment();
                } */


                vm.isAddButtonEnabled = function (index) {
                    if (vm.disabled || !vm.emendments || vm.emendments[index] && index !== vm.emendments.length - 1) {
                        return false;
                    }
                    var emendment = vm.emendments[index];
                    if (!emendment) {
                        return false;
                    }
                    
                    if (vm.includeState && !emendment.state) {
                        return false;
                    }
                    return emendment.numeroEmendamento && emendment.dataProtocollo && emendment.protocollo && emendment.firmatari && emendment.firmatari.length > 0;
                };
            }]
    };

});
