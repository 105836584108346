(function () {
    'use strict';

    angular
            .module('gestioneAttiApp')
            .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
                .state('atto', {
                    parent: 'entity',
                    url: '/atto?page&sort&search',
                    data: {
                        authorities: [],
                        pageTitle: 'gestioneAttiApp.atto.home.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/entities/atto/attos.html',
                            controller: 'AttoController',
                            controllerAs: 'vm'
                        }
                    },
                    params: {
                        page: {
                            value: '1',
                            squash: true
                        },
                        sort: {
                            value: 'id,asc',
                            squash: true
                        },
                        search: null
                    },
                    resolve: {
                        pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                                return {
                                    page: PaginationUtil.parsePage($stateParams.page),
                                    sort: $stateParams.sort,
                                    predicate: PaginationUtil.parsePredicate($stateParams.sort),
                                    ascending: PaginationUtil.parseAscending($stateParams.sort),
                                    search: $stateParams.search
                                };
                            }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('atto');
                                $translatePartialLoader.addPart('tipoAtto');
                                $translatePartialLoader.addPart('tipoProponente');
                                $translatePartialLoader.addPart('global');
                                $translatePartialLoader.addPart('form');
                                $translatePartialLoader.addPart('addable-input');
                                $translatePartialLoader.addPart('document-toolbar');
                                $translatePartialLoader.addPart('archive-tree');
                                $translatePartialLoader.addPart('commission-opinion');
                                $translatePartialLoader.addPart('commission-observation');
                                $translatePartialLoader.addPart('emendments');
                                $translatePartialLoader.addPart('sectionNotes');

                                return $translate.refresh();
                            }]
                    }
                }) // Questo e' lo stato da cambiare
                .state('atto-view', {
                    parent: 'entity',
                    url: '/atto/{id}',
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('atto');
                                $translatePartialLoader.addPart('tipoAtto');
                                $translatePartialLoader.addPart('tipoProponente');
                                $translatePartialLoader.addPart('global');
                                $translatePartialLoader.addPart('form');
                                $translatePartialLoader.addPart('addable-input');
                                $translatePartialLoader.addPart('document-toolbar');
                                $translatePartialLoader.addPart('archive-tree');
                                $translatePartialLoader.addPart('commission-opinion');
                                $translatePartialLoader.addPart('commission-observation');
                                $translatePartialLoader.addPart('emendments');
                                $translatePartialLoader.addPart('sectionNotes');
                                $translatePartialLoader.addPart('section-links');
                                return $translate.refresh();
                            }],
                        entity: ['$stateParams', 'Atto', function ($stateParams, Atto) {
                                return Atto.get({id: $stateParams.id}).$promise;
                            }],
                        commissioni: ['Commissione', function (Commissione) {
                                return Commissione.query({}).$promise;
                            }],
                        soggettiEsterni: ['SoggettoEsterno', function (SoggettoEsterno) {
                                return SoggettoEsterno.query({}).$promise;
                            }],
                        settori: ['Settore', function (Settore) {
                                return Settore.query({}).$promise;
                            }],
                        utente: ['Principal', function (Principal) {
                                return Principal.identity();
                            }],
                        previousState: ["$state", function ($state) {
                                var currentStateData = {
                                    name: $state.current.name || 'home',
                                    params: $state.params,
                                    url: $state.href($state.current.name, $state.params)
                                };
                                return currentStateData;
                            }],
                        // anagrafiche: ['AnagraficaProtocollo', function (AnagraficaProtocollo) {
                        //         return AnagraficaProtocollo.query({}).$promise;
                        //     }],
                        enti: ['AnagraficaIterlegis', function (AnagraficaIterlegis) {
                            return AnagraficaIterlegis.query({'type': 'ENTE'}).$promise;
                        }],
                        organi: ['AnagraficaIterlegis', function (AnagraficaIterlegis) {
                            return AnagraficaIterlegis.query({'type': 'ORGANO'}).$promise;
                        }],
                        direzioni: ['Direzione', function (Direzione) {
                                return Direzione.query({}).$promise;
                            }],
                        publicView: function () {
                            return false;
                        }
                    }
                })
                .state('atto-detail', {
                    parent: 'atto-view',
                    url: '/?sectionId',
                    data: {
                        authorities: [],
                        pageTitle: 'gestioneAttiApp.atto.detail.title'
                    },
                    reloadOnSearch: false,
                    params: {
                        sectionId: {
                          value: null,
                        }
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/entities/atto/atto-detail.html',
                            controller: 'AttoDetailController',
                            controllerAs: 'vm'
                        }
                    },
                    resolve: {
                        editable: function () {
                            return false;
                        },
                        docTypes: function () {
                            return null;
                        },
                        sezioniAtto: ['entity', 'Atto', function (entity, Atto) {
                                return Atto.getSezioni({id: entity.id}).$promise;
                            }]
                    }
                })
                .state('atto-public-detail', {
                    parent: 'atto-view-public',
                    url: '/publicView',
                    data: {
                        authorities: [],
                        pageTitle: 'gestioneAttiApp.atto.detail.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/entities/atto/atto-detail.html',
                            controller: 'AttoDetailController',
                            controllerAs: 'vm'
                        }
                    },
                    resolve: {
                        sezioniAtto: ['entity', 'Atto', function (entity, Atto) {
                                return Atto.getSezioni({id: entity.id, publicView: true}).$promise;
                            }],
                        editable: function () {
                            return false;
                        },
                        docTypes: function () {
                            return null;
                        }                        
                    }
                })
                .state('atto-view-public', {
                    parent: 'entity',
                    url: '/atto/{id}',
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('atto');
                                $translatePartialLoader.addPart('tipoAtto');
                                $translatePartialLoader.addPart('tipoProponente');
                                $translatePartialLoader.addPart('global');
                                $translatePartialLoader.addPart('form');
                                $translatePartialLoader.addPart('addable-input');
                                $translatePartialLoader.addPart('document-toolbar');
                                $translatePartialLoader.addPart('archive-tree');
                                $translatePartialLoader.addPart('commission-opinion');
                                $translatePartialLoader.addPart('commission-observation');
                                $translatePartialLoader.addPart('emendments');
                                $translatePartialLoader.addPart('sectionNotes');
                                $translatePartialLoader.addPart('section-links');
                                return $translate.refresh();
                            }],
                        entity: ['$stateParams', 'Atto', function ($stateParams, Atto) {
                                return Atto.get({id: $stateParams.id, publicView: true}).$promise;
                            }],
                        commissioni: ['Commissione', function (Commissione) {
                                return Commissione.query({}).$promise;
                            }],
                        soggettiEsterni: ['SoggettoEsterno', function (SoggettoEsterno) {
                                return SoggettoEsterno.query({}).$promise;
                            }],
                        settori: ['Settore', function (Settore) {
                                return Settore.query({}).$promise;
                            }],
                        utente: ['Principal', function (Principal) {
                                return Principal.identity();
                            }],
                        previousState: ["$state", function ($state) {
                                var currentStateData = {
                                    name: $state.current.name || 'home',
                                    params: $state.params,
                                    url: $state.href($state.current.name, $state.params)
                                };
                                return currentStateData;
                            }],
                        anagrafiche: ['AnagraficaProtocollo', function (AnagraficaProtocollo) {
                                return AnagraficaProtocollo.query({}).$promise;
                            }],
                        enti: ['AnagraficaIterlegis', function (AnagraficaIterlegis) {
                            return AnagraficaIterlegis.query({'type': 'ENTE'}).$promise;
                        }],
                        organi: ['AnagraficaIterlegis', function (AnagraficaIterlegis) {
                            return AnagraficaIterlegis.query({'type': 'ORGANO'}).$promise;
                        }],
                        direzioni: ['Direzione', function (Direzione) {
                                return Direzione.query({}).$promise;
                            }],
                        publicView: function () {
                            return true;
                        }
                    }
                })
                .state('atto-edit', {
                    parent: 'atto-view',
                    url: '/edit',
                    data: {
                        authorities: ['ROLE_USER'],
                        pageTitle: 'gestioneAttiApp.atto.edit.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/entities/atto/atto-detail.html',
                            controller: 'AttoDetailController',
                            controllerAs: 'vm'
                        }
                    },
                    onEnter: ['$state', 'utente', '$timeout', function ($state, utente, $timeout) {
                            if (utente.commissioneRuolo && utente.commissioneRuolo.nome !== 'Dipendente') {
                                $timeout(function () {
                                    $state.go('home', {}, {reload: true});
                                }, 0);
                            }
                        }],
                    resolve: {
                        editable: function () {
                            return true;
                        },
                        docTypes: ['TipoDocumento', function (TipoDocumento) {
                                return TipoDocumento.query({}).$promise;
                            }],
                        sezioniAtto: ['entity', 'Atto', function (entity, Atto) {
                                return Atto.getSezioni({id: entity.id}).$promise;
                            }]
                    }
                })
                .state('atto-insert', {
                    parent: 'entity',
                    url: '/insert',
                    data: {
                        authorities: ['ROLE_ADMIN', 'ROLE_PROTOCOLLO', 'ROLE_SETTORE'],
                        pageTitle: 'gestioneAttiApp.atto.edit.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/entities/atto/atto-insert.html',
                            controller: 'AttoInsertController',
                            controllerAs: 'vm'
                        }
                    },
                    resolve: {
                        utente: ['Principal', function (Principal) {
                                return Principal.identity();
                            }],
                        previousState: ["$state", function ($state) {
                                var currentStateData = {
                                    name: $state.current.name || 'home',
                                    params: $state.params,
                                    url: $state.href($state.current.name, $state.params)
                                };
                                return currentStateData;
                            }],
                        anagrafiche: ['AnagraficaProtocollo', function (AnagraficaProtocollo) {
                                return AnagraficaProtocollo.query({}).$promise;
                            }],
                        enti: ['AnagraficaIterlegis', function (AnagraficaIterlegis) {
                            return AnagraficaIterlegis.query({'type': 'ENTE'}).$promise;
                        }],
                        organi: ['AnagraficaIterlegis', function (AnagraficaIterlegis) {
                            return AnagraficaIterlegis.query({'type': 'ORGANO'}).$promise;
                        }],
                        direzioni: ['Direzione', function (Direzione) {
                                return Direzione.query({}).$promise;
                            }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('atto');
                                $translatePartialLoader.addPart('tipoAtto');
                                $translatePartialLoader.addPart('tipoProponente');
                                $translatePartialLoader.addPart('global');
                                $translatePartialLoader.addPart('form');
                                $translatePartialLoader.addPart('addable-input');
                                $translatePartialLoader.addPart('document-toolbar');
                                $translatePartialLoader.addPart('archive-tree');
                                $translatePartialLoader.addPart('emendments');
                                $translatePartialLoader.addPart('sectionNotes');
                                $translatePartialLoader.addPart('section-links');

                                return $translate.refresh();
                            }]
                    }
                })
                .state('atto-view-by-pk', {
                    parent: 'entity',
                    url: '/atto/{legislatura}/{anno}/{tipoAtto}/{numeroAtto}',
                    data: {
                        authorities: [],
                        pageTitle: 'gestioneAttiApp.atto.detail.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/entities/atto/atto-detail.html',
                            controller: 'AttoDetailController',
                            controllerAs: 'vm'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('atto');
                                $translatePartialLoader.addPart('tipoAtto');
                                $translatePartialLoader.addPart('tipoProponente');
                                $translatePartialLoader.addPart('global');
                                $translatePartialLoader.addPart('form');
                                $translatePartialLoader.addPart('section-links');
                                return $translate.refresh();
                            }],
                        entity: ['$stateParams', 'Atto', function ($stateParams, Atto) {
                                return Atto.getByPk({
                                    legislatura: $stateParams.legislatura,
                                    anno: $stateParams.anno,
                                    tipoAtto: $stateParams.tipoAtto,
                                    numeroAtto: $stateParams.numeroAtto,
                                }).$promise;
                            }],
                        sezioniAtto: ['entity', 'Atto', function (entity, Atto) {
                                return Atto.getSezioni({id: entity.id}).$promise;
                            }],
                        commissioni: ['Commissione', function (Commissione) {
                                return Commissione.query({}).$promise;
                            }],
                        soggettiEsterni: ['SoggettoEsterno', function (SoggettoEsterno) {
                                return SoggettoEsterno.query({}).$promise;
                            }],
                        settori: ['Settore', function (Settore) {
                                return Settore.query({}).$promise;
                            }],
                        utente: ['Principal', function (Principal) {
                                return Principal.identity();
                            }],
                        anagrafiche: ['AnagraficaProtocollo', function (AnagraficaProtocollo) {
                                return AnagraficaProtocollo.query({}).$promise;
                            }],
                        enti: ['AnagraficaIterlegis', function (AnagraficaIterlegis) {
                            return AnagraficaIterlegis.query({'type': 'ENTE'}).$promise;
                        }],
                        organi: ['AnagraficaIterlegis', function (AnagraficaIterlegis) {
                            return AnagraficaIterlegis.query({'type': 'ORGANO'}).$promise;
                        }],
                        docTypes: function () {
                            return null;
                        },
                        direzioni: ['Direzione', function (Direzione) {
                                return Direzione.query({}).$promise;
                            }],
                        previousState: function () {
                            return false;
                        },
                        editable: function () {
                            return false;
                        }
                    }
                })
                .state('atto-view-by-numeroLeggeDef', {
                    parent: 'entity',
                    url: '/atto/{legislatura}/{numeroLeggeDef}',
                    data: {
                        authorities: [],
                        pageTitle: 'gestioneAttiApp.atto.detail.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/entities/atto/atto-detail.html',
                            controller: 'AttoDetailController',
                            controllerAs: 'vm'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('atto');
                                $translatePartialLoader.addPart('tipoAtto');
                                $translatePartialLoader.addPart('tipoProponente');
                                $translatePartialLoader.addPart('global');
                                $translatePartialLoader.addPart('form');
                                $translatePartialLoader.addPart('section-links');
                                return $translate.refresh();
                            }],
                        entity: ['$stateParams', 'Atto', function ($stateParams, Atto) {
                                return Atto.getByNumeroLeggeDef($stateParams.legislatura, $stateParams.numeroLeggeDef);
                            }],
                        sezioniAtto: ['entity', 'Atto', function (entity, Atto) {
                                return Atto.getSezioni({id: entity.id}).$promise;
                            }],
                        commissioni: ['Commissione', function (Commissione) {
                                return Commissione.query({}).$promise;
                            }],
                        soggettiEsterni: ['SoggettoEsterno', function (SoggettoEsterno) {
                                return SoggettoEsterno.query({}).$promise;
                            }],
                        settori: ['Settore', function (Settore) {
                                return Settore.query({}).$promise;
                            }],
                        utente: ['Principal', function (Principal) {
                                return Principal.identity();
                            }],
                        anagrafiche: ['AnagraficaProtocollo', function (AnagraficaProtocollo) {
                                return AnagraficaProtocollo.query({}).$promise;
                            }],
                        enti: ['AnagraficaIterlegis', function (AnagraficaIterlegis) {
                            return AnagraficaIterlegis.query({'type': 'ENTE'}).$promise;
                        }],
                        organi: ['AnagraficaIterlegis', function (AnagraficaIterlegis) {
                            return AnagraficaIterlegis.query({'type': 'ORGANO'}).$promise;
                        }],
                        docTypes: function () {
                            return null;
                        },
                        direzioni: ['Direzione', function (Direzione) {
                                return Direzione.query({}).$promise;
                            }],
                        previousState: function () {
                            return false;
                        },
                        editable: function () {
                            return false;
                        }
                    }
                }).state('atto-landing-page', {
                    parent: 'entity',
                    url: '/atto-lp?numeroAtto&data&tipoAtto',
                    data: {
                        authorities: []
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/entities/atto/atto-landing-page/atto-landing-page.html',
                            controller: 'AttoLandingPageController',
                            controllerAs: 'vm'
                        }
                    },
                        params: {
                            data: {
                                value: null,
                                squash: true
                            },
                            tipoAtto: {
                                value: null,
                                squash: true
                            },
                            numeroAtto: {
                                value: null,
                                squash:true
                            }
                        },
/*                     onEnter: ['$state', 'Principal', '$timeout', function ($state, Principal, $timeout) {
                        if (Principal.isAuthenticated()) {
                            $timeout(function () {
                                $state.go('home_logged', {}, {reload: true});
                            }, 0);
                        }
                    }], */
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('home');
                            $translatePartialLoader.addPart('atto');
                            $translatePartialLoader.addPart('tipoAtto');
                            $translatePartialLoader.addPart('tipoProponente');
                            $translatePartialLoader.addPart('form');
                            return $translate.refresh();
                        }],
                        attoParams: ['$stateParams', function ($stateParams) {
                            return {
                                data: $stateParams.data,
                                tipoAtto: $stateParams.tipoAtto,
                                numeroAtto: $stateParams.numeroAtto
                            }
                        }],
                    }
                });
    }
})();
