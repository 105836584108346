(function () {
    'use strict';

    var adempimentiListComponent = {

        templateUrl: 'app/components/adempimentiList/adempimenti-list.html',
        bindings: {
            'model' : '=',
            'disabled' : '=',
            'commissioni': '='
        },
        controller: ['toastr', 'AdempimentoUtil', function (toastr, AdempimentoUtil) {
            var vm = this;
            vm.model.adempimenti = !vm.model.adempimenti ? [] : vm.model.adempimenti;

            vm.onAdempimentoDelete = function(index) {
                vm.model.adempimenti.splice(index, 1);
            }

            vm.addNewAdempimento = function() {
                for(var i = 0; i < vm.model.adempimenti.length; i++) {
                    if (!AdempimentoUtil.isAdempimentoValid(vm.model.adempimenti[i])) {
                        toastr.error('Impossibile aggiungere un nuovo adempimento: controllare che i precedenti siano compilati correttamente.');
                        return;
                    }
                }
                vm.model.adempimenti.push({});
            }
        }],
        controllerAs: 'vm'
    };

    angular.module('gestioneAttiApp').component('adempimentiListComponent', adempimentiListComponent);
})();
