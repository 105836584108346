(function () {

    'use strict';

    angular.module('gestioneAttiApp').service('PdrisValidator', ['PdlService', 'MinorBasicValidator', '$location', '$anchorScroll',
        function (PdlService, MinorBasicValidator, $location, $anchorScroll) {

            this.PdlService = PdlService;
            this.$location = $location;
            this.$anchorScroll = $anchorScroll;

            // Presentato
            this.validatePresentation = validatePresentation;

            // Aula
            this.validateCourtroomMeeting = validateCourtroomMeeting;
            this.validateSectorSections = validateSectorSections;
            this.validateCourtroomOutcome = validateCourtroomOutcome;

            // Stato Approvato
            this.validatePostCourtroomSections = validatePostCourtroomSections;
            this.validatePostCourtroom = validatePostCourtroom;

            this.validate = function (state, numeroRinvio, utente, userRolesMap, idPrimaCommissione, action, primaryCommission) {
                var invalidFields = [];
                switch (state) {
                    case "PRESENTATO":
                        return validateSectorSections(invalidFields);
                    case "COMMISSIONE":
                        return MinorBasicValidator.validateCommissionSections(invalidFields, utente, userRolesMap,
                            numeroRinvio, idPrimaCommissione, action && action.name === 'assignToCourtRoom', primaryCommission);
                    case "AULA":
                        // return validateCourtroomSections(invalidFields, numeroRinvio);
                        return MinorBasicValidator.validateCourtroomSections(invalidFields, numeroRinvio);
                    case "APPROVATO":
                        // return validatePostCourtroomSections(invalidFields, numeroRinvio);
                        return MinorBasicValidator.validatePostCourtroomSections(invalidFields, numeroRinvio);
                    default:
                        console.error("STATO SCONOSCIUTO: " + state);
                        return {valid: false, message: "STATO SCONOSCIUTO: " + state};
                }
            };

            function validateSectorSections(invalidFields){
                return {valid: true, message: 'ok', invalidFields: invalidFields};                
            }

            function validateCourtroomSections(invalidFields, numeroRinvio) {
                var result;
                result = validateAssignmentLetter(invalidFields, 0);
                if(!result.valid) {
                    return result;
                }
                for (var i = 0; i <= numeroRinvio; i++) {
                    result = validateCourtroomEmendments(invalidFields, i);
                    if (result.valid) {
                        result = validateCourtroomMeeting(invalidFields, i);
                        if (result.valid) {
                            result = validateCourtroomOutcome(invalidFields, i);
                        } else {
                            break;
                        }
                    } else {
                        break;
                    }
                }
                return result;
            }

            function validatePostCourtroom(invalidFields, idx) {
                var sezione = PdlService.getSezioniFromMap('POST_COURT', idx);
                if (!sezione) {
                    return {valid: true, message: message, invalidFields: invalidFields};
                }
                var suffix = "";
                if (idx > 0) {
                    suffix += "_" + idx;
                }

                var metadata = sezione.metadati;
                var message = '<ul>';
                if (!metadata.numeroLeggeAC || metadata.numeroLeggeAC.trim().length === 0) {
                    message += '<li><i>Numero legge AC</i> è un campo obbligatorio</li>';
                    invalidFields.push('numeroLeggeAC' + suffix);
                }
                if (!metadata.dataLeggeAC) {
                    message += '<li><i>Data legge AC</i> è un campo obbligatorio</li>';
                    invalidFields.push('dataLeggeAC' + suffix);
                }
                message += '</ul>';
                var valid = invalidFields.length === 0;
                if (!valid) {
                    $location.hash('postCourtroom' + suffix);
                    $anchorScroll();
                    sezione.visible = true;
                }
                return {valid: valid, message: message, invalidFields: invalidFields};
            }


            //nel caso della lettera di assegnazione i e' sempre 0!
            function validateAssignmentLetter(invalidFields, i) {
                var metadata = PdlService.sezioniMap.ASSIGNMENT_LETTER.metadati;

                var message = '<ul>';


                if (!metadata.parereReferente || !metadata.parereReferente.length) {
                    message += '<li><i>Assegnazione commissioni:</i> devi selezionare almeno una commissione </li>';
                    invalidFields.push('parereReferente');
                }           
                message += '</ul>';

                var valid = invalidFields.length === 0;
                if (!valid) {
                    $location.hash('assignmentLetter');
                    $anchorScroll();
                    PdlService.sezioniMap.ASSIGNMENT_LETTER.visible = true;
                }

                return {valid: valid, message: message, invalidFields: invalidFields};
            }

            function validatePostCourtroomSections(invalidFields, numeroRinvio) {
                var result;

                for (var i = 0; i <= numeroRinvio; i++) {
                    //result = validatePostCourtroom(invalidFields, i);
                    result = {valid: true};
                    if (result.valid) {
                        result = validateActuationNotes(invalidFields,i);
                        if(!result.valid){
                            break;
                        }
                    } else {
                        break;
                    }
                }

                return result;
            }

            //Da sapere quali campi sono obbligatori!
            function validateActuationNotes(invalidFields, i){
                return {valid: true, message: '', invalidFields: invalidFields};
            }

            function validatePresentation(invalidFields, atto) {
                if (!invalidFields)
                    invalidFields = [];

                var message = '<ul>';

                if (!atto.legislatura) {
                    message += '<li><i>Legislatura</i> è un campo obbligatorio</li>';
                    invalidFields.push('legislatura');
                } else if (isNaN(atto.legislatura)) {
                    message += '<li><i>Legislatura</i> contiene un valore non valido</li>';
                    invalidFields.push('legislatura');
                }

                if (!atto.numeroAtto) {
                    message += '<li><i>Numero Atto</i> è un campo obbligatorio</li>';
                    invalidFields.push('numeroAtto');
                }

                if (!atto.tipoAtto) {
                    message += '<li><i>Tipo Atto</i> è un campo obbligatorio</li>';
                    invalidFields.push('tipoAtto');
                }

                if (!atto.titolo) {
                    message += '<li><i>Titolo</i> è un campo obbligatorio</li>';
                    invalidFields.push('titolo');
                }

                if (!atto.numeroProtocollo) {
                    message += '<li><i>Numero Protocollo</i> è un campo obbligatorio</li>';
                    invalidFields.push('numeroProtocollo');
                } else if (isNaN(atto.numeroProtocollo)) {
                    message += '<li><i>Numero Protocollo</i> contiene un valore non valido</li>';
                    invalidFields.push('numeroProtocollo');
                }

                if (!atto.dataProtocollo) {
                    message += '<li><i>Data Protocollo</i> è un campo obbligatorio</li>';
                    invalidFields.push('dataProtocollo');
                }

                if (!atto.tipoProponente) {
                    message += '<li><i>Tipo Proponente</i> è un campo obbligatorio</li>';
                    invalidFields.push('tipoProponente');
                } else if (!atto.proponente.proponenti || atto.proponente.proponenti.length === 0) {
                    message += '<li>Devi specificare almeno un proponente</li>';
                    invalidFields.push('proponenti');
                }


                message += '</ul>';

                var valid = invalidFields.length === 0;
                if (!valid) {
                    $location.hash('presentation');
                    $anchorScroll();
                    if (PdlService.sezioniMap.PRESENTATION) {
                        PdlService.sezioniMap.PRESENTATION.visible = true;
                    }
                }

                return {valid: valid, message: message, invalidFields: invalidFields};
            }

            function validateCourtroomMeeting(invalidFields, idx) {
                var sezione = PdlService.getSezioniFromMap('COURT_MEETING', idx);
                if (!sezione) {
                    return {valid: true, message: message, invalidFields: invalidFields};
                }

                var suffix = "";
                if (idx > 0) {
                    suffix += "_" + idx;
                }

                var metadata = sezione.metadati;

                var message = '<ul>';

                if (!metadata.sedute || !metadata.sedute[0]) {
                    message += '<li><i>Date sedute:</i> inserire almeno una data</li>';
                    invalidFields.push('sessionsDate' + suffix);
                }

                message += '</ul>';

                var valid = invalidFields.length === 0;
                if (!valid) {
                    $location.hash('courtroomOutcome' + suffix);
                    $anchorScroll();
                    sezione.visible = true;
                }

                return {valid: valid, message: message, invalidFields: invalidFields};
            }

            function validateCourtroomOutcome(invalidFields, idx) {
                var sezione = PdlService.getSezioniFromMap('COURT_OUTCOME', idx);
                if (!sezione) {
                    return {valid: true, message: message, invalidFields: invalidFields};
                }

                var suffix = "";
                if (idx > 0) {
                    suffix += "_" + idx;
                }

                var metadata = sezione.metadati;

                var message = '<ul>';

                //Non sono piu' obbligatori: richiesta del Caldini in data 27/5/2019
/*                 if (typeof (metadata.modificaOggettoTitolo) === 'undefined' || metadata.modificaOggettoTitolo === null) {
                    message += '<li><i>Modifica a titolo della Commissione</i> è un campo obbligatorio</li>';
                    invalidFields.push('modificaOggettoTitoloAula' + suffix);
                } else */ 
                if (metadata.modificaOggettoTitolo && (!metadata.nuovoOggettoTitolo || metadata.nuovoOggettoTitolo.trim().length === 0)) {
                    message += '<li><i>Nuovo titolo</i> è un campo obbligatorio</li>';
                    invalidFields.push('nuovoOggettoTitoloAula' + suffix);
                }
/*                 if (typeof (metadata.coordinamentoTesto) === 'undefined' || metadata.coordinamentoTesto === null) {
                    message += '<li><i>Coordinamento formale testo</i> è un campo obbligatorio</li>';
                    invalidFields.push('coordinamentoTesto' + suffix);
                } */

                message += '</ul>';

                var valid = invalidFields.length === 0;
                if (!valid) {
                    $location.hash('courtroomOutcome' + suffix);
                    $anchorScroll();
                    sezione.visible = true;
                }

                return {valid: valid, message: message, invalidFields: invalidFields};
            }
        }]);
})();
