/**
 * Created by miche on 27/06/2017.
 */
angular.module('gestioneAttiApp').controller('backToTopController', ['$scope', '$window', function($scope, $window){

}]).directive('backToTop', function () {
    return {
        restrict: 'E',
        scope : {
        },
        link: function ($scope, element, attrs) {
            $(window).ready(function() {
                $(element).fadeOut();
            });

            $(window).scroll(function () {

                if ($(window).scrollTop() <= 0) {
                    $(element).fadeOut();
                }
                else {
                    $(element).fadeIn();
                }

            });

            $(element).on('click', function () {
                $('html, body').animate({scrollTop: 0}, 'medium');
            });

        },
        template: '<md-button class="md-fab md-primary back-to-top" aria-label="BackToTop"><i class="glyphicon glyphicon-chevron-up" style="color: white" aria-hidden="true"></i> </md-button>'
    };
});
