angular.module('gestioneAttiApp').directive('courtroomBlock', function () {
    return {
        restrict: "E",
        templateUrl: 'app/components/courtroomBlock/courtroomBlock.directive.html',
        scope: {
            actType: '=',
            changeDocumentTypeCallback: '&?',
            changeDocumentVisibilityCallback: '&?',
            constitutionalAppeal: '=',
            courtEmendments: '=',
            courtMeeting: '=',
            courtOutcome: '=',
            currentUser: '=',
            docTypes: '=',
            invalidFields: '=',
            isAdmin: '=',
            index: '=',
            isProtocollo: '=',
            iterationIndex: '=',
            noticeOfRectification: '=',
            peopleList: '=',
            postCourt: '=',
            sendBackDate: '=?',
            showSectionCallback: '&',
            validating: '=',
            statoAttoCorrente: '=',
            numeroRinvii: '=',
            isAttoMinore: '=',
            actuationNotes: '=',
            assignmentLetter: '=',
            commissioni: '=',
            isSectorUserEnabled:'=',
            attachedActs: '=',
            atto: '=',
            isCourtroom: '=',
            workDocumentsMap: '=?',
            emanazione: "=?",
            viewMode: '=',
            procedimento: '=',
            showCourtroomBlock: '=',
            adempimenti: '=',
            materieSection: '=',
            courtAssignmentLetter: '='
        },
        transclude: false,
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$rootScope', 'PdlService','toastr', 'ACT_TYPES', 'Atto', 'Principal','FileSaver', 'Materia', 'MacroMateria' , function ($rootScope, PdlService,toastr, ACT_TYPES, Atto, Principal, FileSaver, Materia, MacroMateria) {
            var vm = this;
            vm.materiaList = [];
            vm.macroMateriaList = [];

            // Se avevo il numero BURT precedentemente popolato e non esiste l'array burtDetails
            // Crea l'array burtDetails con 1 elemento con valori già esistenti
            if (vm.noticeOfRectification
                && vm.noticeOfRectification.metadati
                && vm.noticeOfRectification.metadati.numeroBurt
                && !vm.noticeOfRectification.metadati.burtDetails)
            {
                vm.noticeOfRectification.metadati.burtDetails = [
                    {
                        numeroBurt: vm.noticeOfRectification.metadati.numeroBurt,
                        parteBurt: vm.noticeOfRectification.metadati.parteBurt,
                        dataBurt: vm.noticeOfRectification.metadati.dataBurt
                    }
                ];

                delete vm.noticeOfRectification.metadati.numeroBurt;
                delete vm.noticeOfRectification.metadati.parteBurt;
                delete vm.noticeOfRectification.metadati.dataBurt;
            }

            // Se nella sezione noticeOfRectification non ha popolato l'array burtDetails -> Crea un array con elemento vuoto
            if(vm.noticeOfRectification && vm.noticeOfRectification.metadati && 
                (vm.noticeOfRectification.metadati.burtDetails === undefined || vm.noticeOfRectification.metadati.burtDetails === null)) {
                vm.noticeOfRectification.metadati.burtDetails = [
                    {
                        numeroBurt: "",
                        parteBurt: "",
                        dataBurt: ""
                    }
                ];
            }

            vm.checkShowSection = function (section) {
                return vm.showSectionCallback({section: section});
            };

            if (vm.materieSection && vm.checkShowSection(vm.materieSection) && vm.materieSection.editable) {
                vm.loadedMacroMaterie = false;
                vm.loadedMaterie = false;
                loadMacroMaterie();
                loadMaterie();
            }
            if(vm.procedimento && (!vm.procedimento.metadati || !vm.procedimento.metadati.procedimentoExArticolo)) {
                vm.procedimento.metadati.procedimentoExArticolo = false;
            }
            vm.isParereReferenteValorised =  !!vm.assignmentLetter && !!vm.assignmentLetter.metadati && !!vm.assignmentLetter.metadati.parereReferente && vm.assignmentLetter.metadati.parereReferente.length > 0;
            vm.isStatoAula = vm.atto.statoCorrente.statoAtto.name === 'AULA';
            vm.isAula = Principal.hasAnyAuthority(['ROLE_AULA']);
            if(vm.assignmentLetter){
                vm.isCommissionAlreadySelected = vm.assignmentLetter.metadati.parereReferente ? true : false;
                if(vm.isAula && vm.actType === 'MOZIONE' && vm.statoAttoCorrente === 'AULA'){
                    vm.assignmentLetter.editable = true;
                    vm.assignmentLetter.visible = true;
                }
            }

                vm.clearRadio = PdlService.clearRadio;
                vm.numeroRinvii = vm.numeroRinvii || 0;

                vm.sezioni = {
                    COURT_EMENDMENTS: {
                        form: 'app/components/courtroomBlock/courtroomEmendments.html'
                    },
                    COURT_MEETING: {
                        form: 'app/components/courtroomBlock/courtroomMeeting.html'
                    },
                    COURT_OUTCOME: {
                        form: (vm.actType === 'IO' || vm.actType === 'IS')
                                ? 'app/components/courtroomBlock/io.courtroomOutcome.html'
                                : 'app/components/courtroomBlock/courtroomOutcome.html'
                    },
                    POST_COURT: {
                        form: getPostCourt()
                    },
                    EMANAZIONE:{
                        form: 'app/components/courtroomBlock/pea.emanazione.html'
                    },
                    NOTICE_OF_RECTIFICATION: {
                        form: 'app/components/courtroomBlock/noticeOfRectification.html'
                    },
                    CONSTITUTIONAL_APPEAL: {
                        form: 'app/components/courtroomBlock/constitutionalAppeal.html'
                    },
                    ACTUATION_NOTES: {
                        form: 'app/components/courtroomBlock/actuationNotes.html'
                    },
                    ASSIGNMENT_LETTER: {
                        form: 'app/entities/atto/sezioni/onlyReferente.assignmentLetter.html'
                    },
                    ATTACHED_ACTS: {
                        form: 'app/entities/atto/sezioni/attachedActs.html'
                    },
                    PROCEDIMENTO: {
                        form: 'app/components/courtroomBlock/procedimento.html'
                    },
                    ADEMPIMENTI: {
                        form: 'app/components/courtroomBlock/adempimenti.html'
                    },
                    MATERIE: {
                        form: 'app/components/courtroomBlock/materie-section.html'
                    },
                    COURT_ASSIGNMENT_LETTER: {
                        form: 'app/components/courtroomBlock/pdls.courtAssignmentLetter.html',
                    },
                };
                vm.getSezioniFromMap = PdlService.getSezioniFromMap;

                vm.stato = {
                    aula: {
                        isCollapsed: (vm.statoAttoCorrente === 'AULA' && vm.numeroRinvii === vm.index) || vm.viewMode
                    },
                    approvato: {
                        isCollapsed: (vm.statoAttoCorrente === 'APPROVATO' && vm.numeroRinvii === vm.index) || vm.viewMode
                    }
                };

                vm.defaultLinks = getDefaultLinks();

                function getPostCourt(){
                    if (vm.actType === 'PDD' || vm.actType === 'RINT'){
                        return 'app/components/courtroomBlock/pdd.postCourtroom.html';
                    }
                    else if (vm.actType === 'PEA'){
                        return 'app/components/courtroomBlock/pea.postCourtroom.html';
                    }
                    else if (vm.actType === 'PDLP'){
                        return 'app/components/courtroomBlock/pdlp.postCourtroom.html';
                    }
                    else return 'app/components/courtroomBlock/postCourtroom.html';
                }

                function getDefaultLinks() {
                    if (vm.actType === 'ODG' || vm.actType === 'PDR' || vm.actType === 'MOZIONE') {
                        return ['burt', 'bancaDatiDelConsiglio'];
                    } else {
                        return (vm.actType !== 'PDLP') ? ['burt', 'regulatoryCollection'] : ['deliberaDefinitiva'];
                    }

                }
                vm.invalidFields = vm.invalidFields || [];
                vm.utente = vm.currentUser;

                function loadMacroMaterie() {
                    MacroMateria.query({onlyValid: true},
                        function(result) {
                            vm.macroMateriaList = result;
                            vm.loadedMacroMaterie = true;
                        },
                        function(error) {console.log('Error while loading materie')});
                }
                function loadMaterie() {
                    Materia.query({onlyValid: true},
                        function(result){
                            vm.materiaList = result;
                            vm.loadedMaterie = true;
                        },
                        function(error) {console.log('Error while loading materie')});
                }
                if (vm.postCourt) {
                    vm.postCourt.metadati = vm.postCourt.metadati || {collegioGaranzia: false};

                    if (!vm.postCourt.metadati.collegioGaranzia) {
                        vm.postCourt.metadati.collegioGaranzia = false;
                    }
                }

                if (vm.courtOutcome) {
                    vm.courtOutcome.metadati.clausolaValutativa = vm.courtOutcome.metadati.clausolaValutativa || false;
                }

                vm.fieldSuffix = '';
                if (vm.iterationIndex && vm.iterationIndex > 0) {
                    vm.fieldSuffix = '_' + vm.iterationIndex;
                }


                vm.changeDocumentType = function (document, newDocumentType) {
                    vm.changeDocumentTypeCallback({document: document, newDocumentType: newDocumentType});
                };

                vm.changeDocumentVisibility = function (document, newDocumentVisibility) {
                    vm.changeDocumentVisibilityCallback({document: document, newDocumentVisibility: newDocumentVisibility});
                };

                vm.isInvalidField = function (fieldName) {
                    return vm.invalidFields.indexOf(fieldName + vm.fieldSuffix) > -1;
                };

                vm.exists = function (item, list) {
                    if (!list || !angular.isArray(list)) {
                        list = [];
                    }
                    return list.indexOf(item) > -1;
                };

                vm.toggle = function (item, metadata, fieldName) {
                    var list = metadata[fieldName];
                    if (!list || !angular.isArray(list)) {
                        metadata[fieldName] = [];
                        list = metadata[fieldName];
                    }

                    var idx = list.indexOf(item);
                    if (idx > -1) {
                        list.splice(idx, 1);
                    } else {
                        list.push(item);
                    }
                };

                vm.showManifestaInfondatezza = function () {
                    return vm.postCourt.metadati.collegioGaranzia === true;
                }
                vm.showConformita = function () {
                    return vm.showManifestaInfondatezza() &&
                    vm.postCourt.metadati.manifestaInfondatezza === false;
                }
                vm.showEntrataVigoreAnticipata = function() {
                    return vm.actType !== 'PDLS' &&
                    vm.actType !== 'PLIP' &&
                    vm.nonContestata();
                }

                vm.nonContestata = function () {
                    return vm.postCourt.metadati.collegioGaranzia === false
                            || (vm.showConformita() && vm.postCourt.metadati.conformita === true);
                };

                vm.checkConformita = function() {
                    if (!vm.showConformita()) {
                        vm.postCourt.metadati.conformita = null;
                    }
                    vm.broadcastConformitaEvent();
                }

                vm.broadcastConformitaEvent = function () {
                    $rootScope.$broadcast('conformitaChange');
                };

                vm.downloadReportEmendamenti = function() {
                    Atto.getEmendamentiPDFReport(vm.courtEmendments.id)
                    .then(
                            function(result) {
                                FileSaver.saveAs(new Blob([result.data], {}), "ReportEmendamentiAula.pdf");
                            },
                            function(error) {
                                var errorCode = error.headers('X-gestioneAttiApp-error');
                                var errorText = '';
                                if(errorCode === 'error.noContent') {
                                    errorText = 'Questa sezione non ha nessun emendamento presentato, quindi non e\' stato possibile generare nessun report!';
                                } else if(errorCode === 'error.forbiddenOp') {
                                    errorText = 'La tua utenza non e\' abilitata alla generazione di report. Si prega di contattare l\'amministratore!'
                                } else {
                                    errorText = 'Errore inaspettato. Si prega di riprovare o contattare l\'amministratore!'
                                }
                                toastr.error(errorText, "Errore!", {
                                    timeOut: 60000,
                                    extendedTimeOut: 0
                                });
                            });;
                }
            }]
    };
});
