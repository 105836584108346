(function () {

    'use strict';

    angular.module('gestioneAttiApp').service('BasicValidator', ['PdlService', '$location', '$anchorScroll',
        function (PdlService, $location, $anchorScroll) {

            this.PdlService = PdlService;
            this.$location = $location;
            this.$anchorScroll = $anchorScroll;

            // Presentato
            this.validatePresentation = validatePresentation;
            this.validateAssignmentLetter = validateAssignmentLetter;

            // Commissione
            this.validateLegitimacy = validateLegitimacy;
            this.validateFeasibility = validateFeasibility;
            this.validateCommissionEmendments = validateCommissionEmendments;
            this.validateCommissionSections = validateCommissionSections;
            this.validateCommissionMessage = validateCommissionMessage;
            this.validateCommissionOpinions = validateCommissionOpinions;
            this.validateCommissionOpinion = validateCommissionOpinion;
            this.validateFinancialCoverage = validateFinancialCoverage;
            this.validateCourtroomSections = validateCourtroomSections;

            // Aula
            this.validateCourtroomEmendments = validateCourtroomEmendments;
            this.validateCourtroomMeeting = validateCourtroomMeeting;
            this.validateSectorSections = validateSectorSections;
            this.validateCourtroomOutcome = validateCourtroomOutcome;

            // Stato Approvato
            this.validatePostCourtroomSections = validatePostCourtroomSections;
            this.validatePostCourtroom = validatePostCourtroom;

            this.validate = function (state, numeroRinvio, utente, userRolesMap, idPrimaCommissione, action, primaryCommission) {
                var invalidFields = [];

                if (userRolesMap.isAdmin) {
                    var result = validateSectorSections(invalidFields, idPrimaCommissione);
                    if (result.valid) {
                        if (!PdlService.sezioniMap.ASSIGNMENT_LETTER.metadati.aulaDiretta) {
                            result = validateCommissionSections(invalidFields, utente, userRolesMap, numeroRinvio,
                                    idPrimaCommissione, action && action.name === 'assignToCourtRoom', primaryCommission);
                        }
                        if (result.valid) {
                            result = validateCourtroomSections(invalidFields, numeroRinvio);
                        }
                    }
                    return result;
                } else {
                    switch (state) {
                        case "PRESENTATO":
                            return validateSectorSections(invalidFields, idPrimaCommissione);
                        case "COMMISSIONE":
                            return validateCommissionSections(invalidFields, utente, userRolesMap, numeroRinvio,
                                    idPrimaCommissione, action && action.name === 'assignToCourtRoom', primaryCommission);
                        case "AULA":
                            return validateCourtroomSections(invalidFields, numeroRinvio);
                        case "APPROVATO":
                            return validatePostCourtroomSections(invalidFields, numeroRinvio);
                        default:
                            console.error("STATO SCONOSCIUTO: " + state);
                            return {valid: false, message: "STATO SCONOSCIUTO: " + state};
                    }
                }
            };

            function validateAssignmentLetter(invalidFields, idPrimaCommissione) {
                var metadata = PdlService.sezioniMap.ASSIGNMENT_LETTER.metadati;

                var message = '<ul>';

                if (typeof (metadata.aulaDiretta) === 'undefined' || metadata.aulaDiretta === null) {
                    message += '<li><i>Direttamente in Aula</i> è un campo obbligatorio</li>';
                    invalidFields.push('aulaDiretta');
                } else if (metadata.aulaDiretta) {
/*                     if (!metadata.dataSedutaUp) {
                        message += '<li><i>Data Seduta UP</i> è un campo obbligatorio</li>';
                        invalidFields.push('dataSedutaUp');
                    } */
                } else {
                    if (!metadata.dataAssegnazione) {
                        message += '<li><i>Data assegnazione</i> è un campo obbligatorio</li>';
                        invalidFields.push('dataAssegnazione');
                    }
                    if (typeof (metadata.riduzioneTermineParere) === 'undefined' || metadata.riduzioneTermineParere === null) {
                        message += '<li><i>Riduzione termine parere</i> è un campo obbligatorio</li>';
                        invalidFields.push('riduzioneTermineParere');
                    }
                    if (typeof (metadata.parereIstituzionale) === 'undefined' || metadata.parereIstituzionale === null) {
                        message += '<li><i>Parere Istituzionale</i> è un campo obbligatorio</li>';
                        invalidFields.push('parereIstituzionale');
                    }
                    if (!metadata.parereReferente || !metadata.parereReferente.length) {
                        message += '<li><i>Parere Referente</i> è un campo obbligatorio</li>';
                        invalidFields.push('parereReferente');
                    } else if (metadata.parereReferente.indexOf(idPrimaCommissione) > -1 && metadata.parereIstituzionale) {
                        message += '<li><i>Parere Istituzionale</i> non può essere impostato a Sì se la Prima Commissione è stata selezionata in Parere Referente</li>';
                        invalidFields.push('parereIstituzionale');
                    }

                    if (metadata.parereSecondario && metadata.parereSecondario.length) {
                        if (metadata.parereSecondario.indexOf(idPrimaCommissione) > -1 && metadata.parereIstituzionale) {
                            message += '<li><i>Parere Istituzionale</i> non può essere impostato a Sì se la Prima Commissione è stata selezionata in Parere Secondario</li>';
                            invalidFields.push('parereIstituzionale');
                        }
                        for (var i = 0; i < metadata.parereSecondario.length; i++) {
                            if (metadata.parereReferente && metadata.parereReferente.indexOf(metadata.parereSecondario[i]) > -1) {
                                message += '<li><i>Parere Secondario</i> non può contenere valori già specificati in Parere Referente</li>';
                                invalidFields.push('parereSecondario');
                                break;
                            }
                        }
                    }

                    if (metadata.parereObbligatorio && metadata.parereObbligatorio.length && metadata.osservazioni && metadata.osservazioni.length) {
                        for (var i = 0; i < metadata.osservazioni.length; i++) {
                            if (metadata.parereObbligatorio.indexOf(metadata.osservazioni[i]) > -1) {
                                message += '<li><i>Osservazioni</i> non può contenere valori già specificati in Parere Obbligatorio</li>';
                                invalidFields.push('osservazioni');
                                break;
                            }
                        }
                    }

                }

                message += '</ul>';

                var valid = invalidFields.length === 0;
                if (!valid) {
                    $location.hash('assignmentLetter');
                    $anchorScroll();
                    PdlService.sezioniMap.ASSIGNMENT_LETTER.visible = true;
                }

                return {valid: valid, message: message, invalidFields: invalidFields};
            }

            function validateSectorSections(invalidFields, idPrimaCommissione) {
                var result = validateAssignmentLetter(invalidFields, idPrimaCommissione);
                return result;
            }

            function validateCommissionSections(invalidFields, utente, userRolesMap, numeroRinvio, idPrimaCommissione, action, primaryCommission) {
                var result = null;
                for (var i = 0; i <= numeroRinvio; i++) {
                    result = validateLegitimacy(invalidFields, userRolesMap, i);
                    if (result.valid) {
                        result = validateFeasibility(invalidFields, userRolesMap, i);
                        if (result.valid) {
                            result = validateCommissionEmendments(invalidFields, i, primaryCommission);//se sono referente
                            if (result.valid) {
                                //result = validateCommissionOpinions(invalidFields, utente, userRolesMap, i, idPrimaCommissione); //Commission Opinions validation disabled as requested by mail from @Caldini in 18/06/19
                                if (result.valid) {
                                    result = validateFinancialCoverage(invalidFields, i, primaryCommission);//se sono referente
                                    if (result.valid) {
                                        result = validateCommissionMessage(invalidFields, i, primaryCommission);//se sono referente
                                    } else {
                                        break;
                                    }
                                } else {
                                    break;
                                }
                            } else {
                                break;
                            }
                        } else {
                            break;
                        }
                    } else {
                        break;
                    }
                }
                return result;
            }

            function validateCourtroomSections(invalidFields, numeroRinvio) {
                var result;

                for (var i = 0; i <= numeroRinvio; i++) {
                    result = validateCourtroomEmendments(invalidFields, i);
                    if (result.valid) {
                        result = validateCourtroomMeeting(invalidFields, i);
                        if (result.valid) {
                            result = validateCourtroomOutcome(invalidFields, i);
                        } else {
                            break;
                        }
                    } else {
                        break;
                    }
                }
                return result;
            }

            function validatePostCourtroomSections(invalidFields, numeroRinvio) {
                var result = {valid: true, message: '', invalidFields: []};

                for (var i = 0; i <= numeroRinvio; i++) {
                    // result = validatePostCourtroom(invalidFields, i);
                    if (!result.valid) {
                        break;
                    }
                }

                return result;
            }

            function validateLegitimacy(invalidFields, userRolesMap, idx) {
                var sezione = PdlService.getSezioniFromMap('LEGITIMACY', idx);
                if (!sezione) {
                    return {valid: true, message: message, invalidFields: invalidFields};
                }

                var suffix = "";
                if (idx > 0) {
                    suffix += "_" + idx;
                }

                var metadata = sezione.metadati;

                var message = '<ul>';

                if (userRolesMap.isAdmin || userRolesMap.isLegitimacy) {
                    /*if (metadata.legittimita === null || typeof (metadata.legittimita) === 'undefined') {
                        message += '<li><i>Scheda di legittimità</i> è un campo obbligatorio</li>';
                        invalidFields.push('legittimita' + suffix);
                    } else */if (metadata.legittimita && (metadata.rilieviLegittimita === null || typeof (metadata.rilieviLegittimita) === 'undefined')) {
                        message += '<li><i>Rilievi</i> per scheda di leggittimità è un campo obbligatorio</li>';
                        invalidFields.push('rilieviLegittimita' + suffix);
                    }
                }

                message += '</ul>';

                var valid = invalidFields.length === 0;
                if (!valid) {
                    $location.hash('legitimacy' + suffix);
                    $anchorScroll();
                    sezione.visible = true;
                }

                return {valid: valid, message: message, invalidFields: invalidFields};
            }

            function validateFeasibility(invalidFields, userRolesMap, idx) {
                var sezione = PdlService.getSezioniFromMap('FEASIBILITY', idx);
                if (!sezione) {
                    return {valid: true, message: message, invalidFields: invalidFields};
                }

                var suffix = "";
                if (idx > 0) {
                    suffix += "_" + idx;
                }

                var metadata = sezione.metadati;

                var message = '<ul>';

                if (userRolesMap.isAdmin || userRolesMap.isFeasibility) {
                    /*if (typeof (metadata.fattibilita) === 'undefined' || metadata.fattibilita === null) {
                        message += '<li><i>Scheda di fattibilità</i> è un campo obbligatorio</li>';
                        invalidFields.push('fattibilita' + suffix);
                    } else */if (metadata.fattibilita && (typeof (metadata.rilieviFattibilita) === 'undefined' || metadata.rilieviFattibilita === null)) {
                        message += '<li><i>Rilievi</i> per scheda di fattibilità è un campo obbligatorio</li>';
                        invalidFields.push('rilieviFattibilita' + suffix);
                    }
                }

                message += '</ul>';

                var valid = invalidFields.length === 0;
                if (!valid) {
                    $location.hash('feasibility' + suffix);
                    $anchorScroll();
                    sezione.visible = true;
                }

                return {valid: valid, message: message, invalidFields: invalidFields};
            }

            function validateCommissionMessage(invalidFields, idx, primaryCommission) {
                var sezione = PdlService.getSezioniFromMap('COMMISSION_MESSAGE', idx);
                if (!sezione || !primaryCommission) {
                    return {valid: true, message: message, invalidFields: invalidFields};
                }

                var suffix = "";
                if (idx > 0) {
                    suffix += "_" + idx;
                }

                var metadata = sezione.metadati;

                var message = '<ul>';

                if (typeof (metadata.modificaOggettoTitolo) === 'undefined' || metadata.modificaOggettoTitolo === null) {
                    message += '<li><i>Modifica a titolo della Commissione</i> è un campo obbligatorio</li>';
                    invalidFields.push('modificaOggettoTitolo' + suffix);
                } else if (metadata.modificaOggettoTitolo && (!metadata.nuovoOggettoTitolo || metadata.nuovoOggettoTitolo.trim().length === 0)) {
                    message += '<li><i>Nuovo titolo</i> è un campo obbligatorio</li>';
                    invalidFields.push('nuovoOggettoTitolo' + suffix);
                }

                if (typeof (metadata.modificaTesto) === 'undefined' || metadata.modificaTesto === null) {
                    message += '<li><i>Modifiche al testo</i> è un campo obbligatorio</li>';
                    invalidFields.push('modificaTesto' + suffix);
                } else if (metadata.modificaTesto && !metadata.tipoModifica) {
                    message += '<li><i>Tipo di modifica</i> è un campo obbligatorio</li>';
                    invalidFields.push('tipoModifica' + suffix);
                }

                if (typeof (metadata.propostaRisoluzione) === 'undefined' || metadata.propostaRisoluzione === null) {
                    message += '<li><i>Proposta di risoluzione collegata con riferimento alla commissione</i> è un campo obbligatorio</li>';
                    invalidFields.push('propostaRisoluzione' + suffix);
                }

                message += '</ul>';

                var valid = invalidFields.length === 0;
                if (!valid) {
                    $location.hash('commissionMessage' + suffix);
                    $anchorScroll();
                    sezione.visible = true;
                }

                return {valid: valid, message: message, invalidFields: invalidFields};
            }

            function validateFinancialCoverage(invalidFields, idx, primaryCommission) {
                var sezione = PdlService.getSezioniFromMap('FINANCIAL_COVERAGE', idx);
                if (!sezione || !primaryCommission) {
                    return {valid: true, message: message, invalidFields: invalidFields};
                }

                var suffix = "";
                if (idx > 0) {
                    suffix += "_" + idx;
                }

                var metadata = sezione.metadati;

                var message = '<ul>';

                if (typeof (metadata.coperturaRichiesta) === 'undefined' || metadata.coperturaRichiesta === null) {
                    message += '<li><i>Attestazione Copertura Finanziaria - Richiesta</i> è un campo obbligatorio</li>';
                    invalidFields.push('coperturaRichiesta' + suffix);
                } else if (metadata.coperturaRichiesta && (typeof (metadata.coperturaAcquisita) === 'undefined' || metadata.coperturaAcquisita === null)) {
                    message += '<li><i>Attestazione Copertura Finanziaria - Acquisita</i> è un campo obbligatorio</li>';
                    invalidFields.push('coperturaAcquisita' + suffix);
                }

                message += '</ul>';

                var valid = invalidFields.length === 0;
                if (!valid) {
                    $location.hash('financialCoverage' + suffix);
                    $anchorScroll();
                    sezione.visible = true;
                }

                return {valid: valid, message: message, invalidFields: invalidFields};
            }

            function validateCommissionEmendments(invalidFields, idx, primaryCommission) {
                var sezione = PdlService.getSezioniFromMap('COMMISSION_EMENDMENTS', idx);

                if (!sezione || !primaryCommission) {
                    return {valid: true, message: message, invalidFields: invalidFields};
                }

                var suffix = "";
                if (idx > 0) {
                    suffix += "_" + idx;
                }

                var metadata = sezione.metadati;

                var message = '<ul>';

                if (metadata.emendamentiPresentatiCommissione) {
                    var i = 0;

                    angular.forEach(metadata.emendamentiPresentati, function (emendment) {
                        if (i > 0
                                //&& (!emendment.numeroEmendamento || emendment.numeroEmendamento.trim().length === 0) // Validazione eliminata poiche' non e' un metadato piu' trattato: @Caldini richieste data 06/19
                                && !emendment.dataProtocollo
                                // && (!emendment.protocollo || emendment.protocollo.trim().length === 0) // Validazione su protocollo rimossa. @See MAIL 16/07/2018 09.09 FROM Caldini Marco.
                                && (!emendment.firmatari || emendment.firmatari.length === 0)) {
                            //rimuovo eventuali emendamenti lasciati vuoti se sono in più al primo (che deve essere compilato)
                            metadata.emendamentiPresentati.splice(metadata.emendamentiPresentati.indexOf(emendment), 1);
                        } else {
                           /*if (!emendment.numeroEmendamento || emendment.numeroEmendamento.trim().length === 0) {
                                invalidFields.push('numeroEmendamento' + suffix + '_' + i); // Validazione eliminata poiche' non e' un metadato piu' trattato: @Caldini richieste data 06/19
                            } */
                            if (!emendment.dataProtocollo) {
                                invalidFields.push('dataProtocollo' + suffix + '_' + i);
                            }
                            /* Validazione su protocollo rimossa. @See MAIL 16/07/2018 09.09 FROM Caldini Marco. */
                            /*if (!emendment.protocollo || emendment.protocollo.trim().length === 0) {
                             invalidFields.push('protocollo' + suffix + '_' + i);
                             }*/
                            if (!emendment.firmatari || emendment.firmatari.length === 0) {
                                invalidFields.push('firmatari' + suffix + '_' + i);
                            }
                        }
                        i++;
                    });
                }

                message += '</ul>';

                var valid = invalidFields.length === 0;
                if (!valid) {
                    message += '<li>Uno o più <i>Emendamenti presentati in commissione</i> non sono stati compilati correttamente</li>';
                    $location.hash('commissionEmendments');
                    $anchorScroll();
                    sezione.visible = true;
                }

                return {valid: valid, message: message, invalidFields: invalidFields};
            }

            function validateCommissionOpinions(invalidFields, utente, userRolesMap, idx, idPrimaCommissione) {
                var sezione = PdlService.getSezioniFromMap('COMMISSION_OPINIONS', idx);
                if (!sezione) {
                    return {valid: true, message: message, invalidFields: invalidFields};
                }

                var suffix = "";
                if (idx > 0) {
                    suffix += "_" + idx;
                }

                var assignmentLetterMetadata = PdlService.sezioniMap.ASSIGNMENT_LETTER.metadati;

                var metadatiSezione = sezione.metadati;
                var message = '<ul>';

                if (assignmentLetterMetadata.parereReferente && assignmentLetterMetadata.parereReferente.length
                        && PdlService.isCommissionOpinionEditable(utente, assignmentLetterMetadata.parereReferente, idx, userRolesMap.isAdmin)) {
                    message = validateCommissionOpinion(invalidFields, assignmentLetterMetadata.parereReferente, metadatiSezione.parereReferente, true, message, suffix);
                }

                if (assignmentLetterMetadata.parereSecondario) {
                    for (var i = 0; i < assignmentLetterMetadata.parereSecondario.length; i++) {
                        var opinion = assignmentLetterMetadata.parereSecondario[i];
                        if (PdlService.isCommissionOpinionEditable(utente, [opinion], idx, userRolesMap.isAdmin)) {
                            message = validateCommissionOpinion(invalidFields, [opinion], metadatiSezione.parereSecondario, false, message, suffix);
                        }
                    }
                }

                if (assignmentLetterMetadata.parereObbligatorio) {
                    for (var i = 0; i < assignmentLetterMetadata.parereObbligatorio.length; i++) {
                        var opinion = assignmentLetterMetadata.parereObbligatorio[i];
                        if (PdlService.isExternalSubjectOpionionEditable(utente, [opinion], idx, userRolesMap.isAdmin)) {
                            message = validateCommissionOpinion(invalidFields, [opinion], metadatiSezione.parereObbligatorio, false, message, suffix);
                        }
                    }
                }

                if (assignmentLetterMetadata.osservazioni) {
                    for (var i = 0; i < assignmentLetterMetadata.osservazioni.length; i++) {
                        var opinion = assignmentLetterMetadata.osservazioni[i];
                        if (PdlService.isExternalSubjectOpionionEditable(utente, [opinion], idx, userRolesMap.isAdmin)) {
                            message = validateCommissionOpinion(invalidFields, [opinion], metadatiSezione.osservazioni, false, message, suffix);
                        }
                    }
                }

                if (assignmentLetterMetadata.parereIstituzionale && PdlService.isCommissionOpinionEditable(utente, [idPrimaCommissione], idx, userRolesMap.isAdmin)) {
                    message = validateCommissionOpinion(invalidFields, [idPrimaCommissione], metadatiSezione.parereIstituzionale, false, message, suffix);
                }

                message += '</ul>';

                var valid = invalidFields.length === 0;
                if (!valid) {
                    $location.hash('commissionOpinions' + suffix);
                    $anchorScroll();
                    sezione.visible = true;
                }

                return {valid: valid, message: message, invalidFields: invalidFields};
            }

            function validateCommissionOpinion(invalidFields, opinionAssignment, opinionMetadata, isJoinedOpinion, message, fieldSuffix) {
                var messageItem = '<li>Ci sono <i>Pareri/Osservazioni</i> non completamente compilati</i>';
                if (!opinionMetadata) {
                    if (isJoinedOpinion) {
                        var subjectCompositeId = '';
                        if (opinionAssignment.length === 1) {
                            subjectCompositeId = opinionAssignment[0];
                        } else {
                            angular.forEach(opinionAssignment, function (subjectId) {
                                subjectCompositeId += '_' + subjectId;
                                message += messageItem;
                                messageItem = '';
                            });
                        }
                        invalidFields.push('data' + fieldSuffix + subjectCompositeId);
                        invalidFields.push('parere' + fieldSuffix + subjectCompositeId);
                        invalidFields.push('tipoFavorevolezza' + fieldSuffix + subjectCompositeId);
                    } else {
                        angular.forEach(opinionAssignment, function (subjectId) {
                            invalidFields.push('data' + fieldSuffix + subjectId);
                            invalidFields.push('parere' + fieldSuffix + subjectId);
                            invalidFields.push('tipoFavorevolezza' + fieldSuffix + subjectId);
                            message += messageItem;
                            messageItem = '';
                        });
                    }
                } else {
                    if (isJoinedOpinion) {
                        var subjectCompositeId = '';
                        if (opinionAssignment.length === 1) {
                            subjectCompositeId = opinionAssignment[0];
                        } else {
                            angular.forEach(opinionAssignment, function (subjectId) {
                                subjectCompositeId += '_' + subjectId;
                                message += messageItem;
                                messageItem = '';
                            });
                        }
                        if (!opinionMetadata.data || !opinionMetadata.data[subjectCompositeId]) {
                            invalidFields.push('data' + fieldSuffix + '_' + subjectCompositeId);
                            message += messageItem;
                            messageItem = '';
                        }
                        if (!opinionMetadata.parere || !opinionMetadata.parere[subjectCompositeId]) {
                            invalidFields.push('parere' + fieldSuffix + '_' + subjectCompositeId);
                            message += messageItem;
                            messageItem = '';
                        }
                        if (!opinionMetadata.tipoFavorevolezza || !opinionMetadata.tipoFavorevolezza[subjectCompositeId]) {
                            invalidFields.push('tipoFavorevolezza' + fieldSuffix + '_' + subjectCompositeId);
                            message += messageItem;
                            messageItem = '';
                        }
                    } else {
                        angular.forEach(opinionAssignment, function (subjectId) {
                            if (!opinionMetadata.data || !opinionMetadata.data[subjectId]) {
                                invalidFields.push('data' + fieldSuffix + '_' + subjectId);
                                message += messageItem;
                                messageItem = '';
                            }
                            if (!opinionMetadata.parere || !opinionMetadata.parere[subjectId]) {
                                invalidFields.push('parere' + fieldSuffix + '_' + subjectId);
                                message += messageItem;
                                messageItem = '';
                            }
                            if (!opinionMetadata.tipoFavorevolezza || !opinionMetadata.tipoFavorevolezza[subjectId]) {
                                invalidFields.push('tipoFavorevolezza' + fieldSuffix + '_' + subjectId);
                                message += messageItem;
                                messageItem = '';
                            }
                        });
                    }
                }
                return message;
            }

            function validateCourtroomEmendments(invalidFields, idx) {
                var sezione = PdlService.getSezioniFromMap('COURT_EMENDMENTS', idx);
                if (!sezione) {
                    return {valid: true, message: message, invalidFields: invalidFields};
                }
                var suffix = "";
                if (idx > 0) {
                    suffix += "_" + idx;
                }

                var metadata = sezione.metadati;
                var message = '<ul>';
                var i = 0;
                angular.forEach(metadata.emendamenti, function (emendment) {
                    if ( //(!emendment.numeroEmendamento || emendment.numeroEmendamento.trim().length === 0)
                            !emendment.dataProtocollo
                            //&& (!emendment.protocollo || emendment.protocollo.trim().length === 0)
                            && (!emendment.firmatari || emendment.firmatari.length === 0)
                            /* && !emendment.state */) {
                        //rimuovo eventuali emendamenti lasciati vuoti se sono in più al primo (che deve essere compilato)
                        if (i > 0) {
                            metadata.emendamenti.splice(metadata.emendamenti.indexOf(emendment), 1);
                        }
                    } else {
/*                         if (!emendment.numeroEmendamento || emendment.numeroEmendamento.trim().length === 0) {
                            invalidFields.push('numeroEmendamento' + suffix + '_' + i);
                        } */
                        if (!emendment.dataProtocollo) {
                            invalidFields.push('dataProtocollo' + suffix + '_' + i);
                        }
/*                         if (!emendment.protocollo || emendment.protocollo.trim().length === 0) {
                            invalidFields.push('protocollo' + suffix + '_' + i);
                        } */
                        if (!emendment.firmatari || emendment.firmatari.length === 0) {
                            invalidFields.push('firmatari' + suffix + '_' + i);
                        }
/*                         if (!emendment.state) {
                            invalidFields.push('state' + suffix + '_' + i);
                        } */
                    }
                    i++;
                });
                message += '</ul>';
                var valid = invalidFields.length === 0;
                if (!valid) {
                    message += '<li>Uno o più <i>Emendamenti presentati in aula</i> non sono stati compilati correttamente</li>';
                    $location.hash('courtroomEmendments' + suffix);
                    $anchorScroll();
                    sezione.visible = true;
                }
                return {valid: valid, message: message, invalidFields: invalidFields};
            }

            function validatePostCourtroom(invalidFields, idx) {
                var sezione = PdlService.getSezioniFromMap('POST_COURT', idx);
                if (!sezione) {
                    return {valid: true, message: message, invalidFields: invalidFields};
                }
                var suffix = "";
                if (idx > 0) {
                    suffix += "_" + idx;
                }

                var metadata = sezione.metadati;
                var message = '<ul>';
                if (!metadata.numeroLeggeAC || metadata.numeroLeggeAC.trim().length === 0) {
                    message += '<li><i>Numero legge AC</i> è un campo obbligatorio</li>';
                    invalidFields.push('numeroLeggeAC' + suffix);
                }
                if (!metadata.dataLeggeAC) {
                    message += '<li><i>Data legge AC</i> è un campo obbligatorio</li>';
                    invalidFields.push('dataLeggeAC' + suffix);
                }
                message += '</ul>';
                var valid = invalidFields.length === 0;
                if (!valid) {
                    $location.hash('postCourtroom' + suffix);
                    $anchorScroll();
                    sezione.visible = true;
                }
                return {valid: valid, message: message, invalidFields: invalidFields};
            }

            function validatePresentation(invalidFields, atto) {
                if (!invalidFields)
                    invalidFields = [];

                var message = '<ul>';

                if (!atto.legislatura) {
                    message += '<li><i>Legislatura</i> è un campo obbligatorio</li>';
                    invalidFields.push('legislatura');
                } else if (isNaN(atto.legislatura)) {
                    message += '<li><i>Legislatura</i> contiene un valore non valido</li>';
                    invalidFields.push('legislatura');
                }

                if (!atto.numeroAtto) {
                    message += '<li><i>Numero Atto</i> è un campo obbligatorio</li>';
                    invalidFields.push('numeroAtto');
                }

                if (!atto.tipoAtto) {
                    message += '<li><i>Tipo Atto</i> è un campo obbligatorio</li>';
                    invalidFields.push('tipoAtto');
                }

                if (!atto.titolo) {
                    message += '<li><i>Titolo</i> è un campo obbligatorio</li>';
                    invalidFields.push('titolo');
                }

                if (!atto.numeroProtocollo) {
                    message += '<li><i>Numero Protocollo</i> è un campo obbligatorio</li>';
                    invalidFields.push('numeroProtocollo');
                } else if (isNaN(atto.numeroProtocollo)) {
                    message += '<li><i>Numero Protocollo</i> contiene un valore non valido</li>';
                    invalidFields.push('numeroProtocollo');
                }

                if (!atto.dataProtocollo) {
                    message += '<li><i>Data Protocollo</i> è un campo obbligatorio</li>';
                    invalidFields.push('dataProtocollo');
                }

                if(atto.tipoAtto === 'PLIP'){
                    if (!atto.proponentiText || atto.proponentiText.length === 0 || atto.proponentiText[0] === '') {
                        message += '<li>Devi specificare almeno un proponente</li>';
                        invalidFields.push('proponenti');
                    }
                } else {
                    if (!atto.tipoProponente) {
                        message += '<li><i>Tipo Proponente</i> è un campo obbligatorio</li>';
                        invalidFields.push('tipoProponente');
                    } else if (!atto.proponente.proponenti || atto.proponente.proponenti.length === 0) {
                        message += '<li>Devi specificare almeno un proponente</li>';
                        invalidFields.push('proponenti');
                    }
                }


                message += '</ul>';

                var valid = invalidFields.length === 0;
                if (!valid) {
                    $location.hash('presentation');
                    $anchorScroll();
                    if (PdlService.sezioniMap.PRESENTATION) {
                        PdlService.sezioniMap.PRESENTATION.visible = true;
                    }
                }

                return {valid: valid, message: message, invalidFields: invalidFields};
            }

            function validateCourtroomMeeting(invalidFields, idx) {
                var sezione = PdlService.getSezioniFromMap('COURT_MEETING', idx);
                if (!sezione) {
                    return {valid: true, message: message, invalidFields: invalidFields};
                }

                var suffix = "";
                if (idx > 0) {
                    suffix += "_" + idx;
                }

                var metadata = sezione.metadati;

                var message = '<ul>';

                if (!metadata.sedute || !metadata.sedute[0]) {
                    message += '<li><i>Date sedute:</i> inserire almeno una data</li>';
                    invalidFields.push('sessionsDate' + suffix);
                }

                message += '</ul>';

                var valid = invalidFields.length === 0;
                if (!valid) {
                    $location.hash('courtroomOutcome' + suffix);
                    $anchorScroll();
                    sezione.visible = true;
                }

                return {valid: valid, message: message, invalidFields: invalidFields};
            }

            function validateCourtroomOutcome(invalidFields, idx) {
                var sezione = PdlService.getSezioniFromMap('COURT_OUTCOME', idx);
                if (!sezione) {
                    return {valid: true, message: message, invalidFields: invalidFields};
                }

                var suffix = "";
                if (idx > 0) {
                    suffix += "_" + idx;
                }

                var metadata = sezione.metadati;

                var message = '<ul>';

                //Non sono piu' obbligatori: richiesta del Caldini in data 27/5/2019
/*                 if (typeof (metadata.modificaOggettoTitolo) === 'undefined' || metadata.modificaOggettoTitolo === null) {
                    message += '<li><i>Modifica a titolo della Commissione</i> è un campo obbligatorio</li>';
                    invalidFields.push('modificaOggettoTitoloAula' + suffix);
                } else */ 
                if (metadata.modificaOggettoTitolo && (!metadata.nuovoOggettoTitolo || metadata.nuovoOggettoTitolo.trim().length === 0)) {
                    message += '<li><i>Nuovo titolo</i> è un campo obbligatorio</li>';
                    invalidFields.push('nuovoOggettoTitoloAula' + suffix);
                }
/*                 if (typeof (metadata.coordinamentoTesto) === 'undefined' || metadata.coordinamentoTesto === null) {
                    message += '<li><i>Coordinamento formale testo</i> è un campo obbligatorio</li>';
                    invalidFields.push('coordinamentoTesto' + suffix);
                } */

                message += '</ul>';

                var valid = invalidFields.length === 0;
                if (!valid) {
                    $location.hash('courtroomOutcome' + suffix);
                    $anchorScroll();
                    sezione.visible = true;
                }

                return {valid: valid, message: message, invalidFields: invalidFields};
            }

            // function validateConstitutionalAppeal(invalidFields, idx) {
            //    var sezione = PdlService.getSezioniFromMap('CONSTITUTIONAL_APPEAL', idx);
            //    if (!sezione) {
            //        return {valid: true, message: message};
            //    }
            //    var suffix = "";
            //    if (idx > 0) {
            //        suffix += "_" + idx;
            //    }
            //
            //    var metadata = sezione.metadati;
            //    var message = '<ul>';
            //    if (typeof (metadata.ricorso) === 'undefined' || metadata.ricorso === null) {
            //        message += '<li><i>Ricorso costituzionale</i> è un campo obbligatorio</li>';
            //        invalidFields.push('ricorso' + suffix);
            //    } else if (metadata.ricorso && (!metadata.esitoRicorso || metadata.esitoRicorso.trim().length === 0)) {
            //        message += '<li><i>Esito ricorso istituzionale</i> è un campo obbligatorio</li>';
            //        invalidFields.push('esitoRicorso' + suffix);
            //    }
            //    message += '</ul>';
            //    var valid = invalidFields.length === 0;
            //    if (!valid) {
            //        $location.hash('constitutionalAppeal' + suffix);
            //        $anchorScroll();
            //        sezione.visible = true;
            //    }
            //    return {valid: valid, message: message, invalidFields: invalidFields};
            // }
        }]);
})();
