angular.module('gestioneAttiApp').directive('retiredBlock', function () {
    return {
        restrict: "E",
        templateUrl: 'app/components/retiredBlock/retiredBlock.html',
        scope: {
            atto: '=',
            statoAttoCorrente: '=',
            presentationSection: '=',
            statoPresentatoCollapsed: '=',
            viewMode: '=',
            docTypes: "=",
            workDocumentsMap: '=',
            retiredSection: '=',
            utente: "=",
            changeDocumentTypeCallback: "=",
            changeDocumentVisibilityCallback: "=",
            publicView: "="
        },
        transclude: false,
        controllerAs: 'vm',
        bindToController: true,
        controller: ['Atto', 'User', 'PdlService', 'Documento', '$location','$anchorScroll', '$timeout', function (Atto, User, PdlService, Documento, $location, $anchorScroll, $timeout) {
            var vm = this;
            vm.actType = vm.atto.tipoAtto;
            vm.attoRitiratoDocs = [];
            vm.retired = {visible: true};
            vm.docPresentationSession = false;
            vm.canEdit = vm.retiredSection.editable;

            Documento.getDocumentiDiRitiroAtto({idAtto: vm.atto.id, mnemonic: 'X0380'}, onSuccess, onError);

            function onSuccess(data) {

                vm.attoRitiratoDocs = data;
                if(vm.attoRitiratoDocs.lenght === 0) {
                    return;
                }
                vm.attoRitiratoDocs.forEach(function(doc){
                    // Provo a settarlo solo se è false
                    // Se il doc di ritiro atto è presente in altre sezioni (oltre che in presentazione) si considera "true"
                    if(!vm.docPresentationSession) {
                        vm.docPresentationSession = (doc.idSezione === vm.presentationSection.id);
                    }
                    if(doc.datiProtocollo && doc.datiProtocollo.numeroProtocolloConsiglio && doc.datiProtocollo.dataProtocolloConsiglio){
                        vm.numeroProtocolloConsiglio = doc.datiProtocollo.numeroProtocolloConsiglio;
                        vm.dataProtocolloConsiglio = doc.datiProtocollo.dataProtocolloConsiglio;
                        return;
                    }
                });
            }

            function onError(error) {
                console.log("An error has occurred retreiving documents for tipo atto Lettera di ritiro atto: ", error);
            }
            vm.sezioni = {
                RETIRED: {
                    form: 'app/components/retiredBlock/retired.html'
                }

            };

            vm.stato = {
                retired: {
                    isCollapsed: vm.statoAttoCorrente === 'RITIRATO' || vm.viewMode
                }
            };

            vm.getSezioniFromMap = PdlService.getSezioniFromMap;
            vm.invalidFields = vm.invalidFields || [];

            vm.scrollToPresentationDocs = function() {
                vm.presentationSection.visible = true;
                vm.statoPresentatoCollapsed = true;
                vm.presentationSection.showDocuments();
                //timeout is 0 because otherwise it is fired the anchor scroll before the dom changes
                $timeout(function () {
                    $anchorScroll(vm.presentationSection.id);
                }, 0);
            };
        }]
    };
});
