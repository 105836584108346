(function () {

    'use strict';

    angular.module('gestioneAttiApp').service('IoValidator', ['PdlService', '$location', '$anchorScroll',
        function (PdlService, $location, $anchorScroll) {

            this.PdlService = PdlService;
            this.$location = $location;
            this.$anchorScroll = $anchorScroll;

            // Presentato
            this.validatePresentation = validatePresentation;
            this.validateAssignmentLetter = validateAssignmentLetter;

            // Commissione

            // Aula
            this.validateEsitoRispostaScritta = validateEsitoRispostaScritta;
            this.validateSectorSections = validateSectorSections;

            this.validate = function (state, numeroRinvio, utente, userRolesMap, idPrimaCommissione, action, primaryCommission) {
                var invalidFields = [];

                if (userRolesMap.isAdmin) {
                    var result = validateSectorSections(invalidFields, idPrimaCommissione);
    
                    if (result.valid) {
                        if (PdlService.sezioniMap.ASSIGNMENT_LETTER && !PdlService.sezioniMap.ASSIGNMENT_LETTER.metadati.aulaDiretta) {
                            result = validateCommissionSections(invalidFields, utente, userRolesMap, numeroRinvio,
                                    idPrimaCommissione, action && action.name === 'assignToCourtRoom', primaryCommission);
                        }
                        if (result.valid) {
                            result = validateCourtroomSections(invalidFields, numeroRinvio);
                        }
                    }
                    return result;
                } else {
                    switch (state) {
                        case "PRESENTATO":
                            return validateSectorSections(invalidFields, idPrimaCommissione);
                        case "COMMISSIONE":
                            return validateCommissionSections(invalidFields, utente, userRolesMap, numeroRinvio,
                                    idPrimaCommissione, action && action.name === 'assignToCourtRoom', primaryCommission);
                        case "AULA":
                            return validateCourtroomSections(invalidFields, numeroRinvio);
                        case "APPROVATO":
                            return validatePostCourtroomSections(invalidFields, numeroRinvio);
                        default:
                            console.error("STATO SCONOSCIUTO: " + state);
                            return {valid: false, message: "STATO SCONOSCIUTO: " + state};
                    }
                }
            };

            function validateAssignmentLetter(invalidFields, idPrimaCommissione) {
                //CASO Interrogazione scritta
                if(!PdlService.sezioniMap.ASSIGNMENT_LETTER) {
                    return {valid: true};
                }
                var metadata = PdlService.sezioniMap.ASSIGNMENT_LETTER.metadati;

                var message = '<ul>';

                if (typeof (metadata.aulaDiretta) === 'undefined' || metadata.aulaDiretta === null) {
                    message += '<li><i>Direttamente in Aula</i> è un campo obbligatorio</li>';
                    invalidFields.push('aulaDiretta');
                } else if (metadata.aulaDiretta) {
/*                     if (!metadata.dataSedutaUp) {
                        message += '<li><i>Data Seduta UP</i> è un campo obbligatorio</li>';
                        invalidFields.push('dataSedutaUp');
                    } */
                } else {
                    if (!metadata.dateAssegnazione || metadata.dateAssegnazione.length === 0 || metadata.dateAssegnazione[0] === "") {
                        message += '<li><i>Data assegnazione</i> è un campo obbligatorio</li>';
                        invalidFields.push('dateAssegnazione');
                    }
                    if (!metadata.parereReferente || !metadata.parereReferente.length) {
                        message += '<li><i>Parere Referente</i> è un campo obbligatorio</li>';
                        invalidFields.push('parereReferente');
                    } else if (metadata.parereReferente.indexOf(idPrimaCommissione) > -1 && metadata.parereIstituzionale) {
                        message += '<li><i>Parere Istituzionale</i> non può essere impostato a Sì se la Prima Commissione è stata selezionata in Parere Referente</li>';
                        invalidFields.push('parereIstituzionale');
                    }

                }

                message += '</ul>';

                var valid = invalidFields.length === 0;
                if (!valid) {
                    $location.hash('assignmentLetter');
                    $anchorScroll();
                    PdlService.sezioniMap.ASSIGNMENT_LETTER.visible = true;
                }

                return {valid: valid, message: message, invalidFields: invalidFields};
            }

            function validateSectorSections(invalidFields, idPrimaCommissione) {
                var result = validateAssignmentLetter(invalidFields, idPrimaCommissione);
                return result;
            }

            function validateEsitoRispostaScritta(invalidFields) {
                var sezione = PdlService.getSezioniFromMap('COURT_OUTCOME', 0);
                if(!invalidFields) {
                    invalidFields = [];
                }
                if (!sezione) {
                    return {valid: true, message: message, invalidFields: invalidFields};
                }

                var metadata = sezione.metadati;

                var message = '<ul>';

                if (!metadata.rispostaScrittaIn3Giorni && !metadata.rispostaScrittaIn15Giorni && !metadata.rispostaScrittaConsegnata) {
                    message += '<li><i>Esisto:</i> inserire almeno una risposta scritta nell\'esito!</li>';
                    invalidFields.push('rispostaScritta');
                }

                message += '</ul>';

                var valid = invalidFields.length === 0;
                if (!valid) {
                    $location.hash('courtroomOutcome');
                    $anchorScroll();
                    sezione.visible = true;
                }

                return {valid: valid, message: message, invalidFields: invalidFields};
            }

            function validateCommissionSections(invalidFields, utente, userRolesMap, numeroRinvio, idPrimaCommissione, action, primaryCommission) {
                return {valid: true};
            }


            /* function validateCourtroomSections(invalidFields, numeroRinvio) {
                return {valid: true};
            }*/

            function validateCourtroomSections(invalidFields, numeroRinvio) {

                if(!invalidFields) {
                    invalidFields = [];
                }

                var sezione = PdlService.getSezioniFromMap('COURT_MEETING', numeroRinvio);
                if (!sezione) {
                    return {valid: true, message: message, invalidFields: invalidFields};
                }

                var suffix = "";
                if (numeroRinvio > 0) {
                    suffix += "_" + numeroRinvio;
                }

                var metadata = sezione.metadati;
                var message = '<ul>';

                // Date sedute iscrizione
                if(!metadata.sedute || !metadata.sedute[0]) {
                    message += '<li><i>Date sedute iscrizione</i> è un campo obbligatorio</li>';
                    invalidFields.push('sedute' + suffix);
                }
                // Date sedute trattazione
                if(!metadata.seduteTrattazione || !metadata.seduteTrattazione[0]) {
                    message += '<li><i>Date sedute trattazione</i> è un campo obbligatorio</li>';
                    invalidFields.push('seduteTrattazione' + suffix);
                }

                message += '</ul>';
                
                var valid = invalidFields.length === 0;

                return {valid: valid, message: message, invalidFields: invalidFields};
            }


            function validatePostCourtroomSections(invalidFields, numeroRinvio) {
                var result;
            
                return {valid: true, invalidFields: invalidFields};
            }

            function validatePresentation(invalidFields, atto) {
                if (!invalidFields)
                    invalidFields = [];

                var message = '<ul>';

                if (!atto.legislatura) {
                    message += '<li><i>Legislatura</i> è un campo obbligatorio</li>';
                    invalidFields.push('legislatura');
                } else if (isNaN(atto.legislatura)) {
                    message += '<li><i>Legislatura</i> contiene un valore non valido</li>';
                    invalidFields.push('legislatura');
                }

                if (!atto.numeroAtto) {
                    message += '<li><i>Numero Atto</i> è un campo obbligatorio</li>';
                    invalidFields.push('numeroAtto');
                }

                if (!atto.tipoAtto) {
                    message += '<li><i>Tipo Atto</i> è un campo obbligatorio</li>';
                    invalidFields.push('tipoAtto');
                }

                if (!atto.titolo) {
                    message += '<li><i>Titolo</i> è un campo obbligatorio</li>';
                    invalidFields.push('titolo');
                }

                if (!atto.numeroProtocollo) {
                    message += '<li><i>Numero Protocollo</i> è un campo obbligatorio</li>';
                    invalidFields.push('numeroProtocollo');
                } else if (isNaN(atto.numeroProtocollo)) {
                    message += '<li><i>Numero Protocollo</i> contiene un valore non valido</li>';
                    invalidFields.push('numeroProtocollo');
                }

                if (!atto.dataProtocollo) {
                    message += '<li><i>Data Protocollo</i> è un campo obbligatorio</li>';
                    invalidFields.push('dataProtocollo');
                }

                if(atto.tipoAtto === 'PLIP'){
                    if (!atto.proponentiText || atto.proponentiText.length === 0 || atto.proponentiText[0] === '') {
                        message += '<li>Devi specificare almeno un proponente</li>';
                        invalidFields.push('proponenti');
                    }
                } else {
                    if (!atto.tipoProponente) {
                        message += '<li><i>Tipo Proponente</i> è un campo obbligatorio</li>';
                        invalidFields.push('tipoProponente');
                    } else if (!atto.proponente.proponenti || atto.proponente.proponenti.length === 0) {
                        message += '<li>Devi specificare almeno un proponente</li>';
                        invalidFields.push('proponenti');
                    }
                }


                message += '</ul>';

                var valid = invalidFields.length === 0;
                if (!valid) {
                    $location.hash('presentation');
                    $anchorScroll();
                    if (PdlService.sezioniMap.PRESENTATION) {
                        PdlService.sezioniMap.PRESENTATION.visible = true;
                    }
                }

                return {valid: valid, message: message, invalidFields: invalidFields};
            }
        }]);
})();
