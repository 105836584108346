(function () {

    'use strict';

    angular.module('gestioneAttiApp').service('MinorBasicValidator', ['PdlService', '$location', '$anchorScroll',
        function (PdlService, $location, $anchorScroll) {

            this.PdlService = PdlService;
            this.$location = $location;
            this.$anchorScroll = $anchorScroll;

            // Presentato
            this.validatePresentation = validatePresentation;

            // Commissione
            this.validateCommissionEmendments = validateCommissionEmendments;
            this.validateCommissionSections = validateCommissionSections;
            this.validateCommissionMessage = validateCommissionMessage;
            this.validateCommissionOpinions = validateCommissionOpinions;
            this.validateCommissionOpinion = validateCommissionOpinion;
            this.validateCourtroomSections = validateCourtroomSections; 

            // Aula
            this.validateCourtroomEmendments = validateCourtroomEmendments;
            this.validateCourtroomMeeting = validateCourtroomMeeting;
            this.validateSectorSections = validateSectorSections;
            this.validateCourtroomOutcome = validateCourtroomOutcome;

            // Stato Approvato
            this.validatePostCourtroomSections = validatePostCourtroomSections;
            this.validatePostCourtroom = validatePostCourtroom;

            this.validate = function (state, numeroRinvio, utente, userRolesMap, idPrimaCommissione, action, primaryCommission) {
                var invalidFields = [];

                if (userRolesMap.isAdmin) {
                    /* var result = validateSectorSections(invalidFields, idPrimaCommissione); */
                    var result = {};
                    result.valid = true;
                    if (result.valid) {
/*                             result = validateCommissionSections(invalidFields, utente, userRolesMap, numeroRinvio,
                                idPrimaCommissione, action && action.name === 'assignToCourtRoom', primaryCommission); */
                        if (result.valid) {
                            //result = validateCourtroomSections(invalidFields, numeroRinvio);
                        }
                    }
                    return result;
                } else {
                    switch (state) {
                        case "PRESENTATO":
                            return validateSectorSections(invalidFields);
                        case "COMMISSIONE":
                            return validateCommissionSections(invalidFields, utente, userRolesMap, numeroRinvio,
                                    idPrimaCommissione, action && action.name === 'assignToCourtRoom', primaryCommission);
                        case "AULA":
                            return validateCourtroomSections(invalidFields, numeroRinvio);
                        case "APPROVATO":
                            return validatePostCourtroomSections(invalidFields, numeroRinvio);
                        default:
                            console.error("STATO SCONOSCIUTO: " + state);
                            return {valid: false, message: "STATO SCONOSCIUTO: " + state};
                    }
                }
            };

            function validateCommissionSections(invalidFields, utente, userRolesMap, numeroRinvio, idPrimaCommissione, action, primaryCommission) {
                var result = null;
                var i = 0;
                if(numeroRinvio > 1) {
                    i = 1;
                } else {
                    return {valid: true, message: 'ok', invalidFields: invalidFields};
                }
                for (i; i <= numeroRinvio; i++) {
                    result = validateCommissionEmendments(invalidFields, i, primaryCommission);//se sono referente
                    if (result.valid) {
                        //result = validateCommissionOpinions(invalidFields, utente, userRolesMap, i, idPrimaCommissione); //Commission Opinions validation disabled as requested by mail from @Caldini in 18/06/19
                        if (result.valid) {
                            result = validateCommissionMessage(invalidFields, i, primaryCommission);//se sono referente
                        } else {
                            break;
                        }

                    } else {
                        break;
                    }

                }
                return result;
            }

            function validateSectorSections(invalidFields){
                return {valid: true, message: 'ok', invalidFields: invalidFields};                
            }

            function validateCourtroomSections(invalidFields, numeroRinvio) {
                var result;
                result = validateAssignmentLetter(invalidFields, 0);
                if(!result.valid) {
                    return result;
                }
                for (var i = 0; i <= numeroRinvio; i++) {
                    result = validateCourtroomEmendments(invalidFields, i);
                    if (result.valid) {
                        result = validateCourtroomMeeting(invalidFields, i);
                        if (result.valid) {
                            result = validateCourtroomOutcome(invalidFields, i);
                        } else {
                            break;
                        }
                    } else {
                        break;
                    }
                }
                return result;
            }

            //nel caso della lettera di assegnazione i e' sempre 0!
            function validateAssignmentLetter(invalidFields, i) {
                var metadata = PdlService.sezioniMap.ASSIGNMENT_LETTER.metadati;

                var message = '<ul>';


                if (!metadata.parereReferente || !metadata.parereReferente.length) {
                    message += '<li><i>Assegnazione commissioni:</i> devi selezionare almeno una commissione </li>';
                    invalidFields.push('parereReferente');
                }           
                message += '</ul>';

                var valid = invalidFields.length === 0;
                if (!valid) {
                    $location.hash('assignmentLetter');
                    $anchorScroll();
                    PdlService.sezioniMap.ASSIGNMENT_LETTER.visible = true;
                }

                return {valid: valid, message: message, invalidFields: invalidFields};
            }

            function validatePostCourtroomSections(invalidFields, numeroRinvio) {
                var result;

                for (var i = 0; i <= numeroRinvio; i++) {
                    //result = validatePostCourtroom(invalidFields, i);
                    result = {valid: true};
                    if (result.valid) {
                        result = validateActuationNotes(invalidFields,i);
                        if(!result.valid){
                            break;
                        }
                    } else {
                        break;
                    }
                }

                return result;
            }

            //Da sapere quali campi sono obbligatori!
            function validateActuationNotes(invalidFields, i){
                return {valid: true, message: '', invalidFields: invalidFields};
            }

            function validateCommissionMessage(invalidFields, idx, primaryCommission) {
                var sezione = PdlService.getSezioniFromMap('COMMISSION_MESSAGE', idx);
                if (!sezione || !primaryCommission) {
                    return {valid: true, message: message, invalidFields: invalidFields};
                }

                var suffix = "";
                if (idx > 0) {
                    suffix += "_" + idx;
                }

                var metadata = sezione.metadati;

                var message = '<ul>';

                if (typeof (metadata.modificaOggettoTitolo) === 'undefined' || metadata.modificaOggettoTitolo === null) {
                    message += '<li><i>Modifica a titolo della Commissione</i> è un campo obbligatorio</li>';
                    invalidFields.push('modificaOggettoTitolo' + suffix);
                } else if (metadata.modificaOggettoTitolo && (!metadata.nuovoOggettoTitolo || metadata.nuovoOggettoTitolo.trim().length === 0)) {
                    message += '<li><i>Nuovo titolo</i> è un campo obbligatorio</li>';
                    invalidFields.push('nuovoOggettoTitolo' + suffix);
                }

                if (typeof (metadata.modificaTesto) === 'undefined' || metadata.modificaTesto === null) {
                    message += '<li><i>Modifiche al testo</i> è un campo obbligatorio</li>';
                    invalidFields.push('modificaTesto' + suffix);
                } else if (metadata.modificaTesto && !metadata.tipoModifica) {
                    message += '<li><i>Tipo di modifica</i> è un campo obbligatorio</li>';
                    invalidFields.push('tipoModifica' + suffix);
                }

                message += '</ul>';

                var valid = invalidFields.length === 0;
                if (!valid) {
                    $location.hash('commissionMessage' + suffix);
                    $anchorScroll();
                    sezione.visible = true;
                }

                return {valid: valid, message: message, invalidFields: invalidFields};
            }

            function validateCommissionEmendments(invalidFields, idx, primaryCommission) {
                var sezione = PdlService.getSezioniFromMap('COMMISSION_EMENDMENTS', idx);

                if (!sezione || !primaryCommission) {
                    return {valid: true, message: message, invalidFields: invalidFields};
                }

                var suffix = "";
                if (idx > 0) {
                    suffix += "_" + idx;
                }

                var metadata = sezione.metadati;

               
                var message = '<ul>';

                if (metadata.emendamentiPresentatiCommissione) {
                    var i = 0;

                    angular.forEach(metadata.emendamentiPresentati, function (emendment) {
                        if (i > 0
                                //&& (!emendment.numeroEmendamento || emendment.numeroEmendamento.trim().length === 0)
                                && !emendment.dataProtocollo
                                // && (!emendment.protocollo || emendment.protocollo.trim().length === 0) // Validazione su protocollo rimossa. @See MAIL 16/07/2018 09.09 FROM Caldini Marco.
                                && (!emendment.firmatari || emendment.firmatari.length === 0)) {
                            //rimuovo eventuali emendamenti lasciati vuoti se sono in più al primo (che deve essere compilato)
                            metadata.emendamentiPresentati.splice(metadata.emendamentiPresentati.indexOf(emendment), 1);
                        } else {
/*                             if (!emendment.numeroEmendamento || emendment.numeroEmendamento.trim().length === 0) {
                                invalidFields.push('numeroEmendamento' + suffix + '_' + i);
                            } */
                            if (!emendment.dataProtocollo) {
                                invalidFields.push('dataProtocollo' + suffix + '_' + i);
                            }
                            /* Validazione su protocollo rimossa. @See MAIL 16/07/2018 09.09 FROM Caldini Marco. */
                            /*if (!emendment.protocollo || emendment.protocollo.trim().length === 0) {
                             invalidFields.push('protocollo' + suffix + '_' + i);
                             }*/
                            if (!emendment.firmatari || emendment.firmatari.length === 0) {
                                invalidFields.push('firmatari' + suffix + '_' + i);
                            }
                        }
                        i++;
                    });
                }

                message += '</ul>';

                var valid = invalidFields.length === 0;
                if (!valid) {
                    message += '<li>Uno o più <i>Emendamenti presentati in commissione</i> non sono stati compilati correttamente</li>';
                    $location.hash('commissionEmendments');
                    $anchorScroll();
                    sezione.visible = true;
                }

                return {valid: valid, message: message, invalidFields: invalidFields};
            }
            
            function validateCommissionOpinions(invalidFields, utente, userRolesMap, idx, idPrimaCommissione) {
                var sezione = PdlService.getSezioniFromMap('COMMISSION_OPINIONS', idx);
                if (!sezione) {
                    return {valid: true, message: message, invalidFields: invalidFields};
                }
                //Perche' la prima volta va direttamente in aula, quindi la prima non deve essere considerata!
                if(idx === 0) {
                    return {valid: true, message: message, invalidFields: invalidFields};
                }
                var suffix = "";
                if (idx > 0) {
                    suffix += "_" + idx;
                }

                var assignmentLetterMetadata = PdlService.sezioniMap.ASSIGNMENT_LETTER.metadati;

                var metadatiSezione = sezione.metadati;
                var message = '<ul>';

                if (assignmentLetterMetadata.parereReferente && assignmentLetterMetadata.parereReferente.length
                        && PdlService.isCommissionOpinionEditable(utente, assignmentLetterMetadata.parereReferente, idx, userRolesMap.isAdmin)) {
                    message = validateCommissionOpinion(invalidFields, assignmentLetterMetadata.parereReferente, metadatiSezione.parereReferente, true, message, suffix);
                }

                message += '</ul>';

                var valid = invalidFields.length === 0;
                if (!valid) {
                    $location.hash('commissionOpinions' + suffix);
                    $anchorScroll();
                    sezione.visible = true;
                }

                return {valid: valid, message: message, invalidFields: invalidFields};
            }

            function validateCommissionOpinion(invalidFields, opinionAssignment, opinionMetadata, isJoinedOpinion, message, fieldSuffix) {
                var messageItem = '<li>Ci sono <i>Pareri/Osservazioni</i> non completamente compilati</i>';
                if (!opinionMetadata) {
                    if (isJoinedOpinion) {
                        var subjectCompositeId = '';
                        if (opinionAssignment.length === 1) {
                            subjectCompositeId = opinionAssignment[0];
                        } else {
                            angular.forEach(opinionAssignment, function (subjectId) {
                                subjectCompositeId += '_' + subjectId;
                                message += messageItem;
                                messageItem = '';
                            });
                        }
                        invalidFields.push('data' + fieldSuffix + subjectCompositeId);
                        invalidFields.push('parere' + fieldSuffix + subjectCompositeId);
                        invalidFields.push('tipoFavorevolezza' + fieldSuffix + subjectCompositeId);
                    } else {
                        angular.forEach(opinionAssignment, function (subjectId) {
                            invalidFields.push('data' + fieldSuffix + subjectId);
                            invalidFields.push('parere' + fieldSuffix + subjectId);
                            invalidFields.push('tipoFavorevolezza' + fieldSuffix + subjectId);
                            message += messageItem;
                            messageItem = '';
                        });
                    }
                } else {
                    if (isJoinedOpinion) {
                        var subjectCompositeId = '';
                        if (opinionAssignment.length === 1) {
                            subjectCompositeId = opinionAssignment[0];
                        } else {
                            angular.forEach(opinionAssignment, function (subjectId) {
                                subjectCompositeId += '_' + subjectId;
                                message += messageItem;
                                messageItem = '';
                            });
                        }
                        if (!opinionMetadata.data || !opinionMetadata.data[subjectCompositeId]) {
                            invalidFields.push('data' + fieldSuffix + '_' + subjectCompositeId);
                            message += messageItem;
                            messageItem = '';
                        }
                        if (!opinionMetadata.parere || !opinionMetadata.parere[subjectCompositeId]) {
                            invalidFields.push('parere' + fieldSuffix + '_' + subjectCompositeId);
                            message += messageItem;
                            messageItem = '';
                        }
                        if (!opinionMetadata.tipoFavorevolezza || !opinionMetadata.tipoFavorevolezza[subjectCompositeId]) {
                            invalidFields.push('tipoFavorevolezza' + fieldSuffix + '_' + subjectCompositeId);
                            message += messageItem;
                            messageItem = '';
                        }
                    } else {
                        angular.forEach(opinionAssignment, function (subjectId) {
                            if (!opinionMetadata.data || !opinionMetadata.data[subjectId]) {
                                invalidFields.push('data' + fieldSuffix + '_' + subjectId);
                                message += messageItem;
                                messageItem = '';
                            }
                            if (!opinionMetadata.parere || !opinionMetadata.parere[subjectId]) {
                                invalidFields.push('parere' + fieldSuffix + '_' + subjectId);
                                message += messageItem;
                                messageItem = '';
                            }
                            if (!opinionMetadata.tipoFavorevolezza || !opinionMetadata.tipoFavorevolezza[subjectId]) {
                                invalidFields.push('tipoFavorevolezza' + fieldSuffix + '_' + subjectId);
                                message += messageItem;
                                messageItem = '';
                            }
                        });
                    }
                }
                return message;
            }

            function validateCourtroomEmendments(invalidFields, idx) {
                var sezione = PdlService.getSezioniFromMap('COURT_EMENDMENTS', idx);
                if (!sezione) {
                    return {valid: true, message: message, invalidFields: invalidFields};
                }
                var suffix = "";
                if (idx > 0) {
                    suffix += "_" + idx;
                }

                var metadata = sezione.metadati;
                var message = '<ul>';
                var i = 0;
                angular.forEach(metadata.emendamenti, function (emendment) {
                    if ( //(!emendment.numeroEmendamento || emendment.numeroEmendamento.trim().length === 0)
                            !emendment.dataProtocollo
                            //&& (!emendment.protocollo || emendment.protocollo.trim().length === 0)
                            && (!emendment.firmatari || emendment.firmatari.length === 0)
                            /* && !emendment.state */) {
                        //rimuovo eventuali emendamenti lasciati vuoti se sono in più al primo (che deve essere compilato)
                        if (i > 0) {
                            metadata.emendamenti.splice(metadata.emendamenti.indexOf(emendment), 1);
                        }
                    } else {
/*                         if (!emendment.numeroEmendamento || emendment.numeroEmendamento.trim().length === 0) {
                            invalidFields.push('numeroEmendamento' + suffix + '_' + i);
                        } */
                        if (!emendment.dataProtocollo) {
                            invalidFields.push('dataProtocollo' + suffix + '_' + i);
                        }
/*                         if (!emendment.protocollo || emendment.protocollo.trim().length === 0) {
                            invalidFields.push('protocollo' + suffix + '_' + i);
                        } */
                        if (!emendment.firmatari || emendment.firmatari.length === 0) {
                            invalidFields.push('firmatari' + suffix + '_' + i);
                        }
/*                         if (!emendment.state) {
                            invalidFields.push('state' + suffix + '_' + i);
                        } */
                    }
                    i++;
                });
                message += '</ul>';
                var valid = invalidFields.length === 0;
                if (!valid) {
                    message += '<li>Uno o più <i>Emendamenti presentati in aula</i> non sono stati compilati correttamente</li>';
                    $location.hash('courtroomEmendments' + suffix);
                    $anchorScroll();
                    sezione.visible = true;
                }
                return {valid: valid, message: message, invalidFields: invalidFields};
            }

            function validatePostCourtroom(invalidFields, idx) {
                var sezione = PdlService.getSezioniFromMap('POST_COURT', idx);
                if (!sezione) {
                    return {valid: true, message: message, invalidFields: invalidFields};
                }
                var suffix = "";
                if (idx > 0) {
                    suffix += "_" + idx;
                }

                var metadata = sezione.metadati;
                var message = '<ul>';
                if (!metadata.numeroLeggeAC || metadata.numeroLeggeAC.trim().length === 0) {
                    message += '<li><i>Numero legge AC</i> è un campo obbligatorio</li>';
                    invalidFields.push('numeroLeggeAC' + suffix);
                }
                if (!metadata.dataLeggeAC) {
                    message += '<li><i>Data legge AC</i> è un campo obbligatorio</li>';
                    invalidFields.push('dataLeggeAC' + suffix);
                }
                message += '</ul>';
                var valid = invalidFields.length === 0;
                if (!valid) {
                    $location.hash('postCourtroom' + suffix);
                    $anchorScroll();
                    sezione.visible = true;
                }
                return {valid: valid, message: message, invalidFields: invalidFields};
            }

            function validatePresentation(invalidFields, atto) {
                if (!invalidFields)
                    invalidFields = [];

                var message = '<ul>';

                if (!atto.legislatura) {
                    message += '<li><i>Legislatura</i> è un campo obbligatorio</li>';
                    invalidFields.push('legislatura');
                } else if (isNaN(atto.legislatura)) {
                    message += '<li><i>Legislatura</i> contiene un valore non valido</li>';
                    invalidFields.push('legislatura');
                }

                if (!atto.numeroAtto) {
                    message += '<li><i>Numero Atto</i> è un campo obbligatorio</li>';
                    invalidFields.push('numeroAtto');
                }

                if (!atto.tipoAtto) {
                    message += '<li><i>Tipo Atto</i> è un campo obbligatorio</li>';
                    invalidFields.push('tipoAtto');
                }

                if (!atto.titolo) {
                    message += '<li><i>Titolo</i> è un campo obbligatorio</li>';
                    invalidFields.push('titolo');
                }

                if (!atto.numeroProtocollo) {
                    message += '<li><i>Numero Protocollo</i> è un campo obbligatorio</li>';
                    invalidFields.push('numeroProtocollo');
                } else if (isNaN(atto.numeroProtocollo)) {
                    message += '<li><i>Numero Protocollo</i> contiene un valore non valido</li>';
                    invalidFields.push('numeroProtocollo');
                }

                if (!atto.dataProtocollo) {
                    message += '<li><i>Data Protocollo</i> è un campo obbligatorio</li>';
                    invalidFields.push('dataProtocollo');
                }

                if (!atto.tipoProponente) {
                    message += '<li><i>Tipo Proponente</i> è un campo obbligatorio</li>';
                    invalidFields.push('tipoProponente');
                } else if (!atto.proponente.proponenti || atto.proponente.proponenti.length === 0) {
                    message += '<li>Devi specificare almeno un proponente</li>';
                    invalidFields.push('proponenti');
                }


                message += '</ul>';

                var valid = invalidFields.length === 0;
                if (!valid) {
                    $location.hash('presentation');
                    $anchorScroll();
                    if (PdlService.sezioniMap.PRESENTATION) {
                        PdlService.sezioniMap.PRESENTATION.visible = true;
                    }
                }

                return {valid: valid, message: message, invalidFields: invalidFields};
            }

            function validateCourtroomMeeting(invalidFields, idx) {
                var sezione = PdlService.getSezioniFromMap('COURT_MEETING', idx);
                if (!sezione) {
                    return {valid: true, message: message, invalidFields: invalidFields};
                }

                var suffix = "";
                if (idx > 0) {
                    suffix += "_" + idx;
                }

                var metadata = sezione.metadati;

                var message = '<ul>';

                if (!metadata.sedute || !metadata.sedute[0]) {
                    message += '<li><i>Date sedute:</i> inserire almeno una data</li>';
                    invalidFields.push('sessionsDate' + suffix);
                }

                message += '</ul>';

                var valid = invalidFields.length === 0;
                if (!valid) {
                    $location.hash('courtroomOutcome' + suffix);
                    $anchorScroll();
                    sezione.visible = true;
                }

                return {valid: valid, message: message, invalidFields: invalidFields};
            }

            function validateCourtroomOutcome(invalidFields, idx) {
                var sezione = PdlService.getSezioniFromMap('COURT_OUTCOME', idx);
                if (!sezione) {
                    return {valid: true, message: message, invalidFields: invalidFields};
                }

                var suffix = "";
                if (idx > 0) {
                    suffix += "_" + idx;
                }

                var metadata = sezione.metadati;

                var message = '<ul>';

                //Non sono piu' obbligatori: richiesta del Caldini in data 27/5/2019
/*                 if (typeof (metadata.modificaOggettoTitolo) === 'undefined' || metadata.modificaOggettoTitolo === null) {
                    message += '<li><i>Modifica a titolo della Commissione</i> è un campo obbligatorio</li>';
                    invalidFields.push('modificaOggettoTitoloAula' + suffix);
                } else */ 
                if (metadata.modificaOggettoTitolo && (!metadata.nuovoOggettoTitolo || metadata.nuovoOggettoTitolo.trim().length === 0)) {
                    message += '<li><i>Nuovo titolo</i> è un campo obbligatorio</li>';
                    invalidFields.push('nuovoOggettoTitoloAula' + suffix);
                }
/*                 if (typeof (metadata.coordinamentoTesto) === 'undefined' || metadata.coordinamentoTesto === null) {
                    message += '<li><i>Coordinamento formale testo</i> è un campo obbligatorio</li>';
                    invalidFields.push('coordinamentoTesto' + suffix);
                } */

                message += '</ul>';

                var valid = invalidFields.length === 0;
                if (!valid) {
                    $location.hash('courtroomOutcome' + suffix);
                    $anchorScroll();
                    sezione.visible = true;
                }

                return {valid: valid, message: message, invalidFields: invalidFields};
            }
        }]);
})();
