(function() {
    'use strict';

    angular
        .module('gestioneAttiApp')
        .controller('PermissionsController', PermissionsController);

        PermissionsController.$inject = ['Principal', 'AlertService', '$state', 'pagingParams', 'paginationConstants', 'JhiLanguageService', 'Atto', '$translate'];

    function PermissionsController (Principal, AlertService, $state, pagingParams, paginationConstants, JhiLanguageService, Atto, $translate) {
        var vm = this;

        
        vm.authorities = ['ROLE_USER', 'ROLE_ADMIN'];
        vm.currentAccount = null;
        vm.languages = null;
        vm.loadAll = loadAll;
        vm.tipiAtto = [];
        vm.page = 1;
        vm.totalItems = null;
        vm.links = null;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.transition = transition;

        vm.loadAll();
        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });
        Principal.identity().then(function(account) {
            vm.currentAccount = account;
        });

        function loadAll () {
            Atto.getAllTipoAtto(onSuccess, onError);
        }

        function onSuccess(data) {
            vm.tipiAtto = data;
        }

        function onError(error) {
            if(error.status === 403){
                AlertService.error($translate.instant('error.access.external.forbidden'));
            } else {
                AlertService.error(error.data);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }
    }
})();
