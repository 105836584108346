/**
 * Created by Michele Romani on 23/06/2017.
 */


angular.module('gestioneAttiApp').directive('attoTableList', function () {
    return {
        restrict: "EA",
        scope: {
            format: '=',
            paging: '=',
            showControls: '=',
            title: '=',
            tableType: '@',
            archiveTree: '&',
        },
        controller: ['$rootScope', '$scope', '$mdSidenav', '$state', 'Atto', 'ParseLinks', 'toastr', 'paginationConstants', 'Principal', 'FileSaver', '$mdDialog', 'SEARCH_STATES', 'ACT_TYPES', 'AdvanceSearchService', 'ATTI_TABLE_TYPE',
            function ($rootScope, $scope, $mdSidenav, $state, Atto, ParseLinks, toastr, paginationConstants, Principal, FileSaver, $mdDialog, SEARCH_STATES, ACT_TYPES, AdvanceSearchService, ATTI_TABLE_TYPE) {
                var vm = this;
                vm.queryStates = SEARCH_STATES;
                vm.actTypes = ACT_TYPES;
                vm.basicSearchFormPath = 'app/components/attoTableList/basicSearchForm.html';
                // defaults
                vm.paging = vm.paging || {};
                vm.format = vm.format || 'expanded';
                vm.tableType = vm.tableType || 0;

                vm.isCommissioneAtti = false;
                vm.isCommissioneAttiEsame = false;
                vm.isCommissioneAttiEsaminati = false;

                switch (Number(vm.tableType)) {
                case ATTI_TABLE_TYPE.COMMISSIONE_ESAME:{
                    vm.isCommissioneAtti = true;
                    vm.isCommissioneAttiEsame = true;
                    break;
                }
                case ATTI_TABLE_TYPE.COMMISSIONE_ESAMINATI: {
                    vm.isCommissioneAtti = true;
                    vm.isCommissioneAttiEsaminati = true;
                    break;
                }
                default:
                    vm.isCommissioneAtti = false;
                }

                vm.getProponentiAttoString = getProponentiAttoString;

                vm.basicSearchType = vm.paging.type;
                vm.basicSearchState = vm.paging.state;
                vm.basicSearchTerm = vm.paging.term;
                vm.basicNumeroAtto = vm.paging.numeroAttoSearch;
                vm.basicSearchLegislatura = vm.paging.legislatura;
                vm.basicSearchNumeroLegge = vm.paging.numeroLegge;
                vm.basicSearchDataPromulgazione = getDataPromulgazioneDate(vm.paging.dataPromulgazione);

                vm.isAdvancedSearchOpen = isAdvancedSearchOpen;
                vm.isAdvancedSearchOpenState = isAdvancedSearchOpenState;
                vm.advancedSearch = advancedSearch;

                vm.attachmentsModal = "attachmentsModal";
                vm.uploadModal = "uploadModal";
                vm.isAuthenticated = Principal.isAuthenticated();
                vm.isAdmin = vm.isAuthenticated && Principal.hasAnyAuthority(['ROLE_ADMIN']);
                vm.isProtocollo = vm.isAuthenticated && Principal.hasAnyAuthority(['ROLE_PROTOCOLLO']);
                vm.isAula = vm.isAuthenticated && Principal.hasAnyAuthority(['ROLE_AULA']);
                vm.isCommissione = vm.isAuthenticated && Principal.hasAnyAuthority(['ROLE_COMMISSIONE']);
                vm.isSettoreAtti = vm.isAuthenticated && Principal.hasAnyAuthority(['ROLE_SETTORE']);
                vm.canEdit = vm.isAuthenticated;
                if (vm.canEdit && !vm.isAdmin) {
                    if (Principal.hasAnyAuthority(['ROLE_COMMISSIONE'])) {
                        Principal.identity().then(function (utente) {
                            vm.canEdit = utente.commissioneRuolo && utente.commissioneRuolo.nome.toString().toUpperCase() === 'DIPENDENTE';
                        });
                    } else {
                        vm.canEdit = vm.isProtocollo || Principal.hasAnyAuthority(['ROLE_SETTORE', 'ROLE_AULA', 'ROLE_EXTERNAL']);
                    }
                }

                vm.editDisabled = function (atto) {
                    if (vm.isAdmin || vm.isProtocollo) {
                        return false;
                    } else if (vm.isCommissione && atto.statoCorrente.statoAtto.name === 'APPROVATO' && ['PDRIS', 'MOZIONE', 'ODG'].includes(atto.tipoAtto)) {
                        return false;
                    } else if (vm.isAula && atto.statoCorrente.statoAtto.name === 'APPROVATO') {
                        return false;
                    } else if(vm.isSettoreAtti && atto.statoCorrente.statoAtto.name === 'TRASMISSIONE GIUNTA') {
                        return false;
                    } else if (vm.isStatoFinale(atto.statoCorrente.statoAtto)) {
                        if((atto.tipoAtto === "PEA" || atto.tipoAtto === "DEA") && (atto.statoCorrente.statoAtto.name === 'TRASMISSIONE GIUNTA' || atto.statoCorrente.statoAtto.name === 'APPROVATO')){
                            return !(vm.isCommissione || vm.isSettoreAtti);
                        }
                        return !(vm.isSettoreAtti || vm.isAula);
                    } else {
                        return false;
                    }
                };

                vm.transition = transition;
                vm.downloadArchive = downloadArchive;
                vm.panelStickyEvent = panelStickyEvent;

                // query stuff
                vm.predicate = vm.paging.predicate;
                vm.reverse = vm.paging.ascending;
                vm.itemsPerPage = paginationConstants.itemsPerPage;

                vm.loading = false;

                vm.legislature = [];
                Atto.queryLegislature({}, onSuccessLeg, onErrorLeg);

                function onSuccessLeg(data){
                    vm.legislature=data;
                    if(vm.legislature && vm.legislature.indexOf(11) === -1) {
                        vm.legislature.push(11);
                    }
                }
                function onErrorLeg(error){
                    console.log("Failure!", error);
                    toastr.error(error.data.message);
                }

                loadAll();

                function loadAll() {
                    vm.loading = true;

                    if (vm.isCommissioneAtti) {
                        Atto.queryCommissioniAtti({
                            page: vm.paging.page - 1,
                            sort: sort(),
                            size: vm.itemsPerPage,
                            type: vm.basicSearchType,
                            term: vm.basicSearchTerm,
                            legislaturaSearch: vm.basicSearchLegislatura === 'ALL' ? null : vm.paging.legislatura, //11
                            numeroAttoSearch: vm.basicNumeroAtto,
                            idCommissione: vm.paging.idCommissione,
                            tipoPaginaCommissione: vm.paging.tipoPaginaCommissione
                        }, onSuccess, onError);
                    }
                    else {
                        // Se e' aperta la ricerca avanzata si fa la query con quella
                        if (vm.isAdvancedSearchOpenState()) {
                            vm.advancedSearch(AdvanceSearchService.getLatestSearchParams());
                        } else {
                            // Altrimenti si usa la basic search
                            Atto.query({
                                page: vm.paging.page - 1,
                                sort: sort(),
                                size: vm.itemsPerPage,
                                state: vm.basicSearchState,
                                type: vm.basicSearchType,
                                term: vm.basicSearchTerm,
                                legislaturaSearch: vm.basicSearchLegislatura === 'ALL' ? null : vm.basicSearchLegislatura,
                                numeroAttoSearch: vm.basicNumeroAtto,
                                numeroLegge: vm.basicSearchNumeroLegge,
                                dataPromulgazione: getFormattedDate(vm.basicSearchDataPromulgazione)
                            }, onSuccess, onError);
                        }
                    }
                }

                function onSuccess(data, headers) {
                    vm.links = ParseLinks.parse(headers('link'));
                    vm.totalItems = headers('X-Total-Count');
                    vm.queryCount = vm.totalItems;
                    vm.attos = data;
                    vm.page = vm.paging.page;
                    vm.loading = false;

                }


                function onError(error) {
                    console.log("Failure!", error);
                    toastr.error(error.data.message);
                    vm.loading = false;
                }

                function sort() {
                    var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                    if (vm.predicate !== 'id') {
                        result.push('id');
                    }
                    return result;
                }

                /**
                 *
                 * @param {*} dataPromulgazioneStr date string format yyyy-MM-dd
                 */
                function getDataPromulgazioneDate(dataPromulgazioneStr) {
                    if (dataPromulgazioneStr && dataPromulgazioneStr.length > 0) {
                        return new Date(dataPromulgazioneStr);
                    }
                    return null;
                }

                function getFormattedDate(date) {
                    if (!date) {
                        return null;
                    }
                    var year = date.getFullYear();
                    var month = date.getMonth() + 1;
                    if (month < 10) {
                        month = '0' + month;
                    }
                    var day = date.getDate();
                    if (day < 10) {
                        day = '0' + day;
                    }
                    return year + '-' + month + '-' + day;
                }

                vm.clearDataPromulgazione = function() {
                    vm.basicSearchDataPromulgazione = null;
                    vm.transition(true);
                };

                function panelStickyEvent(event) {
                    $scope.$apply(function () {
                        vm.sticky = event && event.event && event.event === 'stick';
                    });
                }

                function transition(resetPage) {
                    if (resetPage) {
                        vm.page = 1;
                    }

                    if(vm.isCommissioneAtti) {
                        $state.transitionTo($state.$current, {
                            page: vm.page,
                            sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                            type: vm.basicSearchType,
                            term: vm.basicSearchTerm,
                            legislatura: vm.basicSearchLegislatura,
                            numeroAttoSearch: vm.basicNumeroAtto,
                            idCommissione: vm.paging.idCommissione,
                            tipoPaginaCommissione: vm.paging.tipoPaginaCommissione
                        });
                    }
                    else {
                        if(!vm.basicSearchLegislatura || vm.basicSearchLegislatura > 0 || vm.basicSearchLegislatura === 'ALL'){
                            $state.transitionTo($state.$current, {
                                page: vm.page,
                                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                                state: vm.basicSearchState,
                                type: vm.basicSearchType,
                                term: vm.basicSearchTerm,
                                legislatura: vm.basicSearchLegislatura,
                                numeroAttoSearch: vm.basicNumeroAtto,
                                numeroLegge: vm.basicSearchNumeroLegge,
                                dataPromulgazione: getFormattedDate(vm.basicSearchDataPromulgazione)
                            });

                        } else {
                            vm.basicSearchLegislatura = null;
                            toastr.error('Legislatura deve essere un intero!', {
                                timeOut: 60000,
                                extendedTimeOut: 0
                            });
                        }
                    }
                }

                function downloadArchive(attoId) {
                    if (!attoId)
                        return;
                    vm.loading = true;
                    Atto.getArchive(attoId).then(function (response) {
                        if (response && response.data) {
                            FileSaver.saveAs(new Blob([response.data], {}), 'Fascicolo-' + attoId + '.zip');
                        }
                        vm.loading = false;
                    }, function (error) {
                        console.log('Impossibile scaricare il fascicolo');
                        toastr.error('Impossibile scaricare il fascicolo', error.data.message);
                        vm.loading = false;
                    });

                }

                vm.setAttoIdForArchiveTree = function (attoId) {
                    $rootScope.attoIdForArchiveTree = attoId;
                    vm.attoIdForArchiveTree = attoId;
                    if (!$mdSidenav('right').isOpen()) {
                        $mdSidenav('right').open();
                    }
                    if ($mdSidenav('advanced-search-sidebar').isOpen()) {
                        $mdSidenav('advanced-search-sidebar').close();
                    }
                };

                vm.advancedSearchVisible = function () {
                    return $mdSidenav('advanced-search-sidebar').isOpen();
                };

                vm.deleteAtto = function (attoId) {
                    var confirm = $mdDialog.confirm()
                            .title('Eliminazione atto')
                            .textContent('Sicuro di voler eliminare questo atto?')
                            .ariaLabel('eliminazione atto')
                            .ok('Elimina')
                            .cancel('Annulla');

                    $mdDialog.show(confirm).then(function () {
                        Atto.delete({id: attoId}, function (result) {
                            toastr.success('OK', 'Atto eliminato correttamente');
                            $state.go($state.current, {}, {reload: true});
                        }, function (error) {
                            toastr.error(error);
                        });
                    });
                };

                vm.isStatoFinale = function (stato) {
                    return stato.finalState;
                };

                vm.toggleAdvancedSearch = function () {
                    vm.attoIdForArchiveTree = null;
                    if (!$mdSidenav('advanced-search-sidebar').isOpen()) {
                        $mdSidenav('advanced-search-sidebar').open();
                        AdvanceSearchService.setOpen();
                    } else {
                        $mdSidenav('advanced-search-sidebar').close();
                        AdvanceSearchService.setClosed();
                    }
                };


                function isAdvancedSearchOpen() {
                    return $mdSidenav('advanced-search-sidebar').isOpen();
                }

                function isAdvancedSearchOpenState() {
                    return AdvanceSearchService.isOpen();
                }

                function advancedSearch(searchParams) {
                    vm.loading = false;
                    Atto.advancedSearch({
                        searchParams: searchParams,
                        page: vm.paging.page - 1,
                        size: vm.itemsPerPage,
                        sort: sort()
                    }, onSuccess, onError);
                }

                function getProponentiAttoString(data){

                    if(data.proponente) {
                        var proponenti = data.proponente.proponenti;
                        var descrizioniAnagrafiche = [];

                        if(proponenti) {
                            for (var i = 0; i < proponenti.length; i++) {
                                var descrizione = proponenti[i].descrizione;
                                descrizioniAnagrafiche.push(descrizione);
                            }

                            var resultString = descrizioniAnagrafiche.join(', ');
                            return resultString;
                        } else {
                            return "";
                        }
                    } else if (data.proponentiText) {
                        var proponenti = data.proponentiText;
                        var descrizioniAnagrafiche = [];

                        if(proponenti) {
                            for (var i = 0; i < proponenti.length; i++) {
                                var descrizione = proponenti[i];
                                descrizioniAnagrafiche.push(descrizione);
                            }

                            var resultString = descrizioniAnagrafiche.join(', ');
                            return resultString;
                        } else {
                            return "";
                        }
                    }
                }


            }],
        controllerAs: 'vm',
        bindToController: true,
        templateUrl: 'app/components/attoTableList/attoTableList.html'
    };


});
