(function() {
    'use strict';

    angular
        .module('gestioneAttiApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('permissions', {
            parent: 'admin',
            url: '/permissions?page&sort',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'permissions.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/admin/permissions/permissions.html',
                    controller: 'PermissionsController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort)
                        };
                    }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('permissions');
                        $translatePartialLoader.addPart('home');
                        $translatePartialLoader.addPart('atto');
                        $translatePartialLoader.addPart('tipoAtto');
                        $translatePartialLoader.addPart('tipoProponente');
                        $translatePartialLoader.addPart('form');
                        return $translate.refresh();
                      
                    }]

            }
        })
        .state('permissions.new', {
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            resolve :{
                tipiAtto: ['ACT_TYPES',function(ACT_TYPES){
                    var array = [];
                    for(var i=0; i< ACT_TYPES.length; i++){
                        array[i] = ACT_TYPES[i].value
                    }
                        return array;
                    
                }]
            },
            onEnter: ['$mdDialog', 'ProfileService','tipiAtto', function ($mdDialog, ProfileService, tipiAtto) {
                    $mdDialog.show({
                        clickOutsideToClose: false,
                        fullscreen: true,
                        templateUrl: 'app/admin/permissions/permissions-dialog.html',
                        controller: 'PermissionsDialogController',
                        controllerAs: 'vm',
                        bindToController: true,
                        tipiAtto: tipiAtto,
                        locals: {
                            entity: {
                                id: null, tipoAtto: null, pubblicato: null
                            },
                            env: ProfileService.getProfileInfo()
                        }
                    });
                }]
        })
        .state('permissions.edit', {
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            resolve :{
                tipiAtto: ['ACT_TYPES',function(ACT_TYPES){
                    var array = [];
                    for(var i=0; i< ACT_TYPES.length; i++){
                        array[i] = ACT_TYPES[i].value
                    }
                    return array;
                    
                }]
            },
            onEnter: ['$stateParams', '$mdDialog', 'Atto', 'ProfileService','tipiAtto', function ($stateParams, $mdDialog, Atto, ProfileService, tipiAtto) {
                    $mdDialog.show({
                        clickOutsideToClose: false,
                        fullscreen: true,
                        templateUrl: 'app/admin/permissions/permissions-dialog.html',
                        controller: 'PermissionsDialogController',
                        controllerAs: 'vm',
                        tipiAtto: tipiAtto,
                        bindToController: true,
                        locals: {
                            
                            entity: Atto.getTipoAtto({id: $stateParams.id}),                                      
                            env: ProfileService.getProfileInfo()
                        }
                    });
                }]
        })
        .state('permissions.delete', {
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/admin/permissions/permissions-delete-dialog.html',
                        controller: 'PermissionsDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Atto', function (Atto) {
                                    return Atto.getTipoAtto({id: $stateParams.id});
                                }]
                        }
                    }).result.then(function () {
                        $state.go('permissions', null, {reload: true});
                    }, function () {
                        $state.go('^');
                    });
                }]
        });
    }
})();
