(function () {
    'use strict';
    angular
            .module('gestioneAttiApp')
            .factory('AnagraficaProtocollo', AnagraficaProtocollo);

    AnagraficaProtocollo.$inject = ['$resource'];

    function AnagraficaProtocollo($resource) {
        var resourceUrl = 'api/:public/anagrafiche-protocollo/:id/:search/:protocolAnagrafic/:offices/:userOperatingOffices';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: true,
                params: {public: 'public'}
            },
            'get': {
                method: 'GET',
                params: {public: 'public'},
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'delete': {method: 'DELETE'},
            'search': {method: 'GET', params: {search: 'search', public: 'public'}, isArray: true},
            'listOffices': {method: 'GET', params: {offices: 'offices'}, isArray: true},
            'listFromProtocol': {method: 'GET', params: {protocolAnagrafic: 'protocolAnagrafic'}, isArray: true},
            'userOperatingOffices': {method: 'GET', params: {userOperatingOffices: 'user-operating-offices'}}
        });
    }
})();
