angular.module('angularSlideables', [])
        .directive('slideable', function () {
            return {
                restrict: 'C',
                compile: function (element, attr) {
                    // wrap tag
                    var contents = element.html();
                    element.html('<div class="slideable_content" style="margin:0 !important; padding: 20px;" >' + contents + '</div>');

                    return function postLink(scope, element, attrs) {
                        // default properties
                        attrs.expand = (!attrs.expand) ? false : attrs.expand === "true";
                        attrs.duration = (!attrs.duration) ? '0.2s' : attrs.duration;
                        attrs.easing = (!attrs.easing) ? 'ease-out' : attrs.easing;
                        element.css({
                            'overflow': 'hidden',
                            'transitionProperty': 'height',
                            'transitionDuration': attrs.duration,
                            'transitionTimingFunction': attrs.easing
                        });
                        if (!attrs.expand) {
                            element.css({
                                'height': '0px'
                            });
                        }
                    };
                }
            };
        })
        .directive('slideToggle', function () {
            return {
                restrict: 'A',
                link: function (scope, element, attrs) {
                    var target, content;

                    attrs.expand = (!attrs.expand) ? false : attrs.expand === "true";
                    attrs.expanded = (!attrs.expanded) ? attrs.expand : false;

//                    if (attrs.expanded) {
//                        var slideable = document.querySelector(attrs.slideToggle + ' .slideable_content');
//                        if (!slideable.style.height || slideable.style.height === "") {
//                            document.querySelector(attrs.slideToggle).style.height = slideable.clientHeight + 'px';
//                        }
//                    }

                    element.bind('click', function () {
                        if (!target)
                            target = document.querySelector(attrs.slideToggle);
                        if (!content)
                            content = target.querySelector('.slideable_content');

                        if (!attrs.expanded) {
                            target.style.height = content.clientHeight + 'px';
                        } else {
                            target.style.height = '0px';
                        }
                        attrs.expanded = !attrs.expanded;
                    });
                }
            }
        });
