angular.module('gestioneAttiApp').directive('sectionLinks', function () {
    return {
        restrict: "E",
        templateUrl: 'app/components/sectionLinks/sectionLinks.directive.html',
        scope: {
            defaultLinks: '=?',
            editable: '=',
            links: "=",
            section: '=',
            showLinks: '=?',
            showToggler: '=?'
        },
        transclude: false,
        controllerAs: 'vm',
        bindToController: true,
        controller: [ '$translate',
            function ($translate) {
                var vm = this;

                var DEFAULT_LINKS_MAP = {
                    "burt": $translate.instant('sectionLinks.default.burt'),
                    "regulatoryCollection": $translate.instant('sectionLinks.default.regulatoryCollection'),
                    "bancaDatiDelConsiglio": $translate.instant('sectionLinks.default.bancaDatiDelConsiglio'),
                    "deliberaDefinitiva": $translate.instant('sectionLinks.default.deliberaDefinitiva')
                };
                vm.urlPattern = "(\b(https?|ftp|file)://)?[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]";
                vm.showLinks = vm.showLinks || false;
                vm.includeDefault = (vm.defaultLinks.length) ? true : false;

                var default_links = addDefaultLinksByParameter(vm.defaultLinks);

                vm.canAdd = canAdd;
                vm.addLink = addLink;
                vm.removeItem = removeItem;
                vm.testLink = testLink;

                function addDefaultLinksByParameter(defaultLinks){
                    var links = [];
                    for (var i=0; i < defaultLinks.length; i++) {
                        if (DEFAULT_LINKS_MAP[defaultLinks[i]]){
                            links.push(new Link(DEFAULT_LINKS_MAP[defaultLinks[i]], "", "0", true))
                        }
                    }
                    return links;
                }

                function Link (descr, url, priority, isDefault){
                    this.description = descr;
                    this.url = url;
                    this.ordine = priority;
                    this.isDefault = isDefault || false;
                }

                function addLink(obj){
                    if (!(obj instanceof Link))
                        obj = new Link("", "", "", false);

                    for (var i=0; i < vm.links.length; i++) {
                        var link = vm.links[i];
                        if (obj.description === link.description) return; // Duplicate not accepted
                    }

                    vm.links.push(obj);
                }

                function canAdd() {
                    for (var i=0; i < vm.links.length; i++){
                        var link = vm.links[i];

                        if (link.isDefault) continue;

                        if (!link.url || link.url.length <= 0
                                || !link.description || link.description.length <= 0)
                            return false;
                    }
                    return true;
                }

                function addDefaultLinks(defLink, index, array){
                    if (!defLink instanceof Link)
                        throw "Default link must be of type Link";

                    for (var i=0; i < vm.links.length; i++) {
                        var link = vm.links[i];
                        if (defLink.description === link.description) return; // Duplicate not accepted
                    }

                    vm.links.unshift(defLink);
                }

                function removeItem(index) {
                    var l = vm.links.length;

                    if (!vm.links[index].isDefault) {
                        if (l === 1) {
                            vm.links[index].description = "";
                            vm.links[index].url = "";
                        } else if (l > 1) {
                            vm.links.splice(index, 1);
                        }
                    } else {
                        vm.links[index].url = "";
                    }
                }

                function testLink(url) {
                    if(url.length)
                        window.open(httpizerURL(url), '_blank');
                }

                function clearLinks () {
                    for (var i=0; i < vm.links.length; i++) {
                        var link = vm.links[i];
                        if (!link.isDefault && !link.description && !link.url)
                            vm.removeItem(i); // Remove empty items
                        else
                            link.url = httpizerURL(link.url); // Add http protocol if absent
                    }
                }

                function httpizerURL(url) {
                    if (url && url.length > 0 && url.indexOf("http") < 0) {
                        return "http://".concat(url);
                    } else {
                        return url;
                    }
                }

                (function init(){
                    clearLinks(); // Dovrebbe essere fatto in validazione
                    if (vm.includeDefault && vm.editable) {
                        default_links.forEach(addDefaultLinks);
                    }
                    if (!vm.includeDefault && !vm.links.length) {
                        vm.links.push(new Link("", "", "", false));
                    }
                })()
            }]
    };
});
