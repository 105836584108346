(function () {
    'use strict';

    angular
            .module('gestioneAttiApp')
            .controller('AttoInsertController', AttoInsertController);

    AttoInsertController.$inject = ['toastr', 'previousState', 'Atto', 'anagrafiche', '$state', 'direzioni', 'ValidatorFactory', 'ACT_TYPES'];

    function AttoInsertController(toastr, previousState, Atto, anagrafiche, $state, direzioni, ValidatorFactory, ACT_TYPES) {
        var vm = this;
        vm.actTypes = ACT_TYPES;
        vm.assessori = [];
        vm.consiglieri = [];
        vm.direzioni = direzioni;
        vm.isInsert = true;
        vm.previousState = previousState;
        vm.showConsiglieri = false;

        // default
        vm.atto = {
            legislatura: 10,
            tipoAtto: "",
            proponente: {
                proponenti: []
            }
        };

        // If it is an object, it's already a known chip
        vm.sezioniMap = {
            PRESENTATION: {
                visible: true,
                editable: true,
                insert: true
            }
        };

        angular.forEach(anagrafiche, function (anagrafica) {
            if (anagrafica.tipoProponente === "GR") {
                vm.assessori.push(anagrafica);
            } else {
                vm.consiglieri.push(anagrafica);
            }
        });

        vm.searchFirmatari = function (searchText) {
            var lista = null;
            if (vm.atto.tipoProponente === 'GR') {
                lista = vm.assessori;
            } else {
                lista = vm.consiglieri;
            }
            return searchText ? lista.filter(createFilterFor(searchText)) : [];
        };

        vm.searchAssessori = function (searchText) {
            return searchText ? vm.assessori.filter(createFilterFor(searchText)) : [];
        };

        vm.searchConsiglieri = function (searchText) {
            return searchText ? vm.consiglieri.filter(createFilterFor(searchText)) : [];
        };

        vm.addPersonToList = function (chip) {
            // Otherwise, create a new one
            if (angular.isObject(chip)) {
                return chip;
            }
            return {descrizione: chip};

        };

        function createFilterFor(query) {
            var lowercaseQuery = angular.lowercase(query);

            return function filterFn(firmatario) {
                var codice = firmatario.codice.toLowerCase();
                var descrizione = firmatario.descrizione.toLowerCase();
                return (codice.indexOf(lowercaseQuery) >= 0 || descrizione.indexOf(lowercaseQuery) >= 0);
            };
        }

        vm.createAtto = function () {
            vm.Validator = ValidatorFactory.getValidator(vm.atto.tipoAtto);
            vm.validating = true;
            var outcome = vm.Validator.validatePresentation([], vm.atto);
            vm.invalidFields = outcome.invalidFields;
            if (outcome.valid) {
                vm.validating = false;
                Atto.save({}, vm.atto, function (result) {
                    toastr.success('Atto creato con successo');
                    vm.atto.id = result.id;
                    Atto.updateProponente({id: vm.atto.id, tipoProponente: vm.atto.tipoProponente}, vm.atto.proponente, function (result) {
                        $state.go('atto-edit', {id: vm.atto.id})
                    }, function (error) {
                        toastr.error('Impossibile effettuare salvataggio atto proponente', 'Errore!', error);
                    });
                }, function (error) {
                    toastr.error('Impossibile creare l\'atto', 'Errore!', error);
                });
            } else {
                toastr.error('Ricontrollare i dati inseriti: ' + outcome.message, "Errore di Validazione!", {
                    timeOut: 60000,
                    extendedTimeOut: 0
                });
            }
        };

        vm.isInvalidField = function (fieldName) {
            if (vm.invalidFields && vm.invalidFields.length > 0) {
                return vm.exists(fieldName, vm.invalidFields);
            }
            return false;
        };

        vm.exists = function (item, list) {
            if (!list || !angular.isArray(list)) {
                list = [];
            }
            return list.indexOf(item) > -1;
        };

        vm.tipoAttoChange = function () {
            if (vm.atto.tipoAtto === 'PDR') {
                vm.tipiProponente = ['GR', 'CR', 'COMMISSIONE'];
            } else if (vm.atto.tipoAtto === 'ODG' || vm.atto.tipoAtto === 'PDRIS') {
                vm.tipiProponente = ['CR', 'COMMISSIONE'];
            } else if (vm.atto.tipoAtto === 'MOZIONE') {
                vm.tipiProponente = ['CR'];
            }
            else if (vm.atto.tipoAtto === 'RINT') {
                vm.tipiProponente = ['CR', 'UP'];
            } else if(vm.atto.tipoAtto === 'REL') {
                vm.tipiProponente = ['ORGANISMI'];
            } else {
                vm.tipiProponente = ['GR', 'CR', 'UP'];
            }
        };

        vm.tipoProponenteChange = function () {
            vm.atto.proponente.proponenti = [];
            if (vm.atto.tipoProponente === 'GR') {
                vm.showConsiglieri = false;
            } else {
                vm.showConsiglieri = true;
            }
        };
    }
})();
