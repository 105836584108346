angular.module('gestioneAttiApp').directive('inammissibileBlock', function () {
    return {
        restrict: "E",
        templateUrl: 'app/components/inammissibileBlock/inammissibileBlock.html',
        scope: {
            atto: '=',
            statoAttoCorrente: '=',
            inammissibile: '=',
            isProtocollo: '=',
            isSettoreAtti: '=',
            utente: "=",
            docTypes: "=",
            changeDocumentTypeCallback: "=",
            changeDocumentVisibilityCallback: "=",
            publicView: "=",
            viewMode: '='
            
        },
        transclude: false,
        controllerAs: 'vm',
        bindToController: true,
        controller: ['Atto', 'User', 'PdlService', 'Documento', '$location','$anchorScroll', '$timeout', function (Atto, User, PdlService, Documento, $location, $anchorScroll, $timeout) {
                var vm = this;
                // vm.inammissibile = {visible: true};

                vm.sezioni = {
                    INAMMISSIBILE: {
                        form: 'app/components/inammissibileBlock/inammissibile.html'
                    }
                };

                vm.stato = {
                    inammissibile: {
                        isCollapsed: vm.statoAttoCorrente === 'INAMMISSIBILE' || vm.viewMode
                    }
                };

                vm.getSezioniFromMap = PdlService.getSezioniFromMap;
                vm.invalidFields = vm.invalidFields || [];
                //vm.utente = vm.currentUser;
            }]
    };
});