angular.module('gestioneAttiApp').directive('commissionObservation', function () {
    return {
        restrict: 'E',
        templateUrl: 'app/components/commissionObservation/commissionObservation.directive.html',
        scope: {
            title: '@',
            metadata: '=',
            subjects: '=',
            actType: '=',
            editable: '=',
            invalidFields: '=?',
            validating: '=?',
            postponementNumber: '=?',

        },
        transclude: false,
        controllerAs: 'vm',
        bindToController: true,
        controller: [
            function () {
                var vm = this;

                vm.invalidFields = vm.invalidFields || [];
                vm.subjects = vm.subjects || [];
                vm.postponementSuffix = '';
                if (vm.postponementNumber && vm.postponementNumber > 0) {
                    vm.postponementSuffix = '_' + vm.postponementNumber;
                }

                vm.isInvalidField = function (fieldName, subjectId) {
                    return vm.invalidFields.indexOf(fieldName + vm.postponementSuffix + '_' + subjectId) > -1;
                };

                vm.clearRadio = function (metadataName) {
                    if (vm.editable) {
                        vm.metadata[metadataName][vm.subjectId] = null;
                    }
                };

                vm.subjectId = "";
                if (vm.subjects.length === 1) {
                    vm.subjectId = vm.subjects[0].id;
                } else {
                    vm.subjects.sort();
                    angular.forEach(vm.subjects, function (subject) {
                        vm.subjectId = vm.subjectId + "_" + subject.id;
                    });
                }
                
            }
        ]
    }
})