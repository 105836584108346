(function () {
    'use strict';

    angular
            .module('gestioneAttiApp')
            .controller('AnagraficaProtocolloDeleteController', AnagraficaProtocolloDeleteController);

    AnagraficaProtocolloDeleteController.$inject = ['$uibModalInstance', 'entity', 'AnagraficaProtocollo'];

    function AnagraficaProtocolloDeleteController($uibModalInstance, entity, AnagraficaProtocollo) {
        var vm = this;

        vm.anagraficaProtocollo = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete(id) {
            AnagraficaProtocollo.delete({id: id},
                    function () {
                        $uibModalInstance.close(true);
                    });
        }
    }
})();
