(function () {

    'use strict';

    angular.module('gestioneAttiApp').service('OdgValidator', ['PdlService','MinorBasicValidator', function (PdlService, MinorBasicValidator) {

        this.PdlService = PdlService;
        this.validatePresentation = MinorBasicValidator.validatePresentation;

        this.validate = function (state, numeroRinvio, utente, userRolesMap, idPrimaCommissione, action, primaryCommission) {
            var invalidFields = [];

            switch (state) {
                case "PRESENTATO":
                    return validateSectorSections(invalidFields, idPrimaCommissione);
                case "COMMISSIONE":
                    return MinorBasicValidator.validateCommissionSections(invalidFields, utente, userRolesMap,
                        numeroRinvio, idPrimaCommissione, action && action.name === 'assignToCourtRoom', primaryCommission);
                case "AULA":
                    return MinorBasicValidator.validateCourtroomSections(invalidFields, numeroRinvio);
                case "APPROVATO":
                    return MinorBasicValidator.validatePostCourtroomSections(invalidFields, numeroRinvio);
                default:
                    console.error("STATO SCONOSCIUTO: " + state);
                    return {valid: false, message: "STATO SCONOSCIUTO: " + state};
            }


            function validateSectorSections(invalidFields){
                var result = MinorBasicValidator.validateSectorSections(invalidFields);
                // Richiesta Caldini via mail 21/10/19
/*                 if(result.valid){ 
                    result = validateAttachedActs(invalidFields);
                } */
                return result;
                
            }

            function validateAttachedActs(invalidFields) {
                var valid = true;
                var message = '<ul>';
                var sezioneAttachedActs = PdlService.getSezioniFromMap('ATTACHED_ACTS', 0);
                if(!sezioneAttachedActs.metadati.unifiedTexts || sezioneAttachedActs.metadati.unifiedTexts.length === 0 ) {
                    message += '<li><i>Atti Collegati</i> deve contenere almeno un atto collegato</li>';
                    invalidFields.push('attiCollegati');
                    valid = false;
                }
                message += '</ul>';
                return {valid: valid, message: message, invalidFields: invalidFields};
            }


            function validateCommissionSections(invalidFields, utente, userRolesMap, numeroRinvio, idPrimaCommissione, action, primaryCommission) {
                var result = null;
                for (var i = 0; i <= numeroRinvio; i++) {
                    result = validateCommissionEmendments(invalidFields, i, primaryCommission);//se sono referente
                    if (result.valid) {//Vedere se siamo in aula diretta!
                        //result = validateCommissionOpinions(invalidFields, utente, userRolesMap, i, idPrimaCommissione);
                        if (result.valid) {
                            result = validateCommissionMessage(invalidFields, i, primaryCommission);//se sono referente
                        } else {
                            break;
                        }

                    } else {
                        break;
                    }

                }
                return result;
            }
            // }
        };


    }]);
})();
