(function() {
    'use strict';

    angular
        .module('gestioneAttiApp')
        .controller('UserManagementDetailController', UserManagementDetailController);

    UserManagementDetailController.$inject = ['$stateParams', 'User', 'Commissione', 'CommissioneRuolo', 'SoggettoEsterno', 'Settore'];

    function UserManagementDetailController($stateParams, User, Commissione, CommissioneRuolo, SoggettoEsterno, Settore) {
        var vm = this;

        vm.load = load;
        vm.user = {};
        vm.settori = [];
        vm.commissioni = [];
        vm.commissioniRuoli = [];
        vm.soggettiEsterni = [];
        //vm.commissioneRuoloDesc = "";

        vm.load($stateParams.login);

        function load(login) {
            User.get({login: login}, function(result) {
                vm.user = result;
                
                angular.forEach(vm.user.settori, function(sectorId) {
                    Settore.get({id: sectorId}, {}, function(result) {
                        vm.settori.push(result.nome);
                    });
                });
                
                angular.forEach(vm.user.commissioni, function(commissionId) {
                    Commissione.get({id: commissionId}, {}, function(result) {
                        vm.commissioni.push(result.nome);
                    });
                });
                
                angular.forEach(vm.user.commissioniRuoli, function(commissioniRuoliId) {
                	CommissioneRuolo.get({id: commissioniRuoliId}, {}, function(result) {
                		vm.commissioniRuoli.push(result.nome);
                	});
                });
                
                angular.forEach(vm.user.soggettiEsterni, function(soggettoId) {
                    SoggettoEsterno.get({id: soggettoId}, {}, function(result) {
                        vm.soggettiEsterni.push(result.nome);
                    });
                });
                
                //CommissioneRuolo.get({'id':vm.user.commissioneRuolo}, {}, function( data ) {
                //    console.log(data);
                //    vm.commissioneRuoloDesc=data.nome; 
                //});
                
            });
        }
        
    }
})();
