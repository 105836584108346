/**
 * Created by Michele Romani on 23/06/2017.
 */
angular.module('gestioneAttiApp').directive('notificationsList', function () {
    return {
        restrict: "E",
        scope: {
            idAtto: '='
        },
        transclude: false,
        controllerAs: 'vm',
        bindToController: true,
        controller: ["$scope", 'NotificationsService', 'Principal', 'Commissione',
            function ($scope, NotificationsService, Principal, Commissione) {
                var vm = this;
                vm.notifications = [];
                vm.commissioni = [];

                Commissione.query({}, function (commissioni) {
                    vm.commissioni = commissioni;
                });

                vm.loadNotifications = loadNotifications;
                vm.markAsRead = markAsRead;
                vm.search = search;

                vm.showOnlyUnreadNotifications = false;
                vm.idCommissioniReferenti = [];
                vm.openNotification = null;

                vm.showCommissioniReferentiSearch = Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_COMMISSIONE']);

                /* Load all notifications from db*/
                function loadNotifications() {
                    vm.notifications = [];
                    NotificationsService.getAllNotifications({
                        idAtto: null,
                        onlyUnread: vm.showOnlyUnreadNotifications,
                        idCommissioniReferenti: vm.idCommissioniReferenti.length ? JSON.stringify(vm.idCommissioniReferenti) : null
                    }, function (data) {
                        vm.notifications = data || [];
                        calculateUnreadClass();
                    }, function (error) {
                        console.error("Error: ", error);
                    });

                }

                function search(idCommissione) {
                    var idx = vm.idCommissioniReferenti.indexOf(idCommissione);
                    if (idx > -1) {
                        vm.idCommissioniReferenti.splice(idx, 1);
                    } else {
                        vm.idCommissioniReferenti.push(idCommissione);
                    }
                    loadNotifications();
                }

                /* Mark target notification as read*/
                function markAsRead(notification) {
                    Principal.identity().then(function (user) {
                        var isUnread = notification.utentiLetto.indexOf(user.login) < 0;
                        if (isUnread) {
                            NotificationsService.markAsRead({id: notification.id}).$promise.then(function (payload) {
                                notification.utentiLetto.push(user.login);
                                notification.isUnread = false;
                                if (vm.showOnlyUnreadNotifications) {
                                    vm.notifications.splice(vm.notifications.indexOf(notification));
                                }
                            });
                        }
                    });
                }

                /* Init classes to show unread notifications and to initialize css openin animation*/
                function calculateUnreadClass() {
                    Principal.identity().then(function (user) {
                        angular.forEach(vm.notifications, function (notification) {
                            var isUnread = notification.utentiLetto.indexOf(user.login) < 0;
                            notification.isUnread = isUnread;
                            notification.notificationOpen = false;
                        })
                    });
                }

                loadNotifications();

            }],
        templateUrl: 'app/components/notifications/notifications.html'
    }


});
