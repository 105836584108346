(function () {

    'use strict';

    var ArchiveTreeService = ['toastr', '$mdDialog', '$translate',
        function (toastr, $mdDialog, $translate) {
            var service = this;
            var copyLinkMsg = $translate.instant('archiveTree.copiedMsg');
            
            service.getLink = function(treeLink) {
                $mdDialog.show({
                    clickOutsideToClose: false,
                    templateUrl: 'app/components/archiveTree/get-link-modal.html',
                    controller: ['$scope', '$mdDialog',
                        function ($scope, $mdDialog) {
                            $scope.treeLink = treeLink;

                            $scope.cancel = function () {
                                $mdDialog.cancel();
                            };

                            $scope.copyLink = function () {
                                var inputElement = document.getElementById('tree-link');
                                inputElement.select();
                                document.execCommand("Copy");
                                toastr.success("Ok", copyLinkMsg);
                                $mdDialog.cancel();
                            };
                        }],
                    locals: {
                    }
                });
            }

        }];

    angular.module('gestioneAttiApp').service('ArchiveTreeService', ArchiveTreeService);

})();
