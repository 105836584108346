(function () {
    'use strict';

    angular
            .module('gestioneAttiApp')
            .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
                .state('anagrafica-protocollo', {
                    parent: 'entity',
                    url: '/anagrafica-protocollo?term&page&protocolSystem',
                    data: {
                        authorities: ['ROLE_ADMIN'],
                        pageTitle: 'gestioneAttiApp.anagraficaProtocollo.home.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/entities/anagrafica-protocollo/anagrafica-protocollos.html',
                            controller: 'AnagraficaProtocolloController',
                            controllerAs: 'vm'
                        }
                    },
                    params: {
                        page: {
                            value: '1',
                            squash: true
                        },
                        protocolSystem : {
                            value: '',
                            squash: true
                        },
                        term : {
                            value: ''
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('anagrafica-protocollo');
                                $translatePartialLoader.addPart('global');
                                return $translate.refresh();
                            }],
                        pagingParams: ['PaginationUtil', '$stateParams', function (PaginationUtil, $stateParams) {
                                return {
                                    page: PaginationUtil.parsePage($stateParams.page),
                                };
                            }]
                    }
                })
                .state('anagrafica-protocollo-detail', {
                    parent: 'anagrafica-protocollo',
                    url: '/anagrafica-protocollo/{id}',
                    data: {
                        authorities: ['ROLE_ADMIN'],
                        pageTitle: 'gestioneAttiApp.anagraficaProtocollo.detail.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/entities/anagrafica-protocollo/anagrafica-protocollo-detail.html',
                            controller: 'AnagraficaProtocolloDetailController',
                            controllerAs: 'vm'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('anagrafica-protocollo');
                                return $translate.refresh();
                            }],
                        entity: ['$stateParams', 'AnagraficaProtocollo', function ($stateParams, AnagraficaProtocollo) {
                                return AnagraficaProtocollo.get({id: $stateParams.id}).$promise;
                            }],
                        previousState: ["$state", function ($state) {
                                var currentStateData = {
                                    name: $state.current.name || 'anagrafica-protocollo',
                                    params: $state.params,
                                    url: $state.href($state.current.name, $state.params)
                                };
                                return currentStateData;
                            }]
                    }
                })
                .state('anagrafica-protocollo-detail.edit', {
                    parent: 'anagrafica-protocollo-detail',
                    url: '/detail/edit',
                    data: {
                        authorities: ['ROLE_ADMIN']
                    },
                    onEnter: ['AnagraficaProtocollo', '$stateParams', '$mdDialog', function (AnagraficaProtocollo, $stateParams, $mdDialog) {
                            $mdDialog.show({
                                clickOutsideToClose: false,
                                size: 'lg',
                                templateUrl: 'app/entities/anagrafica-protocollo/anagrafica-protocollo-dialog.html',
                                controller: 'AnagraficaProtocolloDialogController',
                                controllerAs: 'vm',
                                bindToController: true,
                                locals: {
                                    entity: AnagraficaProtocollo.get({id: $stateParams.id}).$promise
                                }
                            });
                        }]
                })
                .state('anagrafica-protocollo.new', {
                    parent: 'anagrafica-protocollo',
                    url: '/new',
                    data: {
                        authorities: ['ROLE_ADMIN']
                    },
                    onEnter: ['$mdDialog', function ($mdDialog) {
                            $mdDialog.show({
                                clickOutsideToClose: false,
                                fullscreen: true,
                                templateUrl: 'app/entities/anagrafica-protocollo/anagrafica-protocollo-dialog.html',
                                controller: 'AnagraficaProtocolloDialogController',
                                controllerAs: 'vm',
                                bindToController: true,
                                locals: {
                                    entity: {
                                        codice: null,
                                        descrizione: null,
                                        id: null,
                                        anagraficaProtocollo: null
                                    }
                                }
                            });
                        }]
                })
                .state('anagrafica-protocollo.edit', {
                    parent: 'anagrafica-protocollo',
                    url: '/{id}/edit',
                    data: {
                        authorities: ['ROLE_ADMIN']
                    },
                    onEnter: ['$stateParams', '$mdDialog', 'AnagraficaProtocollo', function ($stateParams, $mdDialog, AnagraficaProtocollo) {
                            $mdDialog.show({
                                clickOutsideToClose: false,
                                fullscreen: true,
                                templateUrl: 'app/entities/anagrafica-protocollo/anagrafica-protocollo-dialog.html',
                                controller: 'AnagraficaProtocolloDialogController',
                                controllerAs: 'vm',
                                bindToController: true,
                                locals: {
                                    entity: AnagraficaProtocollo.get({id: $stateParams.id}).$promise
                                }
                            });
                        }]
                })
                .state('anagrafica-protocollo.delete', {
                    parent: 'anagrafica-protocollo',
                    url: '/{id}/delete',
                    data: {
                        authorities: ['ROLE_ADMIN']
                    },
                    onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                            $uibModal.open({
                                templateUrl: 'app/entities/anagrafica-protocollo/anagrafica-protocollo-delete-dialog.html',
                                controller: 'AnagraficaProtocolloDeleteController',
                                controllerAs: 'vm',
                                size: 'md',
                                resolve: {
                                    entity: ['AnagraficaProtocollo', function (AnagraficaProtocollo) {
                                            return AnagraficaProtocollo.get({id: $stateParams.id}).$promise;
                                        }]
                                }
                            }).result.then(function () {
                                $state.go('anagrafica-protocollo', null, {reload: 'anagrafica-protocollo'});
                            }, function () {
                                $state.go('^');
                            });
                        }]
                });
    }

})();
