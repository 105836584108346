(function () {

    'use strict';

    angular.module('gestioneAttiApp').service('RintValidator', ['BasicValidator', '$location','$anchorScroll', 'PdlService', function (BasicValidator, $location, $anchorScroll, PdlService) {

        this.validatePresentation = BasicValidator.validatePresentation;

        this.validate = function (state, numeroRinvio, utente, userRolesMap, idPrimaCommissione, action, primaryCommission) {
            var invalidFields = [];

            if (userRolesMap.isAdmin) {

                var result = validateSectorSections(invalidFields, idPrimaCommissione);

                if (result.valid) {
                    if (!BasicValidator.PdlService.sezioniMap.ASSIGNMENT_LETTER.metadati.aulaDiretta) {
                        result = validateCommissionSections(invalidFields, utente, userRolesMap, numeroRinvio, idPrimaCommissione, primaryCommission);
                    }
                    if (result.valid) {
                        result = BasicValidator.validateCourtroomSections(invalidFields, numeroRinvio);
                    }
                }
                return result;
            } else {
                switch (state) {
                    case "PRESENTATO":
                        return validateSectorSections(invalidFields, idPrimaCommissione);
                    case "COMMISSIONE":
                        return validateCommissionSections(invalidFields, utente, userRolesMap, numeroRinvio, idPrimaCommissione, primaryCommission);
                    case "AULA":
                        return BasicValidator.validateCourtroomSections(invalidFields, numeroRinvio);
                    case "APPROVATO":
                        return {valid: true};
                    default:
                        console.error("STATO SCONOSCIUTO: " + state);
                        return {valid: false, message: "STATO SCONOSCIUTO: " + state, invalidFields: invalidFields};
                }
            }
        };

        function validateSectorSections(invalidFields, idPrimaCommissione) {
            var result = validateAssignmentLetter(invalidFields, idPrimaCommissione);

            var openListTagMessage = result.message.slice(0, -5);
            result.message = openListTagMessage;

            result = validateAssignmentLetter(result.invalidFields, idPrimaCommissione, result.valid, result.message);

            return result;
        }

        function validateAssignmentLetter(invalidFields, idPrimaCommissione, valid, message) {
          var metadata = BasicValidator.PdlService.sezioniMap.ASSIGNMENT_LETTER.metadati;

          var message = '<ul>';

          if (typeof (metadata.aulaDiretta) === 'undefined' || metadata.aulaDiretta === null) {
              message += '<li><i>Direttamente in Aula</i> è un campo obbligatorio</li>';
              invalidFields.push('aulaDiretta');
          } else if (metadata.aulaDiretta) {
/*                     if (!metadata.dataSedutaUp) {
                  message += '<li><i>Data Seduta UP</i> è un campo obbligatorio</li>';
                  invalidFields.push('dataSedutaUp');
              } */
          } else {
              if (!metadata.dataAssegnazione) {
                  message += '<li><i>Data assegnazione</i> è un campo obbligatorio</li>';
                  invalidFields.push('dataAssegnazione');
              }
              if (!metadata.parereReferente || !metadata.parereReferente.length) {
                  message += '<li><i>Parere Referente</i> è un campo obbligatorio</li>';
                  invalidFields.push('parereReferente');
              } else if (metadata.parereReferente.indexOf(idPrimaCommissione) > -1 && metadata.parereIstituzionale) {
                  message += '<li><i>Parere Istituzionale</i> non può essere impostato a Sì se la Prima Commissione è stata selezionata in Parere Referente</li>';
                  invalidFields.push('parereIstituzionale');
              }
          }

          message += '</ul>';

          var valid = invalidFields.length === 0;
          if (!valid) {
              $location.hash('assignmentLetter');
              $anchorScroll();
              PdlService.sezioniMap.ASSIGNMENT_LETTER.visible = true;
          }

          return {valid: valid, message: message, invalidFields: invalidFields};
        }

        function validateCommissionSections(invalidFields, utente, userRolesMap, numeroRinvio, idPrimaCommissione, primaryCommission) {
            var result = null;
            for (var i = 0; i <= numeroRinvio; i++) {
                result = BasicValidator.validateLegitimacy(invalidFields, userRolesMap, i);
                if (result.valid) {
                    result = BasicValidator.validateCommissionEmendments(invalidFields, i, primaryCommission);
                    if (result.valid) {
                        //result = BasicValidator.validateCommissionOpinions(invalidFields, utente, userRolesMap, i, idPrimaCommissione);
                        if (result.valid) {
                            result = BasicValidator.validateFinancialCoverage(invalidFields, i, primaryCommission);
                            if (result.valid) {
                                result = BasicValidator.validateCommissionMessage(invalidFields, i, primaryCommission);
                            } else {
                                break;
                            }
                        } else {
                            break;
                        }
                    } else {
                        break;
                    }
                } else {
                    break;
                }
            }
            return result;
        }
    }]);
})();
