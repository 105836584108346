(function () {

    'use strict';

    var AdvanceSearchService = ['Atto', 'Principal', 'User', 'toastr',
        function (Atto, Principal, User, toastr) {
            var service = this;
            var searchParams;

            service.isAdvanceSearchOpen = false;
            
            service.setOpen = function() {
                service.isAdvanceSearchOpen = true;
            }

            service.setClosed = function() {
                service.isAdvanceSearchOpen = false;
            }

            service.isOpen = function() {
                return service.isAdvanceSearchOpen;
            }

            service.saveLatestSearchParams  = function(searchParams) {
                service.searchParams = searchParams;
            }

            service.getLatestSearchParams = function() {
                return service.searchParams;
            }
        }];

    angular.module('gestioneAttiApp').service('AdvanceSearchService', AdvanceSearchService);

})();
