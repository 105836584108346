(function() {
    'use strict';

    angular
        .module('gestioneAttiApp')
        .factory('JhiMetricsService', JhiMetricsService);

    JhiMetricsService.$inject = ['$rootScope', '$http', 'toastr', '$translate'];

    function JhiMetricsService ($rootScope, $http, toastr, $translate) {
        var service = {
            getMetrics: getMetrics,
            threadDump: threadDump
        };

        return service;

        function getMetrics () {
            return $http.get('management/metrics').then(function (response) {
                return response.data;
            }, onError);
        }

        function threadDump () {
            return $http.get('management/dump').then(function (response) {
                return response.data;
            }, onError);
        }

        function onError(error) {
            if(error.status === 403){
                toastr.error($translate.instant('error.access.external.forbidden'));
            } else {
                toastr.error(error.data);
            }
        }

    }
})();
