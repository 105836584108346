/**
 * Created by Michele Romani on 27/06/2017.
 */
angular.module('gestioneAttiApp').directive('formToolbar',  function () {
    return {
        restrict: "E",
        scope: {
        },
        controller: ["$scope",
            function ($scope) {
                var vm = this;
            }],
        templateUrl: 'app/components/formToolbar/formToolbar.html'
    }
});
